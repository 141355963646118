import Config from '@config'

/**
 * 防止刷新数据丢失，做一个缓存处理
 */
export function getMapTypeInfo() {
  if (Config.MAP_TYPE) {
    // console.log("默认用户信息变量存值=>")
    return Config.MAP_TYPE
  } else if (sessionStorage.getItem('configMapTypeInfo')) {
    // console.log("默认缓存的用户信息存值=>")
    return sessionStorage.getItem('configMapTypeInfo')
  } else {
    console.log('缓存地图类型异常')
    return Config.MAP_TYPE
  }
}

/**
 * 选择图片，返回Data URL字符串（仅供测试，请不要投入生产使用）
 */
export function chooseImage() {
  return new Promise<string>((resolve, reject) => {
    const handleChange = (event) => {
      if (event.target.files.length > 0) {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.onloadend = () => {
          resolve(reader.result as string)
          inputElement.removeEventListener('change', handleChange)
          // inputElement.parentElement.removeChild(inputElement);
        }
        reader.readAsDataURL(file)
      }
    }
    const inputElement = document.createElement('input')
    inputElement.type = 'file'
    inputElement.accept = 'image/*'
    inputElement.style.display = 'none'
    inputElement.addEventListener('change', handleChange, false)
    // document.body.appendChild(input);
    inputElement.click()
  })
}

/**
 * 选择视频
 */
export function chooseVideo() {
  return new Promise<string>((resolve, reject) => {
    const handleChange = (event) => {
      if (event.target.files.length > 0) {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.onloadend = () => {
          resolve(reader.result as string)
          inputElement.removeEventListener('change', handleChange)
          // inputElement.parentElement.removeChild(inputElement);
        }
        reader.readAsDataURL(file)
      }
    }
    const inputElement = document.createElement('input')
    inputElement.type = 'file'
    inputElement.accept = 'video/*'
    inputElement.style.display = 'none'
    inputElement.addEventListener('change', handleChange, false)
    // document.body.appendChild(input);
    inputElement.click()
  })
}

/**
 * 解析Data URL
 */
export function parseDataURL(dataURL: string = '') {
  const [, format = '', bodyData = ''] =
    /data:image\/(\w+);base64,(.*)/.exec(dataURL) || []
  return {
    format,
    bodyData,
  }
}

/**
 * 将豆芽拍照返回的 base64 字符串处理成 Blob
 */
export function base64ImageToBlob(base64: string): Blob {
  const binary = atob(base64)
  const view = new Uint8Array(binary.length)
  for (let i = 0; i < view.length; i++) {
    view[i] = binary.charCodeAt(i)
  }
  return new Blob([view], { type: 'image/jpeg' }) // 豆芽返回的图片实际为 JPEG 格式
}

/**
 * 转换数字为货币形式（从万开始格式化）
 * @param numStr string
 * @param point 保留几位小数 （默认2）
 * @return [numberStr, formatTypeStr]
 */
export function getMoneyStrByNumber(numStr = '', point = 2) {
  let moneyStr = numStr
  if (isNaN(Number(moneyStr))) {
    return [moneyStr]
  }
  moneyStr = moneyStr.toString().split('.')[0]
  if (moneyStr.length < 5) {
    return [moneyStr]
  }
  point = point + 1
  const baseCount = 10 ** (point - 1)
  if (moneyStr.length >= 5 && moneyStr.length <= 8) {
    let theMoney = 0
    const decimal = moneyStr.substring(
      moneyStr.length - 4,
      moneyStr.length - 4 + point
    )
    theMoney = parseFloat(
      parseInt((Number(moneyStr) / 10000).toString()) + '.' + decimal
    )
    return [`${Math.round(theMoney * baseCount) / baseCount}`, '万']
  } else if (moneyStr.length > 8) {
    let theMoney = 0
    const decimal = moneyStr.substring(
      moneyStr.length - 8,
      moneyStr.length - 8 + point
    )
    theMoney = parseFloat(
      parseInt((Number(moneyStr) / 100000000).toString()) + '.' + decimal
    )
    return [`${Math.round(theMoney * baseCount) / baseCount}`, '亿']
  }
}
