/*
 * @Author: tzz
 * @LastEditors: tzz
 * @Description:
 * @Date: 2019-03-20 11:01:04
 * @LastEditTime: 2019-04-03 17:59:41
 */
import * as React from 'react'
// import { Icon } from 'antd-mobile';
import { IconEX, Tag } from '@components'
import './index.less'

interface IProps {
  leftIcon?: string
  title: string
  subTitle?: string
  tagName?: string
  rightIcon?: string
  rightName?: string
  onRightClick?: () => void
  rightVisible?: boolean
  themeColor?: string
  rightView?: JSX.Element
}

interface IStates {}

export default class LabelBar extends React.Component<IProps, IStates> {
  static defaultProps = {
    leftIcon: 'left',
    title: '',
    subTitle: '',
    tagName: '',
    rightIcon: 'right',
    rightName: '明细',
    rightVisible: true,
    themeColor: '#3D8BF7',
    rightView: null
  }

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="title">
        <div className="left">
          {this.props.leftIcon && <IconEX type={this.props.leftIcon} />}
          <span className="title-name mgleft">{this.props.title}</span>
          <span className="sub-title-name mgleft">{this.props.subTitle}</span>
          {this.props.tagName && <Tag tagName={this.props.tagName} />}
        </div>
        {this.props.rightView || (
          <div
            className="right"
            onClick={_ => this.props.onRightClick()}
            style={{ display: this.props.rightVisible ? '' : 'none' }}
          >
            <span
              className="right-name"
              style={{ color: this.props.themeColor }}
            >
              {this.props.rightName}
            </span>

            <IconEX
              type="right"
              style={{ color: this.props.themeColor, height: '0.26rem' }}
            />
          </div>
        )}
      </div>
    )
  }
}
