import * as React from 'react'
import './index.less'
import { IconEX } from '@components'
interface IShopInfo {
  storeName: string
  storeCode: string
}
interface IProps {
  shopList?: IShopInfo[]
  onSelect?: (p) => void
  nowShop?: {
    storeName: string
    storeCode: string
  }
}

interface IState {
  isShowList: boolean
  nowShop: {
    storeName: string
    storeCode: string
  }
}

export default class ShopChooseBar extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)

    let defaultShop = this.props.shopList.find(
      item => item.storeCode === this.props.nowShop.storeCode
    )
    if (defaultShop) {
      this.state = {
        nowShop: defaultShop,
        isShowList: false
      }
    } else {
      this.state = {
        nowShop: { storeName: '', storeCode: '' },
        isShowList: false
      }
    }
  }

  showList(type) {
    this.setState({
      isShowList: type
    })
  }
  clickShop(v) {
    this.setState({ nowShop: v, isShowList: false })
    this.props.onSelect && this.props.onSelect(v)
  }

  render() {
    // console.log('--render ShopChooseBar--')
    // console.log('--render ShopChooseBar--props', this.props)
    // console.log('--render ShopChooseBar--state', this.state)
    //只有一个店铺时，不显示选择店铺,不包含所有[{storeName:'test店铺',storeCode:'0001'}]
    //多个店铺时，包含所有[{storeName:'所有',storeCode:'-1'},{storeName:'test店铺',storeCode:'0001'},{storeName:'test店铺',storeCode:'0001'}]
    if (this.props.shopList.length === 1 || this.props.shopList.length === 0) {
      return null
    }

    let shops = this.props.shopList.map((v: any, i) => {
      return (
        <div
          className="shop-one"
          key={i}
          onClick={this.clickShop.bind(this, v)}
        >
          <div
            className={`shops-name ${
              v.storeCode == this.state.nowShop.storeCode
                ? 'shops-name-check'
                : ''
            }`}
          >
            {v.storeName}
          </div>
          <i
            className={`shops-check ${
              v.storeCode == this.state.nowShop.storeCode ? '' : 'hide'
            }`}
          />
        </div>
      )
    })

    return (
      <div className="shops-bar">
        <div
          onClick={this.showList.bind(this, false)}
          className={`shops-mask ${
            this.state.isShowList == true ? '' : 'hide'
          }`}
        />
        <div
          className={`shops-now ${
            this.state.isShowList == true ? 'bor-b' : ''
          }`}
          onClick={this.showList.bind(this, true)}
        >
          <div className="shops-icon">
            <IconEX type="dianpu" />
          </div>
          <p className="shops-name">{this.state.nowShop.storeName}</p>
          <i className="triangle" />
        </div>
        <div
          className={`shops-list ${
            this.state.isShowList == true ? '' : 'hide'
          }`}
        >
          {shops}
        </div>
      </div>
    )
  }
}
