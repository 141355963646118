/*
 * @Author: tzz
 * @LastEditors: tzz
 * @Description:
 * @Date: 2019-03-20 11:01:04
 * @LastEditTime: 2019-04-03 17:59:41
 */
import * as React from 'react'
import './index.less'

import noEmpty from '@assets/images/no-empty.png'

interface IProps {
  title: string
  subtitle: string
  imageSrc: string
}

interface IStates {
  contentHeight: number
}

export default class Index extends React.Component<IProps, IStates> {
  static defaultProps = {
    title: '',
    imageSrc: noEmpty,
    subtitle: '',
  }

  contentRef: any

  constructor(props) {
    super(props)
    this.state = {
      contentHeight: 0,
    }
    this.contentRef = React.createRef()
  }

  componentDidMount() {
    // console.log(' --- window', window.screen.availHeight)
    // console.log(' --- contentRef', this.contentRef.current.offsetTop)
    this.setState({
      contentHeight:
        window.screen.availHeight - this.contentRef.current.offsetTop - 80,
    })
  }

  render() {
    return (
      <div
        className="palceholder-view"
        ref={this.contentRef}
        style={{ height: `${this.state.contentHeight}px` }}
      >
        <img src={this.props.imageSrc} className="palceholder-view-image"></img>
        <span className="palceholder-view-title">{this.props.title}</span>
        <span className="palceholder-view-subtitle">{this.props.subtitle}</span>
        {this.props.title === '您暂无权限访问' ? (
          <div className="placeholder-view-tips">
            当前超经用户无角色或无店铺类型，无法登录，请联系总部配置角色和店铺类型后即可登录！
          </div>
        ) : null}
      </div>
    )
  }
}
