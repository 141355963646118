import joinstorepic from '@assets/images/jiamengdianfazhan@2x.png'
import { baseAllState } from '@/stores'
import { useSnapshot } from 'valtio'
import './index.less'

interface IProps {
  titleName: string
}

/** 加盟店发展 */
export default function JoinStoreDevelop({ titleName }: IProps) {
  const baseAllInfo = useSnapshot(baseAllState)

  return baseAllInfo?.storeDevelopVO ? (
    <div className="join-store-develop">
      <div className="top-wrap">
        <div className="top-left">
          <img src={joinstorepic} className="left-image" />
          <span className="left-title">
            {titleName ? titleName : '加盟店发展'}
          </span>
        </div>
      </div>
      <div className="main-cont">
        <div className="total-box">
          <span className="name">累计开店数</span>
          <span className="num">
            {baseAllInfo?.storeDevelopVO?.cumulativeNumberOfStores}家
          </span>
        </div>
        <div className="section-box">
          <div className="section">
            <span className="sec-num">
              {baseAllInfo?.storeDevelopVO?.effectiveReserveStoreNum}
            </span>
            <span className="sec-name">有效储备门店数</span>
          </div>
          <div className="section section2">
            <span className="sec-num">
              {baseAllInfo?.storeDevelopVO?.inBusinessStoreNum}
            </span>
            <span className="sec-name">在营业门店数</span>
          </div>
          <div className="section section3">
            <span className="sec-num">
              {baseAllInfo?.storeDevelopVO?.closedStoreNum}
            </span>
            <span className="sec-name">已闭店数</span>
          </div>
        </div>
      </div>
    </div>
  ) : null
}
