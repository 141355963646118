import ShopNoData from '../shop-no-date'
import systemPicNoData from '@assets/images/weikaitong@3x2.png'
import { useEffect, useRef, useState } from 'react'
import { Router, Sa, util } from '@kits'
import { ScrollableContainer } from '@components'
import { IMessageMeta } from '@/views/home/types'
import {
  confirmRead,
  querySysNotices,
  updateTabNoticeCount,
} from '@/views/home/HomeService'
import './index.less'

interface IProps {
  onUpdateSysUnread: () => Promise<void>
}

/** 系统消息页面 */
export default function SystemMessage({ onUpdateSysUnread }: IProps) {
  const pageNum = useRef(1)
  const systemStoreName = useRef('')
  const [loading, setLoading] = useState(false)
  const [totalPageCount, setTotalPageCount] = useState(0)
  const [data, setData] = useState<IMessageMeta[]>([])

  async function fetchSysNotices(isFirst = true) {
    if (isFirst) setLoading(true)
    const { totalPageCount: count, dataList } = await querySysNotices(
      pageNum.current
    )
    setTotalPageCount(count)
    setData((prev) => (isFirst ? [...dataList] : [...prev, ...dataList]))
    setLoading(false)
  }

  async function goToNoticeDetail(item) {
    Sa.sendClickData('0qx5wco', {
      eleid: '1',
      eletitle: `查看详情`,
      ...util.saRoleParamClickObj(),
    })

    if (!item && !item.messageContent) return

    if (item.messageContent.indexOf('对您的巡店服务进行了评价') !== -1) {
      systemStoreName.current = item.messageContent.slice(
        0,
        item.messageContent.indexOf('对您的巡店')
      )
    }

    const readed = await confirmRead(item.messageId)
    if (readed) {
      updateTabNoticeCount()
      onUpdateSysUnread()
      setData((prev) => {
        const items = [...prev]
        items.forEach((v) => {
          if (v.messageId === item.messageId) {
            v.readFlag = '1'
          }
        })
        return items
      })
    }

    switch (item.msgType) {
      case '1': // '评价通知'
        Router.Open('/wait-to-do/wait-estimate-current', {
          inspectId: item.outRelationCode,
          storeName: systemStoreName.current,
        })
        break
      case '2': // '整改通知'
        Router.Open(`/wait-to-do/wait-to-correct-detail`, {
          inspectId: item.outRelationCode,
          storeCode: item.storeCode,
        })
        break
      case '3': // '巡检抄送通知'
        Router.Open(`/inspection/region-info-newdetail`, {
          inspectId: item.outRelationCode,
          noNeedFooter: '1',
        })
        break
      default:
        break
    }
  }

  async function onReachBottom() {
    if (
      data.length > 0 &&
      totalPageCount > 1 &&
      pageNum.current < totalPageCount
    ) {
      pageNum.current += 1
      fetchSysNotices(false)
    }
  }

  function getMessageName(msgType) {
    if (msgType === '1') {
      return '收到一条门店评价~'
    }
    if (msgType === '2') {
      return '超期未整改警告！'
    }
    if (msgType === '3') {
      return '收到一份巡检报告~'
    }
  }

  useEffect(() => {
    Sa.update(`pgtitle=系统通知页;pageid=aut1YOAAaA;${util.saRoleParam()}`)
    fetchSysNotices(true)
    updateTabNoticeCount()
  }, [])

  return (
    <div className="message-tab-view">
      <ScrollableContainer
        style={{ height: 'calc(100vh - 1.8rem)' }}
        onReachBottom={onReachBottom}
      >
        {data.map((item, idx) => {
          return (
            <div
              key={idx}
              className="system-box"
              onClick={() => goToNoticeDetail(item)}
            >
              <div className="system-time">{item.createTime}</div>
              <div className="system-essay">
                <div className="tool-box">
                  <span
                    className={
                      item.readFlag === '0' ? 'tool-dotActive' : 'tool-dot'
                    }
                  />
                  <span className="tool-stext">
                    {getMessageName(item.msgType)}
                  </span>
                </div>
                <div className="tool-text">{item.messageContent}</div>
                <div className="footer-right-text">点击查看&gt;&gt;</div>
              </div>
            </div>
          )
        })}
        {!loading && !data.length && (
          <ShopNoData textTag="您还未收到系统消息" imgUrl={systemPicNoData} />
        )}
      </ScrollableContainer>
    </div>
  )
}
