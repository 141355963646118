/*
 * @Author: zk
 * @LastEditors: zk
 * @Description:
 * @Date: 2019-03-27 10:09:00
 * @LastEditTime: 2019-04-15 10:37:44
 */
import React from 'react'
import './index.less'

interface IProps {
  arrList?: { title: string; code: string; selected?: boolean }[]
  onSelect?: (code, index) => void
}
//TODO:name
export default class RadioBar extends React.Component<IProps, any> {
  static defaultProps = {
    arrList: [
      { title: '手机1', code: '1', selected: true },
      { title: '手机2', code: '2', selected: false },
      { title: '手机3', code: '3', selected: true },
      { title: '手机4', code: '4', selected: true },
      { title: '手机5', code: '5', selected: true },
      { title: '手机6', code: '1', selected: true },
      { title: '手机7', code: '2', selected: false },
      { title: '手机8', code: '3', selected: true },
      { title: '手机9', code: '4', selected: true },
      { title: '手机10', code: '5', selected: true }
    ],
    onSelect: (e, i) => {
      //console.log(e);
    }
  }
  constructor(props) {
    super(props)
    this.state = {
      selectIndex: 0 //记录高亮状态
    }
  }
  componentDidMount() {
    //初始化高亮状态,无值时默认第一个
    if (this.props.arrList.length > 0) {
      for (let i = 0; i < this.props.arrList.length; i++) {
        if (this.props.arrList[i].selected) {
          this.setState({
            selectIndex: i
          })
          this.props.onSelect(this.props.arrList[i].code, i)
          return
        }
      }
      // a[0] = true;
      // this.setState({
      //   arrState: a
      // });
      // this.props.onSelect(this.props.arrList[0].code);
    }
  }
  //点击bar
  _onSelset = (i, e) => {
    let a =
      e.target.getBoundingClientRect().left +
      e.target.getBoundingClientRect().right
    let j: number = 0
    let timer = setInterval(function() {
      if (j > 9) {
        clearInterval(timer)
      } else {
        document.getElementsByClassName('radio-bar1')[0].scrollLeft +=
          (a - 375) / 20
        j++
      }
    }, 30)
    this.setState({
      selectIndex: i
    })
    this.props.onSelect(this.props.arrList[i].code, i)
  }
  render() {
    return (
      <div className="radio-bar">
        <ul className="radio-bar1">
          {this.props.arrList.map((v, i) => {
            return (
              <li
                key={i}
                onClick={this._onSelset.bind(this, i)}
                className={this.state.selectIndex == i ? 'active' : ''}
              >
                {v.title}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}
