/*
 * @Author: tzz
 * @LastEditors: tzz
 * @Description:
 * @Date: 2019-03-21 11:52:27
 * @LastEditTime: 2019-04-11 11:23:15
 */

import * as React from 'react'
import './index.less'

interface IProps {
  tagName: string
  bgColor?: string
  fontColor?: string
  className?: string
}

interface IState {}

export default class Tag extends React.Component<IProps, IState> {
  static defaultProps = {
    tagName: '',
    bgColor: '#E3EEFD',
    fontColor: '#3D8BF7',
    className: null
  }

  render() {
    return (
      <div
        className={`tag ${this.props.className}`}
        style={{ background: this.props.bgColor }}
      >
        <span className="text" style={{ color: this.props.fontColor }}>
          {this.props.tagName}
        </span>
      </div>
    )
  }
}
