import React, { useState, useEffect } from 'react'
import styles from './index.module.less'
import { IconEX } from '@components'
import { Observable, BehaviorSubject, Subject, concat, merge } from 'rxjs'
import {
  map,
  withLatestFrom,
  filter,
  debounceTime,
  take,
  mapTo,
  combineLatest,
  startWith,
  tap
} from 'rxjs/operators'
import { useEventCallback, useObservable } from 'rxjs-hooks'

/** 控制按钮 [展开/收起] 类型 */
enum btnType {
  /** 展开详情 */
  close,
  /** 收起详情 */
  open
}

/** 排序类型 */
export enum SortType {
  /** 降序 */
  dasc = -1,
  /** 升序 */
  asc = 1,
  /** 未设置 */
  none = 0
}

/** 排序类型对应的图标 */
const sortIcon = {
  '-1': 'paixu-down',
  '0': 'paixu',
  '1': 'paixu-up'
}

interface IColumn {
  /** 列头主标题 */
  title: string
  /** 列头副标题 */
  subtitle?: string
  /** 列头副对应的 key */
  subtitleKey?: string
  /** 列宽度 */
  width?: string | number
  /** 列数据在数据项中对应的 key */
  dataIndex: string
  /** React 需要的 key，如果已经设置了唯一的 dataIndex，可以忽略这个属性 */
  key: string
  /** 生成复杂数据的渲染函数，参数分别为当前行的值，当前行数据，行索引 */
  // render?: (text: string, record: any, index: number) => JSX.Element
  render?: (item: any, record: any, index: number) => JSX.Element
  /** 排序函数，本地排序使用一个函数(参考 Array.sort 的 compareFunction)，需要服务端排序可设为 true */
  sorter?: (a: any, b: any) => number
  /** 排序状态 */
  sortType?: SortType
}

interface IProps {
  /** 是否处于 loading 状态 */
  loading: boolean
  /** 表格列的配置描述 */
  columns$: Observable<IColumn[]>
  /** 表格的数据源 数据数组 */
  dataSource: any[][]
  /** 当前排序状态状态 */
  currentSortType$: Subject<SortType>
  /** 点击行的回调 */
  onRowClick?: (data: any, outerIndex: number, index: number) => void
}

interface IOpenCloseBtnProps {
  /** 按钮类型 */
  type: btnType
  /**  */
  style?: React.CSSProperties
  /** 按钮点击事件回调 */
  onClick: () => void
}
/**
 * [展开/收起] 按钮组件
 * @param { btnType } type
 *
 */
const OpenCloseBtn: React.SFC<IOpenCloseBtnProps> = props => {
  const { type, style, onClick } = props
  return (
    <div style={style} className={`${styles['btn-wrapper']}`}>
      <button onClick={onClick} className={`${styles['open-close-btn']}`}>
        <span>{type === btnType.open ? '展开详情' : '收起详情'}</span>
        <IconEX
          className={`${styles['btn-icon']}`}
          type={type === btnType.open ? 'down' : 'up'}
        />
      </button>
    </div>
  )
}

const TableGroup: React.SFC<IProps> = props => {
  const { columns$, dataSource, currentSortType$, loading, onRowClick } = props

  /** 表格外容器 */
  const [tableWrapperEle$] = useState(new Subject<Element>())
  /** 上一次的排序列下标 */
  const [previosuColumnIndex$] = useState(new BehaviorSubject(0))
  const { dataSourceKeys, columns } = useObservable(
    () =>
      columns$.pipe(
        map(columns => ({
          dataSourceKeys: columns.map(columns => ({
            key: columns.dataIndex || columns.key,
            subKey: columns.subtitleKey
          })),
          columns
        }))
      ),
    {
      dataSourceKeys: [] as { key: string; subKey: string }[],
      columns: [] as IColumn[]
    }
  )

  const currentSortType = useObservable(() => currentSortType$, SortType.asc)

  /** 排序之后的数据 */
  const [sortClick, sortedDataSource] = useEventCallback<any, any, any>(
    (
      sortClick$: Observable<[(a, b) => number, number, number]>,
      input$: Observable<[any[][], IColumn[]]>,
      _state$: Observable<any[][]>
    ) => {
      /** 点击排序 */
      const sortedDataByClick$ = sortClick$.pipe(
        startWith([]),
        withLatestFrom(input$, currentSortType$),
        map(([[sorter, sortType], [dataSource], currentSortType]) => {
          const result = sortType
            ? dataSource
                .sort((a, b) => sortType * currentSortType * sorter(a, b))
                .slice()
            : dataSource.slice()
          currentSortType$.next(currentSortType)
          return result
        })
      )

      /** 数据更新排序 */
      const sortedDataByDataChange$ = input$.pipe(
        withLatestFrom(currentSortType$),
        map(([[dataSource, columns], currentSortType]) => {
          const shouldColumnSortedFirstIndex = columns.findIndex(
            column => !!column.sorter
          )
          const columnSortedFirstIndex = columns.findIndex(
            column => column.sortType === SortType.dasc
          )
          const columnSortedFirst = columns[columnSortedFirstIndex]
          if (
            columnSortedFirstIndex === shouldColumnSortedFirstIndex &&
            columnSortedFirst
          ) {
            previosuColumnIndex$.next(columnSortedFirstIndex)
            const result = columnSortedFirst.sortType
              ? dataSource
                  .sort(
                    (a, b) =>
                      columnSortedFirst.sortType *
                      currentSortType *
                      columnSortedFirst.sorter(a, b)
                  )
                  .slice()
              : dataSource.slice()
            currentSortType$.next(currentSortType)
            return result
          } else {
            return dataSource
          }
        })
      )
      sortClick$
        .pipe(withLatestFrom(currentSortType$, previosuColumnIndex$))
        .subscribe(
          ([
            [_sorter, _sortType, index],
            currentSortType,
            previosuColumnIndex
          ]) => {
            if (index !== previosuColumnIndex) {
              currentSortType$.next(SortType.asc)
              previosuColumnIndex$.next(index)
            } else {
              currentSortType$.next(
                currentSortType === SortType.asc ? SortType.dasc : SortType.asc
              )
            }
          }
        )
      return merge(sortedDataByClick$, sortedDataByDataChange$)
    },
    [],
    [dataSource, columns]
  )

  /** 动态获取列表外容器宽度 */
  const width = useObservable(
    (_state$: Observable<number>) =>
      tableWrapperEle$.pipe<any, any>(
        filter(Boolean),
        map<Element, number>(
          tableWrapperEle => tableWrapperEle.getBoundingClientRect().width
        )
      ),
    0
  )

  /** 重新定位 小标题的位置 */
  const [scrollCallback, translateX] = useEventCallback(
    (event$: Observable<React.UIEvent<HTMLDivElement>>) =>
      event$.pipe(map(event => event.target['scrollLeft'])),
    0
  )

  return (
    <div
      className={`${styles['table-wrapper']}`}
      ref={instance => tableWrapperEle$.next(instance)}
      onScroll={scrollCallback}
    >
      <table
        cellSpacing={0}
        cellPadding={0}
        className={`
          ${styles['table']}
          ${
            !columns.some(column => !!column.width) ? styles['table-fixed'] : ''
          }
        `}
      >
        <thead className={`${styles['table-thead']}`}>
          <tr className={`${styles['table-tr']}`}>
            {columns.map((column, i) => (
              <th
                className={`
                  ${styles['table-th']}
                  ${column.sorter ? styles['icon-padding'] : ''}
                `}
                key={column.dataIndex || column.key}
              >
                <span
                  style={{ minWidth: column.width || 0 }}
                  className={`${styles['table-cell']}`}
                >
                  <span className={`${styles['table-cell']}`}>
                    <p>{column.title}</p>
                    <p className={`${styles['th-subtitle']}`}>
                      {column.subtitle}
                    </p>
                    {column.sorter && (
                      <IconEX
                        onClick={() => {
                          if (!loading) {
                            columns.forEach(
                              column => (column.sortType = SortType.none)
                            )
                            column.sortType = SortType.dasc
                            sortClick([column.sorter, +column.sortType, i])
                          }
                        }}
                        className={`${styles['icon-sort']}`}
                        type={sortIcon[+column.sortType * currentSortType]}
                      />
                    )}
                  </span>
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={`${styles['table-tbody']}`}>
          {sortedDataSource.map((data, i) => (
            <tr key={i} className={`${styles['table-tr']}`}>
              <td
                colSpan={dataSourceKeys.length}
                className={`${styles['table-td']}`}
              >
                <GroupTableTd
                  sortedDataSource={sortedDataSource}
                  groupDataSource={data}
                  columns={columns}
                  width={width}
                  dataSourceKeys={dataSourceKeys}
                  translateX={translateX}
                  onRowClick={onRowClick}
                  outerIndex={i}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

/** Group 组件入参 */
interface IGroupTableTdProps {
  /** 内部编组的数据集合 */
  groupDataSource: any[]
  /** 表格列的配置描述 */
  columns: IColumn[]
  /** 列表外容器宽度 */
  width: number | string
  /** 头部列表的 key */
  dataSourceKeys: { key: string; subKey: string }[]
  /** 用于固定组标题的位置 */
  translateX: number
  /** 外部下标 */
  outerIndex: number
  /** 排序后的数组 */
  sortedDataSource: any[][]
  /** 点击行的回调 */
  onRowClick?: (data: any, outerIndex: number, index: number) => void
}
const GroupTableTd: React.SFC<IGroupTableTdProps> = props => {
  const {
    sortedDataSource,
    groupDataSource,
    columns,
    width,
    dataSourceKeys,
    translateX,
    onRowClick,
    outerIndex
  } = props
  /** 第一个内嵌组外容器 */
  const [firstGroupWrapperEle$] = useState(new Subject<Element>())
  /** [展开/收起] 按钮切换 */
  const [myBtnTypeChange, { myBtnType, wrapperHeight }] = useEventCallback(
    (btnType$: Observable<btnType>) => {
      const init$ = firstGroupWrapperEle$.pipe(
        filter(Boolean),
        debounceTime(100),
        mapTo(btnType.open),
        take(1)
      )
      return concat(init$, btnType$).pipe(
        withLatestFrom(firstGroupWrapperEle$),
        filter(([_, firstGroupWrapperEle]) => !!firstGroupWrapperEle),
        map(([myBtnType, firstGroupWrapperEle]) => {
          const height = firstGroupWrapperEle.getBoundingClientRect().height
          return {
            myBtnType,
            wrapperHeight: myBtnType === btnType.close ? 'auto' : `${height}px`
          }
        })
      )
    },
    {
      myBtnType: btnType.open,
      wrapperHeight: '0px'
    }
  )

  useEffect(() => myBtnTypeChange(1), [sortedDataSource])
  return (
    <>
      <span
        className={`${styles['group-wrapper-container']}`}
        style={{ height: groupDataSource.length > 1 ? wrapperHeight : 'auto' }}
      >
        {groupDataSource.map((item, index) => (
          <div
            ref={instance =>
              index === 0 && firstGroupWrapperEle$.next(instance)
            }
            className={`
              ${styles['group-wrapper']}
            `}
            key={item.key}
            onClick={() =>
              onRowClick && onRowClick(groupDataSource, outerIndex, index)
            }
          >
            <p
              style={{ width, transform: `translateX(${translateX}px)` }}
              className={`${styles['group-name']}`}
            >
              <span>{item.name}</span>
            </p>
            <div className={`${styles['group-content']}`}>
              {dataSourceKeys.map((keyObj, i) => (
                <div
                  className={`
                    ${styles['group-item']}
                    ${columns[i].sorter ? styles['icon-padding'] : ''}
                  `}
                  style={{ width: columns[i].width || 0 }}
                  key={keyObj.key}
                >
                  {columns[i].render ? (
                    columns[i].render(item, columns[i], i)
                  ) : (
                    <>
                      <p className={`${styles['group-item-value']}`}>
                        {item[keyObj.key] || ''}
                      </p>
                      <p className={`${styles['group-item-subtitle']}`}>
                        {item[keyObj.subKey] || ''}
                      </p>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </span>
      {groupDataSource.length > 1 && (
        <OpenCloseBtn
          onClick={() =>
            myBtnTypeChange(
              myBtnType === btnType.open ? btnType.close : (btnType.open as any)
            )
          }
          style={{ width, transform: `translateX(${translateX}px)` }}
          type={myBtnType}
        />
      )}
    </>
  )
}

export default TableGroup
