import { Component } from 'react'
import RunningLion from '@assets/images/runningLion.gif'
import './index.less'

/** 加载中区块 */
export default class DetailNoDataView extends Component {
  constructor(param) {
    super(param)
  }

  render() {
    return (
      <div className="detail-no-data-view">
        <img src={RunningLion} alt="" className="running-lion" />
        <div className="no-data-title">玩命加载中，请稍后...</div>
      </div>
    )
  }
}
