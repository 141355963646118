/*
 * @Author: 杨建新(20014264)
 * @Email: 20014264@cnsuning.com
 * @Date: 2020-09-14 11:22:51
 * @Last Modified by:   杨建新(20014264)
 * @Last Modified time: 2020-09-14 11:22:51
 * @Description: 地图右下角放大缩小工具
 */

import React, { Component } from 'react'
import { Icon } from 'antd-mobile'
import './index.less'
import maptooladd from '@assets/images/maptooladd.png'
import maptoolreduce from '@assets/images/maptoolreduce.png'

interface IIProps{
  mapInstance?:any
}

interface IState {
  
}

export default class ZoomTool extends Component<IIProps, IState> {
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  
  render() {
    return (
      <div className="zoom-tool-wrap">
        <div onClick={this.zoomInHandler}>
          <img src={maptooladd} alt="" />
        </div>
        <div onClick={this.zoomOutHandler}>
          <img src={maptoolreduce} alt="" />
        </div>
      </div>
    )
  }

  zoomInHandler=()=>{
    //放大
    if(this.props.mapInstance){
      this.props.mapInstance.zoomIn()
    }
  }

  zoomOutHandler=()=>{
    //缩小
    if(this.props.mapInstance){
      this.props.mapInstance.zoomOut()
    }
  }
}
