import { useEffect, useState } from 'react'
import { Icon } from 'antd-mobile'
import dayjs from 'dayjs'
import { Router, Sa, util } from '@kits'
import { IVsaCard, queryVsaSliceSummary } from '@/views/vsa-report/webapi'
import { formatValue, getFirstFillOtherZero } from '@/views/vsa-report/util'
import tipIcon from '@/assets/images/vsa_index_icon.png'
import { currentAreaCode, getGlobalUserConfig, globalUserState } from '@/stores'
import { Cleanup } from '@/stores/util'
import { subscribeKey } from 'valtio/utils'
import './VsaPrototype.scss'

let unWatchRole: Cleanup
let unWatchSliceArea: Cleanup

/** VSA样机数据 */
const VsaPrototype = () => {
  const [data, setData] = useState<IVsaCard>(null)

  if (!unWatchRole) {
    unWatchRole = subscribeKey(globalUserState, 'selectRole', () => {
      // 用于首页角色切换后刷新状态
      fetchData()
    })
  }

  if (!unWatchSliceArea) {
    unWatchSliceArea = subscribeKey(currentAreaCode, 'value', () => {
      // 用于首页片区切换后刷新状态
      fetchData()
    })
  }

  async function fetchData() {
    const currentMonth = dayjs().format('YYYYMM')
    const userState = getGlobalUserConfig()

    queryVsaSliceSummary(
      currentAreaCode.value || userState.sliceArea?.sliceAreaCode,
      currentMonth
    ).then((res) => {
      setData((res || {}) as IVsaCard)
    })
  }

  function goToDetail() {
    Sa.sendClickData('858zqn0', {
      pageid: 'ahj6nBaAAa',
      eleid: '1',
      eletitle: `JSAV数据-查看详情`,
      ...util.saRoleParamClickObj(),
    })

    const userState = getGlobalUserConfig()
    Router.Push(
      `/vsa-report/prototype-data?sliceCode=${
        userState.sliceArea?.sliceAreaCode || ''
      }`
    )
  }

  useEffect(() => {
    fetchData()
    return () => {
      if (unWatchRole) {
        unWatchRole()
        unWatchRole = null
      }
      if (unWatchSliceArea) {
        unWatchSliceArea()
        unWatchSliceArea = null
      }
    }
  }, [])

  return !data ? null : (
    <div className="vsa-prototype-container">
      <div className="header-container">
        <div className="header-left">
          <img
            className="left-img"
            src="https://oss.suning.com/srcfews/lsypc/icon/vsa_icon.png"
          />
          <span className="left-title">JSAV数据</span>
        </div>
        <div className="header-right" onClick={goToDetail}>
          <span className="right-title">查看详情</span>
          <Icon type="right" size="xs" color="#4A90E2" />
        </div>
      </div>

      <div className="ranking-container">
        <div className="ranking-left">
          <img
            className="left-img"
            src="https://oss.suning.com/srcfews/lsypc/icon/paihangbagn_icon.png"
          />
          <span className="ranking-name">全国JSAV提成片区排名</span>
        </div>
        <div className="ranking-right">
          <span className="right-num1">{formatValue(data?.ranking)}</span>
          <span className="right-num2">
            /{formatValue(data?.totalRankingNum)}
          </span>
        </div>
      </div>
      <div className="data-container">
        <div className="data-item">
          <span className="data-value">
            {formatValue(data?.slicePrincipalCommission)}
          </span>
          <span className="data-name">当月提成(元)</span>
        </div>

        <div className="data-item">
          <span className="data-value value-black">
            {getFirstFillOtherZero(data?.meanCommission)}
          </span>
          <span className="data-name">TOP片区</span>
          <span className="data-name2">平均提成(元)</span>
        </div>

        <div className="data-item">
          <span className="data-value value-red">
            {formatValue(data?.sampleWdbShopCount)}
          </span>
          <span className="data-name">出样未达标</span>
          <span className="data-name2">门店(家)</span>
        </div>
      </div>
      <div className="block-line-box"></div>
      <div className="tips-box">
        <img className="tips-icon" src={tipIcon} />
        以上数据仅为预估值，不考虑兑现系数、大单等影响因素
      </div>
    </div>
  )
}

export default VsaPrototype
