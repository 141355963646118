/*
 * @Author: hannianqi
 * @Date: 2019-03-29 14:09:24
 * @Last Modified by: hannianqi
 * @Last Modified time: 2019-03-29 14:42:03
 *
 * 表格左侧排名
 */

import React from 'react'
import styles from './index.module.less'

interface IProps {
  /** 序号 */
  order: number
}

/**
 * 排序图标
 * @param { IProps } props
 */
const OrderNum: React.SFC<IProps> = props => {
  const { order } = props
  return (
    <div className={`${styles['order-num']}`}>
      <span className={`${styles['order']}`}>{order}</span>
    </div>
  )
}

export default OrderNum
