import * as React from 'react'
import classNames from 'classnames'
import uuidv4 from 'uuid/v4'
import { toCanvas } from './draw-rate'
import './index.less'

interface IPros {
  value: string
  type: string
  stepName: string
  timeTitle: string
}

export default class PerRate extends React.Component<IPros, {}> {
  canvasId: string

  constructor(props) {
    super(props)
    this.canvasId = uuidv4()
  }

  componentDidMount() {
    toCanvas(
      this.canvasId,
      this.props.value,
      this.props.type,
      this.props.timeTitle
    )
  }

  componentWillReceiveProps(props) {
    toCanvas(this.canvasId, props.value, props.type, props.timeTitle)
  }

  render() {
    return (
      <div className="achievement-canvas">
        <div className="canvas-inner">
          <canvas id={this.canvasId} width="250" height="250" />
          <div
            className={classNames('rating', 'modify-tuozhan-rating')}
            style={{ bottom: '42px' }}
          >
            {this._renderStepName()}
          </div>
        </div>
      </div>
    )
  }

  _renderStepName = () => {
    // 可选值：Y1 - 红牌，Y2 - 黄牌，Y3 - 良，Y4 - 良+，Y5 - 优。
    // 红牌
    if (this.props.type === 'Y1' || this.props.type === 'T1') {
      return (
        <span
          className="des-bg"
          style={{ background: 'rgba(246,79,79,0.10)', color: '#F64F4F' }}
        >
          {this.props.stepName}
        </span>
      )
    }
    // 黄牌
    if (this.props.type === 'Y2' || this.props.type === 'T2') {
      return (
        <span
          className="des-bg"
          style={{ background: '#Fef7e5', color: '#F7B400' }}
        >
          {this.props.stepName}
        </span>
      )
    }

    // 其他
    return (
      <span
        className="des-bg"
        style={{ background: 'rgba(51,119,255,0.10)', color: '#3377FF' }}
      >
        {this.props.stepName}
      </span>
    )
  }
}
