/*
 * @Author: 齐云猛
 * @Date: 2019-07-19 10:17:43
 * @Last Modified by: 杨建新(20014264)
 * @Last Modified time: 2022-09-15 09:47:31
 */

import React, { Component } from 'react'
import { Picker } from 'antd-mobile'
import './index.less'
import dateImg from '@assets/images/date.png'

interface Iprops {
  defaultValue: string
  colunm: 2 | 3
  mode?: 'quarter' | 'year' | 'month'
  timepickerAffrim?: (e: {}) => void
  limited?: boolean //月度1，2号到上月
  jiduLimited?: boolean //季度1，2号到上月
}

export default class TimePicker extends Component<Iprops> {
  constructor(props) {
    super(props)
  }

  state = {
    visible: false,
    pickerValue: ''
  }

  /**
   * 解析时间
   * "2019年11月" -> ["2019年", "11月"]
   * "2019年11月" -> ["按年", "2019年", "11月"]
   * "2019一季度" -> ["2019年", "一季度"]
   * "2019一季度" -> ["按季", "2019年", "一季度"]
   */
  parsePickerValue = (str: string) => {
    const yearCharIndex = str.indexOf('年')
    if (yearCharIndex > -1) {
      if (this.props.colunm === 3) {
        return [
          '按年',
          str.slice(0, yearCharIndex + 1),
          str.slice(yearCharIndex + 1)
        ]
      } else if (this.props.colunm === 2) {
        return [str.slice(0, yearCharIndex + 1), str.slice(yearCharIndex + 1)]
      } else {
        return null
      }
    } else if (str.includes('季度')) {
      if (this.props.colunm === 3) {
        return [
          '按季',
          str.slice(0, str.length - 3) + '年',
          str.slice(str.length - 3)
        ]
      } else if (this.props.colunm === 2) {
        return [str.slice(0, str.length - 3) + '年', str.slice(str.length - 3)]
      } else {
        return null
      }
    } else {
      return null
    }
  }

  getChooseDate(v: string[]) {
    if (this.props.colunm === 3) {
      let season = ['一季度', '二季度', '三季度', '四季度']
      let res = {
        timeUnit: v[0] === '按年' ? '1' : v[0] === '按季' ? '2' : '3',
        year: v[1].substring(0, v[1].length - 1),
        quarter: v[2] ? 'Q' + (season.indexOf(v[2]) + 1) : ''
      }
      this.setState({
        pickerValue: v[1] + (v[2] ? v[2] : '')
      })
      this.props.timepickerAffrim && this.props.timepickerAffrim(res)
    } else if (this.props.colunm === 2) {
      this.setState({
        pickerValue: v[0] + v[1]
      })
      this.props.timepickerAffrim &&
        this.props.timepickerAffrim([
          v[0].replace('年', ''),
          v[1].replace('月', '')
        ])
    }
  }

  componentDidMount() {
    if (this.props.defaultValue) {
      if (
        this.props.defaultValue.indexOf('年') > -1 &&
        this.props.defaultValue.indexOf('季度') > -1
      ) {
        this.setState({
          pickerValue: this.props.defaultValue.replace('年', '')
        })
      } else {
        this.setState({
          pickerValue: this.props.defaultValue
        })
      }
    }
  }

  componentDidUpdate(preProps) {
    if (this.props.defaultValue !== preProps.defaultValue) {
      if (
        this.props.defaultValue.indexOf('年') > -1 &&
        this.props.defaultValue.indexOf('季度') > -1
      ) {
        this.setState({
          pickerValue: this.props.defaultValue.replace('年', '')
        })
      } else {
        this.setState({
          pickerValue: this.props.defaultValue
        })
      }
    }
  }

  /**获取picker中的3列数据时的时间列表 */
  fetchTimePickData() {
    let currentDate = new Date()
    let quarter = ['一季度', '二季度', '三季度', '四季度'],
      year = currentDate.getFullYear(),
      currentMonth = currentDate.getMonth(),
      day = currentDate.getUTCDate(),
      dataSource_year = [],
      dataSource_season = []

    //1月1，2号 转成上一年，其他季度首月1，2号转成上季度
    if (
      this.props.jiduLimited &&
      currentMonth % 3 === 0 &&
      (day === 1 || day === 2)
    ) {
      if (currentMonth === 0) {
        year = year - 1
        currentMonth = 11
      } else {
        currentMonth = currentMonth - 1
      }
    }

    for (let i = 0; i < year - 2016; i++) {
      /** 获取年份 要从2017年开始算起 */
      dataSource_year.push({
        label: `${year - i}年`,
        value: `${year - i}年`
      })
      /** 获取年份对应的季度 */
      if (i !== 0) {
        dataSource_season.push({
          label: `${year - i}年`,
          value: `${year - i}年`,
          children: [
            {
              label: '一季度',
              value: '一季度'
            },
            {
              label: '二季度',
              value: '二季度'
            },
            {
              label: '三季度',
              value: '三季度'
            },
            {
              label: '四季度',
              value: '四季度'
            }
          ]
        })
      } else {
        let data = [],
          month = []

        for (let i = 0; i <= Math.floor(currentMonth / 3); i++) {
          data.push({
            label: quarter[i],
            value: quarter[i]
          })
        }
        for (let i = 0; i < currentMonth; i++) {
          month.push({
            label: i + 1 + '月',
            value: i + 1 + '月'
          })
        }
        dataSource_season.push({
          label: `${year - i}年`,
          value: `${year - i}年`,
          children: data
        })
      }
    }

    if (this.props.mode === 'quarter') {
      return [
        {
          label: '按季',
          value: '按季',
          children: dataSource_season
        }
      ]
    } else {
      return [
        {
          label: '按年',
          value: '按年',
          children: dataSource_year
        },
        {
          label: '按季',
          value: '按季',
          children: dataSource_season
        }
      ]
    }
  }

  /** 获取pick中2列数据时的列表数据 从2019年8月算起*/
  fetch2ColunmData() {
    let currentDate = new Date()
    let year = currentDate.getFullYear(),
      currentMonth = currentDate.getMonth() + 1,
      day = currentDate.getUTCDate(),
      dataSource = [],
      dataSource_month = []

    if (this.props.limited) {
      if (day === 1 || day === 2) {
        if (currentMonth === 1) {
          currentMonth = 12
          year = year - 1
        } else {
          currentMonth = currentDate.getMonth()
        }
      }
    }

    let fullyear = [],
      year_2019 = []
    for (let j = 1; j <= 12; j++) {
      fullyear.push({
        label: j + '月',
        value: j + '月'
      })
    }
    for (let k = 8; k <= 12; k++) {
      year_2019.push({
        label: k + '月',
        value: k + '月'
      })
    }

    if (year === 2019) {
      //目前为2019年
      let month = []
      // console.log(currentMonth)
      for (let i = 8; i <= currentMonth; i++) {
        month.push({
          label: i + '月',
          value: i + '月'
        })
      }
      dataSource = [
        {
          label: '2019年',
          value: '2019年',
          children: month
        }
      ]
    } else if (year > 2019) {
      //目前不是2019年
      for (let i = 2019; i <= year; i++) {
        if (i === 2019) {
          dataSource.push({
            label: i + '年',
            value: i + '年',
            children: year_2019
          })
        } else if (year - i > 0 && i !== 2019) {
          dataSource.push({
            label: i + '年',
            value: i + '年',
            children: fullyear
          })
        } else if (year === i) {
          let month = []
          for (let i = 1; i <= currentMonth; i++) {
            month.push({
              label: i + '月',
              value: i + '月'
            })
          }
          dataSource.push({
            label: i + '年',
            value: i + '年',
            children: month
          })
        }
      }
    }
    // console.log(dataSource)
    return dataSource
  }

  render() {
    return (
      <div>
        <Picker
          visible={this.state.visible}
          data={
            this.props.colunm === 2
              ? this.fetch2ColunmData()
              : this.fetchTimePickData()
          }
          value={this.parsePickerValue(this.state.pickerValue)}
          onOk={v => {
            this.getChooseDate.call(this, v)
            this.setState({ visible: false })
          }}
          onDismiss={() => this.setState({ visible: false })}
        >
          <div
            className="selectSeason"
            onClick={() => this.setState({ visible: true })}
          >
            <img src={dateImg} alt="日期" />
            <div className="date">
              <span>{this.state.pickerValue}</span>
            </div>
            <span className="arrow-bottom"></span>
          </div>
        </Picker>
      </div>
    )
  }
}
