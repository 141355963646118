import { Button, Flex, List, Modal } from 'antd-mobile'
import { initBaseAllInfo } from '@/views/home/HomeService'
import {
  currentAreaCode,
  getGlobalUserConfig,
  globalUserState,
  updateCurrentAreaCode,
  updateGlobalUserInfo,
  updatePurchaseSwitch,
} from '@/stores'
import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import './index.less'

interface IProp {
  visible: boolean
  toggleVisible: () => void
}

export default function AreaList({ visible, toggleVisible }: IProp) {
  const [selectedSliceAreaCode, setSelectedSliceAreaCode] = useState('')
  const userInfo = useSnapshot(globalUserState)

  /** 隐藏 */
  function hiddenModal() {
    toggleVisible()
  }

  /** 确认切换 */
  async function doSwitch() {
    await updateCurrentAreaCode(selectedSliceAreaCode)
    await updatePurchaseSwitch(false)

    const userState = getGlobalUserConfig()
    const items = userState.sliceAreaList?.filter(
      (item) => item.sliceAreaCode === currentAreaCode.value
    )
    if (items && items[0]) {
      updateGlobalUserInfo({ sliceArea: items[0] })
    }

    hiddenModal()
  }

  function initSelectAreaCode() {
    const userState = getGlobalUserConfig()
    setSelectedSliceAreaCode(
      currentAreaCode.value || userState.sliceAreaList?.[0]?.sliceAreaCode
    )
  }

  useEffect(() => {
    initSelectAreaCode()
  }, [visible])

  return (
    <Modal
      popup
      visible={visible}
      animationType="slide-up"
      onClose={hiddenModal}
    >
      <List
        renderHeader={() => (
          <div className="home-aera-popup-list-title">选择片区</div>
        )}
        className="home-aera-popup-list"
      >
        <Flex direction="column" className="list-content">
          {userInfo?.sliceAreaList &&
            userInfo?.sliceAreaList?.length > 0 &&
            userInfo?.sliceAreaList?.map((i, index) => (
              <List.Item
                key={index}
                className={`${
                  i.sliceAreaCode === selectedSliceAreaCode ? 'isSelected' : ''
                } list-item`}
                onClick={() => setSelectedSliceAreaCode(i.sliceAreaCode)}
              >
                {i.sliceAreaName}
              </List.Item>
            ))}
        </Flex>
        <List.Item>
          <Button
            type="primary"
            className="custom-btn-overwrite"
            onClick={doSwitch}
          >
            确定
          </Button>
        </List.Item>
      </List>
    </Modal>
  )
}
