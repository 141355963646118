import loading from './loading.gif'
import './GlobalLoading.scss'

/**
 * 全局遮罩loading
 */
export default function GlobalLoading() {
  return (
    <div className="global-loader-mask">
      <img src={loading} alt="loading" />
    </div>
  )
}
