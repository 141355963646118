import { useEffect, useLayoutEffect, useRef } from 'react';
// @ts-ignore
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

interface IProp {
  selector: React.MutableRefObject<HTMLDivElement>;
  /**
   * close on tap/click
   * @default true
   */
  clikOnTapOrClose?: boolean
}

interface IDsOptions {
  imgUrls: string[];
  index?: number;
  onclose?: () => void;
  clikOnTapOrClose?: boolean;
}

interface IProps {
  imgUrls: string[];
  index?: number | string;
  onclose?: () => void;
  clikOnTapOrClose?: boolean;
}

function destroyPhotoGallery(lightbox = { current: null }) {
  if (lightbox.current) {
    // @ts-ignore
    lightbox.current?.destroy();
    lightbox.current = null;
  }
}

function initPhotoGallery(
  box: Element | (() => Element),
  lightHandler = { current: null },
  clikOnTapOrClose = true,
) {
  if (lightHandler.current) return;

  const isByCreator = typeof box === 'function';

  setTimeout(() => {
    box = typeof box === 'function' ? box() : box;
    if (box) {
      const items = box.querySelectorAll('img') || [];
      items.forEach((img) => {
        (function(prevImg: HTMLImageElement) {
          const a = document.createElement('a');
          let newImg = document.createElement('img');

          a.className = 'swiper-link';
          a.href = prevImg.src;
          a.rel = 'noreferrer';
          a.appendChild(prevImg.cloneNode(true));
          prevImg.parentNode.insertBefore(a, prevImg);
          prevImg.remove();

          newImg.src = prevImg.src;
          newImg.onload = () => {
            a.setAttribute('data-pswp-width', String(newImg.width));
            a.setAttribute('data-pswp-height', String(newImg.height));
            newImg = null;
          };

          newImg.onerror = () => {
            a.setAttribute('data-pswp-width', '100');
            a.setAttribute('data-pswp-height', '100');
            newImg = null;
          };
        })(img);
      });

      lightHandler.current = new PhotoSwipeLightbox({
        gallery: box,
        children: '.swiper-link',
        pswpModule: () => import('photoswipe'),
        errorMsg: '图片加载失败',
        imageClickAction: clikOnTapOrClose ? 'close' : undefined,
        tapAction: clikOnTapOrClose ? 'close' : undefined,
      });

      setTimeout(() => {
        // @ts-ignore
        lightHandler.current?.init();
      });
    }
  }, isByCreator ? 200 : 0)
}

function usePhotoGalleryWithDs({
  imgUrls,
  index = 0,
  clikOnTapOrClose = true,
  onclose = () => {},
}: IDsOptions) {
  const lightbox = useRef(null);
  const dataSource = imgUrls.map(v => ({ src: v }));

  useLayoutEffect(() => {
    lightbox.current = new PhotoSwipeLightbox({
      dataSource,
      pswpModule: () => import('photoswipe'),
      errorMsg: '图片加载失败',
      imageClickAction: clikOnTapOrClose ? 'close' : undefined,
      tapAction: clikOnTapOrClose ? 'close' : undefined,
      onclose,
    });

    lightbox.current.init();

    lightbox.current.on('beforeOpen', () => {
      const pswp = lightbox.current.pswp;
      const ds = pswp?.options?.dataSource;
      if (Array.isArray(ds)) {
        for (let idx = 0, len = ds.length; idx < len; idx++) {
          const item = ds[idx];
          const img = new Image();
          img.onload = () => {
            item.width = img.naturalWidth
            item.height = img.naturalHeight
            pswp?.refreshSlideContent?.(idx)
          };
          img.src = item.src as string;
        }
      }
    });

    lightbox.current.on('close', () => {
      onclose();
    });

    lightbox.current?.loadAndOpen(index, dataSource);

    return () => {
      destroyPhotoGallery(lightbox);
    }
  }, [])
}

/** 使用相册预览 */
export default function usePhotoGallery({
  selector,
  clikOnTapOrClose = true,
}: IProp) {
  const lightbox = useRef(null);

  useEffect(() => {
    initPhotoGallery(selector.current, lightbox, clikOnTapOrClose);

    return () => {
      destroyPhotoGallery(lightbox);
    };
  }, []);
}

/** 大图滑动预览弹框组件 */
export function ImagesSlide({
  imgUrls,
  index = 0,
  clikOnTapOrClose = true,
  onclose = () => {},
}: IProps) {
  usePhotoGalleryWithDs({
    imgUrls,
    index: typeof index === 'string' ? Number(index || '') : index,
    clikOnTapOrClose,
    onclose,
  });

  return null;
}
