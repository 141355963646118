/*
 * @Author: 柯庆荣19040892
 * @Email: 19040892@cnsuning.com
 * @Date: 2019-10-23 19:05:09
 * @Last Modified by: 柯庆荣19040892
 * @Last Modified time: 2019-10-23 20:29:08
 * @Description: Description
 */
import React from 'react'
import './index.less'

const urls = [
  {
    name: 'SIT',
    url: `https://srcfewsh5sit.cnsuning.com/super-manager/`
  },
  {
    name: 'KE',
    url: `http://10.24.61.45:3000/`
  }
  // {
  //   name: '齐云猛',
  //   url: `http://10.24.61.155:3000/`
  // },
  // {
  //   name: '金志文',
  //   url: `http://192.168.191.1:3000/`
  // },
  // {
  //   name: '潘家林',
  //   url: `http://10.24.61.28:3000/`
  // }
]

interface IProps {}

interface IState {
  /** 距左距离 */
  left: number
  /** 距上距离 */
  top: number
  /** x 偏移量 */
  offsetX: number
  /** y 偏移量 */
  offsetY: number
}

export default class DevMenu extends React.Component<IProps, IState> {
  state = {
    left: 0,
    top: 0,
    offsetX: 0,
    offsetY: 0
  }

  constructor(props) {
    super(props)
  }

  onTouchStart = event => {
    event.stopPropagation()
    const touch = event.changedTouches[0]
    this.setState({
      offsetX: touch.clientX - this.state.left,
      offsetY: touch.clientY - this.state.top
    })
  }

  onTouchMove = event => {
    event.stopPropagation()
    const touch = event.changedTouches[0]
    const left = touch.clientX - this.state.offsetX
    const top = touch.clientY - this.state.offsetY
    this.setState({
      left:
        left < 0
          ? 0
          : left > document.body.clientWidth
          ? document.body.clientWidth
          : left,
      top:
        top < 0
          ? 0
          : top > document.body.clientHeight
          ? document.body.clientHeight
          : top
    })
  }

  render() {
    return (
      <div
        className="dev-menu"
        onTouchStart={this.onTouchStart}
        onTouchMove={this.onTouchMove}
        style={{
          left: this.state.left,
          top: this.state.top
        }}
      >
        {urls.map(item => {
          return (
            <div key={item.url} className="dev-menu-item">
              跳转到 <a href={item.url}>{item.name}</a>
            </div>
          )
        })}
      </div>
    )
  }
}
