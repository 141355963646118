/*
 * @Author: 柯庆荣19040892
 * @Email: 19040892@cnsuning.com
 * @Date: 2019-10-25 15:36:20
 * @Last Modified by: 柯庆荣19040892
 * @Last Modified time: 2019-10-28 14:38:56
 * @Description: Description
 */
interface IOptions {
  /** 边长 */
  edgeLength?: number
  /** 线段宽度 */
  lineWidth?: number
  /** 下层圆环颜色 */
  bgRingColor?: string
  /** 上层圆环颜色 */
  fgRingColor?: string
  /** 百分比值 */
  percentValue?: number
}

/**
 * 绘制饼图
 * @param ctx - Canvas Context 对象
 * @param options - 配置
 */
function drawPie(ctx: CanvasRenderingContext2D, options: IOptions) {
  const {
    edgeLength = 100,
    lineWidth = 2,
    bgRingColor = '#eee',
    fgRingColor = '#3377FF',
    percentValue = 0
  } = options
  /** 圆心的 x 轴坐标 */
  const centerX = edgeLength / 2
  /** 圆心的 y 轴坐标 */
  const centerY = centerX
  /** 圆环半径 */
  const ringRadius = centerX - lineWidth / 2
  /** 圆弧的起点，x轴方向开始计算，单位以弧度表示 */
  const startAngle = -0.5 * Math.PI
  /** 下层圆环的终点 */
  const bgRingEndAngle = 1.5 * Math.PI
  /** 上层圆环的终点，根据百分比计算 */
  const fgRingAngle = (2 * percentValue - 0.5) * Math.PI

  ctx.lineWidth = lineWidth // 线段宽度
  ctx.lineCap = 'round' // 线段末端样式

  // 绘制下层灰色圆环
  ctx.beginPath()
  ctx.strokeStyle = bgRingColor // 画笔颜色
  ctx.arc(centerX, centerY, ringRadius, startAngle, bgRingEndAngle, false)
  ctx.stroke()

  // 绘制上层百分比圆环
  if (percentValue > 0) {
    ctx.beginPath()
    ctx.strokeStyle = fgRingColor // 画笔颜色
    ctx.arc(centerX, centerY, ringRadius, startAngle, fgRingAngle, false)
    ctx.stroke()
  }
}

export { drawPie }
