import * as React from 'react'
import './index.less'
import { RadioBar, Divider, IconEX } from '@components'
import { DatePicker, List } from 'antd-mobile'
import dayjs from 'dayjs'
import { TIME_DURATION } from './duration'
interface IProps {
  showTime?: string
  onChooseTime?: (p1, p2) => void
}

interface IState {
  mode: 'date' | 'month'
  isShowShadow: boolean
  showTime: string
  startTime: string
  endTime: string
  durationTag: string
  [key: string]: any
}

export default class TimePickerBar extends React.Component<IProps, IState> {
  static defaultProps = {
    showTime: dayjs().format('YYYY-MM-DD')
  }

  constructor(props) {
    super(props)
    this.state = {
      mode: 'date',
      showTime: '',
      isShowShadow: false,
      startTime: '开始时间',
      endTime: '结束时间',
      durationTag: '1'
    }
  }
  componentDidMount() {
    this.setState({
      showTime: this.props.showTime
    })
  }

  render() {
    const timeArray = [
      { title: '今日', code: '1', selected: true },
      { title: '近7天', code: '2', selected: true },
      { title: '近30天', code: '3', selected: true },
      { title: '按日查询', code: '4', selected: true },
      { title: '按月查询', code: '5', selected: true }
    ]

    return (
      <div className="snrc-time-picker-bar">
        <div
          onClick={this._hideShadow}
          className={`view-mask ${this.state.isShowShadow ? '' : 'hide'}`}
        />
        {!this.state.isShowShadow && (
          <div className="selected-time" onClick={this._showShadow}>
            <span className="select-name">查询时间</span>
            <span className="shops-name">{this.state.showTime}</span>
            <i className="triangle" />
          </div>
        )}
        <div
          className={`select-condition ${
            this.state.isShowShadow ? '' : 'hide'
          }`}
        >
          <RadioBar arrList={timeArray} onSelect={this._setTimeDuration} />

          <div className="time-duration">
            <span className="conditon-tag">查询时间</span>

            <div className="conditon-time">
              <DatePicker
                mode={this.state.mode}
                value={this.state.date}
                onChange={date => {
                  this._setChooseTime(date, this.state.mode, 'startTime')
                }}
              >
                <List.Item arrow="horizontal">{this.state.startTime}</List.Item>
              </DatePicker>
            </div>
            <div
              style={{ width: '0.5rem', height: '1px', background: '#f0f1f4' }}
            />

            <div className="conditon-time">
              <DatePicker
                mode={this.state.mode}
                value={this.state.date}
                onChange={date => {
                  this._setChooseTime(date, this.state.mode, 'endTime')
                }}
              >
                <List.Item arrow="horizontal">{this.state.endTime}</List.Item>
              </DatePicker>
            </div>
          </div>

          <Divider width="100%" />
          <div className="buttons">
            <span className="cancel" onClick={this._hideShadow}>
              取消
            </span>
            <span className="confirm" onClick={this._confirmTime}>
              确定
            </span>
          </div>
        </div>
      </div>
    )
  }

  /**
   * 显示蒙层
   */
  _showShadow = () => {
    this.setState({
      isShowShadow: true
    })
  }

  /**
   * 隐藏蒙层
   */
  _hideShadow = () => {
    this.setState({
      isShowShadow: false
    })
  }

  /**
   * 确定选择时间
   */
  _confirmTime = () => {
    let showTime = ''

    switch (+this.state.durationTag) {
      case TIME_DURATION.TODAY:
        showTime = `今日(${this.state.startTime})`
        break
      case TIME_DURATION.LATEST_7_DAY:
        showTime = `近7天(${this.state.startTime}至${this.state.endTime})`
        break
      case TIME_DURATION.LATEST_30_DAY:
        showTime = `(${this.state.startTime}至${this.state.endTime})`
        break
      case TIME_DURATION.BY_DAY:
        showTime = `(${this.state.startTime}至${this.state.endTime})`
        break
      case TIME_DURATION.BY_MONTH:
        showTime = `(${this.state.startTime}至${this.state.endTime})`
        break
    }

    this.setState({ showTime })
    this._hideShadow()
    this.props.onChooseTime &&
      this.props.onChooseTime(this.state.startTime, this.state.endTime)
  }

  /**
   * 选择时间段
   */
  _setTimeDuration = item => {
    console.log('---onselct--', item)
    if (item === '1') {
      this.setState({
        startTime: dayjs().format('YYYY-MM-DD'),
        endTime: dayjs().format('YYYY-MM-DD')
      })
    }
    if (item === '2') {
      this.setState({
        startTime: dayjs()
          .subtract(7, 'day')
          .format('YYYY-MM-DD'),
        endTime: dayjs().format('YYYY-MM-DD')
      })
    }
    if (item === '3') {
      this.setState({
        startTime: dayjs()
          .subtract(30, 'day')
          .format('YYYY-MM-DD'),
        endTime: dayjs().format('YYYY-MM-DD')
      })
    }
    if (item === '4') {
      this.setState({
        mode: 'date',
        startTime: '开始时间',
        endTime: '结束时间'
      })
    }
    if (item === '5') {
      this.setState({
        mode: 'month',
        startTime: '开始时间',
        endTime: '结束时间'
      })
    }
    this.setState({
      durationTag: item
    })
  }

  /**
   * 修改时间
   */
  _setChooseTime = (
    date: Date,
    mode: 'date' | 'month',
    type: 'startTime' | 'endTime'
  ) => {
    let obj = {
      date: date => dayjs(date).format('YYYY-MM-DD'),
      month: date => dayjs(date).format('YYYY-MM')
    }

    this.setState({
      [type]: obj[mode](date)
    })
  }
}
