import Achievement from '../achievement-score/Achievement'
import AgainInspection from '../again-inspection/AgainInspection'
import arrowMore from '@assets/images/index_head_down_icon.png'
import dayjs from 'dayjs'
import homeTopBgImg from '@assets/images/home_top_bg.png'
import roleMore from '@assets/images/role-more.png'
import shouye_xunzhang from '@assets/images/shouye_xunzhang@3x.png'
import styles from './index.module.less'
import {
  baseAllState,
  currentAreaCode,
  getGlobalUserConfig,
  globalUserState,
} from '@/stores'
import { Cleanup } from '@/stores/util'
import { EAccountRole } from '@genre'
import { getStoreTypeName } from '@/views/home/utils'
import { IMedalNum } from '@/views/home/types'
import { queryMedalNumInfo } from '@/views/home/HomeService'
import { Router } from '@kits'
import { subscribeKey } from 'valtio/utils'
import { TextAvatar } from '@components'
import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import { getHeaderArea } from '@/kits/util'

let unWatch: Cleanup
let unWatchSliceAreaCode: Cleanup

interface IProps {
  /** 切换用户角色列表显示 */
  toggleRoleList: () => void
  /** 切换区域弹框显示 */
  toggleAreaList: () => void
  /** 有巡检数据 -> 调整高度 */
  onFixedTopChange(top: string): void
}

export default function Header({
  toggleRoleList,
  onFixedTopChange,
  toggleAreaList,
}: IProps) {
  const [rightArea, setRightArea] = useState('')
  const [storeTypeName, setStoreTypeName] = useState('')
  const [showAchieve, setShowAchieve] = useState(false) // 显示运营人员绩效
  const [medalInfo, setMedalInfo] = useState({} as IMedalNum)
  const userInfo = useSnapshot(globalUserState)

  if (!unWatch) {
    unWatch = subscribeKey(globalUserState, 'selectRole', () => {
      // 用于首页角色切换后刷新状态
      initState()
    })

    unWatchSliceAreaCode = subscribeKey(currentAreaCode, 'value', () => {
      // 用于首页片区切换后刷新片区
      initTitle()
    })
  }

  useEffect(() => {
    initState()
    return () => {
      if (unWatch) {
        unWatch()
        unWatch = null
      }
      if (unWatchSliceAreaCode) {
        unWatchSliceAreaCode()
        unWatchSliceAreaCode = null
      }
    }
  }, [])

  async function initState() {
    const userState = getGlobalUserConfig()
    if (!userState.personNo) return

    const info = await queryMedalNumInfo(
      userState.personNo,
      dayjs().format('YYYY')
    )
    const list = userState?.dataGroupList || []

    setMedalInfo(info)
    initTitle()

    if (list.length > 0) {
      let achieveObject = list.find((item) => item.code === 'yyyj')
      if (achieveObject && Object.keys(achieveObject).length !== 0) {
        setShowAchieve(true)
        fixTopStyle(true)
      } else {
        setShowAchieve(false)
        fixTopStyle(false)
      }
    }
  }

  function initTitle() {
    let title = getHeaderArea()
    const userState = getGlobalUserConfig()

    setStoreTypeName(getStoreTypeName(userState.selectStoreType))
    setRightArea(title)
  }

  /** 展示所有的角色 */
  function showRoleList() {
    const userState = getGlobalUserConfig()
    if (userState.roleList && userState.roleList.length > 0) {
      toggleRoleList()
    }
  }

  /** 展示所有的区 */
  function showAllAreas() {
    const userState = getGlobalUserConfig()
    if (
      userState.accountRole !== EAccountRole.AREA ||
      !userState.sliceAreaList ||
      !userState.sliceAreaList.length
    ) {
      return
    }
    toggleAreaList()
  }

  function fixTopStyle(showAchieveArea = false) {
    if (baseAllState.unFinishInspectNum) {
      let top = '1.6rem'
      if (showAchieveArea && !baseAllState.unFinishInspectNum) {
        top = '2.6rem'
      } else if (!showAchieveArea && baseAllState.unFinishInspectNum) {
        top = '2.26rem'
      } else if (showAchieveArea && baseAllState.unFinishInspectNum) {
        top = '3.26rem'
      } else {
        top = '1.6rem'
      }
      onFixedTopChange(top)
    }
  }

  return (
    <div>
      <div
        className={styles['header']}
        style={{ background: `url(${homeTopBgImg})` }}
      >
        <AgainInspection />
        <div className={styles['top']}>
          <div className={styles['top-left']}>
            <TextAvatar className={styles['left-img']} name={userInfo?.name} />
            {medalInfo?.count && medalInfo?.medalVO?.headImgBigUrl && (
              <img
                src={medalInfo?.medalVO?.headImgBigUrl}
                className={styles['qianli']}
              />
            )}

            <div className={styles['left-user']}>
              {/* 切换角色/店铺类型 */}
              <div
                className={styles['user-role-store']}
                onClick={showRoleList}
                style={{ width: medalInfo.count ? '4rem' : 'auto' }}
              >
                {storeTypeName || ''}/{userInfo?.selectRole?.roleName || ''}
                {userInfo?.roleList && userInfo?.roleList.length > 0 && (
                  <img className={styles['role-store-more']} src={arrowMore} />
                )}
              </div>
              <div className={styles['user-name']}>
                {`${userInfo?.name || ''} (${userInfo?.personNo || ''})`}

                {/* 切换地区 */}
                <div
                  style={{ marginLeft: '0.1rem' }}
                  className={styles['user-role']}
                  onClick={showAllAreas}
                >
                  <span className={'role-name-txt'}>{rightArea || ''}</span>
                  {userInfo?.accountRole === EAccountRole.AREA &&
                    userInfo?.sliceAreaList &&
                    userInfo?.sliceAreaList.length > 1 && (
                      <img className={styles['role-more']} src={roleMore} />
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles['top-right']}>
            <div
              className={styles['user_honour']}
              style={
                medalInfo && medalInfo.count ? {} : { visibility: 'hidden' }
              }
              onClick={() => {
                Router.Push(
                  `/award?operationsNo=${userInfo?.personNo || ''}&userName=${
                    userInfo?.name || ''
                  }`
                )
              }}
            >
              <div>
                <img src={shouye_xunzhang} className={styles['icon']} />
              </div>
              {medalInfo && medalInfo.count && (
                <span className={styles['text']}>{medalInfo.count}个勋章</span>
              )}
              <span className={styles['right-triangle']} />
            </div>
          </div>
        </div>
        {showAchieve && <Achievement />}
      </div>
    </div>
  )
}
