import openstorepic from '@assets/images/yeji_shouye@2x.png'
import qs from 'qs'
import { baseAllState } from '@/stores'
import { Icon } from 'antd-mobile'
import { Router, Sa, util } from '@kits'
import { useSnapshot } from 'valtio'
import './index.less'

interface IProps {
  titleName: string
}

export default function OpenStoreAchievement({ titleName }: IProps) {
  const baseAllInfo = useSnapshot(baseAllState)

  function jumpToDetail(year: number) {
    Sa.sendClickData('04knnp4', {
      eleid: '10',
      eletitle: `个人开店业绩查看看详情`,
      ...util.saRoleParamClickObj(),
    })

    Router.Push(`/perf/open-store-achieve?year=${String(year || '')}`)

  }

  return baseAllInfo?.openStorePerformance ? (
    <div className="open-store-achievement">
      <div className="top-wrap">
        <div className="top-left">
          <img src={openstorepic} className="left-image" />
          <span className="left-title">
            {titleName ? titleName : '个人开店业绩'}
          </span>
        </div>
        <div
          className="top-right"
          onClick={() => jumpToDetail(baseAllInfo?.openStorePerformance?.year)}
        >
          <span className="right-title">查看详情</span>
          <Icon type="right" size="xs" color="#4A90E2" />
        </div>
      </div>
      <div className="main-cont">
        <div className="section">
          <span className="number">
            {baseAllInfo?.openStorePerformance.expandStoreNum}
          </span>
          <span className="text">参与拓展门店数</span>
        </div>
        <div className="section">
          <span className="number">
            {baseAllInfo?.openStorePerformance.openStoreNum}
          </span>
          <span className="text">参与开店门店数</span>
        </div>
      </div>
    </div>
  ) : null
}
