import indexstorelocation from '@assets/images/indexstorelocation-pic@2x.png'
import qs from 'qs'
import { openLocationMsgModal } from '@/views/home/utils'
import { getProvinceName } from '../../../../../../kits/douya'
import { EAccountRole } from '@genre'
import { currentAreaCode, getGlobalUserConfig, initDouYaInfo } from '@/stores'
import { gueryNearestStore } from '@/views/home/HomeService'
import { IGetNearestStoreResponse } from '@/views/home/types'
import { Router, Sa, util } from '@kits'
import { useEffect, useState } from 'react'
import { Icon, Modal } from 'antd-mobile'
import './index.less'

const alert = Modal.alert

/** 首页定位区块 */
export default function IndexStoreLocation({
  openLocationTip,
}: {
  openLocationTip: () => void
}) {
  const [nearestStore, setNearestStore] = useState(
    {} as IGetNearestStoreResponse
  )

  useEffect(() => {
    initDouYaData()
  }, [])

  async function initDouYaData() {
    console.log('start init douya on data page')
    initDouYaInfo(fetchNearestStore, (err, notSupport) => {
      openLocationTip()
      if (err) {
        console.log(' ------> get location err', err)
      }
      // if (notSupport) {
      //   // TODO: 要放开？？
      //   fetchNearestStore();
      // }
    })
  }

  async function fetchNearestStore() {
    const userState = getGlobalUserConfig()

    if (userState.latitude) {
      const res = await gueryNearestStore({
        longitude: userState.longitude,
        latitude: userState.latitude,
        provName: getProvinceName(userState),
        cityName: userState.city,
      })
      setNearestStore(res)
    } else {
      openLocationMsgModal()
    }
  }

  function jumpToMoreStore() {
    const userState = getGlobalUserConfig()

    Sa.sendClickData('2hx2n1d', {
      eleid: '2',
      eletitle: `更多门店`,
      ...util.saRoleParamClickObj(),
    })

    let userSelectCode = ''
    if (userState.sliceAreaList && userState.sliceAreaList.length > 0) {
      const items = userState.sliceAreaList.filter(
        (item) => item.sliceAreaCode === currentAreaCode.value
      )
      if (items && items[0]) userSelectCode = items[0].sliceAreaCode || ''
    } else {
      userSelectCode = ''
    }

    Router.Push(
      `/more-store-list/store-list?${qs.stringify({
        accountRole: userState.accountRole,
        longitude: userState.longitude,
        latitude: userState.latitude,
        provName: getProvinceName(userState),
        cityName: userState.city,
        regionCode: userState.regionCode,
        userSelectCode,
        sliceAreaCode: userSelectCode,
      })}`
    )
  }

  function distanceHandler(distance: any) {
    if (distance < 1) {
      if (typeof distance === 'string') {
        return Number(distance) * 1000 + 'm'
      } else {
        return distance * 1000 + 'm'
      }
    } else {
      return distance + 'km'
    }
  }

  function jumpToDetail() {
    const userState = getGlobalUserConfig()

    Sa.sendClickData('2hx2n1d', {
      eleid: '1',
      eletitle: `最近门店`,
      ...util.saRoleParamClickObj(),
    })

    switch (userState.accountRole) {
      /* 若登录角色为总部维度，则所有店铺，均可进入店铺详情页；*/
      case EAccountRole.NATIONAL:
        Router.Push(
          `/store-info-new/store-detail-new?${qs.stringify({
            storeCode: nearestStore.storeCode,
          })}`
        )
        break
      /* 若登录角色为大区维度，则判断该店铺是否属于该员工所在大区范围内，若“是”，则跳转进入店铺详情页，若“否”，则toast提示“您无法查看非本大区的店铺详情！”；*/
      case EAccountRole.REGION:
        if (nearestStore.regionCode == userState.regionCode) {
          Router.Push(
            `/store-info-new/store-detail-new?${qs.stringify({
              storeCode: nearestStore.storeCode,
            })}`
          )
        } else {
          alert('', goPunchStoreAlert(), [
            { text: '知道了', onPress: () => console.log('ok') },
          ])
        }
        break
      /* 若登录角色为片区维度，则判断该店铺是否属于该员工所在片区范围内，若“是”，则跳转进入店铺详情页，若“否”，则toast提示“您无法查看非本片区的店铺详情！”；*/
      case EAccountRole.AREA:
        if (userState.sliceAreaList) {
          const sliceAreaCodeList = userState.sliceAreaList.map(
            (item) => item.sliceAreaCode
          )
          if (sliceAreaCodeList.indexOf(nearestStore.sliceAreaCode) !== -1) {
            Router.Push(
              `/store-info-new/store-detail-new?${qs.stringify({
                storeCode: nearestStore.storeCode,
              })}`
            )
          } else {
            alert('', goPunchStoreAlert(), [
              { text: '知道了', onPress: () => console.log('ok') },
            ])
          }
        }
        break
    }
  }

  return (
    <div className="index-store-location">
      {!nearestStore.storeName && (
        <div className="nearest-fail">
          <img className="nearest-fail-img" src={indexstorelocation} />
          <div className="nearest-fail-text">定位失败，未获取到附近门店</div>
          <i
            className="click-dialog-btn"
            onClick={() => openLocationMsgModal()}
          ></i>
        </div>
      )}
      {nearestStore.storeName && (
        <>
          <div className="nearest-succ-left" onClick={jumpToDetail}>
            <img className="nearest-tag-img" src={indexstorelocation} />
            <div className="nearest-cont">
              <div className="nearest-title">
                <div className="nearest-store-name">
                  {nearestStore.storeName}
                </div>
                <div className="right-jump">
                  <span className="right-left-title">详情</span>
                  <Icon type="right" size="xs" color="#999999" />
                </div>
              </div>
              {/* <div className="nearest-second-title">
                <span>最近门店</span>
                <span className="line-block">|</span>
                <span>{distanceHandler(nearestStore.distance)}</span>
              </div> */}
            </div>
          </div>
          {/* <div className="right-jump" onClick={jumpToMoreStore}>
            <span className="right-jump-title">更多门店</span>
            <Icon type="right" size="xs" color="#4A90E2" />
          </div> */}
        </>
      )}
    </div>
  )
}

function goPunchStoreAlert() {
  return (
    <div style={{ fontSize: '0.28rem', color: '#333' }}>
      您无法查看非本大区/片区门店的数据详情
    </div>
  )
}
