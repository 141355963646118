import { NonIndexRouteObject } from 'react-router-dom'
import HomeDashboard from './views/home/pages/dashboard/HomeDashboard'
import HomeData from './views/home/pages/data/HomeData'
import HomeMessages from './views/home/pages/messages/HomeMessages'
import RoutePaths from './route-paths'
import loadable from '@loadable/component'
import Todos from './views/todos'

/**
 * 待办任务详情
 * {@link routes}
*/
const TodoTaskInfo = loadable(() => import(/* webpackChunkName: 'TodoTaskInfo' */'./views/todos/pages/todo-task-info/TodoTaskInfo'))
const TodoMessageInfo = loadable(() => import(/* webpackChunkName: 'TodoMessageInfo' */'./views/todos/pages/todo-message-info/TodoMessageInfo'))
const TodoQuestionInfo = loadable(() => import(/* webpackChunkName: 'TodoQuestionInfo' */'./views/todos/pages/todo-question-info/TodoQuestionInfo'))
const TodoAnswerInfo = loadable(() => import(/* webpackChunkName: 'TodoAnswerInfo' */'./views/todos/pages/todo-answer-info/TodoAnswerInfo'))
const AreaAchievement = loadable(() => import(/* webpackChunkName: 'AreaAchievement' */'./views/area-achievement'))
const AreaEdit = loadable(() => import(/* webpackChunkName: 'AreaEdit' */'./views/area/edit'))
const AreaList = loadable(() => import(/* webpackChunkName: 'AreaList' */'./views/area/list'))
const AreaManager = loadable(() => import(/* webpackChunkName: 'AreaManager' */'./views/area/manager'))
const Award = loadable(() => import(/* webpackChunkName: 'Award' */'./views/award'))
const CategoryDetail = loadable(() => import(/* webpackChunkName: 'CategoryDetail' */'./views/store-info-new/category-detail'))
const CopyMeStoreList = loadable(() => import(/* webpackChunkName: 'CopyMeStoreList' */'./views/wait-to-do/copy-me-list'))
const EmployeeDetail = loadable(() => import(/* webpackChunkName: 'EmployeeDetail' */'./views/employee/detail'))
const EmployeeList = loadable(() => import(/* webpackChunkName: 'EmployeeList' */'./views/employee/list'))
const EmployeeLocus = loadable(() => import(/* webpackChunkName: 'EmployeeLocus' */'./views/employee-locus'))
const HomeArea = loadable(() => import(/* webpackChunkName: 'HomeArea' */'./views/home-area'))
const HomeRegion = loadable(() => import(/* webpackChunkName: 'HomeRegion' */'./views/home-region'))
const InSpectionFillInformation = loadable(() => import(/* webpackChunkName: 'InSpectionFillInformation' */'./views/inspection/fill-information'))
const InspectDetailList = loadable(() => import(/* webpackChunkName: 'InspectDetailList', webpackPrefetch: true */ './views/wait-to-do/inspection-detail-list'))
const InspectionAreaInfo = loadable(() => import(/* webpackChunkName: 'InspectionAreaInfo' */'./views/inspection/area-info'))
const InspectionAreaMore = loadable(() => import(/* webpackChunkName: 'InspectionAreaMore' */'./views/inspection/area-more'))
const InspectionPunchCard = loadable(() => import(/* webpackChunkName: 'InspectionPunchCard' */'./views/inspection/punch-card/choose-store'))
const InspectionPunchComplete = loadable(() => import(/* webpackChunkName: 'InspectionPunchComplete' */'./views/inspection/punch-card/punch-complete'))
const InspectionPunchCompleteNew = loadable(() => import(/* webpackChunkName: 'InspectionPunchCompleteNew' */'./views/inspection/punch-card/punch-complete-new'))
const InspectionPunchStore = loadable(() => import(/* webpackChunkName: 'InspectionPunchStore', webpackPrefetch: true */ './views/inspection/punch-card/punch-store'))
const InspectionRegionInfo = loadable(() => import(/* webpackChunkName: 'InspectionRegionInfo' */'./views/inspection/region-info'))
const InspectionRegionInfoDetail = loadable(() => import(/* webpackChunkName: 'InspectionRegionInfoDetail' */'./views/inspection/region-info-detail'))
const InspectionRegionInfoNewDetail = loadable(() => import(/* webpackChunkName: 'InspectionRegionInfoNewDetail' */'./views/inspection/region-info-newdetail'))
const InspectionRegionInfoSlice = loadable(() => import(/* webpackChunkName: 'InspectionRegionInfoSlice' */'./views/inspection/region-info-slice'))
const InspectionRoutineInspection = loadable(() => import(/* webpackChunkName: 'InspectionRoutineInspection' */'./views/inspection/punch-card/routine-inspection'))
const InspectionRoutineInspectionNew = loadable(() => import(/* webpackChunkName: 'InspectionRoutineInspectionNew' */'./views/inspection/punch-card/routine-inspection-new'))
const InspectionSendReport = loadable(() => import(/* webpackChunkName: 'InspectionSendReport' */'./views/inspection/punch-card/send-report'))
const InspectionTypeSwutch = loadable(() => import(/* webpackChunkName: 'InspectionTypeSwutch' */'./views/inspection/punch-card/inspection-type-switch'))
const MoreStoreList = loadable(() => import(/* webpackChunkName: 'MoreStoreList', webpackPrefetch: true */ './views/more-store-list/store-list'))
const MyInspectionList = loadable(() => import(/* webpackChunkName: 'MyInspectionList' */'./views/wait-to-do/my-inspection'))
const NationalPerformanceList = loadable(() => import(/* webpackChunkName: 'NationalPerformanceList' */'./views/national/performance-list'))
const NationalWarnList = loadable(() => import(/* webpackChunkName: 'NationalWarnList', webpackPrefetch: true */ './views/national/warn-list'))
const NoGeoInfoStoreList = loadable(() => import(/* webpackChunkName: 'NoGeoInfoStoreList' */'./views/wait-to-do/no-geo-info-list'))
const NotcieDetail = loadable(() => import(/* webpackChunkName: 'NotcieDetail', webpackPrefetch: true */ './views/notice-detail'))
const OpenStoreAchieve = loadable(() => import(/* webpackChunkName: 'OpenStoreAchieve', webpackPrefetch: true */ './views/perf/open-store/open-store-achieve'))
const OpenStorePerformance = loadable( () => import(/* webpackChunkName: 'OpenStorePerformance' */'./views/perf/open-store/performance-list'))
const OperationalArea = loadable(() => import(/* webpackChunkName: 'OperationalArea' */'./views/operational-data/area/OperationalArea'))
const OperationalNation = loadable(() => import(/* webpackChunkName: 'OperationalNation', webpackPrefetch: true */ './views/operational-data/nation/OperationalNation'))
const OperationalRegion = loadable(() => import(/* webpackChunkName: 'OperationalRegion' */'./views/operational-data/region/OperationalRegion'))
const OperationalSingle = loadable(() => import(/* webpackChunkName: 'OperationalSingle' */'./views/operational-data/single/OperationalSingle'))
const PerformanceReportArea = loadable(() => import(/* webpackChunkName: 'PerformanceReportArea' */'./views/performance-report/area/PerformanceReportArea'))
const PerformanceReportNation = loadable(() => import(/* webpackChunkName: 'PerformanceReportNation', webpackPrefetch: true */ './views/performance-report/nation/PerformanceReportNation'))
const PerformanceReportRegion = loadable(() => import(/* webpackChunkName: 'PerformanceReportRegion' */'./views/performance-report/region/PerformanceReportRegion'))
const RegionList = loadable(() => import(/* webpackChunkName: 'RegionList' */'./views/region/list'))
const RegionInfoList = loadable(() => import(/* webpackChunkName: 'RegionInfoList', webpackPrefetch: true */ './views/store-info/region-list'))
const AreaHomeMoreList = loadable(() => import(/* webpackChunkName: 'AreaHomeMoreList' */'./views/area/area-home-more-list'))
const RemoteMonitorAreaList = loadable(() => import(/* webpackChunkName: 'RemoteMonitorAreaList' */'./views/remote-monitor/area-list'))
const RemoteMonitorRegionList = loadable(() => import(/* webpackChunkName: 'RemoteMonitorRegionList' */'./views/remote-monitor/region-list'))

const RemoteMonitorStoreList = loadable(() => import(/* webpackChunkName: 'RemoteMonitorStoreList' */'./views/remote-monitor/store-list'))
const SampleMachineArea = loadable(() => import(/* webpackChunkName: 'SampleMachineArea' */'./views/sample-machine-data/area/SampleMachineArea'))
const SampleMachineNation = loadable(() => import(/* webpackChunkName: 'SampleMachineNation', webpackPrefetch: true */ './views/sample-machine-data/nation/SampleMachineNation'))
const SampleMachineRegion = loadable(() => import(/* webpackChunkName: 'SampleMachineRegion' */'./views/sample-machine-data/region/SampleMachineRegion'))
const Search = loadable(() => import(/* webpackChunkName: 'Search' */'./views/search'))
const SearchShop = loadable(() => import(/* webpackChunkName: 'SearchShop' */'./views/perf/open-store/search-shop'))
const SingleCategoryDetail = loadable(() => import(/* webpackChunkName: 'SingleCategoryDetail' */'./views/store-info-new/single-category-detail'))
const SingleCmmdtyDetail = loadable(() => import(/* webpackChunkName: 'SingleCmmdtyDetail' */'./views/store-info-new/single-cmmdty-detail'))
const StoreCoordinates = loadable(() => import(/* webpackChunkName: 'StoreCoordinates', webpackPrefetch: true */ './views/store-info/store-coordinates'))
const StoreDetail = loadable(() => import(/* webpackChunkName: 'StoreDetail', webpackPrefetch: true */ './views/store-info-new/store-detail-new'))
const StoreManagerEdit = loadable(() => import(/* webpackChunkName: 'StoreManagerEdit' */'./views/store-info/store-manager-edit'))
const StorePerf = loadable(() => import(/* webpackChunkName: 'StorePerf' */'./views/store-info/store-perf'))
const StorePerformanceComplete = loadable(() => import(/* webpackChunkName: 'StorePerformanceComplete' */'./views/store-performance-complete'))
const StorePerformanceRecord = loadable(() => import(/* webpackChunkName: 'StorePerformanceRecord' */'./views/store-performance-record'))
const SubregionList = loadable(() => import(/* webpackChunkName: 'SubregionList' */'./views/store-info/subregion-list'))
const TaskAuditTask = loadable(() => import(/* webpackChunkName: 'TaskAuditTask' */'./views/store-task-info/audit-task/AuditTask'))
const TaskDetailIndex = loadable(() => import(/* webpackChunkName: 'TaskDetailIndex' */'./views/store-task-info/task-detail/TaskDetail'))
const TaskListIndex = loadable(() => import(/* webpackChunkName: 'TaskListIndex' */'./views/store-task-info/task-list/TaskList'))
const UnfinishedInspectionList = loadable(() => import(/* webpackChunkName: 'UnfinishedInspectionList' */'./views/inspection/unfinished-inspection-list'))
const WaitCommentList = loadable(() => import(/* webpackChunkName: 'WaitCommentList' */'./views/wait-to-do/wait-comment-list'))
const WaitCorrectDetailList = loadable(() => import(/* webpackChunkName: 'WaitCorrectDetailList' */'./views/wait-to-do/wait-to-correct-detail'))
const WaitCorrectStoreList = loadable(() => import(/* webpackChunkName: 'WaitCorrectStoreList' */'./views/wait-to-do/wait-to-correct-list'))
const WaitEstimateCurrent = loadable(() => import(/* webpackChunkName: 'WaitEstimateCurrent' */'./views/wait-to-do/wait-estimate-current'))
const WaitEvaluateList = loadable(() => import(/* webpackChunkName: 'WaitEvaluateList' */'./views/wait-to-do/wait-evaluate-list'))
const WaitInspectionStoreList = loadable(() => import(/* webpackChunkName: 'WaitInspectionStoreList' */'./views/wait-to-do/wait-inspection-list'))
const goodsNoExample = loadable(() => import(/* webpackChunkName: 'goodsNoExample' */'./views/store-info-new/store-detail-new/components/storage-sample/store-sample-data-view/components/goods-no-example'))
const goodsNoSale = loadable(() => import(/* webpackChunkName: 'goodsNoSale' */'./views/store-info-new/store-detail-new/components/storage-sample/store-sample-data-view/components/goods-no-sale'))

/** 存销比 */
const SSRNation = loadable(() => import(/* webpackChunkName: 'SSRNation', webpackPrefetch: true */'./views/stock-sale-ratio/nation-ssr'))
const SSRRegion = loadable(() => import(/* webpackChunkName: 'SSRRegion' */'./views/stock-sale-ratio/region-ssr'))
const SSRArea = loadable(() => import(/* webpackChunkName: 'SSRArea' */'./views/stock-sale-ratio/area-ssr'))

/** SAB样机数据 */
const SABYangJjiDataNation = loadable(() => import(/* webpackChunkName: 'SABYangJjiDataNation', webpackPrefetch: true */'./views/sab-yangji/nation'))
const SABYangJjiDataRegion = loadable(() => import(/* webpackChunkName: 'SABYangJjiDataRegion' */'./views/sab-yangji/region'))
const SABYangJjiDataArea = loadable(() => import(/* webpackChunkName: 'SABYangJjiDataArea' */'./views/sab-yangji/area'))

/** 运营人员业绩 */
const RegionPerfList = loadable(() => import(/* webpackChunkName: 'RegionPerfList', webpackPrefetch: true */'./views/perf/region-list'))
const SubRegionPerfList = loadable(() => import(/* webpackChunkName: 'SubRegionPerfList' */'./views/perf/subregion-list'))
const StorPerfList = loadable(() => import(/* webpackChunkName: 'StorPerfList' */'./views/perf/store-list'))

/** 拓展人员业绩 */
const TuozhanRegionPerfList = loadable(() => import(/* webpackChunkName: 'TuozhanRegionPerfList', webpackPrefetch: true */'./views/tuozhan-perf/region-list'))
const TuozhanSubRegionPerfList = loadable(() => import(/* webpackChunkName: 'TuozhanSubRegionPerfList' */'./views/tuozhan-perf/subregion-list'))
const TuozhanStorPerfList = loadable(() => import(/* webpackChunkName: 'TuozhanStorPerfList' */'./views/tuozhan-perf/store-list'))
const TuozhanEmpLocation = loadable(() => import(/* webpackChunkName: 'TuozhanEmpLocation' */'./views/tuozhan-perf/emp-location'))

/** 全国维度巡店报表 */
const NationInspectionReport = loadable(() => import(/* webpackChunkName: 'NationInspectionReport', webpackPrefetch: true */ './views/inspection-report/nation-inspection-report'))
/** 大区维度巡店报表 */
const RegionInspectionReport = loadable(() => import(/* webpackChunkName: 'RegionInspectionReport' */'./views/inspection-report/region-inspection-report'))
/** 片区维度巡店报表 */
const AreaInspectionReport = loadable(() => import(/* webpackChunkName: 'AreaInspectionReport' */'./views/inspection-report/area-inspection-report'))
/** 巡店详情 */
const InspectionReportDetail = loadable(() => import(/* webpackChunkName: 'InspectionReportDetail' */'./views/inspection-report/inspection-report-detail'))

/** 门店筹建 - 筹建准备-筹建计划 */
const SearchStorePreparation = loadable(() => import(/* webpackChunkName: 'SearchStorePreparation' */'@/view-preparation/search-store-preparation/search-store-preparation'))
const AuditRecords = loadable(() => import(/* webpackChunkName: 'AuditRecords' */'@/view-preparation/audit-records'))
const OpenActivity = loadable(() => import(/* webpackChunkName: 'OpenActivity' */'@/view-preparation/open-activity'))
const StorePreparation = loadable(() => import(/* webpackChunkName: 'StorePreparation', webpackPrefetch: true */ '@/view-preparation/store-preparation'))

const AllPreparationOrder = loadable(() => import(/* webpackChunkName: 'AllPreparationOrder', webpackPrefetch: true */ '@/view-preparation/all-preparation-order'))
const PreparationPlanAdd = loadable(() => import(/* webpackChunkName: 'PreparationPlanAdd' */'@/view-preparation/plan-add'))
const DecorationNewCheck = loadable(() => import(/* webpackChunkName: 'DecorationNewCheck' */ '@/view-preparation/docoration-new-check/docorationNewCheck'))

/** 修改计划开业时间 */
const PreparationChangeOpenTime = loadable(() => import(/* webpackChunkName: 'PreparationChangeOpenTime' */'./views/preparation/change-open-time'))
const ChangeOpenTimeRecords = loadable(() => import(/* webpackChunkName: 'ChangeOpenTimeRecords' */'./views/preparation/change-open-time-record'))
const DecorationVerify = loadable(() => import(/* webpackChunkName: 'DecorationVerify' */'@/view-preparation/decoration-verify'))
const VideoPreviewer = loadable(() => import(/* webpackChunkName: 'VideoPreviewer' */'@/view-preparation/video-previewer'))
const PreparationDetail = loadable(() => import(/* webpackChunkName: 'PreparationDetail' */'@/view-preparation/preparation-detail'))

const PrototypeData = loadable(() => import(/* webpackChunkName: 'PrototypeData', webpackPrefetch: true */ '@/views/vsa-report/prototype-data'))
const StoreSampleData = loadable(() => import(/* webpackChunkName: 'StoreSampleData' */'@/views/vsa-report/store-sample-data'))
const ProductSampleData = loadable(() => import(/* webpackChunkName: 'ProductSampleData' */'@/views/vsa-report/product-sample-data'))
const StoreReopenApplyPage = loadable(() => import(/* webpackChunkName: 'StoreReopenApplyPage' */'./views/store-reopen-apply/StoreReopenApplyPage'))

export const routes: Array<IRoute> = [
  {
    path: '/home',
    component: HomeData,
    withTabs: true,
    cache: true,
    when: 'always',
  },
  {
    path: '/message',
    component: HomeMessages,
    withTabs: true,
    cache: true,
    when: 'always',
  },
  {
    path: '/dashboard',
    component: HomeDashboard,
    withTabs: true,
    cache: true,
    when: 'always',
  },
  {
    path: '/todos',
    component: Todos,
    withTabs: true,
    cache: true,
    when: 'always',
  },
  {
    path: '/todo/task-info',
    component: TodoTaskInfo,
  },
  {
    path: '/todo/message-info',
    component: TodoMessageInfo,
  },
  {
    path: '/todo/question-info',
    component: TodoQuestionInfo,
  },
  {
    path: '/todo/answer-info',
    component: TodoAnswerInfo,
  },
  {
    path: '/home-region',
    component: HomeRegion,
  },
  {
    path: '/home-area',
    component: HomeArea,
  },
  {
    path: '/national/performance-list',
    component: NationalPerformanceList,
    cache: true,
  },
  {
    path: '/perf/open-store',
    component: OpenStorePerformance,
    cache: true,
  },
  {
    path: '/national/warn-list', // TODOW scorll save
    component: NationalWarnList,
    cache: true,
  },
  {
    path: '/search',
    component: Search,
    cache: true,
  },
  {
    path: '/search-shop',
    component: SearchShop,
    cache: true,
  },
  {
    path: '/area/list',
    component: AreaList,
    cache: true,
  },
  {
    path: '/area/manager',
    component: AreaManager,
  },
  {
    path: '/area/edit',
    component: AreaEdit,
  },
  {
    path: '/store-info/store-perf',
    component: StorePerf,
  },
  {
    path: '/store-info-new/store-detail-new',
    component: StoreDetail,
    cache: true,
  },
  {
    path: '/store-info-new/category-detail',
    component: CategoryDetail,
  },
  {
    path: '/store-info-new/single-category-detail',
    component: SingleCategoryDetail,
  },
  {
    path: '/store-info-new/goods-no-example',
    component: goodsNoExample,
  },
  {
    path: '/store-info-new/goods-no-sale',
    component: goodsNoSale,
  },
  {
    path: '/store-info-new/single-cmmdty-detail',
    component: SingleCmmdtyDetail,
  },
  {
    path: '/store-info/store-coordinates',
    component: StoreCoordinates,
  },
  {
    path: '/store-info/store-manager-edit',
    component: StoreManagerEdit,
  },
  {
    path: '/store-info/region-list',
    component: RegionInfoList,
    cache: true,
  },
  {
    path: '/area/area-home-more-list',
    component: AreaHomeMoreList,
  },
  {
    path: '/store-info/subregion-list',
    component: SubregionList,
    cache: true,
  },
  {
    path: '/employee/list',
    component: EmployeeList,
    cache: true,
  },
  {
    path: '/employee/detail',
    component: EmployeeDetail,
  },
  {
    path: '/region/list',
    component: RegionList,
    cache: true,
  },
  {
    path: '/perf/region-list',
    component: RegionPerfList,
    cache: true,
  },
  {
    path: '/perf/subregion-list',
    component: SubRegionPerfList,
    cache: true,
  },
  {
    path: '/perf/store-list',
    component: StorPerfList,
    cache: true,
  },
  {
    path: '/tuozhan-perf/region-list',
    component: TuozhanRegionPerfList,
    cache: true,
  },
  {
    path: '/tuozhan-perf/subregion-list',
    component: TuozhanSubRegionPerfList,
    cache: true,
  },
  {
    path: '/tuozhan-perf/store-list',
    component: TuozhanStorPerfList,
    cache: true,
  },
  {
    path: '/tuozhan-perf/emp-location',
    component: TuozhanEmpLocation,
    cache: true,
  },
  {
    path: '/inspection-report/nation-inspection-report', // TODOW scroll
    component: NationInspectionReport,
    cache: true,
  },
  {
    path: '/inspection-report/region-inspection-report',
    component: RegionInspectionReport,
    cache: true,
  },
  {
    path: '/inspection-report/area-inspection-report',
    component: AreaInspectionReport,
    cache: true,
  },
  {
    path: '/inspection-report/inspection-report-detail',
    component: InspectionReportDetail,
  },
  {
    path: '/inspection/unfinished-inspection-list',
    component: UnfinishedInspectionList,
  },
  {
    path: '/inspection/area-info',
    component: InspectionAreaInfo,
  },
  {
    path: '/inspection/area-more',
    component: InspectionAreaMore,
    cache: true,
  },
  {
    path: '/inspection/region-info',
    component: InspectionRegionInfo,
    cache: true,
  },
  {
    path: '/inspection/region-info-slice',
    component: InspectionRegionInfoSlice,
    cache: true,
  },
  {
    path: '/inspection/region-info-detail',
    component: InspectionRegionInfoDetail,
  },
  {
    path: '/inspection/region-info-newdetail',
    component: InspectionRegionInfoNewDetail,
  },
  {
    path: '/inspection/fill-information',
    component: InSpectionFillInformation,
  },
  // 选择门店
  {
    path: '/inspection/punch-card/choose-store',
    component: InspectionPunchCard,
  },
  // 巡店打卡
  {
    path: '/inspection/punch-card/punch-store',
    component: InspectionPunchStore,
  },
  // 巡检题目类型切换
  {
    path: '/inspection/punch-card/inspection-type-switch',
    component: InspectionTypeSwutch,
  },
  // 门店常规巡检
  {
    path: '/inspection/punch-card/routine-inspection',
    component: InspectionRoutineInspection,
  },
  // 门店常规巡检（新）
  {
    path: '/inspection/punch-card/routine-inspection-new',
    component: InspectionRoutineInspectionNew,
  },
  // 巡店完成
  {
    path: '/inspection/punch-card/punch-complete',
    component: InspectionPunchComplete,
  },
  // 巡店完成（新）
  {
    path: '/inspection/punch-card/punch-complete-new',
    component: InspectionPunchCompleteNew,
  },
  {
    path: '/inspection/punch-card/send-report',
    component: InspectionSendReport,
  },
  {
    path: '/store-performance-record',
    component: StorePerformanceRecord,
  },
  {
    path: '/store-performance-complete',
    component: StorePerformanceComplete,
  },
  {
    path: '/employee-locus',
    component: EmployeeLocus,
  },
  {
    path: '/area-achievement',
    component: AreaAchievement,
  },
  {
    path: '/remote-monitor/region-list',
    component: RemoteMonitorRegionList,
  },
  {
    path: '/remote-monitor/area-list',
    component: RemoteMonitorAreaList,
  },
  {
    path: '/remote-monitor/store-list',
    component: RemoteMonitorStoreList,
  },
  {
    path: '/stock-sale-ratio/nation',
    component: SSRNation,
    cache: true,
  },
  {
    path: '/stock-sale-ratio/region',
    component: SSRRegion,
    cache: true,
  },
  {
    path: '/stock-sale-ratio/area',
    component: SSRArea,
  },
  {
    path: '/sab-yangji/nation',
    component: SABYangJjiDataNation,
  },
  {
    path: '/sab-yangji/region',
    component: SABYangJjiDataRegion,
  },
  {
    path: '/sab-yangji/area',
    component: SABYangJjiDataArea,
  },
  {
    path: '/award',
    component: Award,
  },
  {
    path: '/more-store-list/store-list',
    component: MoreStoreList,
  },
  {
    path: '/perf/open-store-achieve',
    component: OpenStoreAchieve,
  },
  {
    path: '/wait-to-do/wait-inspection-list',
    component: WaitInspectionStoreList,
  },
  {
    path: '/wait-to-do/no-geo-info-list',
    component: NoGeoInfoStoreList,
  },
  // wait-to-correct-list
  {
    path: '/wait-to-do/wait-to-correct-list',
    component: WaitCorrectStoreList,
  },
  {
    path: '/wait-to-do/wait-evaluate-list',
    component: WaitEvaluateList,
  },
  {
    path: '/store-task-info/task-list',
    component: TaskListIndex,
    cache: true,
  },
  {
    path: '/store-task-info/task-detail',
    component: TaskDetailIndex,
  },
  {
    path: '/store-task-info/audit-task',
    component: TaskAuditTask,
  },
  {
    path: '/wait-to-do/copy-me-list',
    component: CopyMeStoreList,
  },
  {
    path: '/wait-to-do/wait-comment-list',
    component: WaitCommentList,
  },
  {
    path: '/wait-to-do/inspection-detail-list',
    component: InspectDetailList,
  },
  {
    path: '/wait-to-do/wait-to-correct-detail',
    component: WaitCorrectDetailList,
  },
  {
    path: '/wait-to-do/wait-estimate-current',
    component: WaitEstimateCurrent,
  },
  {
    path: '/wait-to-do/my-inspection',
    component: MyInspectionList,
  },
  {
    path: RoutePaths.NOTICE_DETAIL,
    component: NotcieDetail,
  },
  {
    path: '/operational-data/nation',
    component: OperationalNation,
    cache: true,
  },
  {
    path: '/operational-data/region',
    component: OperationalRegion,
    cache: true,
  },
  {
    path: '/operational-data/area',
    component: OperationalArea,
    cache: true,
  },
  {
    path: '/operational-data/single',
    component: OperationalSingle,
  },
  {
    path: '/performance-report/nation',
    component: PerformanceReportNation,
    cache: true,
  },
  {
    path: '/performance-report/region',
    component: PerformanceReportRegion,
    cache: true,
  },
  {
    path: '/performance-report/area',
    component: PerformanceReportArea,
    cache: true,
  },
  {
    path: '/sample-machine-data/nation',
    component: SampleMachineNation,
    cache: true,
  },
  {
    path: '/sample-machine-data/region',
    component: SampleMachineRegion,
    cache: true,
  },
  {
    path: '/sample-machine-data/area',
    component: SampleMachineArea,
  },
  {
    path: '/preparation/plan/add',
    component: PreparationPlanAdd,
  },
  {
    path: '/preparation/decoration/newCheck',
    component: DecorationNewCheck,
    cache: true
  },
  {
    path: '/preparation/decoration/verify',
    component: DecorationVerify,
    cache: true,
  },
  {
    path: '/preparation/detail',
    component: PreparationDetail,
    cache: true,
  },
  {
    path: '/video/preview',
    component: VideoPreviewer,
  },
  // 门店筹建
  {
    path: '/view-preparation/store-preparation',
    component: StorePreparation,
    cache: true,
  },
  // 筹建明细
  {
    path: '/view-preparation/all-preparation-order',
    component: AllPreparationOrder,
    cache: true,
  },
  // 筹建搜索页面
  {
    path: '/view-preparation/search-store-preparation',
    component: SearchStorePreparation,
    cache: true,
  },
  // 审核记录
  {
    path: '/preparation/change-open-time/:storeCode/:orderNo/:openDate', // 格式为YYYY-MM-DD
    component: PreparationChangeOpenTime,
  },
  {
    path: '/preparation/change-open-time-record', // 格式为YYYY-MM-DD
    component: ChangeOpenTimeRecords,
    cache: true,
  },
  // 审核记录
  {
    path: '/view-preparation/audit-records',
    component: AuditRecords,
  },
  // 开业活动
  {
    path: '/view-preparation/open-activity',
    component: OpenActivity,
    cache: true,
    when: 'always',
  },
  {
    path: '/vsa-report/prototype-data',
    component: PrototypeData,
    cache: true,
  },
  {
    path: '/vsa-report/store-sample-data',
    component: StoreSampleData,
  },
  {
    path: '/vsa-report/product-sample-data',
    component: ProductSampleData,
  },
  {
    path: '/store-reopen-apply',
    component: StoreReopenApplyPage,
  },
]

export const RouterCacheKeys = routes.reduce((value, curr) => {
  value[curr.path] = curr.path
  return value
}, {})

export type CacheWhen = 'always' | 'forward' | 'backward'

/**
 * route节点类型
 */
export interface IRoute extends Omit<NonIndexRouteObject, 'children' | 'element'> {
  /** 路由路径 */
  path: string
  /** 组件 */
  component: React.ReactNode
  /**
   * 是否需要底部导航
   * @default false
   */
  withTabs?: boolean
  /**
   * 是否需要缓存
   * @default false
   */
  cache?: boolean
  /**
   * 手动管理缓存的清除 ('forward' | 'backward'适用)
   * @description 默认会自动结合'forward' | 'backward'场景，进行缓存的清除
   * @default false
   */
  manuallyClearCache?: boolean
  /**
   * Decide when to cache
   * @default 'forward'
   * @description [forward] Cache when forward behavior occurs, corresponding to the PUSH or REPLACE action in react-router
   * @description [always] Always cache routes when leave, no matter forward or backward
   * @description [backward] Cache when back behavior occurs, corresponding to the POP action in react-router
   */
  when?: CacheWhen
}
