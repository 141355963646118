import nodataPic from '@assets/images/no-store-record@2x.png'
import './index.less'

interface IProps {
  textTag?: string
  imgUrl?: string
  smallTextTag?: string
  loadingImg?: string
}

export default function ShopNoData({
  textTag = '暂无数据',
  imgUrl = nodataPic,
  smallTextTag,
  loadingImg,
}: IProps) {
  return (
    <div className="shop-list-no-data">
      {loadingImg && (
        <div className="no-loading-pic">
          <img src={loadingImg} alt="" />
        </div>
      )}

      {!loadingImg && (
        <div className="no-data-pic">
          <img src={imgUrl} alt="" />
        </div>
      )}

      <div className="no-data-text">{textTag}</div>
      {smallTextTag && <div className="small-data-text">{smallTextTag}</div>}
    </div>
  )
}
