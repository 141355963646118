import { IRouterAnimationMode } from '@/router-render'
import { Transition, Variants } from 'framer-motion'

const RouteTransition: Transition = {
  type: 'spring',
  duration: 0.5,
}

const RouteFadeTransition: Transition = {
  type: 'spring',
  duration: 1.6,
}

function getTransition(mode: IRouterAnimationMode) {
  if (mode === 'fade') {
    return {
      PushVariants: {
        initial: {
          opacity: 0,
        },
        in: {
          opacity: 1,
        },
        out: {
          opacity: 0,
        },
      } as Variants,
      PopVariants: {
        initial: {
          opacity: 0,
        },
        in: {
          opacity: 1,
        },
        out: {
          opacity: 0,
        },
      } as Variants,
    }
  }
  if (mode === 'slide') {
    return {
      PushVariants: {
        initial: {
          opacity: 0,
          x: '100vw',
        },
        in: {
          opacity: 1,
          x: 0,
        },
        out: {
          opacity: 0,
          x: '-100vw',
        },
      } as Variants,
      PopVariants: {
        initial: {
          opacity: 0,
          x: '-100vw',
        },
        in: {
          opacity: 1,
          x: 0,
        },
        out: {
          opacity: 0,
          x: '100vw',
        },
      } as Variants,
    }
  }
  return {} as Variants
}

function getRouteTransition(mode: IRouterAnimationMode) {
  if (mode === 'fade') {
    return RouteFadeTransition
  }
  if (mode === 'slide') {
    return RouteTransition
  }
  return {} as Transition
}

export { getRouteTransition, getTransition }
