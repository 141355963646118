import * as React from 'react'
import { Modal, Button } from 'antd-mobile'
import './index.less'

enum EType {
  overview = 'overview',
  store = 'store',
  employe = 'employe'
}

interface IReportItem {
  title: string
  selected: boolean
}

interface IProps {
  affirm: () => void
  visiable: boolean
}

interface IState {
  overviewReport: IReportItem[]
  storeReport: IReportItem[]
  employeReport: IReportItem[]
  visiable: boolean
  showAnnimation: boolean
}

export default class SideScreen extends React.PureComponent<IProps, IState> {
  report = [
    {
      title: '销售额',
      selected: true
    },
    {
      title: '毛利额',
      selected: true
    },
    {
      title: '毛利率',
      selected: true
    },
    {
      title: '销量',
      selected: false
    },
    {
      title: '商品单价',
      selected: false
    },
    {
      title: '退货金额',
      selected: false
    },
    {
      title: '退货量',
      selected: false
    }
  ]

  constructor(props) {
    super(props)
    this.state = {
      overviewReport: [],
      storeReport: [],
      employeReport: [],
      visiable: props.visiable,
      showAnnimation: false
    }
  }

  componentDidMount() {
    let storageReport = localStorage.getItem('sale_report')
    if (storageReport) {
      this.setState({
        overviewReport: JSON.parse(storageReport).overviewReport,
        storeReport: JSON.parse(storageReport).storeReport,
        employeReport: JSON.parse(storageReport).employeReport
      })
    } else {
      this.setState({
        overviewReport: JSON.parse(JSON.stringify(this.report)),
        storeReport: JSON.parse(JSON.stringify(this.report)),
        employeReport: JSON.parse(JSON.stringify(this.report))
      })
      localStorage.setItem(
        'sale_report',
        JSON.stringify({
          overviewReport: this.report,
          storeReport: this.report,
          employeReport: this.report
        })
      )
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      visiable: nextProps.visiable
    })
    setTimeout(() => {
      this.setState({
        showAnnimation: true
      })
    }, 200)
  }

  render() {
    let {
      overviewReport,
      storeReport,
      employeReport,
      visiable,
      showAnnimation
    } = this.state
    return (
      <Modal
        visible={visiable}
        transparent
        wrapClassName="wrap-className"
        className={
          showAnnimation
            ? 'side-screen-modal side-screen-transform-show'
            : 'side-screen-modal side-screen-transform-hidden'
        }
      >
        <div className="side-content">
          {this.renderTitle('数据概览')}
          {this.renderReport(overviewReport, EType.overview)}
          {this.renderTitle('门店分析')}
          {this.renderReport(storeReport, EType.store)}
          {this.renderTitle('员工绩效')}
          {this.renderReport(employeReport, EType.employe)}
        </div>
        <div className="footer">
          <span className="cancel-button" onClick={this.cancelClick.bind(this)}>
            取消
          </span>
          <span
            className="cancel-button affirm-button"
            onClick={this.affirmClick.bind(this)}
          >
            确定
          </span>
        </div>
      </Modal>
    )
  }

  renderTitle(title: string) {
    return (
      <span className="item-title">
        {title}
        <span className="subtitle">(至少选三个)</span>
      </span>
    )
  }

  renderReport(itemList: IReportItem[], type: EType) {
    return (
      <div className="item">
        {itemList.map((item, index) => {
          return (
            <span
              key={`${index}`}
              onClick={() => this.itemClick(itemList, item)}
              style={{
                border: item.selected
                  ? '1px solid #3377FF'
                  : '1px solid #999999',
                color: item.selected ? ' #3377FF' : '#333333'
              }}
              className="item-button"
            >
              {item.title}
            </span>
          )
        })}
      </div>
    )
  }

  itemClick(itemList: IReportItem[], item: IReportItem) {
    let _itemList = itemList.filter(item => item.selected)
    if (_itemList.length === 3 && item.selected) return
    item.selected = !item.selected
    this.forceUpdate()
  }

  cancelClick() {
    this.setState({
      showAnnimation: false
    })
    setTimeout(() => {
      this.setState({
        visiable: false
      })
    }, 600)
  }

  affirmClick() {
    let { employeReport, overviewReport, storeReport } = this.state
    this.cancelClick()
    this.props.affirm()
    localStorage.setItem(
      'sale_report',
      JSON.stringify({
        overviewReport: overviewReport,
        storeReport: storeReport,
        employeReport: employeReport
      })
    )
  }
}
