import yjsj from '@assets/images/yjsj.png'
import { Cleanup } from '@/stores/util'
import { EAccountRole } from '@genre'
import { Flex, Icon } from 'antd-mobile'
import { currentAreaCode, getGlobalUserConfig, globalUserState } from '@/stores'
import {
  IQuerySampleMachineParam,
  ISampleMachineItemData,
} from '@/views/home/types'
import { ISampleMachineRequest } from '@/views/sample-machine-data/types'
import { parseJsonToQueryString } from '@/kits/util'
import { querySampleMachine } from '@/views/home/HomeService'
import { Router } from '@kits'
import { Sa, util } from '@kits'
import { subscribeKey } from 'valtio/utils'
import { Switch } from '@components'
import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import './index.less'

let unWatchRole: Cleanup
let unWatchSliceArea: Cleanup

export default function SampleMachine() {
  const [machineSwitch, setMachineSwitch] = useState(false)
  const [info, setInfo] = useState({} as ISampleMachineItemData)
  const userInfo = useSnapshot(globalUserState)

  if (!unWatchRole) {
    unWatchRole = subscribeKey(globalUserState, 'selectRole', () => {
      // 用于首页角色切换后刷新状态
      setMachineSwitch(false)
      queryData(true)
    })
  }

  if (!unWatchSliceArea) {
    unWatchSliceArea = subscribeKey(currentAreaCode, 'value', () => {
      // 用于首页片区切换后刷新状态
      setMachineSwitch(false)
      queryData(true)
    })
  }

  useEffect(() => {
    queryData(true)

    return () => {
      if (unWatchRole) {
        unWatchRole()
        unWatchRole = null
      }

      if (unWatchSliceArea) {
        unWatchSliceArea()
        unWatchSliceArea = null
      }
    }
  }, [])

  async function fetchSampleMachine(param: IQuerySampleMachineParam) {
    const { sampleDataInfoHeader = {} as ISampleMachineItemData } =
      await querySampleMachine(param)
    setInfo(() => sampleDataInfoHeader)
  }

  /** 年， 季度切换 */
  async function swithChange(val: boolean) {
    Sa.sendClickData('0m5pe98', {
      eleid: '1',
      eletitle: `季年切换`,
      ...util.saRoleParamClickObj(),
    })

    setMachineSwitch(val)
    queryData(!val)
  }

  function queryData(isNowMonth: boolean) {
    const userState = getGlobalUserConfig()
    let param = {
      date: util.getTimeInfo().year + util.getTimeInfo().month,
      queriesDimCodes: '0',
      codeType: userState.accountRole,
      code: '',
    } as IQuerySampleMachineParam

    // 全国
    if (userState.accountRole === EAccountRole.NATIONAL) {
      param.code = ''
    }
    // 大区
    if (
      [EAccountRole.REGION, EAccountRole.REGION_Manager].includes(
        userState.accountRole
      )
    ) {
      param.code = userState.regionCode
    }
    // 片区
    if (userState.accountRole === EAccountRole.AREA) {
      param.code = currentAreaCode.value || userState.sliceArea?.sliceAreaCode
    }

    // 上月
    if (!isNowMonth) {
      param.date = util.getLastMonth()
    }

    fetchSampleMachine(param)
  }

  /** 经营数据更多 */
  function sampleMachineDataClick() {
    Sa.sendClickData('yjsj', {
      eleid: '1',
      eletitle: `查看详情`,
      ...util.saRoleParamClickObj(),
    })

    const userState = getGlobalUserConfig()
    switch (userState.accountRole) {
      case EAccountRole.NATIONAL:
        const nationalQuery = {
          codeType: '1',
          queriesDimCodes: '1',
        } as ISampleMachineRequest
        Router.Push(
          `/sample-machine-data/nation?${parseJsonToQueryString(nationalQuery)}`
        )
        break
      case EAccountRole.REGION:
      case EAccountRole.REGION_Manager:
        const regionQuery = {
          name: userState.regionName,
          code: userState.regionCode,
          codeType: '2',
          queriesDimCodes: '2',
        } as ISampleMachineRequest
        Router.Push(
          `/sample-machine-data/region?${parseJsonToQueryString(regionQuery)}`
        )
        break
      case EAccountRole.AREA:
        const areaQuery = {
          name: userState.sliceArea?.sliceAreaName,
          code: userState.sliceArea?.sliceAreaCode,
          codeType: '3',
          queriesDimCodes: '3',
        } as ISampleMachineRequest
        Router.Push(
          `/sample-machine-data/area?${parseJsonToQueryString(areaQuery)}`
        )
        break
    }
  }

  return (
    <Flex justify="center" direction="column" className="sample-machine-data">
      <Flex justify="between" className="home-area-purchase-sub-item">
        <Flex>
          <img src={yjsj} className="home-area-purchase-sub-item-image"></img>
          <span className="home-area-purchase-sub-item-title">样机数据</span>
        </Flex>
        <Switch
          checked={machineSwitch}
          title1="当月"
          title2="上月"
          onChange={swithChange}
        ></Switch>
        <Flex
          onClick={sampleMachineDataClick}
          className="detail-content"
          justify="end"
        >
          <span className="home-area-purchase-sub-item-detail">查看详情</span>
          <Icon type="right" size="xs" color="#4A90E2" />
        </Flex>
      </Flex>

      <div className="score-list">
        <div className="score-item border-right">
          <span className="score score-font">
            {info?.totalScore || '-'}
            <span style={{ fontSize: '0.26rem' }}>分</span>
          </span>
          <span className="score-name">
            <>
              {userInfo?.accountRole === EAccountRole.NATIONAL && <>全国</>}
              {[EAccountRole.REGION_Manager, EAccountRole.REGION].includes(
                userInfo?.accountRole
              ) && userInfo?.regionName}
              {userInfo?.accountRole === EAccountRole.AREA &&
                userInfo?.sliceArea?.sliceAreaName}
            </>
          </span>
        </div>
        <div className="score-item">
          <span className="score">{info?.positionSatisfyScore || '-'}</span>
          <span className="score-name">坑位满足分</span>
        </div>
        <div className="score-item">
          <span className="score">{info?.vssaSamlpeScore || '-'}</span>
          <span className="score-name">VSSA出样分</span>
        </div>
        <div className="score-item">
          <span className="score">{info?.samlpeEffectiveScore || '-'}</span>
          <span className="score-name">样机有效分</span>
        </div>
      </div>
    </Flex>
  )
}
