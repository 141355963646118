export interface ITodoMessageInfo {
  /** 消息标题 */
  noticeTitle: string
  /** 消息内容 */
  noticeContent: string
  /** 创建人 */
  createUser: string
  /** 创建时间 */
  createTime: string
  /** 修改人 */
  updateUser: string
  /**  */
  updateTime: string
  /** 消息状态 1-正常；2-已删除 */
  status: '1' | '2'
  /** 消息附件列表 */
  noticeAttarchmentList: Array<{
    /** 附件名称 */
    name: string
    /** 附件url */
    url: string
  }>
  isErrorFlag: boolean
}
/**
 * 题型
 * @param Score 1 评分题 (单选)
 * @param Radio 2 单选题
 * @param Muti 3 多选题
 * @param Question 4 问答题
 * @param Matrix 5 矩阵题（单选）
 * @param Image 6 图片题
 */
export enum ETodoQuestionType {
  /** 评分题 (单选) */
  Score = 1,
  /** 单选题 */
  Radio = 2,
  /** 多选题 */
  Muti = 3,
  /** 问答题 */
  Question = 4,
  /** 矩阵题（单选） */
  Matrix = 5,
  /** 图片题 */
  Image = 6,
}

/** 题目跳转关系map */
export interface ITodoQuizSteperMap {
  /** 当前题id */
  [currentQuizId: number]: {
    /**
     * 上一题id
     * @description 如果为空，表示第一题
     */
    prevQuizId?: number | undefined
    /**
     * 下一题id
     * @description 如果为空，表示最后一题
     */
    nextQuizId?: number | undefined
  }
}

/** 必填题目map */
export interface ITodoQuizRquiresMap {
  /** 题目id */
  [quizId: number]: boolean | undefined
}

/** 前端视图层扩充的题目结构 */
export interface ITodoQuestionInView {
  /** 题目跳转map */
  jumpMap?: ITodoQuizSteperMap
  /** 必填题目map */
  requiresMap?: ITodoQuizRquiresMap
  /** 题目序号 */
  currentIndex?: number
}

export function getJumpAndRequiresMap(questions: ITodoQuestion[]) {
  const jumpMap = {} as ITodoQuizSteperMap
  const requiresMap = {} as ITodoQuizRquiresMap

  for (let i = 0, len = questions.length; i < len; i++) {
    const item = questions[i]
    const currentQuizId = Number(item.quizId)
    const prevItem = questions[i - 1] || ({} as ITodoQuestion)
    const nextItem = questions[i + 1] || ({} as ITodoQuestion)

    item.quizId = currentQuizId
    item.currentIndex = i + 1

    jumpMap[currentQuizId] = {}
    jumpMap[currentQuizId].prevQuizId = prevItem.quizId
      ? Number(prevItem.quizId)
      : undefined
    jumpMap[currentQuizId].nextQuizId = nextItem.quizId
      ? Number(nextItem.quizId)
      : undefined

    requiresMap[currentQuizId] = Number(item.ifNecessary || '') === 1
  }

  return {
    jumpMap,
    requiresMap,
  }
}

/** 题目数据结构 */
export interface ITodoQuestion extends ITodoQuestionInView {
  /** 题目ID */
  quizId?: number
  /** 题目标题 */
  title?: string
  /** 题型: 1:单选评分题，2：单选题，3：多选题，4：问答题，5：矩阵单选题，6：图片题 */
  questionType: ETodoQuestionType
  /** 是否必填 0:非必填，1：必填 */
  ifNecessary?: 0 | 1
  /** 填写提示 */
  writeHint?: string
  /**
   * 答案，格式如下：
   * @param 单选 -> '2'
   * @param 多选 -> '2,3'
   * @param 矩阵单选 -> 'titleId:optionId,titleId:optionId,...' (titleid是小标题id, optionId是选择的选项id)
   * @param 问答 -> 'xxxxx'
   * @param 图片 -> 'https://xxx.jpg,https://xxx.png,...'
   */
  quizAnswer?: string
  /** 题目标题 */
  quizOptions?: Array<IQuestionOption>
  /** 矩阵单选题 选项列表 */
  horizontalQuizOptions?: Array<IQuestionOption>
  /** 矩阵单选题 小标题列表 */
  verticalQuizOptions?: Array<IQuestionOption>
  /** 题目跳转列表 */
  jumpQuizList?: Array<{
    /** 选项ID */
    optionId?: string
    /** 跳转题目ID */
    jumpQuizId?: string
  }>
}

export interface IQuestionOption {
  /** 选项id */
  optionId: number
  /** 选项name */
  optionContext: string
}

/**
 * 任务状态
 * 1-未完成；2-已超期；3-已完成；4-已终止
 */
export enum ETaskStatus {
  /** 未完成 */
  UnFinsih = 1,
  /** 已超期 */
  OverTime = 2,
  /** 已完成 */
  Finished = 3,
  /** 已终止 */
  Close = 4,
}

export interface ICommitTaskItemParam {
  /** 任务项ID */
  taskItemId: number
  /** 任务类型 1-到人任务；2-到店任务 */
  taskType: 1 | 2
  /** 店铺任务项ID */
  storeTaskItemId: number
}

export interface ICommitAnswersParam {
  /** 问卷ID (同sourceId) */
  questionnaireId: number
  /** 店铺编码 */
  storeCode: string
  /** 任务类型 1-到人任务；2-到店任务 */
  taskType: 1 | 2
  /** 答案 map */
  answerMap: {
    [quizId: number]: string
  }
  /** 任务项ID */
  taskItemId: number
  /** 店铺任务项ID */
  storeTaskItemId: number
}

export interface ICommitTaskErrorParam {
  /** 任务项ID */
  taskItemId: number
  /** 报备描述 */
  reportDesc: string
  /** 店铺任务项ID */
  storeTaskItemId: number
}

export interface ToTodoMessageState {
  /** 消息ID */
  sourceId: number
  /**
   * 任务状态
   * {@link ETaskStatus}
   */
  taskStatus: ETaskStatus
  /** 任务项ID */
  taskItemId: number
  /** 店铺任务ID */
  storeTaskItemId?: number
  /** 任务类型 1-到人任务；2-到店任务 */
  taskType: 1 | 2
}

export interface ToTodoInfoState {
  /** 任务项ID */
  taskItemId: number
}
export interface ToTodoQuestionState {
  /** 问卷ID （对应任务详情里面的sourceId字段） */
  questionnaireId: number
  /** 店铺编码 */
  storeCode?: string
  /** 任务项ID */
  taskItemId: number
  /** 店铺任务ID */
  storeTaskItemId?: number
  /** 任务类型 1-到人任务；2-到店任务 */
  taskType: 1 | 2
}

export interface ToTodoAnswerState {
  /** 答案id */
  answerId: number
  /** 问卷ID （对应任务详情里面的sourceId字段） */
  questionnaireId: number
  /** 店铺编码 */
  storeCode?: string
  /** 任务类型 1-到人任务；2-到店任务 */
  taskType: 1 | 2
  /** 任务项ID */
  taskItemId: number
}

export interface IQuestionProps {
  /** 题目数据 */
  data: ITodoQuestion
  index?: number
  /**
   * 只读模式？
   * @default false
   */
  readonly?: boolean
  /** 答案 */
  answer?: string
  /** 已填写矩阵题答案 (只有所有子题必选场景才会用到) */
  matrixAnswer?: string
  /** 答案更新事件 */
  onChange?: (quizId: number, answer: string) => void
  /** 保存矩阵题答案 (只有所有子题必选场景才会用到) */
  saveMatrixAnswer?: (quizId: number, answer: string) => void
}

export interface IQuestionViewData {
  /** 题目数据结构 */
  data: ITodoQuestion[]
  /** 题目跳转关系map */
  jumpMap: ITodoQuizSteperMap
  /** 必填题目map */
  requiresMap: ITodoQuizRquiresMap
  /** 报错标识 */
  isErrorFlag: boolean
}
