import Config from '@config'
import fetch from '@fetch'
import {
  globalUserState,
  IUserMeta,
  updateBaseAllState,
  updateGlobalUserInfo,
  updateUnReadNoticeCount,
  updateUserErrorMsg,
} from '@/stores'
import { formatUseInfo, getHttpErrorMessage, getRegionInfo } from './utils'
import {
  IBaseAllWarningInfoRes,
  IBlockMenuData,
  IGetBaseAllInfoParam,
  IGetBaseAllInfoResponse,
  IGetNearestStoreParam,
  IGetNearestStoreResponse,
  IHomeSURRes,
  IMedalNum,
  IMessagePageData,
  INoticePageData,
  INoticeTab,
  IQuerySampleMachineParam,
  ISampleMachineData,
  ISampleMachineItemData,
  IUnreadInspectNum,
} from './types'
import {
  IItems,
  ITaskPerformanceDataInfoPrams,
  ITaskPerformanceDataInfoResponse,
} from '../performance-report/types'
import {
  IOperationalProductHeader,
  IOperationalRequestParam,
  IOperationalRsp,
} from '../operational-data/types'

const succeedCode = '000000'

/** 查询工作台分类工具 */
export async function queryWorkbenchMenus() {
  let result: IBlockMenuData[] = []
  try {
    const { code, err, res } = await fetch.get<IBlockMenuData[]>(
      `${Config.HOST}/index/getWorkbenchTools.do`,
      {},
      { toasthide: true }
    )
    if (code === succeedCode) {
      result = res || []
    } else {
      console.log((err ? err.message : '') || '获取品类列表失败')
    }
  } catch (error) {
    console.log(getHttpErrorMessage(error))
  }
  return result
}

export async function queryOriginUser() {
  let result = {} as IUserMeta
  try {
    const { code, err, res } = await fetch.get<IUserMeta>(
      `${Config.HOST}/user/getUser.do`,
      {},
      { toasthide: true }
    )
    if (code === succeedCode) {
      result = res || ({} as IUserMeta)
    } else {
      const msg = (err ? err.message : '') || '获取用户信息失败'

      if (String(code).includes('SRCPMF')) {
        updateUserErrorMsg(msg)
      } else if (msg.toLowerCase().includes('network error')) {
        updateUserErrorMsg('网络错误')
      }

      throw new Error(msg)
    }

    if (code !== succeedCode) {
    }
  } catch (error) {
    const msg = getHttpErrorMessage(error)
    throw new Error(msg)
  }
  return { result }
}

/** 初始化user配置 with `user/getUser.do` */
export async function initGlobalUserInfo() {
  try {
    const { result: rsp } = await queryOriginUser()
    const result = await formatUseInfo(rsp)
    const newState = { ...rsp, ...result } as any
    newState.personNo = newState.userCode || rsp.personNo
    newState.name = newState.userName || rsp.name
    updateGlobalUserInfo(newState)
  } catch (error) {
    const msg = getHttpErrorMessage(error)
    updateUserErrorMsg('服务器异常')
    console.log('初始化user config: ', msg)
  }
}

/** 获取首页基础数据 */
export async function initBaseAllInfo() {
  let result = {} as IGetBaseAllInfoResponse

  if (globalUserState.originalInfo && globalUserState.originalInfo.personNo) {
    const { params } = getRegionInfo(globalUserState.originalInfo)

    try {
      const url = `${Config.HOST}/index/getBaseAllInfo.do`
      const { res, code } = await fetch.get<IGetBaseAllInfoResponse>(
        url,
        params
      )
      if (code === succeedCode) {
        result = res || ({} as IGetBaseAllInfoResponse)
      }
    } catch (error) {
      //
    }

    // TIPS； 和后端确认了没有小写s的"openstorePerformance"，只有大写S的“openStorePerformance”
    result.openStorePerformance = result.openStorePerformance || {}
    result.storeTotalCount = Number(result.storeTotalCount || '')
    result.unFinishInspectNum = Number(result.unFinishInspectNum || '')
    result.unFinishInspect = result.unFinishInspect || []

    if (result.storeDevelopVO) {
      for (const key in result.storeDevelopVO) {
        if (Object.prototype.hasOwnProperty.call(result.storeDevelopVO, key)) {
          result.storeDevelopVO[key] = result.storeDevelopVO[key] || 0
        }
      }
    }
    if (result.taskManage) {
      for (const key in result.taskManage) {
        if (Object.prototype.hasOwnProperty.call(result.taskManage, key)) {
          result.taskManage[key] = result.taskManage[key] || 0
        }
      }
    }
    updateBaseAllState(result)
  }
  return result
}

/**
 * 获取红黄牌预警信息
 * http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20220530000019
 * @param param
 */
export async function queryBaseAllWarningInfo(param: IGetBaseAllInfoParam) {
  let result = {} as IBaseAllWarningInfoRes
  try {
    const url = `${Config.HOST}/index/getBaseAllWarningInfo.do`
    const { res, code } = await fetch.get<IBaseAllWarningInfoRes>(url, param)
    if (code === succeedCode) {
      result = res || ({} as IBaseAllWarningInfoRes)
    }
  } catch (error) {
    //
  }
  result.redNum = Number(result.redNum || '')
  result.yellowNum = Number(result.yellowNum || '')
  return result
}

/**
 * 首页库存存销比数据
 * http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20220527000018
 * @param param {@link IGetBaseAllInfoParam}
 */
export async function queryStockData(param: IGetBaseAllInfoParam) {
  let result = {} as IHomeSURRes
  try {
    const url = `${Config.HOST}/index/getHomeSUR.do`
    const { res, code } = await fetch.get<IHomeSURRes>(url, param)
    if (code === succeedCode) {
      result = res || ({} as IHomeSURRes)
    }
  } catch (error) {
    //
  }
  return result
}

/** 待办工作事项汇总查询 */
export async function queryUnfinishedWork() {
  let result = {} as IUnreadInspectNum
  try {
    const url = `${Config.HOST}/inspectStore/unfinishedWork.do`
    const { res, code } = await fetch.get<IUnreadInspectNum>(
      url,
      {},
      { toasthide: true }
    )
    if (code === succeedCode) {
      result = res || ({} as IUnreadInspectNum)
    }
  } catch (error) {
    //
  }
  return result
}

/** 查询公告tabs */
export async function queryNoticeTabs() {
  let result = [] as INoticeTab[]
  try {
    const { res, code } = await fetch.post<Array<INoticeTab>>(
      `${Config.HOST}/notice/queryNoticeType.do`,
      {},
      { toasthide: true }
    )
    if (code === succeedCode) {
      result = res || []
    }
  } catch (error) {
    //
  }
  return result
}

/** 设置tab对应得未读消息个数 (from store) */
export async function updateTabNoticeCount() {
  let num = 0
  try {
    const { res, code } = await fetch.post<number>(
      `${Config.HOST}/notice/getNoticeUnreadNum.do`,
      {},
      { toasthide: true }
    )
    if (code === succeedCode) {
      num = Number(res || '')
    }
  } catch (error) {
    //
  }
  updateUnReadNoticeCount(num)
}

/** 查询系统消息页面未读消息个数 */
export async function queryNoticeCount() {
  let num = 0
  try {
    const { res, code } = await fetch.get<number>(
      `${Config.HOST}/sysNotice/unreadNoticeTotalNum.do`,
      {},
      { toasthide: true }
    )
    if (code === succeedCode) {
      num = Number(res || '')
    }
  } catch (error) {
    //
  }
  return num
}

/** 公告通知分页查询 */
export async function queryNotices(param: {
  noticeTypeId: string
  pageNum: number
  pageSize?: number
}) {
  let result = {
    totalPageCount: 0,
    dataList: [],
  } as INoticePageData
  param.pageSize = 15
  try {
    const { res, code } = await fetch.post<INoticePageData>(
      `${Config.HOST}/notice/queryNoticeList.do`,
      param,
      { toasthide: true }
    )
    if (code === succeedCode) {
      result = res || result
    }
  } catch (error) {
    //
  }
  result.dataList = result.dataList || []
  return result
}

/** 系统通知分页查询 */
export async function querySysNotices(pageNum: number) {
  let result = {
    totalPageCount: 0,
    dataList: [],
  } as IMessagePageData
  try {
    const { res, code } = await fetch.post<IMessagePageData>(
      `${Config.HOST}/sysNotice/querySysNoticeList.do`,
      { pageNum, pageSize: 15 },
      { toasthide: true }
    )
    if (code === succeedCode) {
      result = res || result
    }
  } catch (error) {
    //
  }
  result.dataList = result.dataList || []
  return result
}

/** 系统通知确定已读 */
export async function confirmRead(messageId: string) {
  let readed = false
  try {
    const { code } = await fetch.get<string>(
      `${Config.HOST}/sysNotice/confirmRead.do`,
      { messageId: messageId },
      { toasthide: true }
    )
    if (code === succeedCode) {
      readed = true
    }
  } catch (error) {
    //
  }
  return readed
}

/** 设置地图模式（baidu or gaode） */
export async function initMapMode() {
  let mapMode = 'baidumap'

  try {
    const { code, res } = await fetch.get<number>(
      `${Config.HOST}/baidu/queryBaiDuMapSwitch.do`,
      {},
      { toasthide: true }
    )
    if (code === succeedCode) {
      mapMode = res === 1 ? 'gaodemap' : 'baidumap'
    } else {
      console.log('百度地图开关返回数据异常')
      sessionStorage.setItem('configMapTypeInfo', mapMode)
    }
  } catch (error) {
    console.log('百度地图开关返回数据error')
  }

  if (!sessionStorage.getItem('configMapTypeInfo')) {
    sessionStorage.setItem('configMapTypeInfo', mapMode)
  }

  Config.MAP_TYPE = mapMode
  return mapMode as 'gaodemap' | 'baidumap'
}

/** 查询勋章数目 */
export async function queryMedalNumInfo(operationsNo: string, year: string) {
  let result = { medalVO: {} } as IMedalNum
  try {
    const { code, res } = await fetch.get<IMedalNum>(
      `${Config.HOST}/medal/queryMedalNum.do`,
      { operationsNo, year }
    )
    if (code === succeedCode) {
      result = res || result
    }
  } catch (error) {
    //
  }
  return result
}

/** 切换角色 */
export async function changeRole(roleCode: string, storeType: string) {
  let result = {} as any
  let errorMesage = ''
  try {
    const { code, res, err } = await fetch.post<IMedalNum>(
      `${Config.HOST}/user/switchRole.do`,
      { roleCode, storeType },
      { toasthide: true }
    )
    if (code === succeedCode) {
      result = res || result
    } else {
      errorMesage = (err ? err.message : '') || '获取用户信息失败'
    }
  } catch (error) {
    errorMesage = getHttpErrorMessage(error)
  }
  return {
    result,
    errorMesage,
  }
}

/**首页最近店铺定位 */
export async function gueryNearestStore(param: IGetNearestStoreParam) {
  let result = {} as IGetNearestStoreResponse
  try {
    const { code, res } = await fetch.get<IGetNearestStoreResponse>(
      `${Config.HOST}/inspect/getNearestStore.do`,
      param
    )
    if (code === succeedCode) {
      result = res || result
    }
  } catch (error) {
    //
  }
  return result
}

/**
 * 查询任务绩效数据
 * @see http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20220606000037
 */
export async function queryTaskPerformanceDataInfo(
  param: ITaskPerformanceDataInfoPrams
) {
  let result = {
    header: {} as IItems,
    items: [],
  } as ITaskPerformanceDataInfoResponse
  try {
    const { code, res } = await fetch.get<ITaskPerformanceDataInfoResponse>(
      `${Config.HOST}/baichuan/queryTaskPerformanceDataInfo.do`,
      param
    )
    if (code === succeedCode) {
      result = res || result
      result.header = result.header || ({} as IItems)
      result.items = result.items || []
    }
  } catch (error) {
    //
  }
  return result
}

/**
 * 查询首页经营数据
 * @see http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20210915000014
 */
export async function queryHomeBusinessDataInfo(
  param: IOperationalRequestParam
) {
  let result = {
    productHeader: {} as IOperationalProductHeader,
  } as IOperationalRsp
  try {
    const { code, res } = await fetch.get<IOperationalRsp>(
      `${Config.HOST}/baichuan/queryBusinessDataInfo.do`,
      param
    )
    if (code === succeedCode) {
      result = res || result
      result.productHeader =
        result.productHeader || ({} as IOperationalProductHeader)
    }
  } catch (error) {
    //
  }
  return result
}

/** 样机数据查询 */
export async function querySampleMachine(param: IQuerySampleMachineParam) {
  let result = {
    sampleDataInfoHeader: {} as ISampleMachineItemData,
    sampleDataInfoItems: [],
  } as ISampleMachineData
  try {
    const { code, res } = await fetch.get<ISampleMachineData>(
      `${Config.HOST}/baichuan/querySampleDataInfo.do`,
      param
    )
    if (code === succeedCode) {
      result = res || result
      result.sampleDataInfoItems = result.sampleDataInfoItems || []
      result.sampleDataInfoHeader =
        result.sampleDataInfoHeader || ({} as ISampleMachineItemData)
    }
  } catch (error) {
    //
  }

  return result
}
