/*
 * @Author: 韩念琪[18112596]
 * @Email: 18112596@cnsuning.com
 * @Date: 2019-07-23 17:11:38
 * @Last Modified by: 吴超(20023244)
 * @Last Modified time: 2023-07-22 09:23:50
 * @Description: 项目入口页面
 */
import { Component } from 'react'
import { AppContainer } from './router-render'
import fetchJsonp from 'fetch-jsonp'
import Config from '@config'
import ReactDOM from 'react-dom'
import loadPlugins from './load-plugins'
import toastArgoPlugin from './plugins/toast-argo-plugin'
// import passportArgoPlugin from './plugins/passport-argo-plugin'
import uomArgoPlugin from './plugins/uom-argo-plugin'
import exceptionsUploadArgoPlugin from './plugins/exceptions-upload-argo-plugin'
import watermarkArgoPlugin from './plugins/watermark-argo-plugin'
import { initGlobalUserInfo } from './views/home/HomeService'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import 'reflect-metadata'
import './index.less'

//@ts-ignore 要放开
window._AMapSecurityConfig = {
  securityJsCode: Config.AMAP_JS_CODE,
}

// @ts-ignore
@loadPlugins(
  // @ts-ignore
  toastArgoPlugin,
  // passportArgoPlugin,
  uomArgoPlugin,
  exceptionsUploadArgoPlugin,
  watermarkArgoPlugin
)
class Root extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidMount() {
    initGlobalUserInfo()

    setInterval(() => {
      fetchJsonp(`${Config.HOST}/authStatus`).then((res) => {
        console.log(' -----> authStatus', res)
      })
    }, 1 * 60 * 1000)
  }

  render() {
    return <AppContainer />
  }
}

ReactDOM.render(<Root />, document.getElementById('root'))
