/*
 * @Author: hannianqi
 * @Date: 2019-03-11 11:08:57
 * @Last Modified by: 吴超(20023244)
 * @Last Modified time: 2023-06-08 17:24:35
 *
 * 通用埋点装饰器
 */
import { Component } from 'react'

interface ISaParam {
  /** 页面归类 */
  pgcate?: string
  /** 产品类型 */
  prdtp?: string
  /** 页面的唯一标题 */
  pgtitle?: string
  /** 商品编码 */
  prdid?: string
  /** 页面编码 */
  pageid?: string
  /** 产品线 (必填参数) */
  resourceType?: string
}

/**
 * 将键值对转换成对象格式
 * @param { string } saPageInfo [key=value;key=value...] 格式的字符串
 */
function parseSa(saPageInfo: string = ''): ISaParam {
  return saPageInfo
    .split(';')
    .filter(Boolean)
    .reduce((result, value) => {
      const [key, val] = value.split('=').map((val) => val.trim())
      val && (result[key] = val)
      return result
    }, {})
}

const Sa = function (saPageInfo?: string): any {
  return function (WrapperedClass: Function) {
    class SaClass extends Component {
      saPageInfoObj: any
      /** 挂载 pageid 的 meta */
      pageidMeta: HTMLElement
      /** 挂载 pagename 的 隐藏域 */
      pagename: HTMLInputElement

      constructor(props) {
        super(props)
        this.saPageInfoObj = parseSa(saPageInfo)

        console.log(' ------> ', this.saPageInfoObj)

        this.pageidMeta = document.getElementsByName('pageid')[0]
        this.pagename = document.getElementById('pagename') as HTMLInputElement
      }

      /** 发送埋点数据 */
      sendSaData = () => {
        try {
          this.pageidMeta['content'] = this.saPageInfoObj.pageid
          this.pagename.value = saPageInfo
          window['_saPageViewInit']()
        } catch (err) {
          console.error(err)
        }
      }

      componentDidMount() {
        saPageInfo && this.sendSaData()
      }

      render() {
        return <WrapperedClass {...this.props} />
      }
    }
    return SaClass
  }
}

// eslint-disable-next-line
interface Sa {
  /** 更新隐藏域 pagename */
  update: (saPageInfo: string) => void
  /** 手动发送埋点 */
  send(): void
}

Sa.update = function (saPageInfo: string) {
  try {
    const saPageInfoObj = parseSa(saPageInfo)
    const pageidMeta = document.getElementsByName('pageid')[0]
    const pagename = document.getElementById('pagename') as HTMLInputElement
    pagename.value = saPageInfo
    saPageInfoObj.pageid && (pageidMeta['content'] = saPageInfoObj.pageid)
    window['_saPageViewInit']()
  } catch (err) {
    console.error(err)
  }
}

/**
 * 发送自定义事件日志
 * @param eventType 事件名、如'securityAudit'
 * @param opts 由产品人员定义后给出
 */
Sa.send = function (eventType: string, opts: { [k: string]: string }) {
  try {
    if (window['SAUP'] && typeof window['SAUP'].sendLogData == 'function') {
      /**
       *  发送数据接口
       *  第一个参数须传入custom
       *  第二个参数请以json格式传入
       *  eventType: 事件名、如'securityAudit'
       *  其余字段由产品人员定义后给出
       *  若第二个参数中的某个参数的值要传json，请先转成json字符串
       */
      window['SAUP'].sendLogData('custom', { eventType: eventType, ...opts })
    }
  } catch (e) {
    console.log(e)
  }
}

/**
 * 发送点击事件日志
 * @param modid - 区块编码，对应 sap-modid 属性
 * @param data - 数据，对应 sa-data 属性
 *
 * Sa.sendClickData('0f3nz48', {
 *   eleid: '1',
 *   eletitle: '测试'
 * })
 * 相当于点击 <div sap-modid="0f3nz48" sa-data="{"eleid":"1","eletitle":"测试"}">
 */
Sa.sendClickData = function (modid: string, data: { [k: string]: string }) {
  try {
    if (window['SAUP'] && typeof window['SAUP'].sendLogData == 'function') {
      const el = document.createElement('div')
      el.style.display = 'none'
      el.setAttribute('sap-modid', modid)
      el.setAttribute('sa-data', JSON.stringify(data))
      // 方式一：模拟点击
      el.addEventListener(
        'click',
        () => {
          requestAnimationFrame(() => {
            el.remove()
          })
        },
        false
      )
      document.body.appendChild(el)
      el.click()

      // // 方式二：调用 SAUP 提供的 API，实际上报的参数和模拟点击不同，没有包含 safp 等字段
      // window['SAUP'].sendLogData('click', {
      //   tag: el.cloneNode()
      // });
    } else {
      throw new Error('没有引入 sa_simple.js/da_opt.js')
    }
  } catch (e) {
    console.log(e)
  }
}

export default Sa
