import classNames from 'classnames'
import openStore from '@assets/images/open-store.png'
import PieCharts from './pie-charts'
import { EAccountRole, ESearchType } from '@genre'
import { Flex, Icon } from 'antd-mobile'
import { baseAllState, getGlobalUserConfig, globalUserState } from '@/stores'
import { Router, Sa, util } from '@kits'
import { useSnapshot } from 'valtio'
import './index.less'

interface IProps {
  titleName: string
}

export default function OpenStore({ titleName }: IProps) {
  const userInfo = useSnapshot(globalUserState)
  const baseAllInfo = useSnapshot(baseAllState)

  /** 大区详情 */
  function goOpenStore() {
    const userState = getGlobalUserConfig()

    Sa.sendClickData('04knnp4', {
      eleid: '8',
      eletitle: `开店进展查看详情`,
      ...util.saRoleParamClickObj(),
    })

    window.localStorage.setItem('', 'a')

    switch (userState.accountRole) {
      case EAccountRole.NATIONAL:
        window.localStorage.setItem(ESearchType.NATIONAL_PERFORMANCE, 'true')
        Router.Push(`/perf/open-store?type=${ESearchType.NATIONAL_PERFORMANCE}`)
        break
      case EAccountRole.REGION:
      case EAccountRole.REGION_Manager:
        // window.localStorage.setItem(ESearchType.REGION_PERFORMANCE, 'true');
        break
      case EAccountRole.AREA:
        // window.localStorage.setItem(ESearchType.AREA_PERFORMANCE, 'true');
        break
    }
  }

  return (
    <Flex
      justify="center"
      direction="column"
      className={classNames('home-open-store', 'home-open-store-modify')}
    >
      <Flex justify="between" className="home-open-store-sub-item">
        <Flex>
          <img src={openStore} className="home-open-store-sub-item-image" />
          <span className="home-open-store-sub-item-title">{titleName}</span>
        </Flex>
        {userInfo?.accountRole === EAccountRole.NATIONAL && (
          <Flex onClick={goOpenStore} className="detail-content" justify="end">
            <span className="home-purchase-sub-item-detail">查看详情</span>
            <Icon type="right" size="xs" color="#4A90E2" />
          </Flex>
        )}
      </Flex>
      {/* 之前取错字段了，应该是"openStoreProgressVO", 而不是"openstorePerformance" */}
      {baseAllInfo?.openStoreProgressVO?.openedStoreNum ? (
        <Flex direction="column" className="tst">
          <PieCharts
            total={baseAllInfo?.openStoreProgressVO?.totalOpenStoreNum}
            pieData={[
              {
                value: baseAllInfo?.openStoreProgressVO?.openedStoreNum,
                name: '已完成',
              },
              {
                value: baseAllInfo?.openStoreProgressVO?.unOpenStoreNum,
                name: '未完成',
              },
            ]}
            pieName="利润分布图"
          />
        </Flex>
      ) : (
        <Flex className="home-open-store-store-err">
          <span>数据统计中，请明日查看</span>
        </Flex>
      )}
    </Flex>
  )
}
