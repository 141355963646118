/*
 * @Author: 韩念琪[18112596]
 * @Email: 18112596@cnsuning.com
 * @Date: 2019-07-23 22:54:53
 * @Last Modified by: 韩念琪[18112596]
 * @Last Modified time: 2019-08-27 11:54:06
 * @Description: argoJS 插件加载函数
 */

import ArgoPlugin from './plugins/argo-plugin'
import fetch from '@fetch'
import React, { Component } from 'react'
import { createStore } from '@argojs/sim-redux'

interface IState {
  /** 所有初始化插件执行完毕 */
  initReady: boolean
}

function loadPlugins(...plugins: ArgoPlugin[]) {
  return function(Comp: Function) {
    return class extends Component<any, IState, IState> {
      plugins: ArgoPlugin[] = []
      initProcess: IterableIterator<null>
      constructor(props) {
        super(props)
        this.state = {
          initReady: false
        }
        this.initProcess = this.continueProcessAfterInit()
        this.loadLifeCycleHooks()
      }

      componentDidMount() {
        this.initProcess.next()
      }

      /**
       * 加载生命周期
       */
      async loadLifeCycleHooks() {
        await this.callPluginsInit()
        this.addExportsToGlobal()
        this.addFetchRequestListener()
        this.addAfterPageInitListener()
        this.initProcess.next()
      }

      /**
       * 调用 插件 init 方法
       */
      async callPluginsInit() {
        const initTask: Promise<boolean>[] = plugins.map(plugin =>
          plugin.init
            ? Promise.resolve<any>(plugin.init()).catch(() => false)
            : Promise.resolve(true)
        )

        const initResult = await Promise.all(initTask)
        this.plugins = initResult.reduce(
          (acc, initStatus, i) =>
            initStatus !== false ? acc.concat(plugins[i]) : acc,
          []
        )
        return this.plugins
      }

      /**
       * 对外抛出插件的全局对象
       */
      addExportsToGlobal() {
        window['Argo'] = this.plugins.reduce(
          (acc, plugin) => (
            plugin.globalExport &&
              (acc[plugin.constructor.name] = plugin.globalExport()),
            acc
          ),
          window['Argo'] || ({} as any)
        )
      }

      /**
       * 挂载 fetchRequest 生命周期监听
       */
      addFetchRequestListener() {
        const middlewares = this.plugins
          .filter(plugin => !!plugin.fetchRequest)
          .map(plugin => plugin.fetchRequest())
        fetch.addMiddlewares(...middlewares)
      }

      /**
       * 挂载 afterPageInit 生命周期监听
       */
      addAfterPageInitListener() {
        const middlewares = this.plugins
          .filter(plugin => !!plugin.simReduxDataChange)
          .map(plugin => plugin.simReduxDataChange())
        createStore.preMiddlewares = [
          ...createStore.preMiddlewares,
          ...middlewares
        ]
      }

      /**
       * 控制阻塞流程是否继续
       */
      *continueProcessAfterInit() {
        yield null
        this.setState({ initReady: true })
      }

      render() {
        return <>{this.state.initReady && <Comp {...this.props} />}</>
      }
    }
  }
}

export default loadPlugins
