/* eslint-disable snrcfe/with-file-header */
import {
  IDedaultDateByMode,
  EMode,
  ITheTimePickerProps,
  ITimePickerOption,
  modeDescs,
  PickerValue,
  ValueTypes
} from './TimePickerTypes'

class PickerOption<C extends EMode, V extends ValueTypes> {
  settings: Partial<ITheTimePickerProps<C, V>>

  constructor(props: Partial<ITheTimePickerProps<C, V>>) {
    this.settings = props
  }

  getConvertedYearAndMonth() {
    const settings = this.settings
    const currentDate = new Date()
    const day = currentDate.getUTCDate() // todow
    let year = currentDate.getFullYear()
    let month = currentDate.getMonth()

    if (
      (settings.quarterConvert &&
        month % 3 === 0 &&
        (day === 1 || day === 2)) ||
      (settings.monthConvert && (day === 1 || day === 2))
    ) {
      if (month === 0) {
        // 1月1，2号 转成上一年12月
        year = year - 1
        month = 11
      } else {
        // 其他季度首月1，2号转成上个月
        month = month - 1
      }
    }

    return { year, month }
  }

  getInitialTimeOptions(mode: EMode) {
    const { optionsStartDate, optionsEndDate } = this.settings
    const startOpts = optionsStartDate[mode]
    const endOpts = optionsEndDate[mode]
    const yearOpts = this.getYearOptions(mode)

    return { yearOpts, startOpts, endOpts }
  }

  getYearOptions(mode: EMode) {
    const { optionsStartDate, optionsEndDate } = this.settings
    const options: ITimePickerOption[] = []
    const [optionStartYear] = optionsStartDate[mode]
    const [optionEndYear] = optionsEndDate[mode]
    let { year } = this.getConvertedYearAndMonth()
    year = year > optionEndYear ? optionEndYear : year

    for (let i = year; i >= optionStartYear; i--) {
      options.push({
        label: `${i}年`,
        value: i,
        children: []
      })
    }

    return options
  }

  getQuarterOptions() {
    const options: ITimePickerOption[] = []
    const { yearOpts, startOpts, endOpts } = this.getInitialTimeOptions(
      EMode.Quarter
    )
    const [startYear, startQuarter] = startOpts
    const [endYear, endQuarter] = endOpts
    yearOpts.forEach(v => {
      let count = 4
      let tag = 1
      const year = v.value as number
      const children: ITimePickerOption[] = []
      if (year === endYear) {
        count = endQuarter
      }
      if (year === startYear) {
        count = 4
        tag = startQuarter
      }
      count = count > 4 ? 4 : count
      for (let i = tag; i <= count; i++) {
        children.push({
          label: `${toChineseNumber(i)}季度`,
          value: i
        })
      }
      v.children = children
      options.push(v)
    })
    return options
  }

  getMonthOptions(mode = EMode.Month) {
    const options: ITimePickerOption[] = []
    const { yearOpts, startOpts, endOpts } = this.getInitialTimeOptions(mode)
    const [startYear, startMonth] = startOpts
    const [endYear, endMonth] = endOpts
    yearOpts.forEach(v => {
      let count = 12
      let tag = 1
      const year = v.value as number
      const children: ITimePickerOption[] = []
      if (year === endYear) {
        count = endMonth
      }
      if (year === startYear) {
        count = 12
        tag = startMonth
      }
      count = count > 12 ? 12 : count
      for (let i = tag; i <= count; i++) {
        children.push({
          label: `${i.toString().padStart(2, '0')}月`,
          value: i
        })
      }
      v.children = children
      options.push(v)
    })
    return options
  }

  getDayOptions() {
    const options = this.getMonthOptions(EMode.Day)
    const { startOpts, endOpts } = this.getInitialTimeOptions(EMode.Day)
    const [_startYear, startMonth, startDate] = startOpts
    const [_endYear, endMonth, endDate] = endOpts
    for (const o of options) {
      const { value: year } = o
      const months = o.children || []
      for (const m of months) {
        const { value: month, children = [] } = m
        let theStartDate = 1
        let theEndDate = 31
        if ([4, 6, 9, 11].includes(month as number)) {
          theEndDate = 30
        }
        if (month === startMonth) {
          theStartDate = startDate
        }
        if (month === endMonth) {
          theEndDate = endDate
        }
        if (month === 2) {
          theEndDate = this.getFebruaryDays(year as number)
        }
        for (let i = theStartDate; i <= theEndDate; i++) {
          children.push({
            label: `${i.toString().padStart(2, '0')}日`,
            value: i
          })
        }
        m.children = children
      }
    }
    return options
  }

  getFebruaryDays(year: number) {
    let days = 29
    const tempFebDate = new Date(year, 1, 29).getDate()
    if (tempFebDate !== 29) {
      days = 28
    }
    return days
  }

  getSecondLevelOptions(mode: EMode) {
    let options: ITimePickerOption[] = []
    switch (mode) {
      case EMode.Year:
        options = this.getYearOptions(EMode.Year)
        break
      case EMode.Quarter:
        options = this.getQuarterOptions()
        break
      case EMode.Month:
        options = this.getMonthOptions()
        break
      case EMode.Day:
        options = this.getDayOptions()
        break
      default:
        break
    }
    return options
  }

  /**
   * 获取多模式选择场景对应的模式选项
   * @return modes 模式选项数据 {@link ITimePickerOption}[]
   */
  getTimeModeOptions() {
    const byModes: ITimePickerOption[] = []
    for (const mode of this.settings.modes) {
      byModes.push({
        label: modeDescs[mode],
        value: mode,
        children: []
      })
    }
    return byModes
  }
}

/**
 * 获取picker options
 */
export function getTimpePickerOptions<C extends EMode, V extends ValueTypes>(
  props: Partial<ITheTimePickerProps<C, V>>
) {
  const { modes, checkedMode } = props
  const pickerOption = new PickerOption(props)
  const isMutipleMode = modes.length > 1
  let options: ITimePickerOption[] = []
  if (isMutipleMode) {
    // 多场景选择模式
    const modeOptions = pickerOption.getTimeModeOptions()
    modeOptions.forEach((opt, idx) => {
      const currentMode = modes[idx]
      opt.children = pickerOption.getSecondLevelOptions(currentMode)
    })
    options = [...modeOptions]
  } else {
    // 单一场景选择模式
    options = pickerOption.getSecondLevelOptions(checkedMode)
  }
  return options
}

/**
 * 获取picker button area对应的展示文案
 */
export function getTimePickerDisplayName(
  mode: EMode,
  defaultValue: ValueTypes
) {
  let label = ''
  if (defaultValue && defaultValue.length > 0) {
    switch (mode) {
      case EMode.Year:
        {
          const [year = 0] = defaultValue
          label = `${year}年`
        }
        break
      case EMode.Quarter:
        {
          const [year = 0, quarter = 0] = defaultValue
          label = `${year}年${toChineseNumber(quarter) || ''}季度`
        }
        break
      case EMode.Month:
        {
          const [year = 0, month = 0] = defaultValue
          label = `${year}年${getNumberStrOnFixedWithZero(month)}月`
        }
        break
      case EMode.Day:
        {
          const [year = 0, month = 0, day = 0] = defaultValue
          label = `${year}年${getNumberStrOnFixedWithZero(
            month
          )}月${getNumberStrOnFixedWithZero(day)}日`
        }
        break
      default:
        break
    }
  }
  return label
}

/**
 * 获取查询接口需要的时间参数值
 */
export function getTimeValueForPicker(mode: EMode, defaultValue: ValueTypes) {
  let label = ''
  if (defaultValue && defaultValue.length > 0) {
    switch (mode) {
      case EMode.Year:
        {
          const [year = 0] = defaultValue
          label = `${year}`
        }
        break
      case EMode.Quarter:
        {
          const [year = 0, quarter = 0] = defaultValue
          label = `${year}-${getNumberStrOnFixedWithZero(quarter) || ''}`
        }
        break
      case EMode.Month:
        {
          const [year = 0, month = 0] = defaultValue
          label = `${year}-${getNumberStrOnFixedWithZero(month)}`
        }
        break
      case EMode.Day:
        {
          const [year = 0, month = 0, day = 0] = defaultValue
          label = `${year}-${getNumberStrOnFixedWithZero(
            month
          )}-${getNumberStrOnFixedWithZero(day)}`
        }
        break
      default:
        break
    }
  }
  return label
}

/**
 * 获取picker options 列数
 */
export function getTimePickerColumns(mode: EMode, modes: EMode[] = []) {
  let cols = 1
  if (!modes.length || modes.length === 1) {
    // single mode
    switch (mode) {
      case EMode.Year:
        cols = 1
        break
      case EMode.Quarter:
        cols = 2
        break
      case EMode.Month:
        cols = 2
        break
      case EMode.Day:
        cols = 3
        break
      default:
        break
    }
  } else {
    // mutiple modes
    if (modes.includes(EMode.Day)) {
      cols = 4
    } else if (modes.includes(EMode.Quarter) || modes.includes(EMode.Month)) {
      cols = 3
    } else if (modes.includes(EMode.Year)) {
      cols = 2
    }
  }
  return cols
}

export function toChineseNumber(number: number) {
  return {
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
    7: '七',
    8: '八',
    9: '九',
    10: '十',
    11: '十一',
    12: '十二'
  }[number]
}

export function getNumberStrOnFixedWithZero(number: number) {
  return number.toString().padStart(2, '0')
}

export const getDefaultStartDateOptions = (
  options = {} as IDedaultDateByMode
) => {
  return {
    [EMode.Year]: [2017], // 2017年
    [EMode.Quarter]: [2017, 1], // 2017年第一季度
    [EMode.Month]: [2019, 8], // 2019年8月
    [EMode.Day]: [2019, 8, 1], // 2019年8月1日
    ...options
  }
}

export const getDefaultEndDateOptions = (
  options = {} as IDedaultDateByMode
) => {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = new Date().getMonth() + 1

  return {
    [EMode.Year]: [year],
    [EMode.Quarter]: [year, getQuarterByMonth(month)],
    [EMode.Month]: [year, month],
    [EMode.Day]: [year, month, currentDate.getDate()],
    ...options
  }
}

/**
 * 月转换成季度
 * @param month 从1开始
 * @returns quarter
 */
export function getQuarterByMonth(month: number = 1) {
  let quarter = 1

  if ([4, 5, 6].includes(month)) {
    quarter = 2
  }
  if ([7, 8, 9].includes(month)) {
    quarter = 3
  }
  if ([10, 11, 12].includes(month)) {
    quarter = 4
  }

  return quarter
}

/**
 * 根据mode获取基于当前日期的默认值
 * @param mode {@link EMode}
 * @return value string (例如 2021-03-02)
 */
export function getDefaultTimeStrByMode(mode: EMode) {
  let value = ''
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1

  switch (mode) {
    case EMode.Year:
      value = year.toString()
      break
    case EMode.Quarter:
      value = `${year}-${getNumberStrOnFixedWithZero(getQuarterByMonth(month))}`
      break
    case EMode.Month:
      value = `${year}-${getNumberStrOnFixedWithZero(month)}`
      break
    case EMode.Day:
      value = `${year}-${getNumberStrOnFixedWithZero(
        month
      )}-${getNumberStrOnFixedWithZero(currentDate.getDate())}`
      break
    default:
      break
  }
  return value
}

/**
 * 根据mode获取基于当前日期的默认值
 * @param timeStr string  (例如 '2021-09')
 * @return values {@link ValueTypes}
 */
export function getTimeValuesByTimeStr(timeStr = '') {
  const strs = timeStr.split('-')
  const value = strs.map(s => Number(s)) as ValueTypes
  return value
}

/**
 * 根据mode获取基于当前日期的默认值
 * @param values {@link ValueTypes}  (例如 [2021, 9])
 * @return timeStr string (例如 2021-03-02)
 */
export function getTimeStrByTimeValues(values = []) {
  const strs: string[] = values.map((val: number) =>
    getNumberStrOnFixedWithZero(val)
  )
  return strs.join('-')
}

/**
 * 默认值规范检测，并输出错误描述
 * @return errorMsg 为空表示通过验证
 */
export function getDefaultValueErrorMsg<C extends EMode, V extends ValueTypes>(
  defaultValue: PickerValue<C, V>,
  mode: EMode,
  optionsStartDate: IDedaultDateByMode,
  optionsEndDate: IDedaultDateByMode
) {
  let errorMsg = ''
  if (defaultValue && defaultValue.length > 0) {
    const startOpts = optionsStartDate[mode]
    const endOpts = optionsEndDate[mode]
    if (mode === EMode.Year) {
      {
        const [year] = defaultValue
        const [startYear] = startOpts
        const [endYear] = endOpts
        if (!year || year.toString().length !== 4) {
          errorMsg = '[picker: 按年] -> 年份默认值无效，不符合规范，请检查'
        } else if (year < startYear || year > endYear) {
          errorMsg = '[picker: 按年] -> 年份超出选项范围'
        }
      }
    } else if (mode === EMode.Quarter) {
      {
        const [year, quarter] = defaultValue
        const [startYear, startQuarter] = startOpts
        const [endYear, endQuarter] = endOpts
        if (
          !year ||
          !quarter ||
          year.toString().length !== 4 ||
          quarter < 0 ||
          quarter > 4
        ) {
          errorMsg = '[picker: 按季度] -> 默认值无效，不符合规范，请检查'
        } else if (year < startYear || year > endYear) {
          errorMsg = '[picker: 按季度] -> 年份超出选项范围'
        } else if (year === startYear) {
          if (quarter < startQuarter) {
            errorMsg = '[picker: 按季度] -> 季度超出选项开始范围'
          }
        } else if (year === endYear) {
          if (quarter > endQuarter) {
            errorMsg = '[picker: 按季度] -> 季度超出选项结束范围'
          }
        }
      }
    } else if (mode === EMode.Month || mode === EMode.Day) {
      {
        const tag = mode === EMode.Month ? '按月' : '按天'
        const [year, month, date] = defaultValue
        const [startYear, startMonth, startDate] = startOpts
        const [endYear, endMonth, endDate] = endOpts
        if (
          !year ||
          !month ||
          (!date && mode === EMode.Day) ||
          year.toString().length !== 4 ||
          month < 1 ||
          month > 12
        ) {
          errorMsg = `[picker: ${tag}] -> 默认值无效，不符合规范，请检查`
        } else if (year < startYear || year > endYear) {
          errorMsg = `[picker: ${tag}] -> 年份超出选项范围`
        } else if (year === startYear) {
          if (month < startMonth) {
            errorMsg = `[picker: ${tag}] -> 月度超出选项开始范围`
          }
        } else if (year === endYear) {
          if (month > endMonth) {
            errorMsg = `[picker: ${tag}] -> 月度超出选项结束范围`
          }
        }
        if (mode === EMode.Day) {
          if (month === startMonth && date < startDate) {
            errorMsg = `[picker: ${tag}] -> 日期(天)超出选项开始范围`
          }
          if (month === endMonth && date > endDate) {
            errorMsg = `[picker: ${tag}] -> 日期(天)超出选项结束范围`
          }
        }
      }
    }
  }
  return errorMsg
}
