import React, { useState } from 'react'
import styles from './index.module.less'
import { IconEX } from '@components'
import { Observable, BehaviorSubject, Subject, merge } from 'rxjs'
import {
  map,
  withLatestFrom,
  tap,
  combineLatest,
  startWith,
  filter,
  debounceTime
} from 'rxjs/operators'
import { useEventCallback, useObservable } from 'rxjs-hooks'
import OrderNum from '../order-num'

/** 排序类型 */
export enum SortType {
  /** 降序 */
  dasc = -1,
  /** 升序 */
  asc = 1,
  /** 未设置 */
  none = 0
}

/** 排序类型对应的图标 */
const sortIcon = {
  '-1': 'paixu-down',
  '0': 'paixu',
  '1': 'paixu-up'
}

interface IColumn {
  /** 列头主标题 */
  title: string
  /** 列头副标题 */
  subtitle?: string
  /** 列头副对应的 key */
  subtitleKey?: string
  /** 列宽度 */
  width?: string | number
  /** 列数据在数据项中对应的 key */
  dataIndex: string
  /** React 需要的 key，如果已经设置了唯一的 dataIndex，可以忽略这个属性 */
  key: string
  /** 生成复杂数据的渲染函数，参数分别为当前行的值，当前行数据，行索引 */
  render?: (rowData: any, record: any, index: number) => JSX.Element
  /** 排序函数，本地排序使用一个函数(参考 Array.sort 的 compareFunction)，需要服务端排序可设为 true */
  sorter?: (a: any, b: any) => number
  /** 排序状态 */
  sortType?: SortType
}

interface IProps {
  /** 表格列的配置描述 */
  columns$: Observable<IColumn[]>
  /** 表格的数据源 数据数组 */
  dataSource: any[]
  /** 是否处于 loading 状态 */
  loading: boolean
  /** 当前排序状态状态 */
  currentSortType$: Subject<SortType>
  /** 整个表格的宽度 */
  tableWidth?: string | number
  /** 设置表格外容器的 overflow 属性*/
  overflow?: 'hidden' | 'auto' | 'visible' | 'scroll' | 'inherit'
  /** 检查是否出现滚动条 */
  checkIsScroll?: (isScroll: boolean) => void
  /** 更新第一列的宽 */
  updateFixedColumnWidth?: (width: number) => void
  /** 同步用于固定的表格数据 */
  unifyDataSource?: (dataSource: any[]) => void
  /** 最大显示的排序 */
  maxOrder?: number
  /** 点击行的回调 */
  onRowClick?: (data: any, index: number) => void
  /** 自定义背景色 */
  bgc?: 'gray' | 'blue'
}

const TableBase: React.SFC<IProps> = props => {
  const {
    columns$,
    overflow,
    dataSource,
    tableWidth,
    checkIsScroll,
    unifyDataSource,
    currentSortType$,
    updateFixedColumnWidth,
    loading,
    maxOrder,
    onRowClick,
    bgc
  } = props
  /** 表格外容器 ref */
  const [tableWrapperEle$] = useState(new Subject<any>())
  /** 固定位置列 ref */
  const [fixedColumnEle$] = useState(new Subject<any>())
  /** 上一次的排序列下标 */
  const [previosuColumnIndex$] = useState(new BehaviorSubject(0))
  const currentSortType = useObservable(
    (_state$: Observable<SortType>) => currentSortType$,
    SortType.asc
  )

  /** 存储 datasource 用于本地排序 */
  const { dataSourceKeys, columns } = useObservable(
    (
      _state$: Observable<{
        dataSourceKeys: { key: string; subKey: string }[]
        columns: IColumn[]
      }>
    ) => {
      columns$
        .pipe(
          combineLatest(tableWrapperEle$, fixedColumnEle$),
          filter(value => value.every(Boolean)),
          debounceTime(10)
        )
        .subscribe(([_, tableWrapperEle, fixedColumnEle]) => {
          /** 判断是否出现滚动条 */
          const tableWrapperWidth = tableWrapperEle.getBoundingClientRect()
            .width
          const scrollWidth = tableWrapperEle.scrollWidth
          checkIsScroll && checkIsScroll(tableWrapperWidth < scrollWidth)
          /** 动态获取第一列的宽 */
          const fixedColumnWidth = fixedColumnEle.getBoundingClientRect().width
          updateFixedColumnWidth && updateFixedColumnWidth(fixedColumnWidth)
        })
      return columns$.pipe(
        tap(columns =>
          previosuColumnIndex$.next(
            columns.findIndex(column => !!column.sorter)
          )
        ),
        map(columns => ({
          dataSourceKeys: columns.map(columns => ({
            key: columns.dataIndex || columns.key,
            subKey: columns.subtitleKey
          })),
          columns
        }))
      )
    },
    {
      dataSourceKeys: [] as { key: string; subKey: string }[],
      columns: [] as IColumn[]
    }
  )

  const [sortClick, sortedDataSource] = useEventCallback<any>(
    (
      sortClick$: Observable<[(a, b) => number, number, number]>,
      input$: Observable<[any[][], IColumn[]]>,
      _state$: Observable<IColumn[]>
    ) => {
      // const sortedDataSource$ = sortClick$.pipe(
      //   startWith([]),
      //   combineLatest(input$),
      //   withLatestFrom(currentSortType$),
      //   map(([[[sorter, sortType], [dataSource]], currentSortType]) => {
      //     const result = sortType
      //       ? dataSource
      //         .sort((a, b) => sortType * currentSortType * sorter(a, b))
      //         .slice()
      //       : dataSource.slice();
      //     currentSortType$.next(currentSortType);
      //     return result;
      //   }),
      //   tap(dataSource => {
      //     unifyDataSource && unifyDataSource(dataSource);
      //   })
      // );

      /** 点击排序 */
      const sortedDataByClick$ = sortClick$.pipe(
        startWith([]),
        withLatestFrom(input$, currentSortType$),
        map(([[sorter, sortType], [dataSource], currentSortType]) => {
          const result = sortType
            ? dataSource
                .sort((a, b) => sortType * currentSortType * sorter(a, b))
                .slice()
            : dataSource.slice()
          currentSortType$.next(currentSortType)
          return result
        })
      )

      /** 数据更新排序 */
      const sortedDataByDataChange$ = input$.pipe(
        withLatestFrom(currentSortType$),
        map(([[dataSource, columns], currentSortType]) => {
          const shouldColumnSortedFirstIndex = columns.findIndex(
            column => !!column.sorter
          )
          const columnSortedFirstIndex = columns.findIndex(
            column => column.sortType === SortType.dasc
          )
          const columnSortedFirst = columns[columnSortedFirstIndex]
          if (
            columnSortedFirstIndex === shouldColumnSortedFirstIndex &&
            columnSortedFirst
          ) {
            previosuColumnIndex$.next(columnSortedFirstIndex)
            const result = columnSortedFirst.sortType
              ? dataSource
                  .sort(
                    (a, b) =>
                      columnSortedFirst.sortType *
                      currentSortType *
                      columnSortedFirst.sorter(a, b)
                  )
                  .slice()
              : dataSource.slice()
            currentSortType$.next(currentSortType)
            return result
          } else {
            return dataSource
          }
        })
      )

      sortClick$
        .pipe(withLatestFrom(currentSortType$, previosuColumnIndex$))
        .subscribe(
          ([
            [_sorter, _sortType, index],
            currentSortType,
            previosuColumnIndex
          ]) => {
            if (index !== previosuColumnIndex) {
              currentSortType$.next(1)
              previosuColumnIndex$.next(index)
            } else {
              currentSortType$.next(
                currentSortType === SortType.asc ? SortType.dasc : SortType.asc
              )
            }
          }
        )

      return merge(sortedDataByClick$, sortedDataByDataChange$).pipe(
        tap(dataSource => {
          unifyDataSource && unifyDataSource(dataSource)
        })
      )
    },
    // @ts-ignore
    dataSource,
    [dataSource, columns]
  )

  return (
    <div
      ref={instance => tableWrapperEle$.next(instance)}
      className={`${styles['table-wrapper']}`}
      style={{
        overflow: overflow || 'auto'
      }}
    >
      <table
        style={{ width: tableWidth || 'auto' }}
        cellSpacing={0}
        cellPadding={0}
        className={`${styles['table']}`}
      >
        <thead className={`${styles['table-thead']}`}>
          <tr
            className={`${styles['table-tr']} ${
              bgc === 'gray' ? styles['gray'] : ''
            }`}
          >
            {columns.map((column, i) => (
              <th
                ref={instance =>
                  i === 0 ? fixedColumnEle$.next(instance) : null
                }
                className={`
                  ${styles['table-th']}
                  ${column.sorter ? styles['icon-padding'] : ''}
                `}
                key={column.dataIndex || column.key}
              >
                <span
                  style={{ minWidth: column.width || 0 }}
                  className={`${styles['table-cell']}`}
                >
                  <span
                    className={`
                    ${styles['table-cell']}
                  `}
                  >
                    {/* <p>{column.title}</p> */}
                    <p dangerouslySetInnerHTML={{ __html: column.title }} />
                    <p className={`${styles['th-subtitle']}`}>
                      {column.subtitle}
                    </p>
                    {column.sorter && (
                      <IconEX
                        onClick={() => {
                          if (!loading) {
                            columns.forEach(
                              column => (column.sortType = SortType.none)
                            )
                            column.sortType = SortType.dasc
                            sortClick([column.sorter, +column.sortType, i])
                          }
                        }}
                        className={`${styles['icon-sort']}`}
                        type={sortIcon[+column.sortType * currentSortType]}
                      />
                    )}
                  </span>
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={`${styles['table-tbody']}`}>
          {/* @ts-ignore */}
          {sortedDataSource.map((data, i) => (
            <tr
              key={data.key}
              className={`${styles['table-tr']} ${
                bgc === 'gray' ? styles['gray'] : ''
              }`}
              onClick={() => onRowClick && onRowClick(data, i)}
            >
              {dataSourceKeys.map((keyObj, index) => (
                <td className={`${styles['table-td']}`} key={keyObj.key}>
                  {index === 0 && i < maxOrder && <OrderNum order={i + 1} />}
                  {columns[index].render ? (
                    columns[index].render(
                      data, // 将当前行数据返回给 render
                      columns[index],
                      index
                    )
                  ) : (
                    <span className={`${styles['table-cell']}`}>
                      <p>{data[keyObj.key] || ''}</p>
                      <p className={`${styles['td-subtitle']}`}>
                        {data[keyObj.subKey] || ''}
                      </p>
                    </span>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TableBase
