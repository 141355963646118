/*
 * @Author: keqingrong (19040892)
 * @Date: 2019-07-26 17:59:13
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2019-11-04 10:24:19
 * @Description:
 */
import { EDeviceType } from '@/stores'
import { ISystemConfig } from './config'

export default class Config implements ISystemConfig {
  HOST = 'https://lsypartener.suning.com'
  ROOT_PATH = []
  CURRENT_PATH = ''
  SERVICE_IMG_HOST = ''
  APP_BOSS_REPORT_HOST = '//srcfewsh5.suning.com'
  // --------------------------------------------------------- >
  PASSPORT = {
    PASSPORT_LOGIN_HOST: '//passport.suning.com/ids/',
    PASSPORT_LOGIN_THEME: 'lsy_skf',
    PASSPORT_DOMAIN_HOST: '//lsybosspc.suning.com/'
  }
  UOM = {
    SITE_ID: '08CJJLR',
    RESOURCE_TYPE: '08CJJLR',
    BIZ_ID: 'lsypjg'
  }
  EXCEPTIONS_UPLOAD = {
    BIZ_ID: 'super-manager'
  }
  Current_Store_Type = ''
  USER_INFO = {
    uid: '',
    phone: '',
    companyName: '',
    mobileId: '',
    AppSource: '' as EDeviceType,
    isPhoneRoot: false,
    onVirtual: false,
    latitude: '',
    longitude: '',
    street: '',
    AddrStr: '',
    city: '',
    country: '',
    roleList: [],
    selectRole: {
      roleCode: '',
      roleName: '',
      dataDimension: '',
      roleStatus: ''
    },
    selectStoreType: '',
    duty: '',
    dutyDesc: '',
    position: '',
    sliceAreaList: [],
    status: '',
    regionName: '',
    regionCode: ''
  }
  AMAP_KEY = '709309ab6e53b4b179bb3802805ef411'
  AMAP_JS_CODE = 'eddc1bfc79530eeeebc79068a55fb1cf'
  saveSearchStore = {}
  mpAppid =
    'http%3a%2f%2fsnmps.admin.cnsuning.com%2fsnmps-web%2fprogram%2fget_16863bbdb1094ae7a559c9f16370db8a_%7bcurrVersion%7d_0_%7bclientVersion%7d.htm'
  MAP_TYPE = ''
}
