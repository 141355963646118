import { proxy } from 'valtio'
import { DeepPartial, toRaw } from '../util'
import { IGetBaseAllInfoResponse } from '@/views/home/types'

/**
 * store基础信息
 * @description 初始化: initBaseAllInfo function
 * @classdesc 用`updateBaseAllState`函数进行更新
 *
 * ```tsx
 * // 以下方式相当于useState，数据变化时，组件可以重新渲染
 * const baseAllInfo = useSnapshot(baseAllState)
 *
 * return (
 *   <div>{ baseAllInfo?.expandPerformance?.stepCode }</div>
 * );
 * ```
 */
export const baseAllState = proxy({} as IGetBaseAllInfoResponse)

/**
 * 更新 "store基础信息"
 */
export function updateBaseAllState(info: DeepPartial<IGetBaseAllInfoResponse>) {
  const newState = toRaw<IGetBaseAllInfoResponse>(info)
  for (const key in newState) {
    if (Object.prototype.hasOwnProperty.call(newState, key)) {
      baseAllState[key] = newState[key]
    }
  }
  return baseAllState
}
