import jingyinshuju from '@assets/images/jingyinshuju.png'
import { Cleanup, toRaw } from '@/stores/util'
import { defaultCurrentItem, defaultSwitchList, ISwitchItem } from './types'
import { EAccountRole } from '@genre'
import { EMode } from '@/components/the-time-picker/TimePickerTypes'
import { getDefaultTimeStrByMode } from '@/components/the-time-picker/TimePickerUtil'
import { getMoneyStrByNumber } from '@utils'
import { currentAreaCode, getGlobalUserConfig, globalUserState } from '@/stores'
import { Icon } from 'antd-mobile'
import {
  IItems,
  ITaskPerformanceDataInfoPrams,
} from '@/views/performance-report/types'
import { parseJsonToQueryString } from '@/kits/util'
import { queryTaskPerformanceDataInfo } from '@/views/home/HomeService'
import { Router, Sa, util } from '@kits'
import { subscribeKey } from 'valtio/utils'
import { SwitchNew } from '@components'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSnapshot } from 'valtio'
import './index.less'

let unWatchRole: Cleanup
let unWatchSliceArea: Cleanup

/** 全国绩效 */
export default function PerformanceReport() {
  const [switchList, setSwitchList] = useState<ISwitchItem[]>(defaultSwitchList)
  const [currentItem, setCurrentItem] = useState(defaultCurrentItem)
  const [info, setInfo] = useState({} as IItems)
  const userInfo = useSnapshot(globalUserState) // 相当于将store转换成react-state, store变化，会自动更新，重新渲染

  if (!unWatchRole) {
    unWatchRole = subscribeKey(globalUserState, 'selectRole', () => {
      // 用于首页角色切换后刷新状态
      setCurrentItem(defaultCurrentItem)
      switchQueryTaskPerformanceDataInfo()
    })
  }

  if (!unWatchSliceArea) {
    unWatchSliceArea = subscribeKey(currentAreaCode, 'value', () => {
      // 用于首页片区切换后刷新状态
      setCurrentItem(defaultCurrentItem)
      switchQueryTaskPerformanceDataInfo()
    })
  }

  useEffect(() => {
    switchQueryTaskPerformanceDataInfo()

    return () => {
      if (unWatchRole) {
        unWatchRole()
        unWatchRole = null
      }
      if (unWatchSliceArea) {
        unWatchSliceArea()
        unWatchSliceArea = null
      }
    }
  }, [])

  /** 切换调用接口 总部大区片区维度 */
  async function switchQueryTaskPerformanceDataInfo(value = EMode.Month) {
    let params = {} as ITaskPerformanceDataInfoPrams
    const userState = getGlobalUserConfig()

    switch (userState.accountRole) {
      case EAccountRole.NATIONAL:
        params = {
          codeType: '1',
          code: '',
          timeQueryCycle: value,
          date: getDefaultTimeStrByMode(value).replace(/-/g, ''),
        }
        break
      case EAccountRole.REGION:
      case EAccountRole.REGION_Manager:
        params = {
          codeType: '2',
          code: userState.regionCode,
          timeQueryCycle: value,
          date: getDefaultTimeStrByMode(value).replace(/-/g, ''),
        }
        break
      case EAccountRole.AREA:
        params = {
          codeType: '3',
          code: currentAreaCode.value || userState.sliceArea?.sliceAreaCode,
          timeQueryCycle: value,
          date: getDefaultTimeStrByMode(value).replace(/-/g, ''),
        }
        break
    }

    if (params.codeType) {
      const { header = {} } = await queryTaskPerformanceDataInfo(params)
      setInfo(() => header)
    }
  }

  /**处理单位 */
  function getMoneyStrByNumberFunction(numStr = '') {
    if (!numStr || numStr === 'undefined' || numStr === 'null') {
      return <span className="num">-</span>
    }
    const [moneyStr = '', typeStr = ''] = getMoneyStrByNumber(numStr)

    return (
      <>
        <span className="num">{moneyStr}</span>
        {typeStr && <span className="numName">{typeStr}</span>}
      </>
    )
  }

  /**改变名称 */
  function changeName() {
    switch (userInfo.accountRole) {
      case EAccountRole.NATIONAL:
        return '全国绩效'
      case EAccountRole.REGION:
      case EAccountRole.REGION_Manager:
        return '大区绩效'
      case EAccountRole.AREA:
        return '片区绩效'
      default:
        return '全国绩效'
    }
  }

  /** 年， 季度切换 */
  async function swithChange(item: ISwitchItem) {
    const newSwitchList = toRaw<ISwitchItem[]>(switchList)
    for (const v of newSwitchList) {
      if (v.value === item.value) {
        v.checked = true
        setCurrentItem(() => item)
      } else {
        v.checked = false
      }
    }
    setSwitchList(() => newSwitchList)
    switchQueryTaskPerformanceDataInfo(item.value as EMode)
  }

  /**全国绩效更多 */
  function operatingDataClick() {
    const userState = getGlobalUserConfig()

    Sa.sendClickData('qgjx', {
      eleid: '1',
      eletitle: '查看详情',
      ...util.saRoleParamClickObj(),
    })

    switch (userState.accountRole) {
      case EAccountRole.NATIONAL:
        const nationalQuery = {
          codeType: '1',
          queriesDimCodes: '1',
          timeQueryCycle: currentItem.value,
        } as ITaskPerformanceDataInfoPrams
        Router.Push(
          `/performance-report/nation?${parseJsonToQueryString(nationalQuery)}`
        )
        break
      case EAccountRole.REGION:
      case EAccountRole.REGION_Manager:
        const regionQuery = {
          name: userState.regionName,
          codeType: '2',
          code: userState.regionCode,
          queriesDimCodes: '2',
          timeQueryCycle: currentItem.value, // 动态值
        } as ITaskPerformanceDataInfoPrams
        Router.Push(
          `/performance-report/region?${parseJsonToQueryString(regionQuery)}`
        )
        break
      case EAccountRole.AREA:
        const areaQuery = {
          name: userState.sliceArea?.sliceAreaName,
          codeType: '3',
          code: userState.sliceArea?.sliceAreaCode,
          timeQueryCycle: currentItem.value, // 动态值
        } as ITaskPerformanceDataInfoPrams
        Router.Push(
          `/performance-report/area?${parseJsonToQueryString(areaQuery)}`
        )
        break
    }
  }

  return (
    <div className="shop-purchase-performance">
      <div className="operating-data">
        <div className="top">
          <div className="top-left">
            <img src={jingyinshuju} className="left-image" />
            <span className="left-title">
              {changeName()}
              <span className="small-tag">数据计算截止时间为昨日</span>
            </span>
          </div>
          {/* {
            <div className="top-right" onClick={operatingDataClick}>
              <span className="right-title">查看详情</span>
              <Icon type="right" size="xs" color="#4A90E2" />
            </div>
          } */}
        </div>
        <div className="main-wrap">
          <div
            className="switch-outBox"
            style={{ width: `${1.2 * switchList.length}rem` }}
          >
            <SwitchNew
              from="performance-report"
              switchList={switchList}
              currentItem={currentItem}
              onChange={swithChange}
            />
          </div>
          <div className="operating-data-content">
            <div className="operating-section">
              <div className="operating-section-title">
                {getMoneyStrByNumberFunction(info.bwTaskQuota)}
              </div>
              <div className="operating-section-name">BW任务额</div>
            </div>
            <div className="operating-section">
              <div className="operating-section-title">
                {getMoneyStrByNumberFunction(info.bwPickGoodsQuota)}
              </div>
              <div className="operating-section-name">BW提货额</div>
            </div>
            <div className="operating-section">
              <div
                className="operating-section-title"
                style={{
                  fontSize: '0.42rem',
                  fontWeight: 'bold',
                  color: '#333',
                }}
              >
                {info.bwCompletionRate && info.bwCompletionRate !== '-'
                  ? info.bwCompletionRate + '%'
                  : '-'}
              </div>
              <div className="operating-section-name">BW完成率</div>
            </div>
            <div className="operating-section">
              <div className="operating-section-title">
                {getMoneyStrByNumberFunction(`${info.jsavTaskQuota}`)}
              </div>
              <div className="operating-section-name">JSAV任务额</div>
            </div>
            <div className="operating-section">
              <div className="operating-section-title">
                {getMoneyStrByNumberFunction(info.jsavPickGoodsQuota)}
              </div>
              <div className="operating-section-name">JSAV销售额</div>
            </div>
            <div className="operating-section">
              <div
                className="operating-section-title"
                style={{
                  fontSize: '0.42rem',
                  fontWeight: 'bold',
                  color: '#333',
                }}
              >
                {info.jsavCompletionRate && info.jsavCompletionRate !== '-'
                  ? info.jsavCompletionRate + '%'
                  : '-'}
              </div>
              <div className="operating-section-name">JSAV完成率</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
