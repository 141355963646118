import Block from './bolck'
import { Cleanup } from '@/stores/util'
import { Douya, Router, Sa, util } from '@kits'
import { EAccountRole } from '@genre'
import { Flex } from 'antd-mobile'
import { getGlobalUserConfig, globalUserState, initDouYaInfo } from '@/stores'
import { IBlockMenuData, IUnreadInspectNum } from '../../types'
import { subscribeKey } from 'valtio/utils'
import { useActivate } from 'react-activation'
import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import { updateUnFinishTodoSize } from '@/views/todos/webapi'
import {
  queryUnfinishedWork,
  queryWorkbenchMenus,
  updateTabNoticeCount,
} from '../../HomeService'
import './HomeDashboard.less'

let unWatch: Cleanup

/** 工作台 */
export default function HomeDashboard() {
  const [menus, setMenus] = useState<IBlockMenuData[]>([])
  const [unfinishedWork, setUnfinishedWork] = useState({} as IUnreadInspectNum)
  const [loading, setLoading] = useState(true)
  const userInfo = useSnapshot(globalUserState)

  if (!unWatch) {
    unWatch = subscribeKey(globalUserState, 'selectRole', () => {
      // 用于首页角色切换后刷新状态
      fetchUnfinishedWork()
    })
  }

  async function fetchMenus() {
    setLoading(true)
    const data = await queryWorkbenchMenus()
    updateTabNoticeCount()
    updateUnFinishTodoSize()
    setMenus(data)
    setLoading(false)
  }

  async function fetchUnfinishedWork() {
    const userState = getGlobalUserConfig()
    if (userState?.accountRole === EAccountRole.AREA) {
      const res = await queryUnfinishedWork()
      setUnfinishedWork(res)
    }
  }

  async function initDouYaData() {
    console.log('start init douya on dashboard')
    const userState = getGlobalUserConfig()

    // 只在豆芽环境进行定位判断，浏览器直接放行
    if (Douya.isDouya() && !userState?.latitude) {
      initDouYaInfo()
    }
  }

  useActivate(() => {
    Sa.update(`pgtitle=工作台Tab页;pageid=aQt13daAaa;${util.saRoleParam()}`)
  })

  useEffect(() => {
    Sa.update(`pgtitle=工作台Tab页;pageid=aQt13daAaa;${util.saRoleParam()}`)
    initDouYaData()
    fetchMenus()
    fetchUnfinishedWork()

    // 去除我的巡检存储日期
    sessionStorage.setItem('inspection-detail-date', '')
    sessionStorage.setItem('copyMeListDate', '')
    sessionStorage.setItem('waitToCorrectDate', '')

    return () => {
      if (unWatch) {
        unWatch()
        unWatch = null
      }
    }
  }, [])

  return (
    !loading && (
      <>
        <div className="home-container work-bench">
          {/* 未完成任务数据区域 */}
          {userInfo?.accountRole === EAccountRole.AREA && (
            <div className="blue-view">
              <Flex
                direction="column"
                justify="center"
                className="in-item"
                onClick={() => {
                  // 点击埋点
                  Sa.sendClickData('1lb1kui', {
                    eleid: '1',
                    eletitle: `待维护坐标`,
                    ...util.saRoleParamClickObj(),
                  })
                  Router.Push(`/wait-to-do/no-geo-info-list`)
                }}
                key="待维护坐标"
              >
                <span className="sample-value">
                  {unfinishedWork?.coordinateNum || '-'}
                </span>
                <span className="sub-title">待维护坐标</span>
              </Flex>
              <Flex
                direction="column"
                justify="center"
                className="in-item"
                onClick={() => {
                  // 点击埋点
                  Sa.sendClickData('1lb1kui', {
                    eleid: '2',
                    eletitle: `待巡店`,
                    ...util.saRoleParamClickObj(),
                  })
                  Router.Push(`/wait-to-do/wait-inspection-list`)
                }}
                key="待巡检"
              >
                <span className="sample-value">
                  {unfinishedWork?.shopNum || '-'}
                </span>
                <span className="sub-title">待巡检</span>
              </Flex>
              <Flex
                direction="column"
                justify="center"
                className="in-item"
                onClick={() => {
                  // 点击埋点
                  Sa.sendClickData('1lb1kui', {
                    eleid: '3',
                    eletitle: `待评价`,
                    ...util.saRoleParamClickObj(),
                  })
                  Router.Push(`/wait-to-do/wait-evaluate-list`)
                }}
                key="待评价"
              >
                <span className="sample-value">
                  {unfinishedWork?.evaluateNum || '-'}
                </span>
                <span className="sub-title">待评价</span>
              </Flex>
              <Flex
                direction="column"
                justify="center"
                className="in-item"
                onClick={() => {
                  // 点击埋点
                  Sa.sendClickData('1lb1kui', {
                    eleid: '4',
                    eletitle: `超期未整改`,
                    ...util.saRoleParamClickObj(),
                  })
                  Router.Push(`/wait-to-do/wait-to-correct-list`)
                }}
                key="超期未整改"
              >
                <span className="sample-value">
                  {unfinishedWork?.unRectifiedNum || '-'}
                </span>
                <span className="sub-title">超期未整改</span>
              </Flex>
              <Flex
                direction="column"
                justify="center"
                className="in-item"
                onClick={() => {
                  // 点击埋点
                  Sa.sendClickData('1lb1kui', {
                    eleid: '5',
                    eletitle: `抄送我的`,
                    ...util.saRoleParamClickObj(),
                  })
                  Router.Push(`/wait-to-do/copy-me-list`)
                }}
                key="抄送我的"
              >
                <span className="sample-value">
                  {unfinishedWork?.reportNum || '-'}
                </span>
                <span className="sub-title">抄送我的</span>
              </Flex>
              <Flex
                direction="column"
                justify="center"
                className="in-item"
                onClick={() => {
                  Router.Push(`/store-task-info/task-list?status=UNAUDIT`)
                }}
                key="待审核任务"
              >
                <span className="sample-value">
                  {Number(unfinishedWork?.unAuditTaskNum || '') > 1000
                    ? 1000 + '+'
                    : unfinishedWork?.unAuditTaskNum || '-'}
                </span>
                <span className="sub-title">待审核任务</span>
              </Flex>
            </div>
          )}

          {/* 菜单区域 */}
          {menus.length > 0 && (
            <>
              {menus.map((item, index) => {
                return (
                  <div key={index}>
                    <Block
                      title={item.menuClassName}
                      cellList={item.menuList}
                    />
                  </div>
                )
              })}
            </>
          )}
          {!menus.length && (
            <div className="work-bench-nodata-center">暂无数据</div>
          )}
        </div>
      </>
    )
  )
}
