import hong from '@assets/images/dengji_hongpai@2x.png'
import huang from '@assets/images/dengji_huangpai@2x.png'
import liang from '@assets/images/dengji_liang@2x.png'
import liangjia from '@assets/images/dengji_liangjia@2x.png'
import PerRate from './components/per-rate'
import qs from 'qs'
import tuozhanyeji from '@assets/images/tuozhanyeji@3x.png'
import you from '@assets/images/dengji_you@2x.png'
import { baseAllState, getGlobalUserConfig, globalUserState } from '@/stores'
import { EAccountRole } from '@genre'
import { Flex, Icon } from 'antd-mobile'
import { Router, Sa, util } from '@kits'
import { useSnapshot } from 'valtio'
import styles from './index.module.less'

interface IIProps {
  titleName: string
}

export default function TuozhanScore({ titleName }: IIProps) {
  const userInfo = useSnapshot(globalUserState)
  const baseAllInfo = useSnapshot(baseAllState)

  /**
   * 打卡业绩评分页面
   */
  function onOpenPerformanceScore() {
    Sa.sendClickData('04knnp4', {
      eleid: '9',
      eletitle: `拓展业绩查看详情`,
      ...util.saRoleParamClickObj(),
    })

    const userState = getGlobalUserConfig();
    switch (userState.accountRole) {
      case EAccountRole.NATIONAL:
        Router.Push(
          `/tuozhan-perf/region-list?${qs.stringify({
            accountRole: userState.accountRole,
          })}`
        )
        break
      case EAccountRole.REGION:
      case EAccountRole.REGION_Manager:
        Router.Push(
          `/tuozhan-perf/subregion-list?${qs.stringify({
            accountRole: userState.accountRole,
            regionCode: userState.regionCode,
            regionName: userState.regionName,
            year: util.getTimeInfo().year,
            month: util.getTimeInfo().month,
          })}`
        )
        break
      case EAccountRole.AREA:
        Router.Push(
          `/tuozhan-perf/store-list?${qs.stringify({
            accountRole: userState.accountRole,
            regionCode: userState.regionCode,
            regionName: userState.regionName,
            sliceAreaCode: userState.sliceArea
              ? (userState.sliceArea.sliceAreaCode || '')
              : '',
            year: util.getTimeInfo().year,
            month: util.getTimeInfo().month,
            sliceAreaName: userState.sliceArea
              ? (userState.sliceArea.sliceAreaName || '')
              : '',
          })}`
        )
        break
    }
  }

  function getAreaName() {
    //    /** 全国维度的角色    ->   总部管理员  */
    // NATIONAL = '1',
    // /** 大区维度的角色     ->   大区云总*/
    // REGION = '2',
    // /** 大区运营经理 */
    // REGION_Manager = 'dqyyjl',
    // /** 片区维度的角色     ->   片区负责人*/
    // AREA = '3'
    if (userInfo?.accountRole === EAccountRole.NATIONAL) {
      return '全国'
    }
    if (userInfo?.accountRole === EAccountRole.REGION) {
      return '全国'
    }
    if (userInfo?.accountRole === EAccountRole.REGION_Manager) {
      return '全国'
    }
    if (userInfo?.accountRole === EAccountRole.AREA) {
      return '大区内'
    }
  }

  function renderLevel() {
    // 可选值：Y1 - 红牌，Y2 - 黄牌，Y3 - 良，Y4 - 良+，Y5 - 优。
    // if (stepCode === 'YS') {
    //   return <img src={you} className={styles['left-sub-image']} />
    // }
    // if (stepCode === 'Y4') {
    //   return <img src={liangjia} className={styles['left-sub-image']} />
    // }
    // if (stepCode === 'Y3') {
    //   return <img src={liang} className={styles['left-sub-image']} />
    // }
    // if (stepCode === 'Y2') {
    //   return <img src={huang} className={styles['left-sub-image']} />
    // }
    // if (stepCode === 'Y1') {
    //   return <img src={hong} className={styles['left-sub-image']} />
    // }
    // return <span>&nbsp;&nbsp;--</span>

    // 规则变动可选值：Y1 - 优，Y2 - 良+，Y3 - 良，Y4 -黄牌 ，Y5 - 红牌。
    let stepCode = baseAllInfo?.expandPerformance?.stepCode
    if (stepCode === 'YS' || stepCode === 'T5') {
      return <img src={you} className={styles['left-sub-image']} />
    }
    if (stepCode === 'Y4' || stepCode === 'T4') {
      return <img src={liangjia} className={styles['left-sub-image']} />
    }
    if (stepCode === 'Y3' || stepCode === 'T3') {
      return <img src={liang} className={styles['left-sub-image']} />
    }
    if (stepCode === 'Y2' || stepCode === 'T2') {
      return <img src={huang} className={styles['left-sub-image']} />
    }
    if (stepCode === 'Y1' || stepCode === 'T1') {
      return <img src={hong} className={styles['left-sub-image']} />
    }
    return <span>&nbsp;&nbsp;--</span>
  }

  /**
   * 是否显示【本月业绩评分】和【采购业绩】模块
   * 未分配片区的片区负责人，不展示。
   */
  function canShowPerfModule() {
    let enable = false

    switch (userInfo.accountRole) {
      case EAccountRole.NATIONAL:
      case EAccountRole.REGION:
      case EAccountRole.REGION_Manager:
        enable = true
        break
      case EAccountRole.AREA:
        enable = userInfo?.sliceAreaList && userInfo?.sliceAreaList.length > 0
        break
      default:
        enable = false
        break
    }
    return enable
  }

  return (
    <Flex justify="center" direction="column" className={styles['achievement']}>
      <Flex
        justify="between"
        direction="row"
        align="center"
        className={styles['top']}
      >
        <Flex className={styles['top-left']}>
          <img src={tuozhanyeji} className={styles['left-image']} />
          <span className={styles['left-title']}>{titleName}</span>
        </Flex>
        {canShowPerfModule() && (
          <Flex
            className={styles['top-right']}
            onClick={onOpenPerformanceScore}
          >
            <span className={styles['right-title']}>查看详情</span>
            <Icon type="right" size="xs" color="#4A90E2" />
          </Flex>
        )}
      </Flex>

      <Flex
        justify="between"
        direction="row"
        align="center"
        className={styles['top-grade']}
      >
        <Flex className={styles['top-left']}>
          <span className={styles['left-sub-title']}>评定等级</span>
          {renderLevel()}
        </Flex>

        <Flex className={styles['top-right']}>
          <span className={styles['right-sub-title']}>{getAreaName()}排名</span>
          <span className={styles['right-sub-title']}>
            {baseAllInfo?.expandPerformance?.rank}
          </span>
        </Flex>
      </Flex>

      <PerRate
        value={baseAllInfo?.expandPerformance?.operationComScore || '--'}
        type={baseAllInfo?.expandPerformance?.stepCode}
        stepName={'综合拓展评分'}
        timeTitle={`${baseAllInfo?.expandPerformance?.year || '-'}年${
          baseAllInfo?.expandPerformance?.month || '-'
        }月`}
      />
    </Flex>
  )
}
