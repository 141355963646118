import { Component } from 'react'

function setNavigateTitle(title: string): any {
  return function (WrappedComponent) {
    class DecNav extends Component {
      constructor(props) {
        super(props)
      }

      componentDidMount() {
        document.title = title
      }

      render() {
        return <WrappedComponent {...this.props} />
      }
    }
    return DecNav
  }
}

export default setNavigateTitle
