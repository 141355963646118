/*
 * @Author: 苏鑫
 * @Date: 2023-07-05 14:14
 * @Last Modified by: 12072513
 * @Last Modified time: 2023-07-24 17:37:48
 */

import './index.less'

import { Router, Sa, util } from '@kits'

import { ITaskItem } from '../../webapi'
import { ToTodoInfoState } from '../../types'
import todo_task_arrow from '@assets/images/todo-task-arrow.png'

/** 任务列表 */
const TodosTaskItem = (props: TodoTaskItemProps) => {
  const { data = {}, type = 1 } = props

  const toTaskInfo = (item: ITaskItem) => {
    Router.Push<ToTodoInfoState>('/todo/task-info', {
      taskItemId: item.taskItemId,
    })
  }

  return (
    <div
      className="task-item"
      onClick={() => {
        //点击埋点
        Sa.sendClickData('taskcard', {
          eleid: 'infopage',
          eletitle: `详情`,
          ...util.saRoleParamClickObj(),
        })
        toTaskInfo(data)
      }}
    >
      <div className="task-item-wrap">
        <div className="task-item-title">{data.taskName}</div>
        <div className="task-item-arrow">
          <img src={todo_task_arrow} className="icon" />
        </div>
      </div>
      {data.endTime && (
        <div className="task-item-view">
          <div className="task-item-text">任务截止时间：</div>
          <div className="task-item-time">{data.endTime}</div>
        </div>
      )}
      {/* 已完成tab显示任务完成时间 */}
      {type === 3 && data.completeTime && (
        <div className="task-item-view">
          <div className="task-item-text">任务完成时间：</div>
          <div className="task-item-time">{data.completeTime}</div>
        </div>
      )}
      {/* 到店任务展示任务进度 */}
      {data.taskType === 2 && (
        <div className="task-item-view">
          <div className="task-item-text">任务完成进度：</div>
          <div className="task-item-schedule">
            {data.completeTask}/{data.totalTask}
          </div>
        </div>
      )}
      {data.shutTask != null && data.shutTask != 0 && (
        <div className="task-item-view">
          <div className="task-item-text">特殊情况终止：</div>
          <div className="task-item-termination-num">{data.shutTask}</div>
        </div>
      )}
    </div>
  )
}

export type TodoTaskItemProps = {
  data?: ITaskItem
  type?: number //  1-待完成；2--已完成；3-已超期
}

export default TodosTaskItem
