import classNames from 'classnames'
import close from '@assets/images/modal-close.png'
import { getStoreTypeName, getSelectStoreType } from '@/views/home/utils'
import {
  currentAreaCode,
  getGlobalUserConfig,
  globalUserState,
  IRoleItem,
  ISliceArea,
  updateCurrentAreaCode,
  updateGlobalUserInfo,
  updatePurchaseSwitch,
} from '@/stores'
import { EAccountRole } from '@genre'
import { Modal, Toast } from 'antd-mobile'
import {
  changeRole,
  initGlobalUserInfo,
  updateTabNoticeCount,
} from '../../../../HomeService'
import { useSnapshot } from 'valtio'
import { useEffect, useRef, useState } from 'react'
import './index.less'

interface IProp {
  /** 显示用户角色列表？ */
  showRoleList: boolean
  /** 切换用户角色列表显示 */
  toggleRoleList: () => void
}

/** 用户角色列表 modal */
export default function RoleList({
  showRoleList = false,
  toggleRoleList,
}: IProp) {
  const memoizedSelectedStoreType = useRef(
    getGlobalUserConfig().selectStoreType || ''
  )
  const [selectedStoreType, setSelectedStoreType] = useState(
    memoizedSelectedStoreType.current
  )
  const [selectedRoleCode, setSelectedRoleCode] = useState(
    getGlobalUserConfig().selectedRoleCode || ''
  )
  const userInfo = useSnapshot(globalUserState)

  function updateSelectRoleCode(roleCode: string, storeType: string) {
    const userState = getGlobalUserConfig()
    const roleList = userState.roleList || []
    setSelectedStoreType(storeType)
    memoizedSelectedStoreType.current = storeType
    for (const role of roleList) {
      /** 接口返回的角色编码可能会跟传的编码不一致，这里要以接口返回的角色编码为准 */
      if (roleCode === role.roleCode) {
        setSelectedRoleCode(roleCode)
        break
      }
    }
  }

  /** 隐藏 */
  function hiddenModal() {
    const userState = getGlobalUserConfig()
    setSelectedRoleCode(userState.selectRole?.roleCode || '')
    toggleRoleList()
  }

  /** 确认切换角色 */
  async function doSwitchRole(storeType: string) {
    // change role
    let { errorMesage, result } = await changeRole(selectedRoleCode, storeType)
    if (errorMesage) {
      Toast.info(errorMesage)
      return
    }

    updateSelectRoleCode(result.roleCode || '', storeType)
    updateTabNoticeCount()
    toggleRoleList()

    // init global user info state
    await initGlobalUserInfo()
    await updatePurchaseSwitch(false)

    updateGlobalFlag(result)
  }

  /** 判断缓存标识和接口返回的是否一致，如果不一致则更新 */
  function updateGlobalFlag(result) {
    const userState = getGlobalUserConfig()
    const items = userState.sliceAreaList?.filter(
      (item) => item.sliceAreaCode === currentAreaCode.value
    )

    const swicthedAccountRole = result.dataDimension
    const swicthedSliceArea =
      (items && items[0]) ||
      ({ sliceAreaCode: '', sliceAreaName: '' } as ISliceArea)
    const swicthedAreaCode =
      swicthedAccountRole !== EAccountRole.AREA
        ? ''
        : swicthedSliceArea?.sliceAreaCode

    if (swicthedAreaCode !== currentAreaCode.value) {
      updateCurrentAreaCode(swicthedAreaCode)
    }

    if (
      swicthedSliceArea.sliceAreaCode !== userState?.sliceArea?.sliceAreaCode
    ) {
      updateGlobalUserInfo({ sliceArea: swicthedSliceArea })
    }
  }

  useEffect(() => {
    if (showRoleList) setSelectedStoreType('')
  }, [selectedRoleCode])

  useEffect(() => {
    if (!showRoleList && !selectedStoreType) {
      setSelectedStoreType(memoizedSelectedStoreType.current)
    }
  }, [showRoleList])

  return (
    <Modal
      popup
      visible={showRoleList}
      animationType="slide-up"
      className={'home-role-storeType-modal'}
      onClose={hiddenModal}
    >
      <div className={'header'}>
        <div className={'left'} />
        <div className={'middle align-center'}>请选择</div>
        <div className={'right align-center'}>
          <img src={close} className={'close-icon'} onClick={hiddenModal} />
        </div>
      </div>
      <div className={'home-role-popup-tip'}>选择角色/店铺类型</div>
      <div className={'home-role-popup-content'}>
        <div className={'home-role-list'}>
          {userInfo?.roleList &&
            userInfo?.roleList.map((item: IRoleItem) => (
              <div
                key={`role_${item.roleCode}`}
                onClick={() => setSelectedRoleCode(item.roleCode)}
                className={classNames(
                  'list-item',
                  item.roleCode === selectedRoleCode ? 'item-selected' : null
                )}
              >
                {item.roleName}
              </div>
            ))}
        </div>
        <div className={'home-storeType-list'}>
          {selectedRoleCode && userInfo?.roleList ? (
            <>
              {getSelectStoreType(
                selectedRoleCode,
                userInfo?.roleList as any
              ).map((type) => (
                <div
                  key={`storeType_${type}`}
                  onClick={() => doSwitchRole(type)}
                  className={classNames(
                    'list-item',
                    type === selectedStoreType ? 'item-selected' : null
                  )}
                >
                  {getStoreTypeName(type)}
                </div>
              ))}
            </>
          ) : null}
        </div>
      </div>
    </Modal>
  )
}
