import React from 'react'
import styles from './index.module.less'

interface IProps {
  /** 空数据文案 */
  emptyText: string | JSX.Element
}

const EmptyText: React.SFC<IProps> = props => {
  const { emptyText } = props
  return (
    <div className={`${styles['empty-text']}`}>{emptyText || '暂无数据'}</div>
  )
}

export default EmptyText
