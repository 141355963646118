/*
 * @Author: 柯庆荣19040892
 * @Email: 19040892@cnsuning.com
 * @Date: 2019-10-25 10:50:52
 * @Last Modified by: 杨建新(20014264)
 * @Last Modified time: 2021-09-02 15:31:09
 * @Description: Description
 */
import React, { RefObject } from 'react'
import classNames from 'classnames'
import { drawPie } from './draw-utils'
import styles from './index.module.less'

interface IProps {
  /** 类型 */
  type?: 'info' | 'warning' | 'error'
  /** 尺寸 */
  size?: 'small' | 'large'
  /** 样式类名 */
  className?: string
  /** 百分比值 */
  value?: number
  /** 标题 */
  title?: string
  /** 副标题 */
  subtitle?: string
  /** 标题颜色，默认和 type 对应颜色一致 */
  titleColor?: string
  /** 副标题颜色，默认为 #666 */
  subtitleColor?: string
}

interface IState {
  /** canvas 实际边长 */
  canvasEdgeLength: number
  /** canvas 中线段实际宽度 */
  lineWidth: number
}

/**
 * 获取类型对应的颜色
 * @param type
 */
function getTypeColor(type: 'info' | 'warning' | 'error') {
  switch (type) {
    case 'info':
      return '#3377FF'
    case 'warning':
      return '#F7B400'
    case 'error':
      return '#F64F4F'
    default:
      return '#eee'
  }
}

/**
 * 绘制 canvas 时，为了在2倍、3倍屏下不模糊，CSS 110px，实际绘制 pixelRatio*110 尺寸的 canvas
 * 计算时需要参考 hotcss.js 对根元素 fontSize 的处理
 *
 * 小于 750px 的屏幕，pixelRatio > 1，做相应缩放
 * 大于 750px 的屏幕，pixelRatio 为 1
 */
const pixelRatio = 750 / Math.min(document.documentElement.clientWidth, 750)

export default class EvaluationPie extends React.Component<IProps, IState> {
  canvasRef: RefObject<HTMLCanvasElement> = React.createRef()

  state = {
    canvasEdgeLength: 0,
    lineWidth: 0
  }

  static defaultProps = {
    type: 'info',
    size: 'small',
    className: '',
    value: 0,
    title: '',
    subtitle: '',
    titleColor: '',
    subtitleColor: '#666'
  }

  constructor(props) {
    super(props)
  }

  async componentDidMount() {
    await this.calcSizes()
    this.renderCanvas()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.size !== this.props.size) {
      this.calcSizes()
    }
    if (
      prevProps.size !== this.props.size ||
      prevProps.type !== this.props.type ||
      prevProps.value !== this.props.value
    ) {
      this.renderCanvas()
    }
  }

  /**
   * 计算 canvas 边长、线段宽度
   */
  calcSizes() {
    /** 视觉稿中饼图边长 */
    let canvasCSSEdgeLength = 110
    /** 视觉稿中线段宽度 */
    let lineCSSWidth = 10

    switch (this.props.size) {
      case 'small':
        canvasCSSEdgeLength = 110
        lineCSSWidth = 10
        break
      case 'large':
        canvasCSSEdgeLength = 220
        lineCSSWidth = 20
        break
    }

    /** canvas 实际边长 */
    const canvasEdgeLength =
      (canvasCSSEdgeLength * window.devicePixelRatio) / pixelRatio
    /** canvas 中线段实际宽度 */
    const lineWidth = (lineCSSWidth * window.devicePixelRatio) / pixelRatio

    this.setState({
      canvasEdgeLength,
      lineWidth
    })
  }

  /**
   * 渲染 canvas
   */
  renderCanvas() {
    if (this.canvasRef && this.canvasRef.current) {
      const canvasElement = this.canvasRef.current
      drawPie(canvasElement.getContext('2d'), {
        edgeLength: this.state.canvasEdgeLength,
        lineWidth: this.state.lineWidth,
        bgRingColor: '#eee',
        fgRingColor: getTypeColor(this.props.type),
        percentValue: this.props.value
      })
    }
  }

  render() {
    return (
      <div
        className={classNames(
          styles['evaluation-pie'],
          styles[`evaluation-pie-${this.props.type}`],
          styles[`evaluation-pie-${this.props.size}`],
          this.props.className
        )}
      >
        <canvas
          ref={this.canvasRef}
          className={styles['evaluation-pie-canvas']}
          width={this.state.canvasEdgeLength}
          height={this.state.canvasEdgeLength}
        />
        <div className={styles['evaluation-pie-text']}>
          <div
            className={styles['evaluation-pie-title']}
            style={{
              color: this.props.titleColor || getTypeColor(this.props.type)
            }}
          >
            {this.props.title}
          </div>
          <div
            className={styles['evaluation-pie-subtitle']}
            style={{
              color: this.props.subtitleColor
            }}
          >
            {this.props.subtitle}
          </div>
        </div>
      </div>
    )
  }
}
