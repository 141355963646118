/*
 * @Author: 韩念琪[18112596]
 * @Email: 18112596@cnsuning.com
 * @Date: 2019-07-11 21:06:18
 * @Last Modified by: 吴超(20023244)
 * @Last Modified time: 2023-06-07 19:25:27
 * @Description: toast 中间件
 */

import { BaseFetch } from '@kits'
import { Toast } from 'antd-mobile'
import ArgoPlugin from './argo-plugin'

interface IExtentions {
  toasthide: boolean
}

export const toastMiddleware: BaseFetch.middleware<
  any,
  IExtentions
> = next => async (requestConfig, state) => {
  const { toasthide = false } = state
  await next(requestConfig, state)
  if (!toasthide) {
    try {
      if (state.ret.err) {
        Toast.info(state.ret.err.message)
      }
    } catch (e) {
      console.error('[system]', e)
      Toast.info(`未知错误`)
    }
  }
}

class ToastArgoPlugin extends ArgoPlugin {
  // @ts-ignore
  fetchRequest() {
    return toastMiddleware
  }
}

export default new ToastArgoPlugin()
