import './TabBars.less'

import { unFinishTodoCount, unReadNoticeCount } from '@/stores'
import { useLayoutEffect, useState } from 'react'

import { Router } from '@kits'
import { useSnapshot } from 'valtio'

const tabs = [
  {
    tag: 'home',
    name: '数据',
  },
  // {
  //   tag: 'todos',
  //   name: '待办',
  // },
  // {
  //   tag: 'message',
  //   name: '消息',
  // },
  {
    tag: 'dashboard',
    name: '工作台',
  },
] as Array<{ tag: TabKey; name: string }>

export type TabKey = 'home' | 'message' | 'dashboard' | 'todos'

/** 底部导航菜单 */
export default function TabBars() {
  const [activeTab, setActiveTab] = useState<TabKey>('home')
  const unReadNoticeNum = useSnapshot(unReadNoticeCount)
  const unFinishTodoNum = useSnapshot(unFinishTodoCount)
  const changeTab = (tabKey: TabKey) => {
    setActiveTab(tabKey)
    Router.Replace(`/${tabKey}`)
  }

  useLayoutEffect(() => {
    document.title = '超级经理人'
    const tabKey = window.location.href.split('#/')[1] || 'home'
    setActiveTab(tabKey as TabKey)
  }, [])

  return (
    <div className="tab-bars-wrapper">
      {tabs.map((tab, idx) => {
        return (
          <div
            key={idx}
            className={[
              'tab-bar-item',
              `${tab.tag === activeTab ? 'active' : ''}`,
              `${
                tab.tag === 'todos' && unFinishTodoNum.value > 0
                  ? 'with-unread-to-do'
                  : ''
              }`,
              `${
                tab.tag === 'message' && unReadNoticeNum.value > 0
                  ? 'with-unread'
                  : ''
              }`,
            ].join(' ')}
            onClick={() => {
              changeTab(tab.tag)
            }}
          >
            <div className={`tab-bar-item-icon ${tab.tag}`} />
            <div className="tab-bar-item-name">{tab.name}</div>
          </div>
        )
      })}
    </div>
  )
}
