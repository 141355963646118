/*
 * @Author: 柯庆荣19040892
 * @Email: 19040892@cnsuning.com
 * @Date: 2019-11-09 11:18:44
 * @Last Modified by: 柯庆荣19040892
 * @Last Modified time: 2020-09-22 16:26:59
 * @Description: Description
 */
import React from 'react'
import classNames from 'classnames'
import ResizeObserver from 'resize-observer-polyfill'
import styles from './index.module.less'

interface IPageScrollEventObject {
  /** 页面在垂直方向已滚动的距离，单位为px */
  scrollTop: number
}

interface IProps {
  /** 样式类名 */
  className?: string
  /** 样式 */
  style?: React.CSSProperties
  /** 页面上拉触底事件触发时距页面底部距离，单位为px */
  onReachBottomDistance?: number
  /** 监听用户上拉触底事件 */
  onReachBottom?: () => void
  /** 监听用户滑动事件 */
  onContainerScroll?: (params: IPageScrollEventObject) => void
}

export default class ScrollableContainer extends React.Component<IProps> {
  /** 组件DOM引用 */
  containerRef: React.RefObject<HTMLDivElement> = React.createRef()
  /** 触底事件是否可以触发，在触发距离内滑动期间，触底事件只会被触发一次 */
  canOnReachBottomTrigger: boolean = true

  static defaultProps: IProps = {
    onReachBottomDistance: 50
  }

  resizeObserver = new ResizeObserver(entries => {
    // 容器大小变化，重置标志位
    this.canOnReachBottomTrigger = true;
  })


  componentDidMount() {
    this.resizeObserver.observe(this.containerRef.current);
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  /**
   * 监听容器滚动事件。
   * 距离底部距离达到 onReachBottomDistance 范围后，触发一次 onReachBottom()，超出后重置。
   * 在触发距离内滑动期间，应该只被触发一次。
   */
  onContainerScroll = () => {
    if (!this.containerRef.current) {
      return
    }
    const containerElement = this.containerRef.current
    /** 容器在垂直方向已滚动的距离 */
    const scrollTop = containerElement.scrollTop

    if (this.props.onContainerScroll) {
      this.props.onContainerScroll({
        scrollTop: scrollTop
      })
    }

    if (this.props.onReachBottom) {
      const scrollHeight = containerElement.scrollHeight
      const { height } = containerElement.getBoundingClientRect()
      // 距离容器底部距离 = 容器实际高度 - 可视区域高度 - 距顶部高度
      const reachBottomDistance = scrollHeight - height - scrollTop
      // 容器原本已经处在触底状态，如果发生重新渲染且容器内容变少撑不满容器（容器实际高度不再大于可视区域高度），这时需要重置标志位。
      if (this.canOnReachBottomTrigger === false && scrollHeight === height) {
        this.canOnReachBottomTrigger = true
        return
      }
      if (reachBottomDistance <= this.props.onReachBottomDistance) {
        if (this.canOnReachBottomTrigger === true) {
          this.canOnReachBottomTrigger = false
          this.props.onReachBottom()
        }
      } else {
        this.canOnReachBottomTrigger = true
      }
    }
  }

  render() {
    return (
      <div
        ref={this.containerRef}
        className={classNames(
          styles['scrollable-container'],
          this.props.className
        )}
        style={this.props.style}
        onScroll={this.onContainerScroll}
      >
        {this.props.children}
      </div>
    )
  }
}
