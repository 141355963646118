import { useState } from 'react'
import './index.less'

interface IProps {
  /** 通知标题 */
  noticeTitle: string
  /** 系统消息标题 */
  sysMsgTitle: string
  /** 系统消息未读数量 */
  sysMsgNum?: number
  /** 点击公告通知tab */
  onNoticeTabActive: () => void
  /** 点击系统消息tab */
  onSysMsgTabActive: () => void
}

export default function MessageSegmentControl({
  noticeTitle,
  sysMsgTitle,
  sysMsgNum = 0,
  onNoticeTabActive,
  onSysMsgTabActive,
}: IProps) {
  const [currentTabIdx, setCurrentTabIdx] = useState(0)

  return (
    <div className="segment-control-view">
      <div className="left-control">
        <button
          className={
            currentTabIdx === 0 ? 'active-button' : 'default-button left-state'
          }
          onClick={() => {
            onNoticeTabActive()
            setCurrentTabIdx(0)
          }}
        >
          {noticeTitle}
        </button>
      </div>
      <div className="right-control">
        <button
          className={
            currentTabIdx === 1 ? 'active-button' : 'default-button right-state'
          }
          onClick={() => {
            onSysMsgTabActive()
            setCurrentTabIdx(1)
          }}
        >
          {sysMsgTitle}
        </button>
        {sysMsgNum > 0 && <div className="unread-number">{`${sysMsgNum}`}</div>}
      </div>
    </div>
  )
}
