import { EAccountRole } from '@genre'
import { proxy } from 'valtio'
import { DeepPartial, toRaw } from '../util'
import { Douya } from '@kits'

/**
 * 用户配置数据持久化，防止刷新丢失
 */
function saveUserToSessionStorage(state: IUserStore) {
  sessionStorage.setItem('configUserInfo', JSON.stringify(toRaw(state)))
}

/**
 * 全局用户信息
 * @description 初始化包含（initDouYaInfo, initGlobalUserInfo）
 * @description 分别位于 Indexstorelocation.tsx, src/index.tsx
 *
 * @classdesc 用`updateGlobalUserInfo`函数进行更新
 */
export type IUserStore = Omit<
  IGlobalUser & IUserMeta & DeepPartial<IDouYaUser>,
  'userCode' | 'userName'
>

/**
 * 全局用户信息（globalUserState本身不会造成组件重新渲染, 可以用于点击事件中）
 * @description 用`updateGlobalUserInfo`函数进行更新
 *
 * ```tsx
 * // 以下方式相当于useState，数据变化时，组件可以重新渲染
 * const userInfo = useSnapshot(globalUserState)
 *
 * return (
 *   <div>{ userInfo.name }</div>
 * );
 * ```
 */
export const globalUserState = proxy({} as IUserStore)

/**
 * 更新 userinfo信息
 */
export function updateGlobalUserInfo(info: DeepPartial<IUserStore>) {
  const newState = toRaw<IUserStore>(info)

  for (const key in newState) {
    if (Object.prototype.hasOwnProperty.call(newState, key)) {
      globalUserState[key] = newState[key]
    }
  }

  saveUserToSessionStorage(newState)
  return globalUserState
}

/**
 * 获取全局user配置 (TIPS；该函数返回的数据不会造成组件重新渲染, 所以一般用于点击事件中)
 * @description 默认从globalUserState中取，若没有，则取sessionStorage.configUserInfo
 * @description sessionStorage.configUserInfo 为持久化方案
 */
export function getGlobalUserConfig(): IUserStore {
  const state = toRaw<IUserStore>(globalUserState) || ({} as IUserStore)
  if (state && state.personNo) {
    return state
  } else if (sessionStorage.getItem('configUserInfo')) {
    return JSON.parse(sessionStorage.getItem('configUserInfo')) as IUserStore
  } else {
    console.log('缓存getUserInfo异常')
    return {} as IUserStore
  }
}

/** 初始化豆芽信息 */
export async function initDouYaInfo(
  onSuccess?: () => void,
  onFail?: (err?: any, notSupport?: boolean) => void
) {
  if (Douya.canIuse('getUserInfo')) {
    console.log('init douya ---> getUserInfo')
    let { res, err } = await Douya.getUserInfo()
    if (!err) {
      const newInfo = { ...getGlobalUserConfig(), ...res } as IUserStore
      newInfo.personNo = res.userCode || newInfo.personNo
      newInfo.name = res.userName || newInfo.name
      updateGlobalUserInfo(newInfo)
    }
  }

  if (Douya.canIuse('getLocationInfo')) {
    console.log('init douya ---> getLocationInfo')
    Douya.getLocationInfo()
      .then((result: any) => {
        console.log(' ------> get location', result)
        const userState = getGlobalUserConfig()
        const newInfo = { ...userState, ...result } as IUserStore
        newInfo.personNo = userState?.personNo || result.userCode
        newInfo.name = userState?.name || result.userName
        updateGlobalUserInfo(newInfo)
        onSuccess && onSuccess()
      })
      .catch((err) => {
        console.log(' ------> get location err', err)
        onFail && onFail(err)
      })
  } else {
    onFail && onFail(undefined, true)
  }

  if (__MOCK_DOUYA__) {
    onSuccess && onSuccess()
  }
}

export interface ISliceArea {
  /** 片区编码 */
  sliceAreaCode: string
  /** 片区名称 */
  sliceAreaName: string
}

export interface IRoleItem {
  /**角色编码 */
  roleCode: string
  /** 角色名称 */
  roleName: string
  /** 数据维度 (可选值：1 - 全国，2 - 大区，3 - 片区。) */
  dataDimension: string
  /** 角色状态 (可选值：1 - 正常，-100 - 未分配大区，-101 - 未分配片区。) */
  roleStatus: string
  /** 店铺列表 -- ST1：快修店 ST2：综合家电精选店 ST12：手机快修 ST13：电子烟专卖 ST14：家居 */
  storeTypeList: Array<string>
}

export interface IUserRole extends IRoleItem {
  /** 菜单列表 */
  menuList: IMenuItem[]
  /** 数据类目集 */
  dataGroupList: Array<IDataGroup>
}

export interface IMenuItem {
  /** 菜单编码 */
  menuCode: string
  /** 菜单名称 */
  menuName: string
  /** 菜单链接 */
  menuUrl: string
  /** 菜单图片地址 */
  imgUrl: string
  /** 模块副标题 */
  menuSubName?: string
  /** 模块 eleid */
  eleid?: string
}

export interface IDataGroup {
  name: string
  code: string
}

export interface IUserMeta {
  /** 工号 */
  personNo: string
  /** 姓名 */
  name: string
  /** 职务 */
  duty: string
  /** 职务描述 */
  dutyDesc: string
  /** 岗位 */
  position: string
  /** 大区 */
  regionCode: string
  /** 大区名称 */
  regionName: string
  /** 员工片区信息列表 */
  sliceAreaList: ISliceArea[]
  /** 员工角色信息列表 */
  roleList: IUserRole[]
  /** 当前角色 */
  selectRole: IUserRole
  /** 当前店铺类型: ST1：快修店 ST2：综合家电精选店 ST12：手机快修 ST13：电子烟专卖 ST14：家居 */
  selectStoreType: string
  /** 用户状态 可选值：1 - 在职，2 - 离职，3 - 删除。 */
  status: string
}

export interface IGlobalUser {
  /** 用户角色 */
  accountRole: EAccountRole
  /** 默认展示的区 */
  sliceArea?: ISliceArea
  /** 大区 */
  regionCode?: string
  /** 大区名称 */
  regionName?: string
  /** 员工的片区列表数据 */
  sliceAreaList: ISliceArea[]
  /** 菜单列表 */
  menuList: IMenuItem[]
  /** 选中的角色code */
  selectedRoleCode?: string
  /** 选中的店铺类型 */
  selectedStoreType?: string
  /** 选中的店铺类型: ST1：快修店 ST2：综合家电精选店 ST12：手机快修 ST13：电子烟专卖 ST14：家居 */
  selectStoreType?: string
  /** 数据类目集 */
  dataGroupList?: Array<IDataGroup>
  /** user接口返回得数据 */
  originalInfo?: IUserMeta
}

/** 存销比和样机数据 */
export class ICSurPrototypeData {
  /** 实货存销比 */
  stockUseRatio: string
  /** 加在途存销比 */
  transitStockUseRatio: string
  /** S级样机进店率 */
  sInStoreRate: string
  /** A级样机进店率 */
  aInStoreRate: string
  /** B级样机进店率 */
  bInStoreRate: string
  /** S级样机动销率 */
  sSaleRate: string
  /** A级样机动销率 */
  aSaleRate: string
  /** B级样机动销率 */
  bSaleRate: string
}

export enum EDeviceType {
  ANDROID = 'Android',
  IOS = 'iOS',
}

/** 豆芽APP获取的用户信息(优先级最高) */
export interface IDouYaUser {
  /** 可选，若能取到gps信息，返回省份，如"江苏省" */
  province?: string
  /** 可选，若能取到gps信息，返回区县，如"玄武区" */
  district?: string
  /*0:百度地图坐标，1:高德地图坐标*/
  mapType?: number | string
  /** 豆芽身份的uid */
  uid: string
  /**工号 */
  userCode?: string
  /** 姓名 */
  userName?: string
  /** 公司手机号 */
  phone: string
  /** 公司名称 */
  companyName: string
  /** 设备号 */
  mobileId: string
  /** 平台 */
  AppSource: EDeviceType
  /** 是否root或越狱 */
  isPhoneRoot: boolean
  /** 是否运行在虚机上 */
  onVirtual: boolean
  /** 可选，若能取到gps信息，返回纬度 */
  latitude?: string
  /** 可选，若能取到gps信息，返回经度 */
  longitude?: string
  /** 街道 */
  street: string
  /** 地址 */
  AddrStr: string
  /** 城市 */
  city: string
  /** 国家 */
  country: string
  /** 用户角色列表 */
  roleList: IRoleItem[]
  /** 当前角色 */
  selectRole: IRoleItem
  /** 当前店铺类型: ST1：快修店 ST2：综合家电精选店 ST12：手机快修 ST13：电子烟专卖 ST14：家居 */
  selectStoreType: string
  /** 大区名称 */
  regionName: string
  /** 大区编码 */
  regionCode: string
  /** 职务 */
  duty: string
  /** 职务描述 */
  dutyDesc: string
  /** 岗位 */
  position: string
  /** 片区列表 */
  sliceAreaList: ISliceArea[]
  /** 用户状态 可选值：1 - 在职，2 - 离职，3 - 删除。 */
  status: string
}
