import React, { useState, useEffect } from 'react'
import styles from './index.module.less'
import BaseTable, { SortType } from './components/base-table'
import EmptyText from './components/empty-text'
import { Icon } from 'antd-mobile'
import { Subject, BehaviorSubject, ReplaySubject } from 'rxjs'
import BaseTableGroup from './components/base-table-group'

interface IColumn {
  /** 列头主标题 */
  title: string
  /** 列头副标题 */
  subtitle?: string
  /** 列头副对应的 key */
  subtitleKey?: string
  /** 列宽度 */
  width?: string | number
  /** 列数据在数据项中对应的 key */
  dataIndex: string
  /** React 需要的 key，如果已经设置了唯一的 dataIndex，可以忽略这个属性 */
  key: string
  /** 生成复杂数据的渲染函数，参数分别为当前行的值，当前行数据，行索引 */
  render?: (text: string, record: any, index: number) => JSX.Element
  /** 排序函数，本地排序使用一个函数(参考 Array.sort 的 compareFunction)，需要服务端排序可设为 true */
  sorter?: (a: any, b: any) => number
  /** 排序状态 */
  sortType?: SortType
}

interface IProps {
  /** 表格列的配置描述 */
  columns: IColumn[]
  /** 表格的数据源 数据数组 */
  dataSource: any[]
  /** 是否需要固定第一列 */
  fixed?: boolean
  /** 空数据文案 */
  emptyText?: string | JSX.Element
  /** 是否加载中 */
  loading?: boolean
  /** 最大显示的排序 小于等于 0 不显示 */
  maxOrder?: number
  /** 点击行的回调 */
  onRowClick?: (data: any, index: number) => void
  /** 自定义背景色 */
  bgc?: 'gray' | 'blue'
}

/**
 * 通用的 移动端 表格组件
 * @param { IProps } props
 */
function Table(props: IProps) {
  const {
    columns,
    dataSource,
    fixed,
    emptyText,
    loading,
    maxOrder,
    onRowClick,
    bgc
  } = props
  /** 设置是否出现滚动 */
  const [isScroll, setIsScroll] = useState(true)
  /** 左侧固定栏 宽度 */
  const [fixedColumnWidth, setFixedColumnWidth] = useState(0)
  /** columns$ 变化流 */
  const [columns$] = useState(new ReplaySubject<IColumn[]>())
  /** 当前的排序状态 */
  const [currentSortType$] = useState(
    new BehaviorSubject<SortType>(SortType.asc)
  )
  /** 用于同步的 左侧固定列 的数据源 */
  const [fixedDataSource, setFixedDataSource] = useState(dataSource)
  useEffect(() => {
    columns.forEach(column => {
      if (column.sorter) {
        column.sortType = SortType.none
      }
    })
    const columnSortedFirst = columns.find(column => !!column.sorter)
    columnSortedFirst && (columnSortedFirst.sortType = SortType.dasc)
    /** 更新 columns */
    columns$.next(columns)
    currentSortType$.next(SortType.asc)
  }, [columns, dataSource])

  return (
    <div className={`${styles['table-wrapper']}`}>
      {fixed && isScroll && (
        <div
          className={`${styles['table-fixed']}`}
          style={{ width: fixedColumnWidth }}
        >
          <BaseTable
            overflow="hidden"
            columns$={columns$}
            dataSource={loading ? [] : fixedDataSource}
            currentSortType$={currentSortType$}
            maxOrder={maxOrder || 0}
            loading={loading}
            onRowClick={onRowClick}
            bgc={bgc}
          />
        </div>
      )}
      <BaseTable
        bgc={bgc}
        tableWidth="100%"
        columns$={columns$}
        maxOrder={maxOrder || 0}
        checkIsScroll={setIsScroll}
        currentSortType$={currentSortType$}
        unifyDataSource={setFixedDataSource}
        dataSource={loading ? [] : dataSource}
        updateFixedColumnWidth={setFixedColumnWidth}
        onRowClick={onRowClick}
        loading={loading}
      />
      {loading ? (
        <div className={`${styles.loading}`}>
          <Icon type="loading" />
        </div>
      ) : !dataSource.length ? (
        <EmptyText emptyText={emptyText} />
      ) : null}
    </div>
  )
}

interface IGroupProps {
  /** 表格列的配置描述 */
  columns: IColumn[]
  /** 表格的数据源 数据数组 */
  dataSource: any[][]
  /** 空数据文案 */
  emptyText?: string | JSX.Element
  /** 是否加载中 */
  loading?: boolean
  /** 点击行的回调 */
  onRowClick?: (data: any, outerIndex: number, index: number) => void
}

/**
 * 带 分组的表格组件 组件
 * @param { IGroupProps } props
 */
const Group: React.SFC<IGroupProps> = props => {
  const { columns, dataSource, loading, emptyText, onRowClick } = props
  /** 当前的排序状态 */
  const [currentSortType$] = useState(
    new BehaviorSubject<SortType>(SortType.asc)
  )
  /** columns$ 变化流 */
  const [columns$] = useState(new Subject<IColumn[]>())
  /** 监听 columns 变化 */
  useEffect(() => {
    columns.forEach(column => {
      if (column.sorter) {
        column.sortType = SortType.none
      }
    })
    const columnSortedFirst = columns.find(column => !!column.sorter)
    columnSortedFirst && (columnSortedFirst.sortType = SortType.dasc)
    /** 更新 columns */
    currentSortType$.next(SortType.asc)
    columns$.next(columns.slice())
  }, [columns, dataSource])
  return (
    <div className={`${styles['table-wrapper']}`}>
      <BaseTableGroup
        columns$={columns$}
        dataSource={loading ? [] : dataSource}
        currentSortType$={currentSortType$}
        loading={loading}
        onRowClick={onRowClick}
      />
      {loading ? (
        <div className={`${styles.loading}`}>
          <Icon type="loading" />
        </div>
      ) : !dataSource.length ? (
        <EmptyText emptyText={emptyText} />
      ) : null}
    </div>
  )
}

/** 静态属性和方法 */
interface Table {
  /** 带 分组的表格组件 组件 */
  Group: typeof Group
}

Table.Group = Group

export default Table
