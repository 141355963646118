/*
 * @Author: 韩念琪[18112596]
 * @Email: 18112596@cnsuning.com
 * @Date: 2019-07-10 19:32:58
 * @Last Modified by: 杨建新(20014264)
 * @Last Modified time: 2021-09-09 14:10:41
 * @Description: 基于 BaseFetch 的业务 Fetch
 */

import qs from 'qs'
import { BaseFetch } from '@kits'
import { IUomParam } from './plugins/uom-argo-plugin'

/** 可选配置项参数 */
interface IOpts {
  /** uom 参数 */
  uomParam?: IUomParam
  /** 是否需要隐藏 toast 提示 */
  toasthide?: boolean
  /** 是否需要检查登陆状态 */
  needCheckLogin?: boolean
}

/**
 * 继承 BaseFetch 类 的业务 Fetch 类
 */
class Fetch extends BaseFetch {
  opts: IOpts
  constructor(middlewares: BaseFetch.middleware[] = [], opts: IOpts = {}) {
    super(middlewares)
    this.opts = opts
  }

  /**
   * GET
   * @param uri
   * @param query
   * @param opts
   */
  get<T>(uri: string, query: object = {}, opts: IOpts = {}) {
    return this.common<T>(
      uri,
      { method: 'GET', params: query },
      { ...this.opts, ...opts }
    )
  }

  /**
   * POST
   * @param uri
   * @param data
   * @param opts
   */
  post<T>(uri: string, data: object, opts: IOpts = {}) {
    return this.common<T>(
      uri,
      { method: 'POST', data: qs.stringify(data) },
      { ...this.opts, ...opts }
    )
  }

  /**
   *
   * @param uri
   * @param body
   * @param opts
   */
  postFile<T>(uri: string, body: Object, opts: IOpts = {}) {
    return this.common<T>(
      uri,
      {
        method: 'POST',
        timeout: 50000,
        maxContentLength: 50 * 1024 * 1024,
        data: body,
        headers: { 'Content-Type': 'multipart/form-data' }
      },
      { ...this.opts, ...opts }
    )
  }


  /**
   * POSTJSON
   * @param uri
   * @param data
   * @param opts
   */
  postJson<T>(uri: string, data: object, opts: IOpts = {}) {
    return this.common<T>(
      uri,
      { method: 'POST', data, headers: { 'Content-Type': 'application/json' } },
      { ...this.opts, ...opts }
    )
  }

  /**
   * PUT
   * @param uri
   * @param data
   * @param opts
   */
  put<T>(uri: string, data: object = {}, opts: IOpts = {}) {
    return this.common<T>(
      uri,
      { method: 'PUT', data: qs.stringify(data) },
      { ...this.opts, ...opts }
    )
  }

  /**
   * DELETE
   * @param uri
   * @param data
   * @param opts
   */
  delete<T>(uri: string, data: object = {}, opts: IOpts = {}) {
    return this.common<T>(
      uri,
      { method: 'DELETE', data: qs.stringify(data) },
      { ...this.opts, ...opts }
    )
  }
}

const fetch = new Fetch()

export default fetch
