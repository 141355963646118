/*
 * @Author: 韩念琪[18112596]
 * @Email: 18112596@cnsuning.com
 * @Date: 2019-07-12 14:12:11
 * @Last Modified by: 吴超(20023244)
 * @Last Modified time: 2023-06-07 19:24:47
 * @Description: 根据子域名后缀动态判断当前所处环境
 */

/**
 * 环境匹配规则
 */
const validHostRules = {
  dev: /^(?:127\.0\.0\.1|localhost)(?:\:\d*)?$/,
  sit: /^.+sit\.cnsuning\.com(?:\:\d*)?$/,
  prexg: /^.+prexg\.cnsuning\.com(?:\:\d*)?$/,
  prexz: /^.+prexz\.cnsuning\.com(?:\:\d*)?$/,
  pro: []
}

/**
 * 自动根据 host 获取运行环境
 * @param { string[] } PRO_HOST_LIST 生产环境白名单
 */
function autoGetEnv(PRO_HOST_LIST: string[]): keyof typeof validHostRules {
  validHostRules.pro = [...validHostRules.pro, ...PRO_HOST_LIST]
  const { host } = location
  const { pro: proHostWhiteList, ...devHostRules } = validHostRules
  if (proHostWhiteList.find(validHost => validHost.includes(host))) {
    return 'pro'
  } else {
    // @ts-ignore
    return (Object.keys(devHostRules).find((key: keyof typeof devHostRules) =>
      devHostRules[key].test(host)
    ) || 'dev') as keyof typeof validHostRules
  }
}

export default autoGetEnv
