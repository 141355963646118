/*
 * @Author: 唐正宗(18133443)
 * @Email: 18133443@cnsuning.com
 * @Date: 2020-06-24 15:43:49
 * @Last Modified by: 杨建新(20014264)
 * @Last Modified time: 2021-02-26 17:16:17
 * @Description: Description
 */

import React from 'react'
import './index.less'
import nodataPic from '@assets/images/no-store-record@2x.png'
import pageLoading from '@assets/images/loading.svg'
interface IProps {
  textTag?: string
  imgUrl?: string
  smallTextTag?: string
  loadingImg?: string
  /**默认loading图 */
  defaultLoadingImg?:boolean
  //最外围容器背景色
  backgroundColor?:string
  /**加一个class用于自定义样式 */
  className?:string
}

export default class ShopNoData extends React.Component<IProps> {
  static defaultProps = {
    textTag: '暂无数据',
    imgUrl: nodataPic,
    defaultLoadingImg:false
  }
  constructor(props) {
    super(props)
  }

  render() {
    const { loadingImg, textTag, imgUrl, smallTextTag ,backgroundColor,className,defaultLoadingImg} = this.props
    return (
      <div className={`global-no-data-wrap ${className}`} style={{background:backgroundColor?backgroundColor:""}}>
        {/*自定义loading图*/}
        {loadingImg && (
          <div className="no-loading-pic">
            <img src={this.props.loadingImg} alt="" />
          </div>
        )}
        {/*无数据显示 */}
        {!loadingImg && !defaultLoadingImg&& (
          <div className="no-data-pic">
            <img src={imgUrl} alt="" />
          </div>
        )}
        {/**使用默认loading图 */}
        {
          !loadingImg && defaultLoadingImg && (
            <div className="no-loading-pic">
            <img src={pageLoading} alt="" />
          </div>
          )
        }

        <div className="no-data-text">{textTag}</div>
        {smallTextTag && <div className="small-data-text">{smallTextTag}</div>}
      </div>
    )
  }
}
