import info from '@assets/images/info2.png'
import { baseAllState } from '@/stores'
import { Flex } from 'antd-mobile'
import { Router, Sa, util } from '@kits'
import { useSnapshot } from 'valtio'
import './index.less'

export default function AgainInspection() {
  const baseAllInfo = useSnapshot(baseAllState)

  function agianInspection() {
    Sa.sendClickData('1wdgxzf', {
      eleid: '1',
      eletitle: `继续巡店`,
      ...util.saRoleParamClickObj(),
    })

    Router.Push('/inspection/unfinished-inspection-list')
  }

  return baseAllInfo?.unFinishInspectNum ? (
    <Flex className="components-again-inspection" justify="between">
      <Flex align="center">
        <img className="unsupport-icon" src={info} alt=""></img>
        <span className="hint">
          您有
          <span className="hint-number">
            {baseAllInfo?.unFinishInspectNum || ''}
          </span>
          个未完成的巡店
        </span>
      </Flex>
      <Flex justify="center" className="button" onClick={agianInspection}>
        <span>继续巡店</span>
      </Flex>
    </Flex>
  ) : (
    <div className="home-again-inspection-blank"></div>
  )
}
