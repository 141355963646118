import * as React from 'react'
import './index.less'

interface SwitchProps {
  onChange?: (value: boolean) => void
  checked?: boolean
  title1?: string
  title2?: string
}

export default class Index extends React.PureComponent<SwitchProps> {
  static defaultProps = {
    checked: false,
    title1: '季度',
    title2: '年度'
  }

  render() {
    return (
      <div className="snrc-switch" onClick={this.onChange.bind(this)}>
        <div className="snrc-switch-title">{this.props.title1}</div>
        <div className="snrc-switch-title">{this.props.title2}</div>
        <span
          className={`snrc-switch-suspension ${
            !this.props.checked
              ? 'snrc-switch-suspension-normal'
              : 'snrc-switch-suspension-move'
            }`}
        >
          {this.props.checked ? this.props.title2 : this.props.title1}
        </span>
      </div>
    )
  }

  onChange() {
    this.props.onChange && this.props.onChange(!this.props.checked)
  }
}
