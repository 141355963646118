/*
 * @Author: 柯庆荣19040892
 * @Email: 19040892@cnsuning.com
 * @Date: 2019-11-05 20:29:33
 * @Last Modified by: 柯庆荣19040892
 * @Last Modified time: 2019-11-05 21:01:24
 * @Description: Description
 */
import React from 'react'
import classNames from 'classnames'
import styles from './index.module.less'

interface IProps {
  /** 姓名 */
  name: string
  /** 样式类名 */
  className?: string
  /** 宽度 */
  width?: string
}

export default class TextAvatar extends React.Component<IProps> {
  static defaultProps = {
    name: '',
    className: ''
  }

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div
        className={classNames(styles['text-avatar'], this.props.className)}
        style={{
          width: this.props.width,
          height: this.props.width
        }}
      >
        {this.props.name.length > 2
          ? this.props.name.slice(this.props.name.length - 2)
          : this.props.name}
      </div>
    )
  }
}
