/*
 * @Author: 韩念琪[18112596]
 * @Email: 18112596@cnsuning.com
 * @Date: 2019-07-11 21:06:43
 * @Last Modified by: 韩念琪[18112596]
 * @Last Modified time: 2019-08-17 10:44:08
 * @Description: 水印插件
 */

import ArgoPlugin from './argo-plugin'
import watermark from '@suning/sn-watermark-dom'

declare global {
  namespace Argo {
    /** 水印全局对象 */
    namespace WatermarkArgoPlugin {
      /** 更新水印设置 */
      const update: WatermarkArgoPlugin['update']
    }
  }
}

interface IWatermarkSetting {
  /** 水印总体dom的id（默认值：wm_div_id） */
  watermark_id?: string
  /** 小水印div的id前缀(默认值：mask_div_id) */
  watermark_prefix?: string
  /** 水印内容(默认值："测试水印") */
  watermark_txt?: string
  /** 水印起始位置距离x轴距离（默认值：20） */
  watermark_x?: number
  /** 水印起始位置距离y轴距离（默认值：20） */
  watermark_y?: number
  /** 水印行数（默认值：0，自适应行数） */
  watermark_rows?: number
  /** 水印列数（默认值：0，自适应列数） */
  watermark_cols?: number
  /** 水印之间的水平距离（默认值：100） */
  watermark_x_space?: number
  /** 水印之间的水平距离（默认值：50） */
  watermark_y_space?: number
  /** 水印的字体（默认值：'微软雅黑'） */
  watermark_font?: string
  /** 水印的颜色（默认值: 'black'） */
  watermark_color?: string
  /** 水印字体大小（默认值：'18px'） */
  watermark_fontsize?: string
  /** 水印的透明度(`注意`:要求设置在大于等于0.005，默认值：0.15) */
  watermark_alpha?: number
  /** 每个小水印的宽度（默认值：150） */
  watermark_width?: number
  /** 每个小水印的高度（默认值：100） */
  watermark_height?: number
  /** 每个小水印的旋转角度（默认值：15） */
  watermark_angle?: number
  /** 水印的总体宽度（默认值：body的scrollWidth和clientWidth的较大值） */
  watermark_parent_width?: number
  /** 水印的总体高度（默认值：body的scrollHeight和clientHeight的较大值） */
  watermark_parent_height?: number
  /** 水印插件挂载的父元素element，不输入则默认挂在body上 */
  watermark_parent_node?: HTMLElement
}

class WatermarkArgoPlugin extends ArgoPlugin {
  /** 默认水印配置 */
  private setting: IWatermarkSetting = {
    watermark_txt: '',
    watermark_x_space: 20,
    watermark_y_space: 25,
    watermark_fontsize: '12px',
    watermark_width: 120,
    watermark_height: 70
  }

  /** update 迭代器 */
  updateIterator: IterableIterator<void>

  constructor() {
    super()
    this.updateIterator = this.updateGenerator()
  }

  /** 注册水印自动监听 */
  register() {
    const observer = new MutationObserver((_mutations, observer) => {
      // 清空记录
      observer.takeRecords()
      watermark.load(this.setting)
    })
    observer.observe(document.getElementById('root'), {
      childList: true,
      subtree: true,
      attributes: false,
      attributeOldValue: false,
      characterDataOldValue: false
    })
  }

  globalExport() {
    return {
      update: this.update
    }
  }

  /** 更新水印设置 */
  update = (setting: IWatermarkSetting) => {
    this.setting = {
      ...this.setting,
      ...setting
    }
    this.updateIterator.next()
  }

  /** 更新水印配置生成器 */
  private *updateGenerator() {
    watermark.init(this.setting)
    watermark.load(this.setting)
    this.register()
    while (true) {
      yield
      watermark.load(this.setting)
    }
  }
}
const watermarkArgoPlugin = new WatermarkArgoPlugin()

// window['Watermark'] = {
//   update: watermarkArgoPlugin.update
// }

export default watermarkArgoPlugin
