/*
 * @Author: tzz
 * @LastEditors: tzz
 * @Description:
 * @Date: 2019-03-19 14:45:09
 * @LastEditTime: 2019-04-15 09:58:21
 */

import * as React from 'react'
import './index.less'

interface IProps {
  tabs: string[]
  checkedTag?: string
  onChecked: (p) => void
}

interface IStates {
  checkedTag: string
}

export default class SwitchDay extends React.Component<IProps, IStates> {
  static defaultProps = {
    tabs: [],
    checkedTag: '0'
  }

  constructor(props) {
    super(props)
    this.state = {
      checkedTag: this.props.checkedTag
    }
  }

  componentWillReceiveProps(props) {
    this.setState({ checkedTag: props.checkedTag })
  }

  render() {
    return (
      <div className="switch-day">
        {this.props.tabs.map((title, index) => {
          return (
            <div
              className="item"
              key={index}
              onClick={_ => this._onCheckTag('' + index)}
            >
              <span
                className={
                  this.state.checkedTag === '' + index ? 'title-b' : 'title-nb'
                }
              >
                {title}
              </span>
            </div>
          )
        })}
      </div>
    )
  }

  _onCheckTag = (tag: string) => {
    this.setState({ checkedTag: tag })
    this.props.onChecked(tag)
  }
}
