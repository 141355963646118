/*
 * @Author: 柯庆荣19040892
 * @Email: 19040892@cnsuning.com
 * @Date: 2019-11-18 19:38:21
 * @Last Modified by: 柯庆荣19040892
 * @Last Modified time: 2019-11-18 19:55:43
 * @Description: Description
 */
import React, { RefObject } from 'react'
import classNames from 'classnames'
import './index.less'
import { SearchBar } from 'antd-mobile'
import { SearchBarProps } from 'antd-mobile/lib/search-bar/index'
import { util } from '@kits'

interface IProps extends SearchBarProps {
  /** 样式类名 */
  snrcClassName?: string
  /** 点击搜索 */
  disClick?: () => void
  /** 是否默认 focus */
  initFocus?: boolean
}

export default class Index extends React.PureComponent<IProps, any> {
  static defaultProps = {
    initFocus: false
  }

  constructor(props) {
    super(props)
    this.state = {
      device: 'iphone'
    }
  }

  searchBarRef: RefObject<any> = React.createRef()

  componentDidMount() {
    this.setState({
      device: util.getDevice().device
    })

    setTimeout(() => {
      console.log(' -----> sarch ref', this.searchBarRef)
      this.props.initFocus &&
        this.searchBarRef &&
        this.searchBarRef.current &&
        this.searchBarRef.current.focus()
    }, 800)
  }

  contentClick() {
    if (!this.props.disabled) return
    this.props.disClick && this.props.disClick()
  }

  render() {
    const { device } = this.state
    const { initFocus, snrcClassName, disClick, ...restProps } = this.props
    return (
      <div
        className={classNames(
          {
            'android-snrc-search-bar': device === 'android',
            'snrc-search-bar': device !== 'android'
          },
          this.props.snrcClassName
        )}
        onClick={this.contentClick.bind(this)}
      >
        <SearchBar
          className="snrc-search-bar-content"
          ref={this.searchBarRef}
          {...restProps}
        />
      </div>
    )
  }
}
