/*
 * @Author: 杨建新(20014264)
 * @Email: 20014264@cnsuning.com
 * @Date: 2021-09-18 10:18:40
 * @Last Modified by: 吴超(20023244)
 * @Last Modified time: 2023-06-20 11:03:56
 * @Description: switch切换
 */

import * as React from 'react'
import './index.less'

export interface ISwitchItem {
  /**名称 */
  name?: string
  /**值 */
  value?: string
  /**是否选中 */
  checked?: boolean

}

interface SwitchProps {
  /**from 2 */
  from?: string
  /**数据源 */
  switchList?: ISwitchItem[],
  /**初始选中的值，就是默认值 */
  currentItem?: ISwitchItem
  /**点击事件 */
  onChange?: (value: ISwitchItem) => void
}



export default class Index extends React.PureComponent<SwitchProps> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  render() {
    const { switchList, currentItem, from } = this.props;
    return (
      <>
        <div className="src-new-switch">
          {
            switchList && switchList.map((item) => {
              return (
                <div className="src-new-switch-title" onClick={() => this.checkHandler(item)} key={item.name}>
                  {item.name}
                </div>
              )
            })
          }
        </div>
        {
          this.currentRender()
        }

      </>
    )
  }

  currentRender() {
    const { currentItem, from } = this.props;
    if (from === 'performance-report') {
      return (
        <span className="src-new-switch-suspension" style={{ left: `${1.2 * (Number(currentItem.value) - 2)}rem` }}>
          {currentItem.name}
        </span>
      )
    } else {
      if (currentItem) {
        return (
          <span className="src-new-switch-suspension" style={{ left: `${1.2 * (Number(currentItem.value) - 1)}rem` }}>
            {currentItem.name}
          </span>
        )
      }
    }
  }
  /**点击 */
  checkHandler(item: ISwitchItem) {
    this.props.onChange && this.props.onChange(item)
  }
}
