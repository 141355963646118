import { EMode } from '@/components/the-time-picker/TimePickerTypes'

export interface ISwitchItem {
  /**名称 */
  name?: string
  /**值 1当月 2当季 3当年*/
  value?: string
  /**是否选中 */
  checked?: boolean
}

export const defaultSwitchList: ISwitchItem[] = [
  {
    name: '当月',
    value: EMode.Month,
    checked: false,
  },
  {
    name: '当季',
    value: EMode.Quarter,
    checked: false,
  },
  {
    name: '当年',
    value: EMode.Year,
    checked: false,
  },
]

export const defaultCurrentItem: ISwitchItem = {
  name: '当月',
  value: EMode.Month,
  checked: true,
}
