/*
 * @Author: 柯庆荣19040892
 * @Email: 19040892@cnsuning.com
 * @Date: 2019-10-25 10:50:52
 * @Last Modified by: 柯庆荣19040892
 * @Last Modified time: 2019-11-21 10:07:46
 * @Description: Description
 */
import React from 'react'
import classNames from 'classnames'
import styles from './index.module.less'
import { util } from '@kits'

interface IProps {
  /** 类型 */
  type?: 'info' | 'warning' | 'error'
  /** 样式类名 */
  className?: string
}

export default class EvaluationLabel extends React.Component<IProps> {
  static defaultProps = {
    type: 'info',
    className: ''
  }

  state = {
    isAndroid: false
  }

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (util.getDevice().device === 'android') {
      this.setState({
        isAndroid: true
      })
    }
  }

  render() {
    return (
      <div
        className={classNames(
          styles['evaluation-label'],
          styles[`evaluation-label-${this.props.type}`],
          this.state.isAndroid && styles[`evaluation-label-android`],
          this.props.className
        )}
      >
        <span
          className={classNames(
            styles['evaluation-label-dot'],
            styles['evaluation-label-dot-left']
          )}
        ></span>
        <span className={styles['evaluation-label-title']}>
          {this.props.children}
        </span>
        <span
          className={classNames(
            styles['evaluation-label-dot'],
            styles['evaluation-label-dot-right']
          )}
        ></span>
      </div>
    )
  }
}
