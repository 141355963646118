import { proxy } from 'valtio'

/** 首页区域弹框-当前选中得区域code */
export const currentAreaCode = proxy({ value: '' })

/**
 * 更新 "首页区域弹框-当前选中得区域code"
 */
export function updateCurrentAreaCode(code: string) {
  currentAreaCode.value = code
}
