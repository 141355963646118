import hong from '@assets/images/yyyj_icon5.png'
import huang from '@assets/images/yyyj_icon4.png'
import liang from '@assets/images/yyyj_icon3.png'
import liangjia from '@assets/images/yyyj_icon2.png'
import qs from 'qs'
import you from '@assets/images/yyyj_icon1.png'
import { baseAllState, getGlobalUserConfig, globalUserState } from '@/stores'
import { EAccountRole } from '@genre'
import { Flex } from 'antd-mobile'
import { Router, Sa, util } from '@kits'
import { useSnapshot } from 'valtio'
import styles from './index.module.less'

export default function Achievement() {
  const baseAllInfo = useSnapshot(baseAllState)
  const userInfo = useSnapshot(globalUserState)

  /** 运营人员详情 */
  function onOpenPerformanceScore() {
    const userState = getGlobalUserConfig();

    Sa.sendClickData('04knnp4', {
      eleid: '5',
      eletitle: `运营业绩查看详情`,
      ...util.saRoleParamClickObj(),
    })

    switch (userState.accountRole) {
      case EAccountRole.NATIONAL:
        Router.Push(
          `/perf/region-list?${qs.stringify({
            accountRole: userState.accountRole,
          })}`
        )
        break
      case EAccountRole.REGION:
      case EAccountRole.REGION_Manager:
        Router.Push(
          `/perf/subregion-list?${qs.stringify({
            accountRole: userState.accountRole,
            regionCode: userState.regionCode,
            regionName: userState.regionName,
            year: util.getTimeInfo().year,
            month: util.getTimeInfo().adjustMonth,
          })}`
        )
        break
      case EAccountRole.AREA:
        Router.Push(
          `/perf/store-list?${qs.stringify({
            accountRole: userState.accountRole,
            regionCode: userState.regionCode,
            regionName: userState.regionName,
            sliceAreaCode: userState.sliceArea
              ? userState.sliceArea.sliceAreaCode
              : '',
            sliceAreaName: userState.sliceArea
              ? userState.sliceArea.sliceAreaName
              : '',
            year: util.getTimeInfo().year,
            month: util.getTimeInfo().adjustMonth,
          })}`
        )
        break
    }
  }

  return (
    <Flex
      justify="center"
      direction="column"
      className={styles['achievement-score']}
    >
      <div className={styles['yyyj-wrap']}>
        <div className={styles['left-box']}>
          {/* level： 可选值：Y1 - 红牌，Y2 - 黄牌，Y3 - 良，Y4 - 良+，Y5 - 优。 */}
          <>
            {!baseAllInfo?.expandPerformance?.stepCode && (
              <span>&nbsp;&nbsp;</span>
            )}
            {baseAllInfo?.expandPerformance?.stepCode === 'Y5' && (
              <img src={you} className={styles['yyyj-icon']} />
            )}
            {baseAllInfo?.expandPerformance?.stepCode === 'Y4' && (
              <img src={liangjia} className={styles['yyyj-icon']} />
            )}
            {baseAllInfo?.expandPerformance?.stepCode === 'Y3' && (
              <img src={liang} className={styles['yyyj-icon']} />
            )}
            {baseAllInfo?.expandPerformance?.stepCode === 'Y2' && (
              <img src={huang} className={styles['yyyj-icon']} />
            )}
            {baseAllInfo?.expandPerformance?.stepCode === 'Y1' && (
              <img src={hong} className={styles['yyyj-icon']} />
            )}
          </>
          <div className={styles['left-text-box']}>
            <div className={styles['name']}>
              <span>
                {[
                  EAccountRole.NATIONAL,
                  EAccountRole.REGION,
                  EAccountRole.REGION_Manager,
                ].includes(userInfo?.accountRole) && <>全国</>}
                {[EAccountRole.AREA].includes(userInfo?.accountRole) && (
                  <>大区内</>
                )}
              </span>
              排名
            </div>
            <div className={styles['num']}>
              {baseAllInfo?.staffPerformance?.rank || '-'}
            </div>
          </div>
          <div className={styles['left-text-box2']}>
            <div className={styles['name']}>综合运营评分</div>
            <div className={styles['num']}>
              {baseAllInfo?.staffPerformance?.operationComScore || '-'}
            </div>
          </div>
        </div>
        <div className={styles['right-box']} onClick={onOpenPerformanceScore}>
          <span className={styles['right-text']}>查看详情</span>
          <span className={styles['right-triangle']} />
        </div>
      </div>
    </Flex>
  )
}
