/*
 * @Author: 韩念琪[18112596]
 * @Email: 18112596@cnsuning.com
 * @Date: 2019-07-11 20:06:13
 * @Last Modified by: 吴超(20023244)
 * @Last Modified time: 2023-06-20 11:02:07
 * @Description: 基于 axios 封装的通用 BaseFetch 类
 */

import axios, { AxiosRequestConfig, AxiosPromise, AxiosResponse } from 'axios'

/** 网络请求超时 */
const NETWORK_TIMEOUT = 'NETWORK_TIMEOUT'
/** 没有受到服务器返回 */
const NONE_RESPONSE = 'NONE_RESPONSE'
/** 未知异常 */
const UNKNOWN_EXCEPTION = 'NONE_RESPONSE'

/**
 * 基础 fetch 类，支持中间件机制
 */
class BaseFetch {
  /** 前置 middleware 中间件 */
  static preMiddlewares: BaseFetch.middleware[] = []

  /** 最大请求超时时间 */
  private readonly NETWORK_TIMEOUT: number = 30

  /** axios 请求基础参数 */
  private readonly requestConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'GET',
    withCredentials: true,
  }

  /** 中间件函数数组 */
  private middlewares: BaseFetch.middleware<any>[] = []

  /** 请求调用链 */
  private requestChain: <T>(
    requestConfig: AxiosRequestConfig,
    state: IMiddlewareState<T>
  ) => Promise<any>

  constructor(middlewares: BaseFetch.middleware<any>[] = []) {
    this.middlewares = middlewares
    this.requestChain = this.applyMiddlewares()
    this.common = this.common.bind(this)
  }

  /**
   * 动态添加前置的 middleware
   * @param args
   */
  addMiddlewares(...args: BaseFetch.middleware<any>[]) {
    this.middlewares = [...args, ...this.middlewares]
    this.requestChain = this.applyMiddlewares()
  }

  /**
   * 实现中间件调用链
   */
  private applyMiddlewares<T = any>() {
    const finalMiddleware = async (
      requestConfig: AxiosRequestConfig,
      state: IMiddlewareState<T>
    ) => {
      const result = await Promise.race([
        this.request<T>(requestConfig),
        this.requestTimeout(),
      ])
      state.rawRes = result
      if (result) {
        const { code, data, msg } = result.data
        state.ret = {
          err: code !== '000000' && msg ? new Error(msg) : null,
          res: data,
          code,
        }
      }
      return result
    }
    return this.middlewares.reduceRight(
      (chain, middleware) => middleware(chain),
      finalMiddleware
    )
  }

  /** 请求超时方法 */
  private requestTimeout(): AxiosPromise<BaseFetch.IResponse<null>> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: {
            code: NETWORK_TIMEOUT,
            msg: `网络请求超时`,
            data: null,
          },
          status: -1,
          statusText: NETWORK_TIMEOUT,
          headers: null,
          config: null,
          request: null,
        })
      }, this.NETWORK_TIMEOUT * 1000)
    })
  }

  /**
   * 通用的系统增加前缀的 log: [system] ...
   * @param args
   */
  private log(...args: any[]) {
    if (!__ENV_DEV__) {
      console.log('[system]', ...args)
    }
  }

  /**
   * 格式化 axios 返回值
   */
  private async request<T = any>(requestConfig: AxiosRequestConfig) {
    if (__ENV_DEV__) {
      this.log('HTTP REQUEST=>' + requestConfig.url)
      this.log('     REQUEST body =>', requestConfig)
    }
    try {
      const axiosResponsePromise: AxiosPromise<BaseFetch.IResponse<T>> = axios(
        requestConfig
      ).catch((reason) => {
        if (reason) {
          return {
            data: {
              code: reason.message,
              msg: reason.message,
              data: null,
            },
            status: reason.status,
            statusText: reason.message,
            headers: null,
            config: reason.config,
            request: reason.request,
          }
        } else {
          return {
            data: {
              code: NONE_RESPONSE,
              msg: `未能接收到服务器返回的数据，请检查是否跨域或重定向`,
              data: null,
            },
            status: NONE_RESPONSE as any,
            statusText: `未能接收到服务器返回的数据，请检查是否跨域或重定向`,
            headers: null,
            config: null,
            request: null,
          }
        }
      })
      const axiosResponse = await axiosResponsePromise
      const { data: responseData } = axiosResponse
      const { code, data } = responseData
      if (code === '000000' && __ENV_DEV__) {
        this.log('     HTTP RESPONSE', data)
      }
      return axiosResponse
    } catch (e) {
      this.log(e)
      return {
        data: {
          code: UNKNOWN_EXCEPTION,
          msg: `未知异常`,
          data: null,
        },
        status: UNKNOWN_EXCEPTION as any,
        statusText: `未知异常`,
        headers: null,
        config: null,
        request: null,
      }
    }
  }

  /**
   * 基础 fetch 组件
   * @param urlPath 输入url等
   * @param init 初始化http header信息等
   * @param state 中间件全局 state
   */
  async common<T>(
    urlPath: string,
    init: AxiosRequestConfig = {},
    state: IMiddlewareState<T> = {}
  ) {
    // headers["Content-Type"] = "application/json";
    // headers["mode"] = "cors";
    // headers["credentials"] = "omit";
    // headers["credentials"] = "include";
    // headers["version"] = Config.SERVERVERSION;
    // headers['Authorization'] = Auth.sessionId ? Auth.sessionId : '';

    /** 请求参数 */
    const requestConfig: AxiosRequestConfig = {
      ...this.requestConfig,
      url: urlPath,
      ...init,
    }

    await this.requestChain(requestConfig, state)
    return (
      state.ret || {
        err: new Error(`未知异常`),
        res: null,
        code: UNKNOWN_EXCEPTION,
      }
    )
  }
}

/** 中间件全局 state */
type IMiddlewareState<T, E = {}> = {
  /** 中间件调用链回溯阶段会添加 ret字段 */
  ret?: BaseFetch.IResult<T>
  /** 原始返回 */
  rawRes?: AxiosResponse
  [key: string]: any
} & E

namespace BaseFetch {
  /** 中间件函数 */
  export type middleware<T = any, E = {}> = (
    next: (
      requestConfig: AxiosRequestConfig,
      state: IMiddlewareState<T, E>
    ) => Promise<any>
  ) => (
    requestConfig: AxiosRequestConfig,
    state: IMiddlewareState<T, E>
  ) => Promise<any>
  /** axios 泛型定义 */
  export interface IResponse<R> {
    data: R
    msg: string
    code: string | number
  }

  /** 对外返回的数据结构 */
  export interface IResult<T> {
    err: Error
    res: T
    code: string | number
  }
}

export default BaseFetch
