/* eslint-disable snrcfe/with-file-header */
import * as React from 'react'
import './index.less'
import { Flex, ActivityIndicator } from 'antd-mobile'
import ImageView from '../image-view'
import AddImage from '@assets/images/addImg.png'
import UploadFail from '@assets/images/uploadFail.png'
import { Douya } from '@kits'
import { chooseImage, parseDataURL } from '@utils';
import { ImagesSlide } from '../use-photo-gallery/usePhotoGallery'

enum EImageStatus {
  ADD = 'ADD',
  // REMOVE = 'REMOVE',
  REPLACE = 'REPLACE',
  UPLOADING = 'UPLOADING',
  ERR = 'ERR'
}

interface IImageItem {
  url: string
  id: string
  type: EImageStatus
}

interface IUploading {
  imageStr: string
  success: (e: string, idx?:number) => void
  fail: () => void
}

interface IProps {
  /**  */
  imageIndex?: number
  /** 最多几个 */
  length?: number
  /** 一行几个 */
  lineNumber?: number

  imageData?: IImageItem[]

  /** 上传图片 */
  uploading?: (param: IUploading) => void
  /** */
  imagePlaceholder?: string

  /** 每次操作完图片都会调用 */
  updataImage?: (data: IImageItem[], imageIndex: number) => void
}

interface IState {
  /** 图片数组 */
  imageData: IImageItem[]
  /** 图片宽度 */
  imageWidth: number
  /** 是否显示图片预览组件 */
  imagePreviewerVisible?: boolean;
  current?: number;
  urls?: string[]
}

export default class ImagePickerNew extends React.PureComponent<IProps, IState> {
  static defaultProps = {
    lineNumber: 5,
    length: 9
  }

  initImageItem = {
    url: AddImage,
    id: `${Math.random()}-image`,
    type: EImageStatus.ADD
  }

  constructor(props) {
    super(props)
    this.state = {
      imageData: [],
      imageWidth: 0,
      imagePreviewerVisible: false,
      current: 0,
      urls: [],
    }
  }

  componentDidMount() {
    let imageWidth =
      (window.screen.availWidth - 30 - this.props.lineNumber) /
      this.props.lineNumber
    const { imageData, length } = this.props
    this.setState({
      imageData:
        imageData.length >= length
          ? imageData
          : [...imageData, this.initImageItem],
      imageWidth
    })
  }

  render() {
    let { imageData = [], urls = [], current = 0, imageWidth, imagePreviewerVisible } = this.state

    return (
      <Flex wrap="wrap" className="snrc-image-picker-new">
        {imageData.length < 2 && (
          <span
            className="snrc-image-picker-new-placeholder"
            style={{ left: imageWidth + 10 }}
          >
            {this.props.imagePlaceholder}
          </span>
        )}
        {imageData.map((item, index) => {
          return (
            <Flex
              key={item.id}
              justify="center"
              className="snrc-image-picker-new-image-view"
              style={{
                width: `${imageWidth}px`,
                height: `${imageWidth}px`
              }}
            >
              <ImageView
                url={item.url}
                showDeleteIcon={item.type !== EImageStatus.ADD}
                width={'calc(100% - 10px)'}
                height={'calc(100% - 10px)'}
                deleteImage={this.deleteItem.bind(this, index)}
                onChange={this.imageOnChange.bind(this, index)}
                imageLoadErr={this.imageLoadErr.bind(this, item.id)}
              />

              {item.type === EImageStatus.ERR && (
                <Flex className="snrc-image-picker-new-err">
                  <ImageView url={UploadFail} />
                </Flex>
              )}

              {item.type === EImageStatus.UPLOADING && (
                <Flex className="snrc-image-picker-new-err" justify="center">
                  <Flex
                    justify="center"
                    className={'snrc-image-picker-new-err-content'}
                    style={{
                      width: 'calc(100% - 10px)',
                      height: 'calc(100% - 10px)'
                    }}
                  >
                    <ActivityIndicator />
                  </Flex>
                </Flex>
              )}
            </Flex>
          )
        })}
        {
          imagePreviewerVisible && (
            <ImagesSlide
              imgUrls={urls}
              index={current}
              onclose={() => {
                this.setState({
                  imagePreviewerVisible: false,
                  current: 0,
                  urls: [],
                });
              }}
            />
          )
        }
      </Flex>
    )
  }

  imageOnChange(index: number) {
    let { imageData } = this.state
    let item = imageData[index]
    console.log(' ---> image on change item', item)
    switch (item.type) {
      case EImageStatus.ADD:
        this.addImage()
        break
      case EImageStatus.REPLACE:
        // this.replaceImage(index)
        // 由重新选图改为查看大图
        this.openRawImage(
          index,
          imageData.filter(v => v.type !== EImageStatus.ADD).map(v => v.url)
        )
        break
    }
  }

  /**
   * 查看原图
   */
  openRawImage(index: number, urls: string[]) {
    this.setState({
      imagePreviewerVisible: true,
      current: index,
      urls: urls
    });
  }

  /** 选择图片 */
  async addImage() {
    let { imageData } = this.state
    let _imageData = imageData
    let snap: IImageItem
    if (Douya.canIuse('startCamera')) {
      let response = await Douya.startCamera()
      // console.log(' ----- > camera', response.res.imageStr)
      snap = {
        url: `data:image/png;base64,${response.res.imageStr}`,
        id: `${Math.random()}-image`,
        type: EImageStatus.UPLOADING
      }
      this.props.uploading({
        imageStr: response.res.imageStr,
        success: this.imageUploadSuccess.bind(this, snap.id),
        fail: this.imageUploadFail.bind(this, snap.id)
      })
    } else {
      const dataUrl = await chooseImage();
      const {bodyData} = parseDataURL(dataUrl);
      snap = {
        url: dataUrl,
        id: `${Math.random()}-image`,
        type: EImageStatus.UPLOADING
      }
      this.props.uploading({
        imageStr: bodyData,
        success: this.imageUploadSuccess.bind(this, snap.id),
        fail: this.imageUploadFail.bind(this, snap.id)
      })
    }
    imageData.splice(imageData.length - 1, 0, snap)
    if (imageData.length > this.props.length) {
      _imageData = imageData.filter(item => item.type !== EImageStatus.ADD)
    }
    this.setState({
      imageData: [..._imageData]
    })
  }

  async replaceImage(index: number) {
    let { imageData } = this.state
    let snap: IImageItem
    if (Douya.canIuse('startCamera')) {
      let response = await Douya.startCamera()
      // console.log(' ----- > camera', response.res.imageStr)
      snap = {
        url: `data:image/png;base64,${response.res.imageStr}`,
        id: `${Math.random()}-image`,
        type: EImageStatus.UPLOADING
      }
      this.props.uploading({
        imageStr: response.res.imageStr,
        success: this.imageUploadSuccess.bind(this, snap.id),
        fail: this.imageUploadFail.bind(this, snap.id)
      })
    } else {
      const dataUrl = await chooseImage();
      const {bodyData} = parseDataURL(dataUrl);
      snap = {
        url: dataUrl,
        id: `${Math.random()}-image`,
        type: EImageStatus.UPLOADING
      }
      this.props.uploading({
        imageStr: bodyData,
        success: this.imageUploadSuccess.bind(this, snap.id),
        fail: this.imageUploadFail.bind(this, snap.id)
      })
    }
    imageData.splice(index, 1, snap)
    this.setState({
      imageData: [...imageData]
    })
  }

  /** 删除按钮 */
  deleteItem(index: number) {
    let { imageData } = this.state
    delete imageData[index]
    let _imageData = imageData.filter(item => item)
    const addImage = imageData.filter(item => item.type === EImageStatus.ADD)
    this.setState(
      {
        imageData: addImage.length
          ? [..._imageData]
          : [..._imageData, this.initImageItem]
      },
      () => {
        this.props.updataImage(this.state.imageData, this.props.imageIndex)
      }
    )
  }

  /** 图片加载失败 */
  imageLoadErr(id: string) {
    console.log(' ----> imageLoadErr')
    this.setImageStatus(id, EImageStatus.ERR)
  }

  /** 图片上传失败 */
  imageUploadFail(id: string) {
    console.log(' ----> imageUploadFail')
    this.setImageStatus(id, EImageStatus.ERR)
  }

  /** 图片上传成功 */
  imageUploadSuccess(id: string, imageUrl: string, index: number) {
    console.log(' ----> imageUploadSuccess', imageUrl, id)
    this.setImageStatus(id, EImageStatus.REPLACE, imageUrl)
  }

  setImageStatus(id: string, status: EImageStatus, imageUrl?: string) {
    let { imageData } = this.state
    imageData.forEach(item => {
      if (item.id === id) {
        item.type = status
        if (imageUrl) {
          item.url = imageUrl
        }
      }
    })
    this.props.updataImage(imageData, this.props.imageIndex)
    this.setState({
      imageData: [...imageData]
    })
  }
}
