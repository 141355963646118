/*
 * @Author: tzz
 * @LastEditors: 唐正宗(18133443)
 * @Description: 进度圆弧
 * @Date: 2019-03-19 14:45:09
 * @LastEditTime: 2019-05-23 14:50:04
 */

/**
 *
 * @param id html元素id
 * @param progress 进度值
 * @param type 进度描述
 */
// let circleLoading

function toCanvas(id, progress, stepCode, timeTitle) {
  progress = progress.replace('%', '')

  //圆弧起止点颜色
  //默认蓝色
  // 可选值：Y1 - 红牌，Y2 - 黄牌，Y3 - 良，Y4 - 良+，Y5 - 优。

  //蓝色
  let startColor: string = '#74BEFF'
  let endColor: string = '#4286FF'
  //红牌
  if (stepCode === 'Y1') {
    startColor = '#F63E8A'
    endColor = '#F62D2D'
  }
  //黄牌
  if (stepCode === 'Y2') {
    startColor = '#F7B400'
    endColor = '#F73000'
  }

  let devicePixelRatio = window.devicePixelRatio || 1
  let canvas0 = document.getElementById(id) as HTMLCanvasElement
  if (!canvas0) return
  let context = canvas0.getContext('2d')
  let backingStoreRatio = (context as any).webkitBackingStorePixelRatio || 1
  let ratio = devicePixelRatio / backingStoreRatio

  //canvas进度条
  let canvas = document.getElementById(id) as HTMLCanvasElement
  let ctx = canvas.getContext('2d')
  let percent = progress
  //最终百分比

  let radius = 90 //圆环半径
  let lineWidth = 20 // 圆形线条的宽度
  let fontSize = 14 //字体大小

  canvas.width = 250 * ratio
  canvas.height = 250 * ratio

  let circleX = canvas.width / 2 / ratio // 中心x坐标
  let circleY = canvas.height / 2 / ratio //中心y坐标

  canvas.style.width = '220px'
  canvas.style.height = '220px'
  ctx.scale(ratio, ratio)

  /**
   * 	画圆环
   * 	cx 圆环中心x坐标
   * 	cy 圆环中心y坐标
   * 	r  圆环半径
   * */
  function drawCircle(cx, cy, r) {
    ctx.beginPath()
    ctx.lineWidth = lineWidth
    ctx.strokeStyle = '#eee'
    ctx.lineCap = 'round'
    ctx.arc(cx, cy, r, Math.PI * (1 - 1 / 5), Math.PI * (2 + 1 / 5))
    ctx.stroke()
  }

  /**
   * 	画弧线
   * 	cx 圆环中心x坐标
   * 	cy 圆环中心y坐标
   * 	r  圆环半径
   * 	endProgress 弧线占圆弧百分比
   * */
  function drawSector(cx, cy, r, endProgress) {
    ctx.beginPath()
    ctx.lineWidth = lineWidth
    // 渐变色 - 可自定义
    let linGrad = ctx.createLinearGradient(
      circleX - radius - lineWidth,
      circleY,
      circleX + radius + lineWidth,
      circleY
    )
    linGrad.addColorStop(0.0, startColor)
    linGrad.addColorStop(1.0, endColor)
    ctx.strokeStyle = linGrad
    //圆弧两端的样式
    ctx.lineCap = 'round'
    ctx.arc(
      cx,
      cy,
      r,
      Math.PI * (1 - 1 / 5),
      Math.PI * (1 - 1 / 5 + ((endProgress / 100) * 7) / 5)
    )
    ctx.stroke()
  }

  //刷新
  function loading() {
    //清除canvas内容
    ctx.clearRect(0, 0, circleX * 2, circleY * 2)

    //文字 综合完成率
    ctx.font = fontSize + 'px April'
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillStyle = '#999'
    ctx.fillText(timeTitle, circleX, (circleY * 5) / 7)

    //百分比 50%
    ctx.font = 'bold 36px Arial'
    ctx.fillStyle = endColor
    if (stepCode === 'Y2') {
      ctx.fillStyle = startColor
    }
    if (progress === '--') {
      ctx.fillStyle = '#d0d0d0'
      ctx.fillText(toFixed(process + ''), circleX, circleY + 3)
    } else {
      ctx.fillText(toFixed(process + '') + '%', circleX, circleY + 3)
    }

    //最外层圆环
    drawCircle(circleX, circleY, radius)

    let endProgress = process
    if (process > 100) {
      endProgress = 100
    }
    if (process < 0) {
      endProgress = 0
    }

    //圆弧
    drawSector(circleX, circleY, radius, endProgress)
    //控制结束时动画的速度
    if (process < progress) {
      if (process / percent > 0.9) {
        process += 0.3
      } else if (process / percent > 0.8) {
        process += 0.55
      } else if (process / percent > 0.7) {
        process += 0.75
      } else {
        process += 1.0
      }
    }
    if (process >= progress) {
      process = progress
    }
  }

  let process: number = 0.0
  process = progress

  loading()
  setTimeout(() => {
    loading()
  }, 10)
}

const toFixed = (ori: string) => {
  if (ori.includes('.')) {
    return parseFloat(ori + '').toFixed(1)
  } else {
    return ori
  }
}

export { toCanvas }
