import * as React from 'react'
import './index.less'

import DeleteIcon from '@assets/images/image-delete.png'

interface IProps {
  /**错误地址 */
  errUrl?: string
  /**常规地址 */
  url?: string
  /**默认地址 */
  defaultUrl?: string
  /**展示删除图标 */
  showDeleteIcon?: boolean
  /**宽高意义不大 */
  width?: string
  height?: string

  deleteImage?: () => void
  /**图片改变 */
  onChange?: () => void
  imageLoadErr?: () => void
  /** 最多几个 */
  length?:number,
  /** 当前第几个 */
  currLength?:number
}

interface IState {
  /** 错误地址 */
  _errUrl: string
}

export default class Index extends React.PureComponent<IProps, IState> {
  static defaultProps = {
    showDeleteIcon: false
  }

  constructor(props) {
    super(props)
    this.state = {
      _errUrl: ''
    }
  }

  render() {
    const { url, defaultUrl, showDeleteIcon, width, height,length,currLength } = this.props
    const { _errUrl } = this.state
    return (
      <div
        className={'snrc-image-view'}
        style={{ width: width, height: height }}
      >
        <div className="addImgBox" onClick={this.imageClick.bind(this)}>
          <img
            src={_errUrl || url || defaultUrl}
            onError={this.imageLoadErr.bind(this)}
            className={`image`}
          />
          {
            !showDeleteIcon && length > 0 && (
              <span>{currLength - 1}/{length}</span>
            )
          }
        </div>

        {showDeleteIcon && (
          <img
            src={DeleteIcon}
            className="delete-image"
            onClick={this.deleteBtn.bind(this)}
          />
        )}
      </div>
    )
  }

  imageLoadErr() {
    this.props.imageLoadErr && this.props.imageLoadErr()
    this.setState({
      _errUrl: this.props.errUrl
    })
  }

  deleteBtn(e) {
    e.stopPropagation()
    this.props.deleteImage && this.props.deleteImage()
  }

  imageClick(e) {
    e.stopPropagation();
    this.props.onChange && this.props.onChange()
  }


}
