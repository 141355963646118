import messageGuidePic from '@assets/images/xiaoxitongzhi_yindao@2x.png'
import { openLocationMsgModal } from '@/views/home/utils'
import { getGlobalUserConfig } from '@/stores'
import './index.less'

interface IProps {
  visible?: boolean
  onCloseMessageGuide?: (value: boolean) => void
}

export default function GuideDialog({ visible, onCloseMessageGuide }: IProps) {
  const nextHandler = () => {
    const userState = getGlobalUserConfig();

    onCloseMessageGuide(false)
    if (!userState.latitude) {
      openLocationMsgModal()
    }
  }

  return (
    <div
      className="message-dialog-container"
      style={{ display: visible ? 'block' : 'none' }}
    >
      <div className="guide-dialog-mask"></div>
      <div className="guide-dialog-wrap">
        {visible && (
          <div className="guide-page">
            <img className="img" src={messageGuidePic} alt=""></img>
            <div className="btn-sure" onClick={nextHandler}>
              完成
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
