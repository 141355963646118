/*
 * @Author: 苏鑫
 * @Date: 2023-07-05 16:24
 * @Last Modified time: 2023-07-05 16:24
 */

import {
  ICommitAnswersParam,
  ICommitTaskErrorParam,
  ICommitTaskItemParam,
  IQuestionViewData,
  ITodoMessageInfo,
  ITodoQuestion,
  ITodoQuizRquiresMap,
  ITodoQuizSteperMap,
  getJumpAndRequiresMap,
} from './types'

import Config from '@config'
import { Toast } from 'antd-mobile'
import fetch from '@fetch'
import { updateUnFinishTodoCount } from '@/stores'

const succeedCode = '000000'

export function getHttpErrorMessage(error: any) {
  return (
    error?.message ||
    error?.statusText ||
    error?.body?.message ||
    error?.body?.error ||
    error?.response?.message ||
    error.response?.error ||
    ''
  )
}

export interface ITaskInfo {
  totalCount?: number
  totalPageCount?: number
  pageSize?: number
  pageNum?: number
  dataList: ITaskItem[]
}
export interface ITaskItem {
  taskItemId?: number //任务项ID
  taskId?: string //任务ID
  taskName?: string //任务名称
  taskType?: number // 任务类型 1-到人任务；2-到店任务
  taskStatus?: number // 任务状态 1-未完成；2-已超期；3-已完成；4-已终止
  beginTime?: string // 任务开始时间
  endTime?: string // 任务结束时间
  completeTime?: string //任务完成时间
  shutTime?: string // 总任务数
  totalTask?: number //任务终止时间
  completeTask?: number // 已完成任务数
  shutTask?: number // 特殊终止任务数
}

/**
 * 任务执行单列表查询
 * 魔客: http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20230705000002
 * @param param
 */
export const queryTaskItemByPage = async (
  taskStatus: number,
  pageNum: number
) => {
  const url = `${Config.HOST}/questTask/queryTaskItemByPage.do`
  // const url = `http://localhost:8091/api/questTask/queryTaskItemByPage.do`
  const { err, res } = await fetch.get<ITaskInfo>(
    url,
    { taskStatus, pageSize: '10', pageNum },
    { toasthide: true }
  )
  if (err) {
    Toast.fail(err?.message || '')
    return
  }
  return res
}

export interface ITaskItemDetail {
  taskItemId?: number //任务项ID
  taskId?: string //任务名称
  taskType?: 1 | 2 // 任务类型 1-到人任务；2-到店任务
  taskStatus?: number // 任务状态 1-未完成；2-已超期；3-已完成；4-已终止
  beginTime?: string // 任务开始时间
  endTime?: string // 任务结束时间
  completeTime?: string //任务完成时间
  shutTime?: string // 任务终止时间
  taskName?: string // 任务标题
  reported?: string // 是否报备;1-已报备
  taskDesc?: string // 任务说明
  reportContent?: string // 报备说明
  sourceType?: number //1-消息；2-问卷
  sourceId?: number //消息ID、问卷ID等
  storeTaskItems?: ISoreTaskItem[] //店铺任务项列表
  answerId?: number
}

export interface ISoreTaskItem {
  storeTaskItemId?: number
  taskItemId?: number //任务项ID
  storeCode?: string // 店铺编码
  storeName?: string // 店铺名称
  taskStatus?: number // 任务状态
  beginTime?: string // 任务开始时间
  endTime?: string // 任务结束时间
  completeTime?: string //任务完成时间
  shutTime?: string // 任务终止时间
  reported?: string // 是否报备;1-已报备
  reportTime?: string // 报备时间
  reportContent?: string // 报备说明
  sourceType?: number //1-消息；2-问卷
  sourceId?: number //消息ID、问卷ID等
  answerId?: number
}
/**
 * 查询任务详情
 * 魔客: http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20230705000005
 * @param param
 */
export const queryTaskItemDetailByItemId = async (taskItemId: number) => {
  const url = `${Config.HOST}/questTask/queryTaskItemDetailByItemId.do`
  // const url = `http://localhost:8091/api/questTask/queryTaskItemDetailByItemId.do`
  const { err, res } = await fetch.get<ITaskItemDetail>(
    url,
    { taskItemId },
    { toasthide: true }
  )
  if (err) {
    Toast.fail(err?.message || '')
    return
  }
  return res
}

/**
 * 更新未完成任务数量查询
 * @see http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20230705000002
 */
export const updateUnFinishTodoSize = async () => {
  let count = 0
  const { err, res } = await fetch.get<ITaskInfo>(
    `${Config.HOST}/questTask/queryTaskItemByPage.do`,
    { taskStatus: 1, pageSize: '100', pageNum: '1' },
    { toasthide: true }
  )
  if (!err) {
    const list = res?.dataList || []
    count = list.length
  }
  updateUnFinishTodoCount(count)
}

/**
 * 查询问卷题目或消息
 * @see http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20230705000009
 */
export async function queryQuestion<
  T extends IQuestionViewData | ITodoMessageInfo
>(sourceId: number, isQuestion = true) {
  let jumpMap = {} as ITodoQuizSteperMap
  let requiresMap = {} as ITodoQuizRquiresMap
  let data: ITodoQuestion[] = []
  let isErrorFlag: boolean = false
  let messageInfo = {
    noticeAttarchmentList: [],
  } as ITodoMessageInfo

  try {
    const { code, err, res } = await fetch.get<{
      statisticsQuizVOList: ITodoQuestion[]
      noticeInfoVO: ITodoMessageInfo
    }>(
      `${Config.HOST}/questTask/queryQuestionnaireById.do`,
      { sourceId, sourceType: isQuestion ? 2 : 1 },
      { toasthide: true }
    )
    if (code === succeedCode) {
      messageInfo = res.noticeInfoVO || (messageInfo as ITodoMessageInfo)
      messageInfo.noticeAttarchmentList =
        messageInfo.noticeAttarchmentList || []
      data = res.statisticsQuizVOList || []
      messageInfo.isErrorFlag = false
      const { jumpMap: j, requiresMap: r } = getJumpAndRequiresMap(data)
      jumpMap = j
      requiresMap = r
    } else {
      isErrorFlag = true
      messageInfo.isErrorFlag = true
      Toast.info((err ? err.message : '') || '获取数据失败', 2)
    }
  } catch (error) {
    isErrorFlag = true
    messageInfo.isErrorFlag = true
    Toast.info(getHttpErrorMessage(error), 2)
  }

  return (
    isQuestion ? { data, jumpMap, requiresMap, isErrorFlag } : messageInfo
  ) as T
}

/**
 * 查询问卷答案
 * @see http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20230705000015
 */
export async function queryAnswers(
  questionnaireId: number,
  taskType: 1 | 2,
  storeCode: string,
  answerId: number
) {
  let questions: ITodoQuestion[] = []
  try {
    const { code, err, res } = await fetch.get<any>(
      `${Config.HOST}/questTask/queryQuestionnaireAndAnswer.do`,
      { questionnaireId, taskType, storeCode, answerId },
      { toasthide: true }
    )
    if (code === succeedCode) {
      const questionList = res || []
      for (const v of questionList) {
        v.matrixOptions = (v.horizontalQuizOptions || []).concat(
          v.verticalQuizOptions || []
        )
      }
      questions = questionList
    } else {
      Toast.info((err ? err.message : '') || '获取数据失败', 2)
    }
  } catch (error) {
    Toast.info(getHttpErrorMessage(error), 2)
  }
  return questions
}

/**
 * 提交消息任务
 * @see http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20230705000012
 */
export async function commitTaskItem({
  taskItemId,
  storeTaskItemId,
  taskType,
}: ICommitTaskItemParam) {
  let errorMessage = ''
  try {
    const { code, err } = await fetch.get(
      `${Config.HOST}/questTask/commitTaskItem.do`,
      {
        taskItemId,
        storeTaskItemId,
        taskType: Number(taskType),
      },
      { toasthide: true }
    )
    if (code !== succeedCode) {
      errorMessage = (err ? err.message : '') || '操作失败'
    }
  } catch (error) {
    errorMessage = getHttpErrorMessage(error)
  }
  return errorMessage
}

/**
 * 提交问卷答案
 * @see http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20230705000010
 */
export async function commitAnswers({
  answerMap = {},
  taskType,
  storeCode,
  taskItemId,
  storeTaskItemId,
  questionnaireId,
}: ICommitAnswersParam) {
  let errorMessage = ''

  try {
    const { code, err } = await fetch.postJson(
      `${Config.HOST}/questTask/submitStaffQuest.do`,
      {
        storeCode,
        questionnaireId: Number(questionnaireId),
        taskType: Number(taskType),
        staffQuizAnswerList: Object.entries(answerMap)
          .reduce(
            (rs, curr) => [
              ...rs,
              { quizId: Number(curr[0]), quizAnswer: curr[1] },
            ],
            []
          )
          .filter((v) => !!v.quizAnswer),
        taskItemCommitVO: {
          taskItemId: Number(taskItemId),
          storeTaskItemId: Number(storeTaskItemId),
          taskType: Number(taskType),
        },
      },
      { toasthide: true }
    )
    if (code !== succeedCode) {
      errorMessage = (err ? err.message : '') || '操作失败'
    }
  } catch (error) {
    errorMessage = getHttpErrorMessage(error)
  }
  return errorMessage
}

/**
 * 特殊情况报备
 * @see http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20230705000013
 */
export async function reportErrorTask({
  taskItemId,
  storeTaskItemId,
  reportDesc,
}: ICommitTaskErrorParam) {
  let errorMessage = ''
  try {
    const { code, err } = await fetch.get(
      `${Config.HOST}/questTask/reportTaskItem.do`,
      {
        taskItemId,
        storeTaskItemId,
        reportDesc,
      },
      { toasthide: true }
    )
    if (code !== succeedCode) {
      errorMessage = (err ? err.message : '') || '操作失败'
    }
  } catch (error) {
    errorMessage = getHttpErrorMessage(error)
  }
  return errorMessage
}
