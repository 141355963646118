import { ISystemConfig } from './config'
import autoGetEnv from '../kits/auto-get-env'
import config_dev from './index.dev'
import config_prexg from './index.prexg'
import config_prexz from './index.prexz'
import config_pro from './index.pro'
import config_sit from './index.sit'

enum SNENV {
  dev = 'dev',
  sit = 'sit',
  prexg = 'prexg',
  prexz = 'prexz',
  pro = 'pro',
}

/** 生产环境白名单 */
const PRO_HOST_LIST = ['srcfewsh5.suning.com']
const __ENV__ = SNENV[autoGetEnv(PRO_HOST_LIST)]

let Config: ISystemConfig
console.log('[system]', '-----------_env_----------', __ENV__)

const isDouya = navigator.userAgent.toLowerCase().indexOf('douya') > -1

// TODO: 异步引入 eruda
if (!['pro', 'dev'].includes(__ENV__)) {
  import('eruda').then(({ default: eruda }) => {
    eruda.init()
  })
}

switch (__ENV__) {
  case 'pro':
    Config = new config_pro() // 生产环境
    break
  case 'sit':
    Config = new config_sit() // sit 环境
    break
  case 'prexg':
    Config = new config_prexg() // prexg 环境
    break
  case 'prexz':
    Config = new config_prexz() // prexg 环境
    break
  case 'dev':
    // Config = new config_sit() // sit 环境
    Config = new config_dev() // 本地 mock 环境
    // Config = new config_pro() //
    break
  default:
    console.log('环境未配置,请检查 REACT_APP_SNENV')
  // Config = new config_dev(); // 本地 mock 环境
}

// 下面代码禁止修改
if (__MOCK_IN_PRODUCTION__) {
  Config = new config_dev()
  // Config = new config_sit()
  // Config = new config_pro()
}

console.log(
  `[system] __ENV_DEV__=${__ENV_DEV__} __VERSION__=${__VERSION__}  __ENV__=${__ENV__} __BUILDDATE__=%c${__BUILDDATE__}`,
  'color: #f00; font-weight: bold'
)
console.log('[system]', 'config =>', Config)

export default Config
export { __ENV__, SNENV }
