/*
 * @Author: keqingrong (19040892)
 * @Date: 2019-07-26 17:59:13
 * @LastEditors  : Please set LastEditors
 * @LastEditTime : 2020-01-02 17:30:25
 * @Description:
 */
import { EDeviceType } from '@/stores'
import { ISystemConfig } from './config'

const mockPath = `http${__HTTPS__ ? 's' : ''}://${
  __MOCK_PATH__ || location.hostname
}${__MOCK_PATH__ ? '' : `:${__HTTPS__ ? 8090 : 8091}`}/api`

export default class Config implements ISystemConfig {
  IS_MOCK = true

  HOST = mockPath
  APP_BOSS_REPORT_HOST = mockPath
  ROOT_PATH = []
  CURRENT_PATH = ''
  SERVICE_IMG_HOST = ''

  // --------------------------------------------------------- >
  PASSPORT = {
    PASSPORT_LOGIN_HOST: '',
    PASSPORT_LOGIN_THEME: 'lsy_skf',
    PASSPORT_DOMAIN_HOST: '',
  }
  UOM = {
    SITE_ID: '08CJJLR',
    RESOURCE_TYPE: '08CJJLR',
    BIZ_ID: 'lsypjg',
  }
  EXCEPTIONS_UPLOAD = {
    BIZ_ID: 'super-manager',
  }
  Current_Store_Type = ''
  USER_INFO = {
    uid: '',
    phone: '',
    companyName: '',
    mobileId: '',
    AppSource: '' as EDeviceType,
    isPhoneRoot: false,
    onVirtual: false,
    latitude: '32.094763',
    longitude: '118.899073',
    street: '',
    AddrStr: '江苏省南京市玄武区环园东路',
    city: '南京市',
    country: '中国',
    roleList: [],
    selectRole: {
      roleCode: '',
      roleName: '',
      dataDimension: '',
      roleStatus: '',
    },
    selectStoreType: '',
    duty: '',
    dutyDesc: '',
    position: '',
    sliceAreaList: [],
    status: '',
    regionName: '',
    regionCode: '',
  }
  AMAP_KEY = '709309ab6e53b4b179bb3802805ef411'
  AMAP_JS_CODE = 'eddc1bfc79530eeeebc79068a55fb1cf'
  saveSearchStore = {}
  mpAppid = ''
  MAP_TYPE = ''
}
