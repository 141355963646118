import React from 'react'
import styles from './index.module.less'
import {} from 'rxjs'
import {} from 'rxjs/operators'
import './lib/iconfont.js'
import {} from 'rxjs-hooks'
import loadScript from 'load-script'

interface IProps {
  /** 内置 icon 名称 */
  type: string
  /** icon 的样式 */
  style?: React.CSSProperties
  /** 图标的样式名 */
  className?: string
  /** 点击事件 */
  onClick?: (e: any) => void
}

// loadScript('//at.alicdn.com/t/font_970979_bz2qrngt9z.js')
// loadScript('//at.alicdn.com/t/font_970979_bz2qrngt9z.js')

const IconEx: React.SFC<IProps> = props => {
  const { className, type, onClick, style } = props
  return (
    <svg
      className={`
        ${styles.icon}
        ${className || ''}
      `}
      style={style}
      aria-hidden="true"
      onClick={onClick || (() => void 0)}
    >
      <use xlinkHref={`#icon-${type}`} />
    </svg>
  )
}

export default IconEx
