/*
 * @Author: 苏鑫
 * @Date: 2022-09-09 9:40:43
 * @Last Modified by: 12072513
 * @Last Modified time: 2023-07-24 17:16:25
 */

import './custorm-tab.less'

export const CustormTab = (props: CustormTabProps) => {
  const {
    tabList = [
      {
        text: '待完成',
        value: 1,
      },
      {
        text: '已完成',
        value: 3,
      },
      {
        text: '已超期',
        value: 2,
      },
    ],
    onChange,
    currentTab = 0,
  } = props
  return (
    tabList.length > 0 && (
      <div className="custorm-tab">
        {tabList.map((item, index) => {
          return (
            <div
              className={
                currentTab === item.value
                  ? 'custorm-tab-item-active'
                  : 'custorm-tab-item'
              }
              key={item.value}
              onClick={() => {
                onChange(item.value, item.text)
              }}
            >
              <div className="custorm-tab-name">{item.text}</div>
              {currentTab === item.value && (
                <div className="custorm-tab-bar"></div>
              )}
            </div>
          )
        })}
      </div>
    )
  )
}

export type CustormTabProps = {
  tabList?: []
  onChange?: (index, text) => void
  currentTab?: number
}
