/*
 * @Author: keqingrong (19040892)
 * @Date: 2019-07-30 09:13:46
 * @LastEditors: keqingrong (19040892)
 * @LastEditTime: 2019-08-02 14:49:36
 * @Description:
 */
import mitt from 'mitt'
import * as Util from './util'
import { NavigateFunction } from 'react-router-dom'
import { IDouYaUser } from '@/stores'

const emitter = mitt()
/**
 * 豆芽移动端开放接口
 * 正向接口：豆芽定义，H5调用豆芽。
 * 反向接口：js定义，豆芽调用H5 js。
 * @see http://wiki.cnsuning.com/pages/viewpage.action?pageId=33103757
 */
declare global {
  // eslint-disable-next-line
  interface Window {
    navigate: NavigateFunction
  }
  interface IDouyaWindow {
    /** 位置信息获取反向接口 */
    getLocationInfoResult: (resultJson: string) => void
    /** 获取手机的IMEI的反向接口 */
    getMobileUniqueIDResult: (mobileId: string) => void
    /** 返回当前用户的详细信息的反向接口 */
    getUserInfoResult: (resultJson: string) => void
    /** 返回当前用户缩略信息的反向接口 */
    getUserShortInfoResult: (resultJson: string) => void
    /** 获取gps和wifi信息反向接口 */
    jsLocationAndWIFIInfoFunc: (resultJson: string) => void
    /** 监控用户周围立方品牌的门禁设备列表更新的反向接口 */
    rfmDoorChanged: (
      /** ","分割的mac地址。若周围无设备返回"null" */
      macList: string
    ) => void
    /** 返回开门禁结果的反向接口 */
    rfmOpenDoorResult: (
      /** 开门结果：0 – 开门成功，7 – 蓝牙通讯异常， 8 – 蓝牙通讯超时，9 – 动态开门密码错误 */
      resultCode: number,
      /** 成功或者失败的提示信息，用于前端展示给用户 */
      message: string,
      /** 门禁设备的mac地址 */
      mac: string
    ) => void
    /** 返回扫码结果的反向接口 */
    scanBarcodeResult: (
      /** 码中所含字串 */
      code: string
    ) => void
    /** 返回多选联系人选择（可选群组）结果的反向接口 */
    selectContactResult: (resultJson: string) => void
    /** 语音识别反向接口 */
    startAsrDialogResult: (resultJson: string) => void
    /** 人脸比对反向接口 */
    startFaceCompareResult: (resultJson: string) => void
    /** 启动拍照界面反向接口 */
    startCameraResult: (resultJson: string) => void
    /** 使用开放接口的权限判断反向接口 */
    onPermissionDenied: () => void
    /** 更新使用印章次数的反向接口 */
    updateSealCount: (resultJson: string) => void
    /** 上传使用印章拍照图片的反向接口 */
    uploadSealPicture: (resultJson: string) => void
  }
}

declare global {
  interface IDouyaAPI {
    /** 关闭当前的网页加载器，旧版，使用 `closeBrowser` 代替 */
    callbackToMoblieMethod?: () => void
    /** 关闭当前的网页加载器 */
    closeBrowser: () => void
    /** 未写到文档中，勿用 */
    getLocation?: () => void
    /** 获取gps和wifi信息 */
    getLocationAndWIFIInfo: () => void
    /** 位置信息获取接口 */
    getLocationInfo: () => void
    /** 获取手机的IMEI */
    getMobileUniqueID: () => void
    /** 获取当前用户的详细信息 */
    getUserInfo: () => void
    /** 获取当前用户的工号、姓名 */
    getUserShortInfo: () => void
    /** 未写到文档中，勿用，仅 iOS 端实现 */
    getWIFIInfo?: () => void
    /** 加入视频会议房间 */
    joinVideoRoom: (liveId: string) => void
    /** 网页登陆豆芽成功 */
    loginSuccess: (userId: string) => void
    /** 启动拨号界面 */
    makeCall: (phone: string) => void
    /** 调用多选联系人界面 */
    multiSelectContacts: (maxCount: number) => void
    /** 调用多选联系人（可选群组）界面 */
    multiSelectContactsWithRooms: (maxCount: number) => void
    /** 未写到文档中，勿用，仅 Android 端实现 */
    openImage: () => void
    /** 开启立方品牌的门禁设备连接能力 */
    requestRfmAccessAbility: () => void
    /** 打开立方品牌的门禁 */
    rfm_openDoor: () => void
    /** 启动扫码界面 */
    scanBarcode: () => void
    /** 分享微应用图片接口 */
    shareImage: (imageStr: string) => void
    /** 未写到文档中，勿用，仅 iOS 端实现 */
    showServiceDetailCallback?: () => void
    /** 显示用户资料 */
    showUserInfo: (uid: string) => void
    /** 调用单选联系人界面 */
    singleSelectContact: () => void
    /** 启动豆芽客服页面 */
    startAgentSession: (channelId: string) => void
    /** 启动语音识别 */
    startAsrDialog: () => void
    /** 启动拍照界面 */
    startCamera: () => void
    /** 启动聊天界面 */
    startChat: (employeeCode: string, companyCode: string) => void
    /** 启动人脸比对页面 */
    startFaceCompare: (errorTimes: number) => void
    /** 开始蓝牙印章 */
    startSeal: (
      deviceNumber: string,
      documentNumber: string,
      totalCount: number,
      useCount: number,
      photoDelay: number
    ) => void
  }
}

declare let window: IDouyaWindow

enum EDeviceType {
  ANDROID = 'Android',
  IOS = 'iOS',
}

/**
 * FIXME: 如果无法访问外网，iOS 版豆芽依然会返回所有字段，Android 版豆芽不返回地址信息相关字段
 */
interface ILocationInfo {
  /** 设备号 */
  mobileId: string
  /** 是否root或越狱 */
  isPhoneRoot: boolean
  /** 平台 */
  AppSource: EDeviceType
  /** 可选，若能取到gps信息，返回纬度 */
  latitude?: string
  /** 可选，若能取到gps信息，返回经度 */
  longitude?: string
  /** 可选，若能取到gps信息，返回国家，如"中国" */
  country?: string
  /** 可选，若能取到gps信息，返回省份，如"江苏省" */
  province?: string
  /** 可选，若能取到gps信息，返回城市，如"南京市" */
  city?: string
  /** 可选，若能取到gps信息，返回区县，如"玄武区" */
  district?: string
  /** 可选，若能取到gps信息，返回街道，如"环园东路" */
  street?: string
  /** 可选，若能取到gps信息，返回详细地址信息，如"中国江苏省南京市玄武区环园东路" */
  AddrStr?: string
  /*0:百度地图坐标，1:高德地图坐标*/
  mapType?: number | string
}

interface ILocationAndWifiInfo {
  /** 可选，若能取到gps信息，返回详细地址信息 */
  AddrStr?: string
  /** 返回接入设备类型：Android/IOS */
  AppSource: EDeviceType
  /** 可选，若能取到gps信息，返回定位精度半径，单位是米 */
  accuracy?: string
  /** 可选，若连接wifi，返回当前所连wifi的bssid */
  bssid?: string
  /** 是否root或越狱 */
  isPhoneRoot: boolean
  /** 是否链接wifi */
  isWifiConnected: boolean
  /** 可选，若能取到gps信息，返回纬度 */
  latitude?: string
  /** 可选，若能取到gps信息，返回经度 */
  longitude?: string
  /** 当前手机的IMEI */
  mobileId: string
}

type IGetUserInfo = Pick<IDouYaUser, 'uid' | 'userCode' | 'userName' | 'phone' | 'companyName'>;

interface IGetCamera {
  imageStr: string
}

/**
 * [正向接口, 反向接口]
 */
const ApiPairs: [keyof IDouyaAPI, keyof IDouyaWindow][] = [
  ['closeBrowser', null],
  ['getLocationAndWIFIInfo', 'jsLocationAndWIFIInfoFunc'],
  ['getLocationInfo', 'getLocationInfoResult'],
  ['getMobileUniqueID', 'getMobileUniqueIDResult'],
  ['getUserInfo', 'getUserInfoResult'],
  ['getUserShortInfo', 'getUserShortInfoResult'],
  ['joinVideoRoom', null],
  ['loginSuccess', null],
  ['makeCall', null],
  ['multiSelectContacts', 'selectContactResult'], // 不确定
  ['multiSelectContactsWithRooms', 'selectContactResult'], // 不确定
  ['requestRfmAccessAbility', null], // 不确定
  ['rfm_openDoor', 'rfmOpenDoorResult'],
  ['scanBarcode', 'scanBarcodeResult'],
  ['shareImage', null],
  ['showUserInfo', null],
  ['singleSelectContact', null],
  ['startAgentSession', null],
  ['startAsrDialog', 'startAsrDialogResult'],
  ['startCamera', 'startCameraResult'],
  ['startChat', null],
  ['startFaceCompare', 'startFaceCompareResult'],
  ['startSeal', null],
  [null, 'rfmDoorChanged'],
  [null, 'onPermissionDenied'],
  [null, 'updateSealCount'], // 不确定
  [null, 'uploadSealPicture'], // 不确定
]

/**
 * FIXME: 已知问题，同步调用多个不同的豆芽 API，前者会被豆芽客户端忽略
 * 如下代码：
 * window.getLocationInfoResult = () => {}
 * window.jsLocationAndWIFIInfoFunc = () => {}
 * imJsInterface.getLocationInfo()
 * imJsInterface.getLocationAndWIFIInfo()
 * 只有 jsLocationAndWIFIInfoFunc 会被客户端调用
 */
class Douya {
  constructor() {
    this.attachHandlers()

    __ENV_DEV__ &&
      console.log(
        `[douya] 当前豆芽版本 ${this.getClientVersion() || 'Unknown'}`
      )
  }

  /**
   * 判断当前是否是豆芽
   */
  isDouya() {
    return navigator.userAgent.toLowerCase().indexOf('douya') > -1
  }

  /**
   * 获取豆芽版本
   * douya/4.32.0.0(prexg)/iOS
   * douya/4.31.0.2(sit)/iOS
   * douya/4.31.2.1beta/Android
   */
  getClientVersion() {
    const matched = navigator.userAgent.match(/douya\/([.()\d\w]*)/i)
    if (matched && matched[1]) {
      return matched[1] as string
    }
    return null
  }

  /**
   * 获取豆芽客户端注入到 JavaScript context 的 `imJsInterface` 对象
   */
  private getDouyaObject() {
    if ('imJsInterface' in window) {
      return window['imJsInterface'] as IDouyaAPI
    }
    return null
  }

  /**
   * 判断API是否支持
   * @param method
   *
   * 如：
   * Douya.canIUse('getLocationInfo')
   * Douya.canIUse('makeCall')
   */
  canIuse(method: string) {
    const douyaObj = this.getDouyaObject()
    if (douyaObj && typeof douyaObj[method] === 'function') {
      return true
    }
    return false
  }

  /**
   * 附加反向接口到 `window` 对象上，提供给豆芽客户端调用
   */
  private attachHandlers() {
    ApiPairs.forEach(([apiName, rApiName]) => {
      if (rApiName !== null) {
        // 保留 `window` 上已经存在处理函数
        let prevHandler = null
        if (rApiName in window) {
          prevHandler = window[rApiName]
        }
        window[rApiName] = (...args) => {
          __ENV_DEV__ &&
            console.log(`[douya] ${rApiName}() is invoked by Douya client`)

          if (prevHandler) {
            prevHandler(...args)
          }
          /**
           * 处理不同返回值
           * 一个参数尝试作为 JSON 解析
           * 多个参数作为数组返回
           */
          let result = undefined
          if (args.length === 1) {
            result = args[0]
            if (typeof args[0] === 'string') {
              try {
                result = JSON.parse(args[0])
              } catch (err) {
                // 忽略
              }
            }
          } else if (args.length > 1) {
            result = args
          }

          // 广播对应事件
          emitter.emit(apiName || rApiName, result)
        }
      }
    })
  }

  /**
   * API 调用
   * @param method
   * @param args
   */
  private callApi<T>(method: string, ...args) {
    __ENV_DEV__ && console.log(`[douya] ${method}() is invoked`)

    return new Promise<T>((resolve, reject) => {
      if (!this.canIuse(method)) {
        if (this.isDouya()) {
          reject(new Error(`[douya] 当前版本豆芽不支持 ${method}()`))
        } else {
          reject(new Error(`[douya] 当前环境暂不支持 ${method}()`))
        }
      }
      this.getDouyaObject()[method](...args)
      // 有反向接口监听结果，没有则直接resolve
      const targetApiPair = ApiPairs.find((item) => item[0] === method)
      if (targetApiPair && targetApiPair[1] !== null) {
        const listener = (data) => {
          resolve(data)
          emitter.off(method, listener)
        }
        emitter.on(method, listener)
      } else {
        resolve(null)
      }
    })
  }

  /**
   * 返回当前所在位置信息，具体地址信息需要外网返回
   */
  getLocationInfo() {
    return this.callApi<ILocationInfo>('getLocationInfo')
  }

  /**
   * 获取gps和wifi信息
   */
  getLocationAndWIFIInfo() {
    return this.callApi<ILocationAndWifiInfo>('getLocationAndWIFIInfo')
  }

  closeBrowser() {
    return this.callApi('closeBrowser')
  }

  /**
   * 判断微应用是否有开放接口的权限，仅在无权限时返回
   * @param callback - 监听调用开放接口被拒绝
   * @requires - 取消监听
   */
  onPermissionDenied(callback: () => () => void) {
    emitter.on('onPermissionDenied', callback)
    return () => {
      emitter.off('onPermissionDenied', callback)
    }
  }

  /**
   *  拨打电话
   */
  _makeCall(phone: string) {
    return this.callApi('makeCall', phone)
  }

  makeCall(phone: string) {
    return Util.errCaptured(this._makeCall.bind(this), phone)
  }

  that = this

  /**
   *  获取当前用户的详细信息
   */
  _getUserInfo() {
    return this.callApi<IGetUserInfo>('getUserInfo')
  }

  getUserInfo() {
    return Util.errCaptured<IGetUserInfo>(this._getUserInfo.bind(this))
  }

  /**
   * 启动拍照界面
   * data:image/png;base64,
   * */
  _startCamera() {
    return this.callApi('startCamera')
  }

  startCamera() {
    return Util.errCaptured<IGetCamera>(this._startCamera.bind(this))
  }
}

/**
 * 获取省份名称，兼容旧版本
 * 旧版本豆芽只返回 `AddrStr`, `country`, `city`，新版本增加 `province`
 * http://wiki.cnsuning.com/pages/viewpage.action?pageId=33415241
 */
export function getProvinceName(douyaLocation: {
  AddrStr?: string
  country?: string
  province?: string
  city?: string
  [key: string]: any
}) {
  if ('province' in douyaLocation) {
    return douyaLocation['province']
  }
  if (douyaLocation.AddrStr) {
    let province = douyaLocation.AddrStr.replace(douyaLocation.country, '')
    // "中国江苏省南京市玄武区环园东路" -> "江苏省"
    return province.slice(0, province.indexOf(douyaLocation.city))
  }
  return ''
}

export default new Douya()
