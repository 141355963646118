import * as React from 'react'

interface IProps {
  height?: string
  width?: string
  color?: string
}

interface IState {}

export default class VerticalLine extends React.Component<IProps, IState> {
  static defaultProps = {
    height: '80%',
    width: '0.02rem',
    color: '#f0f1f4'
  }

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div
        style={{
          height: this.props.height,
          width: this.props.width,
          background: this.props.color
        }}
      />
    )
  }
}
