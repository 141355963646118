/**
 * 方式执行节流装饰器
 * @param { number } throttleTime 两次点击的间隔时间
 */
export function decThrottle(throttleTime: number = 600) {
  let timeStampKeyList: any[] = [] // 所有的方法
  return function(_target, key: string, descriptor) {
    const rawFunc = descriptor.value
    descriptor.value = function() {
      // 当前的点击触发时间
      const currentTimeStamp: number = new Date().getTime()
      // 当前的方法对应的对象的下标
      timeStampKeyList = timeStampKeyList.filter(
        value => Math.abs(currentTimeStamp - value.value) <= throttleTime
      )
      const readyTrigger: boolean =
        timeStampKeyList.findIndex((value: any) => value.key === key) === -1
      // 是否应该真正地触发方法
      if (readyTrigger) {
        timeStampKeyList.push({ value: currentTimeStamp, key })
        return rawFunc.apply(this, arguments)
      } else {
        return null
      }
    }
    return descriptor
  }
}

export default decThrottle()
