import Layout from './components/layout/Layout'
import zhCN from './utils/antd/zh_CN'
import { AliveScope, KeepAlive } from 'react-activation'
import {
  createHashRouter,
  Navigate,
  NonIndexRouteObject,
  Outlet,
  RouterProvider,
} from 'react-router-dom'
import { IRoute, RouterCacheKeys, routes } from './routes'
import { LocaleProvider } from 'antd-mobile'
import ErrorPage from './components/error-page/ErrorPage'

export type IRouterAnimationMode = 'fade' | 'slide' | 'none'

export interface IRouteAnimation {
  /**
   * 动画模式 -> "fade" | "slide" | "none"
   * @default 'none'
   */
  routerAnimationMode: IRouterAnimationMode
}

export const ROOTPATH = '/home'

/** 路由过场动画模式 */
const routerAnimationMode: IRouterAnimationMode = 'none'

export function AppContainer() {
  return (
    <LocaleProvider locale={zhCN}>
      <RouterProvider router={router} />
    </LocaleProvider>
  )
}

export const router = createHashRouter([
  {
    path: '/',
    errorElement: <ErrorPage />,
    element: (
      <AliveScope>
        <Outlet />
      </AliveScope>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to={ROOTPATH} replace />,
      },
      ...getRoutes(),
    ],
  },
] as Array<NonIndexRouteObject>)

function getRoutes() {
  return routes.map(({ component: Component, ...otherOptions }) => {
    const { path, cache, caseSensitive, index, ...restProps } = otherOptions

    return {
      path: '/',
      key: path,
      element: (
        <Layout
          options={otherOptions as IRoute}
          routerAnimationMode={routerAnimationMode}
        />
      ),
      children: [
        {
          path,
          caseSensitive: caseSensitive || true,
          index: index || path === ROOTPATH,
          element: cache ? (
            <KeepAlive name={RouterCacheKeys[path]} key={RouterCacheKeys[path]}>
              {/* @ts-ignore */}
              <Component />
            </KeepAlive>
          ) : (
            // @ts-ignore
            <Component />
          ),
          ...restProps,
        },
      ],
    } as NonIndexRouteObject
  })
}
