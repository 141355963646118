/*
 * @Author: your name
 * @Date: 2019-09-20 19:15:19
 * @LastEditTime: 2019-11-04 10:24:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \super-manager\src\config\index.prexg.ts
 */
import { EDeviceType } from '@/stores'
import { ISystemConfig } from './config'

export default class Config implements ISystemConfig {
  HOST = ''
  ROOT_PATH = []
  CURRENT_PATH = ''
  SERVICE_IMG_HOST = ''
  APP_BOSS_REPORT_HOST = '//srcfewsh5prexg.cnsuning.com'
  // --------------------------------------------------------- >
  PASSPORT = {
    PASSPORT_LOGIN_HOST: '//passportpre.cnsuning.com/ids/',
    PASSPORT_LOGIN_THEME: 'lsy_skf',
    PASSPORT_DOMAIN_HOST: '//lsybosspcprexg.cnsuning.com/'
  }
  UOM = {
    SITE_ID: '08CJJLR',
    RESOURCE_TYPE: '08CJJLR',
    BIZ_ID: 'lsypjg'
  }
  EXCEPTIONS_UPLOAD = {
    BIZ_ID: 'super-manager'
  }
  Current_Store_Type = ''
  USER_INFO = {
    uid: '',
    phone: '',
    companyName: '',
    mobileId: '',
    AppSource: '' as EDeviceType,
    isPhoneRoot: false,
    onVirtual: false,
    latitude: '',
    longitude: '',
    street: '',
    AddrStr: '',
    city: '',
    country: '',
    roleList: [],
    selectRole: {
      roleCode: '',
      roleName: '',
      dataDimension: '',
      roleStatus: ''
    },
    selectStoreType: '',
    duty: '',
    dutyDesc: '',
    position: '',
    sliceAreaList: [],
    status: '',
    regionName: '',
    regionCode: ''
  }
  AMAP_KEY = '709309ab6e53b4b179bb3802805ef411'
  AMAP_JS_CODE = 'eddc1bfc79530eeeebc79068a55fb1cf'
  saveSearchStore = {}
  mpAppid = ''
  MAP_TYPE = ''
}
