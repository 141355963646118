import JoinStoreDevelop from '../../pages/data/widgets/join-store-develop/JoinStoreDevelop'
import OpenStore from '../../pages/data/widgets/open-store/OpenStore'
import OpenStoreAchievement from '../../pages/data/widgets/open-store-achievement/OpenStoreAchievement'
import PerformanceReport from '../../pages/data/widgets/performance-report/PerformanceReport'
import PurchasePerformance from '../../pages/data/widgets/purchase-performance/PurchasePerformance'
import RedYellowCard from '../../pages/data/widgets/red-yellow-card/RedYellowCard'
import SampleMachine from '../../pages/data/widgets/sample-machine/SampleMachine'
import StockData from '../../pages/data/widgets/stock-data/StockData'
import TaskManageBlock from '../../pages/data/widgets/task-manage-block/TaskManageBlock'
import TuozhanScore from '../../pages/data/widgets/tuozhan-score/TuozhanScore'
import VsaPrototype from '../../pages/data/widgets/vsa-prototype/VsaPrototype'
import { globalUserState } from '@/stores'
import { useSnapshot } from 'valtio'

/** 首页卡片展示区域 */
export default function RenderCards() {
  const userInfo = useSnapshot(globalUserState) // 相当于将store转换成react-state, store变化，会自动更新，重新渲染

  return (
    <>
      {(userInfo?.dataGroupList || []).map((item) => {
        // 任务管理-待审核任务
        if (item?.code === 'rwgl') {
          return <TaskManageBlock key={item.code} />
        }

        // JSAV样机数据
        if (item?.code === 'xyjsj') {
          return <VsaPrototype key={item.code} />
        }

        // 全国绩效
        if (item?.code === 'qgjj') {
          return <PerformanceReport key={item.code} />
        }

        // 门店绩效/采购业绩
        if (item?.code === 'mdjx') {
          return <PurchasePerformance key={item.code} titleName={item.name} />
        }

        // 红黄牌预计
        if (item?.code === 'hhpyj') {
          return <RedYellowCard key={item.code} titleName={item.name} />
        }

        // 拓展人员业绩
        if (item?.code === 'tzyj' || item?.code == 'tzryyj') {
          return <TuozhanScore key={item.code} titleName={item.name} />
        }

        // 开店进展
        if (item?.code === 'kdjz') {
          return <OpenStore key={item.code} titleName={item.name} />
        }

        // 新增开店业绩code需要后期改掉
        if (item?.code === 'grkdyj') {
          return <OpenStoreAchievement key={item.code} titleName={item.name} />
        }

        // 加盟店发展
        if (item?.code === 'jmdfz') {
          return <JoinStoreDevelop key={item.code} titleName={item.name} />
        }

        // 库存数据
        if (item?.code === 'kcsj') {
          return <StockData key={item.code} titleName={item.name} />
        }

        // 样机数据
        if (item?.code === 'yjsj') {
          return <SampleMachine key={item.code} />
        }
      })}
    </>
  )
}
