import { CacheWhen, IRoute, routes } from '@/routes'
import { Action } from './Layout'

export const routeStacks: string[] = []

interface IOptions {
  route: IRoute
  action: Action
  unmountRoute: (routePath: string, prevRouteWhen: CacheWhen) => void
  checkCacheOnForward?: (routePath?: string) => void
}

/**
 * 依据route action以及cache配置进行缓存组件的自动清理，以节省内存
 * @param options {@link IOptions}
 */
export function managerRouteStackAndCaches({
  route = {} as IRoute,
  action,
  unmountRoute,
  checkCacheOnForward = () => {},
}: IOptions) {
  const {
    path,
    cache = false,
    when = 'forward',
    manuallyClearCache = false,
  } = route

  if (!routeStacks.length) {
    routeStacks.push(path)
    logRoutes()
    return
  }

  const prevRouteStacks: string[] = JSON.parse(JSON.stringify(routeStacks))
  const prevRoutePath = prevRouteStacks[prevRouteStacks.length - 1] as string
  const prevRoute =
    routes.find((v) => v.path === prevRoutePath) || ({} as IRoute)

  const {
    when: prevRouteWhen = 'forward',
    cache: prevCache = false,
    manuallyClearCache: prevManuallyClearCache = false,
  } = prevRoute

  switch (action) {
    case Action.Replace:
      // routes stack manage
      routeStacks[routeStacks.length - 1] = path

      // routes cache manage
      if (!prevManuallyClearCache && prevCache && prevRouteWhen !== 'always') {
        unmountRoute(prevRoutePath, prevRouteWhen)
      }

      if (
        cache &&
        !manuallyClearCache &&
        when === 'forward' &&
        prevRoutePath !== path
      ) {
        checkCacheOnForward(path)
      }
      break

    case Action.Push:
      // routes stack manage
      if (prevRoutePath !== path) routeStacks.push(path)

      // routes cache manage
      if (
        cache &&
        !manuallyClearCache &&
        when === 'forward' &&
        prevRoutePath !== path
      ) {
        checkCacheOnForward(path)
      }

      if (
        !prevManuallyClearCache &&
        prevCache &&
        prevRouteWhen === 'backward' &&
        prevRoutePath !== path
      ) {
        unmountRoute(prevRoutePath, prevRouteWhen)
      }
      break

    case Action.Pop:
      // routes stack manage
      routeStacks.splice(routeStacks.length - 1)

      // routes cache manage
      if (
        !prevManuallyClearCache &&
        !routeStacks.includes(prevRoutePath) &&
        prevCache &&
        prevRouteWhen === 'forward'
      ) {
        unmountRoute(prevRoutePath, prevRouteWhen)
      }
      break

    default:
      break
  }

  logRoutes()
}

function logRoutes() {
  console.log(
    `%c[Routes stack] lastest is: ${JSON.stringify(routeStacks)}`,
    'color: #3377ff'
  )
}
