import classNames from 'classnames'
import IndexTaskIcon from '@assets/images/index_task_icon.png'
import qs from 'qs'
import { EAccountRole } from '@genre'
import { baseAllState, globalUserState } from '@/stores'
import { Icon } from 'antd-mobile'
import { Router } from '@kits'
import { useMemo } from 'react'
import { useSnapshot } from 'valtio'
import './index.less'

/** 任务管理 */
export default function TaskManageBlock() {
  const userInfo = useSnapshot(globalUserState)
  const baseAllInfo = useSnapshot(baseAllState)
  const unAuditTaskNum = useMemo(
    () => Number(baseAllInfo?.taskManage?.unAuditTaskNum || ''),
    [baseAllInfo?.taskManage]
  )

  /** 跳转到任务列表 */
  function jumpToTaskList() {
    Router.Push(
      `/store-task-info/task-list?${qs.stringify({ status: 'UNAUDIT' })}`
    )
  }

  return baseAllInfo?.taskManage ? (
    <div>
      {userInfo?.accountRole === EAccountRole.AREA && (
        <div className="task-manage-block" onClick={jumpToTaskList}>
          <div className="task-left-block">
            <img className="task-img" src={IndexTaskIcon} />
            <div className="task-title">任务管理</div>
          </div>
          <div className="task-right-block">
            <div className="task-title">待审核任务</div>
            <div
              className={classNames(
                unAuditTaskNum >= 1 && unAuditTaskNum < 10 ? 'num-box' : '',
                unAuditTaskNum >= 10 ? 'num-any-box' : ''
              )}
            >
              <span>{unAuditTaskNum > 1000 ? 1000 + '+' : unAuditTaskNum}</span>
            </div>
            <Icon type="right" size="xs" color="#4A90E2" />
          </div>
        </div>
      )}
    </div>
  ) : null
}
