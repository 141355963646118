import _zh_CN2 from 'antd-mobile/lib/date-picker-view/locale/zh_CN';
import _zh_CN4 from 'antd-mobile/lib/date-picker/locale/zh_CN';
import _zh_CN6 from 'antd-mobile/lib/input-item/locale/zh_CN';
import _zh_CN8 from 'antd-mobile/lib/pagination/locale/zh_CN';
import _zh_CN10 from 'antd-mobile/lib/picker/locale/zh_CN';
import _zh_CN12 from 'antd-mobile/lib/search-bar/locale/zh_CN';
import _zh_CN14 from 'antd-mobile/lib/menu/locale/zh_CN';
import _zh_CN16 from 'antd-mobile/lib/pull-to-refresh/locale/zh_CN';
import _zh_CN18 from 'antd-mobile/lib/calendar/locale/zh_CN';

export default {
  locale: 'zh',
  Pagination: _zh_CN8,
  DatePicker: _zh_CN4,
  DatePickerView: _zh_CN2,
  InputItem: _zh_CN6,
  Picker: _zh_CN10,
  SearchBar: _zh_CN12,
  Menu: _zh_CN14,
  PullToRefresh: _zh_CN16,
  Calendar: _zh_CN18,
};
