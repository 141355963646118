/*
 * @Author: keqingrong (19040892)
 * @Date: 2019-08-11 09:12:12
 * @LastEditors: keqingrong (19040892)
 * @LastEditTime: 2019-08-19 17:29:57
 * @Description:
 */
import Douya from './douya'
/**
 * 高德Web服务API调用限制流量 <https://lbs.amap.com/api/webservice/guide/tools/flowlevel>
 * 高德地图JS API无明确限制？ <https://lbs.amap.com/api/javascript-api/reference/lnglat-to-address>
 * 优先使用地图JS API，地图未加载时使用Web服务 API。
 */

/**
 * 百度地图获取用户当前的位置信息，仅 https 协议或者 localhost 可用，优先使用浏览器定位，失败后使用IP定位。
 */
function getBaiduCurrentPosition() {
  return new Promise((resolve, reject) => {
    let geolocation = new BMap.Geolocation({
      enableHighAccuracy: true, //是否使用高精度定位，默认:true
      timeout: 10000, //超过10秒后停止定位，默认：无穷大
    })
    geolocation.getCurrentPosition(function (r) {
      __ENV_DEV__ &&
        console.log('[geolocation.getCurrentPosition]', r, this.getStatus())
      console.log(r)
      if (this.getStatus() === 0) {
        resolve(r.point)
      } else {
        reject(new Error('定位失败'))
      }
    })
  })
}

function getBaiduAddressByLnglat(lnglat: any, city?: string) {
  return new Promise((resolve, reject) => {
    if (!('BMap' in window)) {
      reject(new Error('百度地图未加载成功'))
      return
    }
    const geocoder = new BMap.Geocoder({
      city: city || '全国',
      radius: 1000,
      batch: false,
      extensions: 'base',
      extensions_town: true,
    })
    geocoder.getLocation(new BMap.Point(lnglat[0], lnglat[1]), (result) => {
      __ENV_DEV__ && console.log('[geocoder.getLocation]', lnglat, result)
      if (result) {
        resolve(result)
      } else {
        reject(new Error('经纬度的详细地址查询失败'))
      }
    })
  })
}

/**
 * 坐标转换，将其他地图服务商的坐标转换成高德地图经纬度坐标
 */
function convertCoordinates(
  lnglat: AMap.LocationValue,
  type: 'gps' | 'baidu' | 'mapbar'
) {
  return new Promise<[number, number]>((resolve, reject) => {
    if (!('AMap' in window)) {
      reject(new Error('高德地图未加载成功'))
      return
    }
    AMap.convertFrom(lnglat, type, (status, result) => {
      __ENV_DEV__ && console.log('[convertFrom]', lnglat, result)
      if (
        status === 'complete' &&
        typeof result !== 'string' &&
        Array.isArray(result.locations) &&
        result.locations.length > 0
      ) {
        const lnglat = result.locations[0]
        resolve([lnglat.getLng(), lnglat.getLat()])
      } else {
        reject(new Error('坐标转换失败'))
      }
    })
  })
}

/**
 * 百度地图逆地理编码（坐标 -> 地址）
 * @param lnglat - 经纬度
 * @param city - 城市，地址所在城市，设置后可以提高准确度
 */
function regeoBaiducode(lnglat: any, city?: string) {
  return new Promise<string>((resolve, reject) => {
    getBaiduAddressByLnglat(lnglat, city).then(
      (regeocodeResult: any) => {
        console.log('getBaiduAddressByLnglat=>', regeocodeResult)
        //@ts-ignore
        if (regeocodeResult.address) {
          resolve(regeocodeResult.address)
        }
      },
      (err) => {
        reject(err)
      }
    )
  })
}

/**
 * 预加载插件
 */
function preloadPlugins() {
  if ('AMap' in window) {
    AMap.plugin(
      ['AMap.DistrictSearch', 'AMap.Geocoder', 'AMap.Geolocation'],
      () => {}
    )
  }
}

/**
 * 判断是否是合法经纬度，仅支持数字，不支持字符串经纬度
 * longitude: [-180, 180]
 * latitude: [-90, 90]
 *
 * [null, null], [NaN, NaN] 非法
 */
function isValidCoordinates(lnglat: [number, number]) {
  if (
    Array.isArray(lnglat) &&
    lnglat.length === 2 &&
    typeof lnglat[0] === 'number' &&
    typeof lnglat[1] === 'number' &&
    lnglat[0] >= -180 &&
    lnglat[0] <= 180 &&
    lnglat[1] >= -90 &&
    lnglat[1] <= 90
  ) {
    return true
  }
  return false
}

/**
 * 解析经纬度，将经纬度字符串数组解析成数字数组
 * 解析失败返回 null
 */
function parseCoordinates(lnglat: [number, number] | [string, string]) {
  if (Array.isArray(lnglat) && lnglat.length === 2) {
    const lng =
      typeof lnglat[0] === 'number' ? lnglat[0] : parseFloat(lnglat[0])
    const lat =
      typeof lnglat[1] === 'number' ? lnglat[1] : parseFloat(lnglat[1])
    const newLnglat: [number, number] = [lng, lat]
    if (isValidCoordinates(newLnglat)) {
      return newLnglat
    }
    return null
  }
  return null
}

/**
 * 判断两个经纬度是否相等
 * @param lnglat1
 * @param lnglat2
 */
function isCoordinatesEqual(lnglat1, lnglat2) {
  if (isValidCoordinates(lnglat1) && isValidCoordinates(lnglat2)) {
    return lnglat1[0] === lnglat2[0] && lnglat1[1] === lnglat2[1]
  }
  return lnglat1 === lnglat2
}

/**
 * 计算两个经纬度点之间的实际距离。单位：米
 */
function calculateBaiduDistance(lnglat1: any, lnglat2: any, map?: any) {
  if (!('BMap' in window)) {
    console.error('[calculateBaiduDistance] 百度地图未加载成功')
    return Infinity
  }
  let pointA = new BMap.Point(lnglat1[0], lnglat1[1])
  let pointB = new BMap.Point(lnglat2[0], lnglat2[1])
  const distance = map.getDistance(pointA, pointB).toFixed(2)
  __ENV_DEV__ &&
    console.log('[calculateBaiduDistance]', lnglat1, lnglat2, distance)
  return distance
}

/**
 * 获取用户当前经纬度，优先使用豆芽API，失败时使用百度地图API（优先使用浏览器定位，失败后使用IP定位）
 */
async function getBaiduCurrentLngLat() {
  try {
    if (!('BMap' in window)) {
      throw new Error('百度地图未加载成功')
    }

    if (Douya.canIuse('getLocationInfo')) {
      const locationInfo = await Douya.getLocationInfo()
      let douyaLngLat: [number, number] = parseCoordinates([
        locationInfo.longitude,
        locationInfo.latitude,
      ])
      // 旧版豆芽，不返回mapType，默认为百度地图坐标，需要转换成高德坐标。
      // 新版豆芽，返回mapType，需要进行判断，如果是百度左边则转换成高德坐标。
      // 0:百度地图坐标，1:高德地图坐标
      if (Number(locationInfo.mapType) !== 1) {
        douyaLngLat = await convertCoordinates(douyaLngLat, 'baidu')
      }
      __ENV_DEV__ &&
        console.log('[getCurrentLngLat]', locationInfo, douyaLngLat)
      return douyaLngLat
    } else {
      // 非豆芽环境使用高德地图定位插件作为回退
      const currentPosition: any = await getBaiduCurrentPosition()
      __ENV_DEV__ && console.log('[getCurrentLngLat]', currentPosition)
      return [currentPosition.lng, currentPosition.lat] as [number, number]
    }
  } catch (err) {
    throw new Error('定位失败')
  }
}
/**高德地图转百度 */
function bd_encrypt(gg_lng, gg_lat) {
  let X_PI = (Math.PI * 3000.0) / 180.0
  let x = gg_lng,
    y = gg_lat
  let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI)
  let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI)
  let bd_lng = z * Math.cos(theta) + 0.0065
  let bd_lat = z * Math.sin(theta) + 0.006
  return {
    bd_lng: handleDecimalBit(bd_lng),
    bd_lat: handleDecimalBit(bd_lat),
  }
}
/**渲染层，处理把高德地图坐标转换成百度地图坐标 */
function handleBaiduFunc(longitude, latitude) {
  let bd_encryptResult = bd_encrypt(longitude, latitude)
  return bd_encryptResult
}

/**百度地图转高德地图 */
function gaode_encrypt(bd_lng, bd_lat) {
  let X_PI = (Math.PI * 3000.0) / 180.0
  let x = bd_lng - 0.0065
  let y = bd_lat - 0.006
  let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI)
  let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI)
  let gg_lng = z * Math.cos(theta)
  let gg_lat = z * Math.sin(theta)
  console.log('gaode_encrypt', gg_lng, gg_lat)
  return {
    gd_lng: handleDecimalBit(gg_lng),
    gd_lat: handleDecimalBit(gg_lat),
  }
}
/**渲染层，处理把百度地图坐标转换成高德地图坐标 */
function handleGaodeFunc(longitude, latitude) {
  let gd_encryptResult = gaode_encrypt(longitude, latitude)
  return gd_encryptResult
}

function handleDecimalBit(longLatVal) {
  console.log('handleDecimalBit', typeof longLatVal)
  if (longLatVal.toString().indexOf('.') > 0) {
    const longLatSplit = longLatVal.toString().split('.')
    if (longLatSplit.length >= 2) {
      return longLatSplit === ''
        ? ''
        : longLatSplit[0] + '.' + longLatSplit[1].slice(0, 6)
    }
  }
}
export {
  convertCoordinates,
  regeoBaiducode,
  regeoBaiducode as lnglat2Baiduaddress,
  getBaiduAddressByLnglat,
  getBaiduCurrentPosition,
  getBaiduCurrentLngLat,
  isValidCoordinates,
  isCoordinatesEqual,
  calculateBaiduDistance,
  parseCoordinates,
  preloadPlugins,
  bd_encrypt,
  gaode_encrypt,
  handleBaiduFunc,
  handleGaodeFunc,
}
