/*
 * @Author: 刘辉
 * @Date: 2019-07-19 10:18:09
 * @Last Modified by: 杨建新(20014264)
 * @Last Modified time: 2023-01-31 14:50:58
 */
import fetch from '@fetch'
import Config from '@config'
import { Toast } from 'antd-mobile'
import qs from 'qs'

/**
 * 片区排名数据（片区卡片通用）
 * 魔客: http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20221028000005
 * @param storeCode
 */
export const queryVsaSliceSummary = async (sliceCode: string, period: string) => {
  const url = `${Config.HOST}/vsa/queryVsaSliceSummary.do?sliceCode=${sliceCode}&period=${period}`
  // const url = `http://localhost:8090/api/vsa/queryVsaSliceSummary.do?sliceCode=${sliceCode}&period=${period}`
  const { err, res } = await fetch.get<IVsaCard>(url,{},{ toasthide: true })
  if (err) {
    return {}
  }
  return res || {}
}

export interface IVsaCard {
	sliceCode?: string;
	sliceName?: string;
	regionCode?: string;
	regionName?: string;
	slicePrincipalCommission?: string;
	meanCommission?: string;
	sampleWdbShopCount?: number;
	ranking?: number;
	totalRankingNum?: number;

  b2cSalesAmount?: string;
  b2cSalesNum?: number;
  taskQuota?: string;
  b2bSalesNum?: string;
}

/**
 * 查询片区下门店样机数据
 * 魔客: http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20221028000006
 * @param storeCode
 */
export const queryVsaStoreSummary = async (sliceCode: string, period: string) => {
  const url = `${Config.HOST}/vsa/queryVsaStoreSummary.do?sliceCode=${sliceCode}&period=${period}`
  // const url = `http://localhost:8090/api/vsa/queryVsaStoreSummary.do?sliceCode=${sliceCode}&period=${period}`
  const { err, res } = await fetch.get<IStoreData[]>(url,{},{ toasthide: true })
  if (err) {
    return []
  }
  return res
}

export interface IStoreData {
	storeCode?: string;
	storeName?: string;
	hasSampleNum?: number;
	shouldSampleNum?: number;
	slicePrincipalCommission?: string;
	b2cSalesAmount?: string;
	b2cSalesNum?: number;
	b2bSalesNum?: number;
	ranking?: string;
}

/**
 * 全部品类查询
 * http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20221102000033
 */
export const queryBuInfo = async () => {
  const url = `${Config.HOST}/buBrand/queryBuInfo.do`
  // const url = `http://localhost:8090/api/buBrand/queryBuInfo.do`
  const { err, res } = await fetch.get<ICategory[]>(url,{},{ toasthide: true })
  if (err) {
    return []
  }
  return res
}

export interface ICategory {
	buCode?: string;
	buName?: string;
}
export enum ETagCode{
  ALL = '',
  S = 'S',
  VS = 'VS',
  A = 'A'
}
/**
 * 片区SPU商品列表查询
 * http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20221028000018
 *
 * @param sliceCode
 * @param period
 * @param buCode
 */
export const queryVsaSampleSummaryList = async (sliceCode: string, period: string, buCode: string, tagCode: ETagCode) => {
  const url = `${Config.HOST}/vsaSample/queryVsaSampleSummaryList.do?sliceCode=${sliceCode}&period=${period}&buCode=${buCode}&tagCode=${tagCode}`
  // const url = `http://localhost:8090/api/vsaSample/queryVsaSampleSummaryList.do?sliceCode=${sliceCode}&period=${period}&buCode=${buCode}&tagCode=${tagCode}`
  const { err, res } = await fetch.get<IProductData[]>(url,{},{ toasthide: true })
  if (err) {
    return []
  }
  return res
}

export interface IProductData {
	sliceCode?: string;
	sliceName?: string;
	tagCode?: string;
	buCode?: string;
	buName?: string;
	spuCode?: string;
	spuName?: string;
	slicePrincipalCommission?: string;
	topAverageCommission?: string;
	hasSampleStorenum?: string;
  sliceTotalStoreNum?: string
	b2cSalesNum?: string;
	b2cSalesTaskNum?: string;
	b2bSalesNum?: string;
}

/**
 * 店铺出样数据卡片
 * http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20221028000012
 * @param period
 * @param storeCode
 */
export const queryVsaStoreSampleSummaryTop = async (period: string, storeCode: string) => {
  const url = `${Config.HOST}/vsa/queryVsaStoreSampleSummaryTop.do?period=${period}&storeCode=${storeCode}`
  // const url = `http://localhost:8090/api/vsa/queryVsaStoreSampleSummaryTop.do?period=${period}&storeCode=${storeCode}`
  const { err, res } = await fetch.get<ITabData>(url,{},{ toasthide: true })
  if (err) {
    return {}
  }
  return res
}

export interface ITabData {
	shouldSampleNum?: string;
	noneStockUpNum?: string;
	noneSampleNum?: string;
	hasSampleNum?: string;
}

/**
 * 店铺单品出样数据
 * http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20221028000007
 *
 * @param params
 */
export const queryVsaStoreSampleSummary = async (params: queryIStoreGoodsInfoParams) => {
  const url = `${Config.HOST}/vsa/queryVsaStoreSampleSummary.do?${qs.stringify(params)}`
  // const url = `http://localhost:8090/api/vsa/queryVsaStoreSampleSummary.do?${qs.stringify(params)}`
  const { err, res } = await fetch.get<IStoreGoodsInfo[]>(url,{},{ toasthide: true })
  if (err) {
    return []
  }
  return res
}

export type queryIStoreGoodsInfoParams = {
  period: string,
  storeCode: string,
  sampleType: string,
  grossProfitSort?: string,
  slicePrincipalCommissionSort?: string,
  topAverageSalesNumSort?: string,
  sliceSalesNumSort?: string,
  hasSimpleZtylSort?: string,
  hasSimpleCdsalesSort?: string,
  hasSimpleXztcSort?: string
}

export interface IStoreGoodsInfo {
	tagCode?: string;
	skuCode?: string;
	skuName?: string;
	spuCode?: string;
	spuName?: string;
	grossProfit?: string;
	slicePrincipalCommission?: string;
	topAverageSalesNum?: number;
	sliceSalesNum?: number;
	sampleType?: string;
	hasSimpleZtyl?: string;
	hasSimpleCdsales?: number;
	hasSimpleXztc?: string;
  isSelect: boolean
}

/**
 * 推荐出样
 * http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20221028000038
 *
 * @param params
 */
export const recommendVsaSample = async (params: IRecommendVsaSampleParams) => {
  const url = `${Config.HOST}/vsaSample/recommendVsaSample.do`
  const { err, res } = await fetch.postJson<{ recToken: string }>(url, params,{ toasthide: true })
  if (err) {
    return ''
  }
  return res.recToken
}

export interface IRecommendVsaSampleParams {
	period?: string;
	storeCode?: string;
	recommendPerson?: string;
	itemList: Item[];
}
export interface Item {
	skuCode?: string;
}

/**
 * 单品出样数据卡片
 * http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20221028000025
 *
 * @param period
 * @param sliceCode
 * @param spuCode
 */
export const queryVsaSampleTopCard = async (period: string, sliceCode: string, spuCode: string) => {
  const url = `${Config.HOST}/vsaSample/queryVsaSampleTopCard.do?${qs.stringify({ period, sliceCode, spuCode })}`
  // const url = `http://localhost:8090/api/vsaSample/queryVsaSampleTopCard.do?${qs.stringify({ period, sliceCode, spuCode })}`
  const { err, res } = await fetch.get<IProductCardInfo>(url,{},{ toasthide: true })
  if (err) {
    return {}
  }
  return res
}

export interface IProductCardInfo {
	spuCode?: string;
	spuName?: string;
	hasSampleStorenum?: string;
	slicePrincipalCommission?: string;
	b2cSalesAmount?: string;
	b2cSalesNum?: string;
	b2cSalesTaskNum?: string;
}

/**
 * 单品出样门店数据查询
 * http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20221028000027
 *
 * @param period
 * @param sliceCode
 * @param spuCode
 */
export const queryVsaSampleStoreSummaryList = async (period: string, sliceCode: string, spuCode: string) => {
  const url = `${Config.HOST}/vsaSample/queryVsaSampleStoreSummaryList.do?${qs.stringify({ period, sliceCode, spuCode })}`
  // const url = `http://localhost:8090/api/vsaSample/queryVsaSampleStoreSummaryList.do?${qs.stringify({ period, sliceCode, spuCode })}`
  const { err, res } = await fetch.get<IProductItemInfo[]>(url,{},{ toasthide: true })
  if (err) {
    return []
  }
  return res
}

export interface IProductItemInfo {
	storeCode?: string;
	storeName?: string;
	isSampleSet?: string;
	slicePrincipalCommission?: string;
	b2bSalesNum?: string;
	b2cSalesNum?: string;
}

/**
 * SPU单品门店出样状态查询
 * http://mk.cnsuning.com/umsm/interfaceInfo/shareInterface.htm?interfaceCode=I20221028000039
 *
 * @param period
 * @param sliceCode
 * @param spuCode
 * @param storeCode
 */
export const queryStoreVsaSampleStatus = async (period: string, sliceCode: string, spuCode: string, storeCode: string) => {
  const url = `${Config.HOST}/vsaSample/queryStoreVsaSampleStatus.do?${qs.stringify({ period, sliceCode, spuCode, storeCode })}`
  // const url = `http://localhost:8090/api/vsaSample/queryStoreVsaSampleStatus.do?${qs.stringify({ period, sliceCode, spuCode, storeCode })}`
  const { err, res } = await fetch.get<ISimpleStatus>(url,{},{ toasthide: true })
  if (err) {
    return {}
  }
  return res
}

export interface ISimpleStatus {
	spuCode?: string;
	spuName?: string;
	sampleStatus?: string;
	sampleStatusName?: string;
}
