/*
 * @Author: 刘辉
 * @Date: 2022-11-03 17:18:09
 * @Last Modified by: 刘辉(2002013)
 * @Last Modified time: 2022-11-03 17:18:09
 */

export const storeDataTabs = [
  { label: '未备货', code: '1', num: null },
  { label: '未出样', code: '2', num: null },
  { label: '已出样', code: '3', num: null }
]

// (未备货、未出样) 排序item
export const storeDataSortList1 = [
  {
    name: '预估毛利(元)',
    value: 'grossProfitSort',
    sort: 1
  },
  {
    name: '县总提成(元)',
    value: 'slicePrincipalCommissionSort',
    sort: null
  },
  {
    name: 'TOP门店月均销售(台)',
    value: 'topAverageSalesNumSort',
    sort: null
  },
  {
    name: '片区销售(台)',
    value: 'sliceSalesNumSort',
    sort: null
  }
]

//(已出样) 排序item
export const storeDataSortList2 = [
  {
    name: '总体盈利(元)',
    value: 'hasSimpleZtylSort',
    sort: 1
  },
  {
    name: 'C端销量(台)',
    value: 'hasSimpleCdsalesSort',
    sort: null
  },
  {
    name: '县总提成(元)',
    value: 'hasSimpleXztcSort',
    sort: null
  }
]

export function copy (text) {
  // text是复制文本
  // 创建input元素
  const el = document.createElement('input')
  // 给input元素赋值需要复制的文本
  el.setAttribute('value', text)
  // 将input元素插入页面
  document.body.appendChild(el)
  // 选中input元素的文本
  el.select()
  // 复制内容到剪贴板
  document.execCommand('copy')
  // 删除input元素
  document.body.removeChild(el)
}

/**
 * 没值情况展示 -
 * @param va
 * @example
 *  "" -> "-"
 *  0 -> 0
 */
export const formatValue = (va: string | number) => {
  return va === undefined || va === "" ? '-' : va
}

/**
 * 取首位，其余零补齐
 * @example
 * ======2: 2
 * ======28: 20+
 * ======518: 500+
 * ======10248: 10000+
 */
export const getFirstFillOtherZero = (va: string) => {
  if (va === undefined || va === '') {
    return '-'
  }
  let result = va
  if (va.includes('.')) {
    result = va.split('.')[0]
  }
  result = result.substr(0, 1).padEnd(result.length, '0')
  if (result.length > 1) {
    result += '+'
  }
  return result
}
