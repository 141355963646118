/*
 * @Author: hannianqi
 * @Date: 2019-03-25 14:49:52
 * @Last Modified by: 吴超 20023244
 * @Last Modified time: 2023-07-24 19:33:17
 *
 * 通用工具方法
 */
import { EAccountRole } from '@genre'
import { currentAreaCode, getGlobalUserConfig } from '@/stores'
import dayjs from 'dayjs'
import qs from 'qs'
/**
 * 将键值对转换成对象格式
 * @param { string } cookie [key=value;key=value...] 格式的字符串
 */
export function parseCookie<T = any>(cookie: string): T {
  return cookie
    .split(';')
    .filter(Boolean)
    .reduce<T>((result, value) => {
      const [key, val] = value.split('=').map((val) => val.trim())
      val && (result[key] = decodeURIComponent(val))
      return result
    }, {} as T)
}

/**
 * 解析地址栏的参数
 */
export function useQueryParams<
  QueryParams extends { [K in keyof QueryParams]?: unknown } = {}
>(): QueryParams {
  const location = window.location.toString()
  const params = location.includes('?') ? location.split('?')[1] : '{}'
  const query = qs.parse(params, { ignoreQueryPrefix: true })

  return query as QueryParams
}

/**
 * @description 解析传入字符串, 若不传第二个参数(key)，默认解析整个字符传并返回整个对象
 * @param { string } queryString
 * @param { string } key
 */
export function parseQueryString<T = any>(queryString?: string): T
// @ts-ignore
export function parseQueryString<T = any>(
  queryString: string,
  key?: keyof T
): string
export function parseQueryString<T = any>(queryString?: string, key?: string) {
  queryString = queryString || window.location.href.split('?')[1] || ''
  if (queryString) {
    queryString = ~queryString.indexOf('?')
      ? queryString.split('?')[1]
      : queryString
    if (!key) {
      return queryString.split('&').reduce<T>((acc, str) => {
        const [key, val] = str.split('=')
        acc[key] = decodeURIComponent(val || '').trim()
        return acc
      }, {} as T)
    } else {
      const result = queryString.match(new RegExp(`${key}=(\\w+)`))
      if (result) {
        return decodeURIComponent(result[1] || '').trim()
      } else {
        return null
      }
    }
  } else {
    return null
  }
}

/**
 * json 转换成 'a=...&b=...&c=...' 格式
 */
export function parseJsonToQueryString<T>(json: T): string {
  return Object.entries(json)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&')
}

/**
 * 序列化埋点参数，忽略null、undefined、空字符串
 * @param params
 * {
 *   pgtitle: '首页',
 *   pageid: '123',
 *   foo: '',
 *   bar: null,
 * }
 * => 'pgtitle=首页;pageid=123'
 */
export function stringifySa(params: Object): string {
  return Object.keys(params)
    .map((key) => {
      const val = params[key]
      if (
        val === null ||
        val === undefined ||
        (typeof val === 'string' && val.length === 0)
      ) {
        return false
      } else {
        return `${key}=${val}`
      }
    })
    .filter(Boolean)
    .join(';')
}

/**
 * 检验输入是否为整数
 * 如Input组件限制输入整数
 * @param value
 */
export const checkInt = (value: string): boolean => {
  const integerRegx = /^\+?[1-9][0-9]*$/
  return integerRegx.test(value)
}

/**
 * 手机号码加****
 * 13812345678 -> 138****5678
 */
export const fuzzyMobile = (mobile: string): string => {
  //tel.replace(tel.substring(3,7), "****")
  const reg = /(\d{3})\d{4}(\d{4})/
  return mobile.replace(reg, '$1****$2')
}

/**
 * 数值转百分比
 * 这里需要先用Number进行数据类型转换，然后去指定截取转换后的小数点后几位(按照四舍五入)，这里是截取一位，0.1266转换后会变成12.7%
 */
export const toPercent = (point: number) => {
  var str = Number(point * 100).toFixed(1)
  str += '%'
  return str
}

/**
 * 百分比转小数
 * "0.0%" -> 0
 * "-4.5%" -> -0.045
 * "5" -> null
 */
export const parsePercent = (str) => {
  if (str.includes('%')) {
    return parseFloat(str) / 100
  } else {
    return null
  }
}

export const toFixed = (ori: string) => {
  if (!ori.includes('.')) {
    return ori
  }
  let x1 = Number(ori) + ''
  var f = parseFloat(x1)
  if (isNaN(f)) {
    return false
  }
  let x = Number(ori)
  var f = Math.round(x * 100) / 100
  var s = f.toString()
  var rs = s.indexOf('.')
  if (rs < 0) {
    rs = s.length
    s += '.'
  }
  while (s.length <= rs + 2) {
    s += '0'
  }
  return s
}

/** async await 包装try->catch */
export async function errCaptured<T>(
  asyncFunc,
  param?: any
): Promise<{ res: T; err: any }> {
  try {
    let res = param ? await asyncFunc(param) : await asyncFunc()
    // console.log('======= asyncFunc res:', res)
    return { res, err: null }
  } catch (err) {
    // console.log('======= asyncFunc err:', err)
    return { err, res: null }
  }
}
/**
 * @description 判断经纬度合法性
 * @param latitude 纬度
 * @param longitude 经度
 */
export const checkedLangLat = (position): boolean => {
  //var longrg = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,6})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,6}|180)$/
  //var latreg = /^(\-|\+)?([0-8]?\d{1}\.\d{0,6}|90\.0{0,6}|[0-8]?\d{1}|90)$/
  var longrg = /^(\-|\+)?([\.\d]*)$/
  var latreg = /^(\-|\+)?([\.\d]*)$/

  var flag = true
  if (!latreg.test(position.latitude) || !longrg.test(position.longitude)) {
    flag = false
  }
  return flag
}

/**
 * 判断字符串长度
 * @param str 字符串
 */
export const getStrLength = (str: string): number => {
  var len = 0
  for (var i = 0; i < str.length; i++) {
    var a = str.charAt(i)
    if (a.match(/[^\x00-\xff]/gi) != null) {
      len += 2
    } else {
      len += 1
    }
  }
  return len / 2
}
/**
 * @description 字符串截取函数
 * @param str 字符串
 * @param length 截取长度
 */
export const subStr = (str: string, length: number): string => {
  var a = str.match(/[^\x00-\xff]|\w{1,2}/g)
  return a.length < length ? str : a.slice(0, length).join('') + '...'
}

/**
 * 季度 可选值：Q1 - 一季度，Q2 - 二季度，Q3 - 三季度，Q4 - 四季度。
 */
export enum EQuarter {
  /** 一季度 */
  Q1 = 'Q1',
  /** 二季度 */
  Q2 = 'Q2',
  /** 三季度 */
  Q3 = 'Q3',
  /** 四季度 */
  Q4 = 'Q4',
}

/**
 *  获取当前年份 | 季度 | 月份
 *  返回 格式
 *  {
 *      year: string,
        season: string,
        quarter_name: Q1 | Q2 | Q3 | Q4,
        month: string
 *  }
 */

interface IGetTimeInfoR {
  year: string
  // season: string,
  quarter_name: string
  quarter: EQuarter
  month: string
  adjustYear?: string
  adjustMonth?: string
  fixjiduYear?: string
  fixjiduQuarter?: EQuarter
}
export const getTimeInfo = (): IGetTimeInfoR => {
  let systemDate = new Date()
  let quarter = ['一季度', '二季度', '三季度', '四季度'],
    year = systemDate.getFullYear() + '',
    month = systemDate.getMonth() + 1

  let adjustMonth = month
  let adjustYear = year
  let day = systemDate.getUTCDate()
  //每月1，2号记做上个月，1月做上年12月
  if (day === 1 || day === 2) {
    if (month === 1) {
      adjustYear = systemDate.getFullYear() - 1 + ''
      adjustMonth = 12
    } else {
      adjustMonth = adjustMonth - 1
    }
  }

  let fixjiduYear = year
  let fixjiduQuarter = ('Q' + Math.ceil(month / 3)) as EQuarter
  if (systemDate.getMonth() % 3 === 0 && (day === 1 || day === 2)) {
    if (systemDate.getMonth() === 0) {
      fixjiduYear = systemDate.getFullYear() - 1 + ''
      fixjiduQuarter = EQuarter.Q4
    } else {
      fixjiduQuarter = ('Q' + Math.ceil((month - 1) / 3)) as EQuarter
    }
  }

  return {
    year: year + '',
    quarter_name: quarter[Math.floor((month - 1) / 3)],
    quarter: ('Q' + Math.ceil(month / 3)) as EQuarter,
    month: String(month).padStart(2, '0'),
    adjustYear: adjustYear + '',
    adjustMonth: String(adjustMonth).padStart(2, '0'),
    fixjiduYear: fixjiduYear,
    fixjiduQuarter: fixjiduQuarter,
  }
}

/**
 * 上个月 YYYYMM
 * @returns
 */
export const getLastMonth = () => {
  return dayjs().add(-1, 'month').startOf('month').format('YYYYMM')
}

//3获取当前设备类型
export const getDevice = (): { device: string } => {
  let agent = navigator.userAgent.toLowerCase()
  let result = {
    device: (function () {
      if (/windows/.test(agent)) {
        return 'windows pc'
      } else if (/iphone|ipod/.test(agent) && /mobile/.test(agent)) {
        return 'iphone'
      } else if (/ipad/.test(agent) && /mobile/.test(agent)) {
        return 'ipad'
      } else if (/android/.test(agent) && /mobile/.test(agent)) {
        return 'android'
      } else if (/linux/.test(agent)) {
        return 'linux pc'
      } else if (/mac/.test(agent)) {
        return 'mac'
      } else {
        return 'other'
      }
    })(),
  }
  return result
}

export const listenKeybord = () => {
  const device = getDevice().device
  const activeElement = document.activeElement
  if (device === 'iphone' || device === 'ipad') {
    // IOS 键盘弹起后操作
    activeElement.addEventListener(
      'focus',
      function () {
        activeElementScrollIntoView(activeElement, 100)
      },
      false
    )
    // IOS 键盘收起：IOS 点击输入框以外区域或点击收起按钮，输入框都会失去焦点，键盘会收起，
    activeElement.addEventListener('blur', () => {
      // 微信浏览器版本6.7.4+IOS12会出现键盘收起后，视图被顶上去了没有下来
      var wechatInfo = window.navigator.userAgent.match(
        /MicroMessenger\/([\d\.]+)/i
      )
      if (!wechatInfo) return
      var wechatVersion = wechatInfo[1]
      var version = navigator.appVersion.match(/OS (\d+)(\d+)?(\d+)?/)
      if (+wechatVersion.replace(/./g, '') >= 674 && +version[1] >= 12) {
        setTimeout(function () {
          window.scrollTo(
            0,
            Math.max(
              document.body.clientHeight,
              document.documentElement.clientHeight
            )
          )
        })
      }
    })
  } else if (device === 'android') {
    let originHeight =
      document.documentElement.clientHeight || document.body.clientHeight
    window.addEventListener(
      'resize',
      function () {
        const resizeHeight =
          document.documentElement.clientHeight || document.body.clientHeight
        if (originHeight < resizeHeight) {
          // Android 键盘收起后操作
          // 修复小米浏览器下，输入框依旧被输入法遮挡问题
          // if (judgeDeviceType.isMiuiBrowser) {
          //   document.body.style.marginBottom = '0px';
          // }
        } else {
          // Android 键盘弹起后操作

          // 修复小米浏览器下，输入框依旧被输入法遮挡问题
          // if (judgeDeviceType.isMiuiBrowser) {
          //   document.body.style.marginBottom = '0px';
          // }

          const editable = activeElement.getAttribute('contenteditable')
          if (
            activeElement.tagName == 'INPUT' ||
            activeElement.tagName == 'TEXTAREA' ||
            editable === '' ||
            editable
          ) {
            activeElement.scrollIntoView()
          }
          activeElementScrollIntoView(activeElement, 100)
        }
        originHeight = resizeHeight
      },
      false
    )
  } else {
    //其它情况
  }
}

function activeElementScrollIntoView(activeElement, delay) {
  var editable = activeElement.getAttribute('contenteditable')
  // 输入框、textarea或富文本获取焦点后没有将该元素滚动到可视区
  if (
    activeElement.tagName == 'INPUT' ||
    activeElement.tagName == 'TEXTAREA' ||
    editable === '' ||
    editable
  ) {
    setTimeout(function () {
      activeElement.scrollIntoView({
        behavior: 'auto',
        block: 'start',
        inline: 'end',
      })
    }, delay)
  }
}

/**
 * 从角色列表中获取一个角色
 */
export function getRoleCode() {
  const state = getGlobalUserConfig()

  if (Array.isArray(state.roleList) && state.roleList.length > 0) {
    return state.roleList?.[0]?.roleCode || ''
  }
  return ''
}

/**
 * @param 返回字符串格式类型
 */
enum SaStringType {
  EQUAL = 'EQUAL', // key=value 类型
  QUOTE = 'QUOTE', // "key":"value" 类型
  OTHER = 'OTHER', // 其他
}

export const saRoleid = (saStrType: SaStringType) => {
  const state = getGlobalUserConfig()

  if (Array.isArray(state.roleList) && state.roleList.length > 0) {
    return saStrType === SaStringType.EQUAL
      ? `roleid=${state.selectRole?.roleCode || ''}`
      : `"roleid": "${state.selectRole?.roleCode || ''}"`
  }
  return saStrType === SaStringType.EQUAL ? `roleid=` : `"roleid": ""`
}

/** 是否是总部 埋点也无需求 */
export const saLsyhead = (saStrType: SaStringType) => {
  const state = getGlobalUserConfig()

  if (Array.isArray(state.roleList) && state.roleList.length > 0) {
    const lsyhead =
      state.selectRole?.dataDimension === EAccountRole.NATIONAL ? '1' : '0'
    return saStrType === SaStringType.EQUAL
      ? `lsyhead=${lsyhead}`
      : `"lsyhead":"${lsyhead}"`
  }
  return saStrType === SaStringType.EQUAL ? `lsyhead=` : `"lsyhead":""`
}

/** 销售大区， 埋点 */
export const saLsyregin = (saStrType: SaStringType) => {
  const state = getGlobalUserConfig()

  if (
    Array.isArray(state.roleList) &&
    state.roleList.length > 0 &&
    state.regionCode
  ) {
    return saStrType === SaStringType.EQUAL
      ? `lsyregin=${state.regionCode}`
      : `"lsyregin":"${state.regionCode}"`
  }
  return saStrType === SaStringType.EQUAL ? `lsyregin=` : `"lsyregin":""`
}

/** 片区 埋点 */
export const saLsyarea = (saStrType: SaStringType) => {
  const state = getGlobalUserConfig()

  if (
    Array.isArray(state.roleList) &&
    state.roleList.length > 0 &&
    state.selectRole?.dataDimension === EAccountRole.AREA &&
    state.sliceAreaList &&
    state.sliceAreaList.length > 0 &&
    state.sliceAreaList?.[0]?.sliceAreaCode
  ) {
    let selectSliceAreaCode = ''
    for (let v of state.sliceAreaList) {
      if (currentAreaCode.value === v?.sliceAreaCode) {
        selectSliceAreaCode = currentAreaCode.value
        break
      }
    }
    if (saStrType === SaStringType.EQUAL) {
      // 有选择值取选择值，没有取第一项
      if (selectSliceAreaCode) {
        return `lsyarea=${selectSliceAreaCode}`
      } else {
        return `lsyarea=${state.sliceAreaList?.[0]?.sliceAreaCode || ''}`
      }
    } else {
      if (selectSliceAreaCode) {
        return `"lsyarea":"${selectSliceAreaCode}"`
      } else {
        return `"lsyarea":"${state.sliceAreaList?.[0]?.sliceAreaCode || ''}"`
      }
    }
  }
  return saStrType === SaStringType.EQUAL ? `lsyarea=` : `"lsyarea":""`
}

/** 会员ID(传员工工号),2019.12.27 版本改成从 cookie 中取 custno */
export const saMemberid = (saStrType: SaStringType) => {
  const state = getGlobalUserConfig()

  if (state.personNo) {
    return saStrType === SaStringType.EQUAL
      ? `custno=${state.personNo}`
      : `"custno":"${state.personNo}"`
  }
  return saStrType === SaStringType.EQUAL ? `custno=` : `"custno":""`
}

export function getHeaderArea() {
  let areaTitle = ''
  const userState = getGlobalUserConfig()

  switch (userState.accountRole) {
    case EAccountRole.NATIONAL:
      areaTitle = '全国'
      break
    case EAccountRole.REGION:
    case EAccountRole.REGION_Manager:
      areaTitle = userState.regionName || ''
      break
    case EAccountRole.AREA:
      if (userState.sliceAreaList && userState.sliceAreaList.length > 0) {
        for (const v of userState.sliceAreaList) {
          if (v.sliceAreaCode === currentAreaCode.value) {
            areaTitle = v.sliceAreaName
            break
          }
        }
      } else {
        areaTitle = '无片区绑定'
      }
      break
  }
  return areaTitle
}

/** 获取Text 维度，取值登录人所属大区；若属于总部则传空 */
export const saRegionName = (saStrType: SaStringType) => {
  let area = getHeaderArea()

  if (area) {
    return saStrType === SaStringType.EQUAL ? `text=${area}` : `"text":""`
  }
  return saStrType === SaStringType.EQUAL ? `text=` : `"text":""`
}

/** 埋点所需要的用户信息 */
export const saRoleParam = () => {
  console.log(
    `${saRoleid(SaStringType.EQUAL)};${saLsyhead(
      SaStringType.EQUAL
    )};${saLsyregin(SaStringType.EQUAL)};${saLsyarea(
      SaStringType.EQUAL
    )};${saMemberid(SaStringType.EQUAL)};${saRegionName(SaStringType.EQUAL)}`
  )

  return `${saRoleid(SaStringType.EQUAL)};${saLsyhead(
    SaStringType.EQUAL
  )};${saLsyregin(SaStringType.EQUAL)};${saLsyarea(
    SaStringType.EQUAL
  )};${saMemberid(SaStringType.EQUAL)};${saRegionName(SaStringType.EQUAL)}`
}

export const saRoleParamClickStr = () => {
  console.log(
    `点击埋点：${saRoleid(SaStringType.EQUAL)},${saLsyhead(
      SaStringType.EQUAL
    )},${saLsyregin(SaStringType.EQUAL)},${saLsyarea(
      SaStringType.EQUAL
    )},${saMemberid(SaStringType.EQUAL)},${saRegionName(SaStringType.EQUAL)}`
  )

  return `${saRoleid(SaStringType.EQUAL)},${saLsyhead(
    SaStringType.EQUAL
  )},${saLsyregin(SaStringType.EQUAL)},${saLsyarea(
    SaStringType.EQUAL
  )},${saMemberid(SaStringType.EQUAL)},${saRegionName(SaStringType.EQUAL)}`
}
//这处
export const saRoleParamClickObj = () => {
  console.log(
    `{${saRoleid(SaStringType.QUOTE)},${saLsyhead(
      SaStringType.QUOTE
    )},${saLsyregin(SaStringType.QUOTE)},${saLsyarea(
      SaStringType.QUOTE
    )},${saMemberid(SaStringType.QUOTE)},${saRegionName(SaStringType.QUOTE)}}`
  )

  return JSON.parse(
    `{${saRoleid(SaStringType.QUOTE)},${saLsyhead(
      SaStringType.QUOTE
    )},${saLsyregin(SaStringType.QUOTE)},${saLsyarea(
      SaStringType.QUOTE
    )},${saMemberid(SaStringType.QUOTE)},${saRegionName(SaStringType.QUOTE)}}`
  )
}

/**
 * 修改页面标题
 * @param title
 */
export const updatePageTitle = (title: string) => {
  console.log(`[updatePageTitle] before "${document.title}"`)
  console.log(`[updatePageTitle] set to "${title}"`)
  if (title !== document.title) {
    document.title = title
  }
  console.log(`[updatePageTitle] after "${document.title}"`)
}

/**
 * 处理月份，接口参数中月份都是两位
 */
export function normalizeMonth(month: string | number) {
  if (month) {
    return typeof month === 'number'
      ? month.toString(10).padStart(2, '0')
      : month.padStart(2, '0')
  } else {
    return ''
  }
}

export const isSuccessCode = (code: string | number) =>
  ['000000', '0000', '1', 0].includes(code)
