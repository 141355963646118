import * as React from 'react'
import ReactEcharts from 'echarts-for-react'
import './index.less'

interface IData {
  value: string
  name: string
}

interface IProps {
  pieName?: string
  pieData: IData[]
  className?: string
  total?: string
  /** 单位 ***万 */
  unit?: string
}

export default class PieCharts extends React.Component<IProps, {}> {
  static defaultProps = {
    pieName: '',
    pieData: [],
    className: '',
  }

  render() {
    let { pieData, className } = this.props

    return (
      <div
        className={className}
        style={{
          width: '6rem',
          height: '4.4rem',
          position: 'relative',
          top: '-0.45rem',
        }}
      >
        {pieData.length > 0 ? (
          <ReactEcharts option={this._getEchartsOption2()} />
        ) : (
          <div className="cl-sr">
            <span className="sr-echart-no-data">暂无数据</span>
          </div>
        )}
      </div>
    )
  }

  _getEchartsOption2() {
    let { unit } = this.props
    unit = unit || ''
    return {
      title: {
        text: this.props.total,
        subtext: '门店考核任务',
        x: 'center',
        y: 'center',
        textStyle: {
          fontWeight: 'normal',
          fontSize: 20,
          color: '#333',
        }, // 标题
        subtextStyle: {
          fontWeight: 'normal',
          fontSize: 12,
          color: '#666',
        }, // 副标题
      },
      tooltip: {
        trigger: 'item',
        show: false,
        formatter: '{a} <br/>{b}: {c} ({d}%)',
      },

      // legend: {
      //   orient: 'vertical',
      //   left: 10,
      //   data: ['已完成', '未完成']
      // },
      color: ['#5ba8f0', '#f64f3f'],
      series: [
        {
          name: '',
          type: 'pie',
          // radius: ['30%', '45%'],
          animation: false,
          radius: ['36%', '51%'],
          label: {
            // formatter: '{c|{c}\n{b}}',
            // formatter: '{c|{c}}',
            // backgroundColor: '#eee',
            formatter: `{c}${unit}\n{b}`,

            borderRadius: 4,

            // position: 'outer',
            // alignTo: 'edge',
            // margin: '25%',
            align: 'right',
            rich: {
              a: {
                color: '#999',
                lineHeight: 22,
              },
              c: {
                lineHeight: 22,
                align: 'right',
                fontWeight: 'bolder',
              },
              hr: {
                borderColor: '#aaa',
                width: '100%',
                borderWidth: 0.5,
                height: 0,
              },
              b: {
                fontSize: 18,
                lineHeight: 33,
              },
              per: {
                color: '#eee',
                backgroundColor: '#334455',
                padding: [2, 4],
                borderRadius: 2,
              },
            },
          },
          labelLine: {
            // show: false,
            length: '5px',
            length2: '10px',
          },
          data: this.props.pieData,
        },
      ],
    }
  }
}
