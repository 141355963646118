/*
 * @Author: keqingrong (19040892)
 * @Date: 2019-07-26 17:59:13
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2019-12-04 14:29:04
 * @Description:
 */
import { EDeviceType } from '@/stores'
import { ISystemConfig } from './config'

export default class Config implements ISystemConfig {
  //TODO:测试地址
  HOST = 'https://lsypartenersit.cnsuning.com'
  // HOST = `http://127.0.0.1:8090/api`
  ROOT_PATH = []
  CURRENT_PATH = ''
  SERVICE_IMG_HOST = ''
  APP_BOSS_REPORT_HOST = '//srcfewsh5sit.cnsuning.com'
  // --------------------------------------------------------- >
  PASSPORT = {
    PASSPORT_LOGIN_HOST: '//passportsit.cnsuning.com/ids/',
    PASSPORT_LOGIN_THEME: 'lsy_skf',
    PASSPORT_DOMAIN_HOST: '//lsybosspcsit.cnsuning.com/'
  }
  UOM = {
    SITE_ID: '08CJJLR',
    RESOURCE_TYPE: '08CJJLR',
    BIZ_ID: 'lsypjg'
  }
  EXCEPTIONS_UPLOAD = {
    BIZ_ID: 'super-manager'
  }
  Current_Store_Type = ''
  USER_INFO = {
    uid: '',
    phone: '',
    companyName: '',
    mobileId: '',
    AppSource: '' as EDeviceType,
    isPhoneRoot: false,
    onVirtual: false,
    latitude: '32.094763',
    longitude: '118.899073',
    street: '',
    AddrStr: '江苏省南京市玄武区环园东路',
    city: '南京市',
    country: '中国',
    roleList: [],
    selectRole: {
      roleCode: '',
      roleName: '',
      dataDimension: '',
      roleStatus: ''
    },
    selectStoreType: '',
    duty: '',
    dutyDesc: '',
    position: '',
    sliceAreaList: [],
    status: '',
    regionName: '',
    regionCode: ''
  }
  AMAP_KEY = '709309ab6e53b4b179bb3802805ef411'
  AMAP_JS_CODE = 'eddc1bfc79530eeeebc79068a55fb1cf'
  saveSearchStore = {}
  mpAppid =
    'http%3a%2f%2fsnmpssit.cnsuning.com%2fsnmps-web%2fprogram%2fget_0b75ba783d514189b1dcf4e38766da08_%7bcurrVersion%7d_0_%7bclientVersion%7d.htm'
  // BAID_KEY = 'jIoFltaGc9MWRYAnLv9eXc2TEd0Q73Qy'
  MAP_TYPE = ''
}
