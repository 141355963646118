import jingyinshuju from '@assets/images/jingyinshuju.png'
import { Cleanup, toRaw } from '@/stores/util'
import { defaultCurrentItem, defaultSwitchList, ISwitchItem } from './types'
import { EAccountRole } from '@genre'
import { EMode } from '@/components/the-time-picker/TimePickerTypes'
import { getDefaultTimeStrByMode } from '@/components/the-time-picker/TimePickerUtil'
import { getMoneyStrByNumber } from '@utils'
import { currentAreaCode, getGlobalUserConfig, globalUserState } from '@/stores'
import { Icon } from 'antd-mobile'
import {
  IOperationalProductHeader,
  IOperationalRequestParam,
} from '@/views/operational-data/types'
import { parseJsonToQueryString } from '@/kits/util'
import { queryHomeBusinessDataInfo } from '@/views/home/HomeService'
import { Router, Sa, util } from '@kits'
import { subscribeKey } from 'valtio/utils'
import { SwitchNew } from '@components'
import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import './index.less'

interface IProps {
  titleName: string
}

let unWatchRole: Cleanup
let unWatchSliceArea: Cleanup

/** 门店绩效/采购业绩 */
export default function PurchasePerformance({ titleName }: IProps) {
  const [switchList, setSwitchList] = useState<ISwitchItem[]>(defaultSwitchList)
  const [currentItem, setCurrentItem] = useState(defaultCurrentItem)
  const [info, setInfo] = useState({} as IOperationalProductHeader)
  const userInfo = useSnapshot(globalUserState)

  if (!unWatchRole) {
    unWatchRole = subscribeKey(globalUserState, 'selectRole', () => {
      // 用于首页角色切换后刷新状态
      setCurrentItem(defaultCurrentItem)
      switchQueryBusinessDataInfo()
    })
  }

  if (!unWatchSliceArea) {
    unWatchSliceArea = subscribeKey(currentAreaCode, 'value', () => {
      // 用于首页片区切换后刷新状态
      setCurrentItem(defaultCurrentItem)
      switchQueryBusinessDataInfo()
    })
  }

  useEffect(() => {
    switchQueryBusinessDataInfo()

    return () => {
      if (unWatchRole) {
        unWatchRole()
        unWatchRole = null
      }
      if (unWatchSliceArea) {
        unWatchSliceArea()
        unWatchSliceArea = null
      }
    }
  }, [])

  /** 切换调用接口 总部大区片区维度 */
  async function switchQueryBusinessDataInfo(value = EMode.Day) {
    let params = {} as IOperationalRequestParam
    const userState = getGlobalUserConfig()

    switch (userState.accountRole) {
      case EAccountRole.NATIONAL:
        params = {
          isSingleProduct: 'N',
          codeType: '1',
          code: '',
          timeQueryCycle: value,
          date: getDefaultTimeStrByMode(value).replace(/-/g, ''),
        }
        break
      case EAccountRole.REGION:
      case EAccountRole.REGION_Manager:
        params = {
          isSingleProduct: 'N',
          codeType: '2',
          code: userState.regionCode,
          timeQueryCycle: value,
          date: getDefaultTimeStrByMode(value).replace(/-/g, ''),
        }
        break
      case EAccountRole.AREA:
        params = {
          isSingleProduct: 'N',
          codeType: '3',
          code: currentAreaCode.value || userState.sliceArea?.sliceAreaCode,
          timeQueryCycle: value,
          date: getDefaultTimeStrByMode(value).replace(/-/g, ''),
        }
        break
    }

    if (params.codeType) {
      const { productHeader = {} } = await queryHomeBusinessDataInfo(params)
      setInfo(() => productHeader)
    }
  }

  /**处理单位 */
  function getMoneyStrByNumberFunction(numStr = '') {
    if (!numStr) {
      return <span className="num">-</span>
    }
    const [moneyStr = '', typeStr = ''] = getMoneyStrByNumber(numStr)

    return (
      <>
        <span className="num">{moneyStr}</span>
        {typeStr && <span className="numName">{typeStr}</span>}
      </>
    )
  }

  /** 年， 季度切换 */
  async function swithChange(item: ISwitchItem) {
    const newSwitchList = toRaw<ISwitchItem[]>(switchList)
    newSwitchList.forEach((v) => {
      if (v.value === item.value) {
        v.checked = true
        setCurrentItem(() => item)
      } else {
        v.checked = false
      }
    })
    setSwitchList(() => newSwitchList)
    switchQueryBusinessDataInfo(item.value as EMode)
  }

  /** 经营数据更多 */
  function operatingDataClick() {
    const userState = getGlobalUserConfig()

    Sa.sendClickData('mdjx', {
      eleid: '1',
      eletitle: `查看详情`,
      ...util.saRoleParamClickObj(),
    })

    switch (userState.accountRole) {
      case EAccountRole.NATIONAL:
        const nationalQuery = {
          codeType: '1',
          queriesDimCodes: '1',
          timeQueryCycle: currentItem.value, // 动态值
        } as IOperationalRequestParam
        Router.Push(
          `/operational-data/nation?${parseJsonToQueryString(nationalQuery)}`
        )
        break
      case EAccountRole.REGION:
      case EAccountRole.REGION_Manager:
        const regionQuery = {
          name: userState.regionName,
          code: userState.regionCode,
          codeType: '2',
          queriesDimCodes: '2',
          timeQueryCycle: currentItem.value, // 动态值
        } as IOperationalRequestParam
        Router.Push(
          `/operational-data/region?${parseJsonToQueryString(regionQuery)}`
        )
        break
      case EAccountRole.AREA:
        const areaQuery = {
          name: userState.sliceArea?.sliceAreaName,
          code: userState.sliceArea?.sliceAreaCode,
          codeType: '3',
          queriesDimCodes: '3',
          timeQueryCycle: currentItem.value, // 动态值
        } as IOperationalRequestParam
        Router.Push(
          `/operational-data/area?${parseJsonToQueryString(areaQuery)}`
        )
        break
    }
  }

  /**
   * 是否显示【本月业绩评分】和【采购业绩】模块
   * 未分配片区的片区负责人，不展示。
   */
  function canShowPerfModule() {
    let enable = false
    switch (userInfo.accountRole) {
      case EAccountRole.NATIONAL:
      case EAccountRole.REGION:
      case EAccountRole.REGION_Manager:
        enable = true
        break
      case EAccountRole.AREA:
        enable = userInfo?.sliceAreaList && userInfo.sliceAreaList.length > 0
        break
      default:
        enable = false
        break
    }
    return enable
  }

  return (
    <div className="shop-purchase-performance">
      <div className="operating-data">
        <div className="top">
          <div className="top-left">
            <img src={jingyinshuju} className="left-image" />
            <span className="left-title">{titleName}</span>
          </div>
          {/* {canShowPerfModule() && (
            <div className="top-right" onClick={operatingDataClick}>
              <span className="right-title">查看详情</span>
              <Icon type="right" size="xs" color="#4A90E2" />
            </div>
          )} */}
        </div>
        <div className="main-wrap">
          <div
            className="switch-outBox"
            style={{ width: `${1.2 * switchList.length}rem` }}
          >
            <SwitchNew
              switchList={switchList}
              currentItem={currentItem}
              onChange={swithChange}
            />
          </div>
          <div className="operating-data-content">
            <div className="operating-top">
              <div className="operating-section">
                <div className="operating-section-title">
                  {getMoneyStrByNumberFunction(info.cPrimeCostSum)}
                </div>
                <div className="operating-section-name">C端成本额</div>
              </div>
              <div className="operating-section">
                <div className="operating-section-title">
                  {getMoneyStrByNumberFunction(info.bDeliveryAmount)}
                </div>
                <div className="operating-section-name">B端提货额</div>
              </div>
            </div>
            <div className="operating-bottom">
              <div className="operating-section">
                <div className="operating-section-title">
                  {getMoneyStrByNumberFunction(info.jDeliveryAmount)}
                </div>
                <div className="operating-section-name">J提货额</div>
              </div>
              <div className="operating-section">
                <div className="operating-section-title">
                  {getMoneyStrByNumberFunction(info.sDeliveryAmount)}
                </div>
                <div className="operating-section-name">S提货额</div>
              </div>
              <div className="operating-section">
                <div className="operating-section-title">
                  {getMoneyStrByNumberFunction(info.aDeliveryAmount)}
                </div>
                <div className="operating-section-name">A提货额</div>
              </div>
              <div className="operating-section">
                <div className="operating-section-title">
                  {getMoneyStrByNumberFunction(info.vDeliveryAmount)}
                </div>
                <div className="operating-section-name">V提货额</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
