/*
 * @Author: your name
 * @Date: 2020-07-28 14:53:58
 * @LastEditTime: 2020-08-25 16:29:06
 * @LastEditors: Please set LastEditors
 * @Description: 头部提示组件
 * 
 */
import React, { Component } from 'react'
import styles from './WarnTitle.module.less'
import WarningImg from '@assets/images/warning@3x.png'

interface IIProps {
  /**传入文字 */
  title: string
  /**容器背景色 */
  background?:string
  /**文字颜色 */
  color?:string
}

export default class WarnTitle extends Component<IIProps> {
  static defaultProps={
    background:'#FFF1E9',
    color:'#FF7B2B'
  }
  constructor(param) {
    super(param)
  }

  render() {
    const {background,title,color} =this.props;
    return (
      <div className={styles['warn-title-box']} style={{backgroundColor:`${background}`}}>
        <img src={WarningImg} alt="" className={styles['warning-img']}/>
        <div className={styles['warn-title']} style={{color:`${color}`}}>{title}</div>
      </div>
    )
  }
}
