import qs from 'qs'
import { openLocationMsgModal } from '../../utils'
import { Divider } from '@components'
import { EAccountRole, ECateFuntion, ESearchType } from '@genre'
import { getProvinceName } from '../../../../kits/douya'
import { getGlobalUserConfig, IMenuItem } from '@/stores'
import { Douya, Router, Sa, util } from '@kits'

interface IProps {
  /** 区块名称 */
  title: string
  /** 菜单items */
  cellList: Array<IMenuItem>
}

export default function Block({ title = '', cellList = [] }: IProps) {
  function cateClick(type: ECateFuntion) {
    let route = ''
    const userState = getGlobalUserConfig()

    switch (type) {
      case ECateFuntion.INSECTION_CARD:
        // 点击埋点
        Sa.sendClickData('08kvmmq', {
          eleid: '1',
          eletitle: `巡店打卡`,
          ...util.saRoleParamClickObj(),
        })

        route = `/more-store-list/store-list?${qs.stringify({
          accountRole: userState.accountRole,
          longitude: userState.longitude,
          latitude: userState.latitude,
          provName: getProvinceName(userState),
          userSelectCode: userState.sliceArea?.sliceAreaCode,
          cityName: userState.city,
          regionCode: userState.regionCode,
          sliceAreaCode: userState.sliceArea?.sliceAreaCode,
        })}`

        // 只在豆芽环境进行定位判断，浏览器直接放行
        if (Douya.isDouya()) {
          if (!userState?.latitude) {
            openLocationMsgModal()
            return
          }
        }
        break

      case ECateFuntion.INSRCTION_INFO:
        // 点击埋点
        Sa.sendClickData('08kvmmq', {
          eleid: '2',
          eletitle: `巡店明细`,
          ...util.saRoleParamClickObj(),
        })
        if (userState.accountRole === EAccountRole.AREA) {
          // route = `/inspection/region-info?type=${ESearchType.AREA_INSPECTION_INFO}&regionCode=${userState.regionCode}&sliceAreaCode=${userState.sliceArea?.sliceAreaCode}`
          route = `/wait-to-do/inspection-detail-list`
        } else if (
          userState.accountRole === EAccountRole.REGION ||
          userState.accountRole === EAccountRole.REGION_Manager
        ) {
          // route = `/inspection/region-info?type=${ESearchType.REGION_INSECTION_INFO}&regionCode=${userState.regionCode}&regionName=${userState.regionName}`
          route = `/wait-to-do/inspection-detail-list`
        } else {
          // route = `/inspection/region-info?type=${ESearchType.NATIONAL_INSECTION_INFO}&regionCode=${userState.regionCode}&regionName=${userState.regionName}`
          route = `/wait-to-do/inspection-detail-list`
        }
        break
      case ECateFuntion.TASK_DETAIL:
        route = `/store-task-info/task-list`
        break
      case ECateFuntion.EMPLOYEE_MANAGER:
        // 点击埋点
        Sa.sendClickData('08kvmmq', {
          eleid: '4',
          eletitle: `员工管理`,
          ...util.saRoleParamClickObj(),
        })
        route = `/employee/list?type=${ESearchType.EMPLOYEE}`
        break
      case ECateFuntion.AERA_MANAGER:
        // 点击埋点
        Sa.sendClickData('08kvmmq', {
          eleid: '5',
          eletitle: `片区管理`,
          ...util.saRoleParamClickObj(),
        })
        route = `/area/manager?regionCode=${userState.regionCode}`
        break
      case ECateFuntion.STORE_MANAGER:
        // 点击埋点
        Sa.sendClickData('08kvmmq', {
          eleid: '3',
          eletitle: `门店管理`,
          ...util.saRoleParamClickObj(),
        })
        if (userState.accountRole === EAccountRole.NATIONAL) {
          route = `/store-info/region-list?${qs.stringify({
            type: ESearchType.NATIONAL_STORE_MANAGER,
          })}`
        } else if (
          userState.accountRole === EAccountRole.REGION ||
          userState.accountRole === EAccountRole.REGION_Manager
        ) {
          route = `/store-info/subregion-list?${qs.stringify({
            type: ESearchType.REGIN_STORE_MANAGER,
            regionCode: userState.regionCode,
            regionName: userState.regionName,
          })}`
        } else if (userState.accountRole === EAccountRole.AREA) {
          route = `/store-info/subregion-list?${qs.stringify({
            type: ESearchType.AERA_STORE_MANAGER,
            regionCode: userState.regionCode,
            regionName: userState.regionName,
            sliceAreaCode: userState.sliceArea?.sliceAreaCode,
            sliceAreaName: userState.sliceArea?.sliceAreaName,
          })}`
        } else {
          console.error('未识别的角色')
        }
        break
      case ECateFuntion.REMOTE_MONITOR:
        if (userState.accountRole === EAccountRole.NATIONAL) {
          // 总部角色，进入大区列表
          route = '/remote-monitor/region-list'
        } else if (
          userState.accountRole === EAccountRole.REGION ||
          userState.accountRole === EAccountRole.REGION_Manager
        ) {
          // 大区角色，进入片区列表
          route = `/remote-monitor/area-list?${qs.stringify({
            regionCode: userState.regionCode,
            regionName: userState.regionName,
          })}`
        } else if (userState.accountRole === EAccountRole.AREA) {
          // 片区角色，进入店铺列表
          route = `/remote-monitor/store-list?${qs.stringify({
            regionCode: userState.regionCode,
            regionName: userState.regionName,
            sliceAreaCode: userState.sliceArea?.sliceAreaCode,
            sliceAreaName: userState.sliceArea?.sliceAreaName,
          })}`
        }
        break

      case ECateFuntion.INSECTION_REPORT:
        if (userState.accountRole === EAccountRole.NATIONAL) {
          // 总部角色，进入大区列表
          route = '/inspection-report/nation-inspection-report'
        } else if (
          userState.accountRole === EAccountRole.REGION ||
          userState.accountRole === EAccountRole.REGION_Manager
        ) {
          // 大区角色，进入片区列表
          route = `/inspection-report/region-inspection-report?${qs.stringify({
            timeCycle:
              util.getTimeInfo().adjustYear + util.getTimeInfo().adjustMonth,
            regionCode: userState.regionCode,
            regionName: userState.regionName,
          })}`
        } else if (userState.accountRole === EAccountRole.AREA) {
          // 片区角色，进入店铺列表
          route = `/inspection-report/area-inspection-report?${qs.stringify({
            timeCycle:
              util.getTimeInfo().adjustYear + util.getTimeInfo().adjustMonth,
            regionCode: userState.regionCode,
            regionName: userState.regionName,
            sliceCode: userState.sliceArea?.sliceAreaCode,
            sliceName: userState.sliceArea?.sliceAreaName,
          })}`
        }
        break
      case ECateFuntion.STORE_PREPARATION:
        route = `/view-preparation/store-preparation`
        break
      case ECateFuntion.STORE_PREPARATION_DETAIL:
        route = `/view-preparation/all-preparation-order`
        break
      case ECateFuntion.STORE_REOPEN_APPLY:
        route = `/store-reopen-apply`
    }
    Router.Push(route)
  }

  return (
    <div className="work-bench-item">
      <div className="title">{title}</div>
      <Divider width="95%" left={0.2} right={0.2} />
      <div className="cell-list">
        {cellList.map((cell, index) => {
          return (
            <div
              className="cell-item"
              key={index}
              onClick={() => {
                cateClick(cell.menuCode as ECateFuntion)
              }}
            >
              <img className="icon" src={cell.imgUrl} />
              <div className="name">{cell.menuName}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
