import honghuangpai from '@assets/images/honghuangpai.png'
import { Cleanup } from '@/stores/util'
import { EAccountRole, ESearchType } from '@genre'
import { getRegionInfo } from '@/views/home/utils'
import { currentAreaCode, getGlobalUserConfig, globalUserState } from '@/stores'
import { IBaseAllWarningInfoRes } from '@/views/home/types'
import { Icon, Toast } from 'antd-mobile'
import { queryBaseAllWarningInfo } from '@/views/home/HomeService'
import { Router, Sa, util } from '@kits'
import { subscribeKey } from 'valtio/utils'
import { useEffect, useState } from 'react'
import './index.less'

let unWatchRole: Cleanup
let unWatchSliceArea: Cleanup

function getRegionQuery(slice = true) {
  const userState = getGlobalUserConfig()

  return slice
    ? `&regionCode=${userState?.regionCode || ''}&sliceAreaCode=${
        userState?.sliceArea?.sliceAreaCode || ''
      }&sliceAreaName=${userState?.sliceArea?.sliceAreaName || ''}`
    : `&regionCode=${userState?.regionCode || ''}&regionName=${
        userState?.regionName || ''
      }`
}

/** 大区维度?? */
function isRegionManager() {
  const userState = getGlobalUserConfig()

  return (
    userState.accountRole === EAccountRole.REGION ||
    userState.accountRole === EAccountRole.REGION_Manager
  )
}

interface IProps {
  titleName: string
}

/** 红黄牌预警 */
export default function RedYellowCard({ titleName }: IProps) {
  const [data, setData] = useState({} as IBaseAllWarningInfoRes)

  if (!unWatchRole) {
    unWatchRole = subscribeKey(globalUserState, 'selectRole', () => {
      // 用于首页角色切换后刷新状态
      fetchData()
    })
  }

  if (!unWatchSliceArea) {
    unWatchSliceArea = subscribeKey(currentAreaCode, 'value', () => {
      // 用于首页片区切换后刷新状态
      fetchData()
    })
  }

  async function fetchData() {
    const userState = getGlobalUserConfig()

    if (userState.originalInfo && userState.originalInfo.personNo) {
      const { params } = getRegionInfo(userState.originalInfo)
      const res = await queryBaseAllWarningInfo(params)
      setData(() => res)
    }
  }

  /** 全国维度跳转红黄牌警告 */
  function nationalWarningClick() {
    const userState = getGlobalUserConfig()

    // 拓展大区和片区的跳转逻辑
    if (userState.accountRole === EAccountRole.NATIONAL) {
      /** 全国维度跳转红黄牌警告 */
      Sa.sendClickData('2nj4tv2', {
        eleid: '1',
        eletitle: `红黄牌大区详情`,
        ...util.saRoleParamClickObj(),
      })
      Router.Push('/national/warn-list')
    } else if (isRegionManager()) {
      Router.Push(
        `/region/list?type=${ESearchType.REGION_MORE}${getRegionQuery(false)}`
      )
    } else if (userState.accountRole === EAccountRole.AREA) {
      /** 片区维度 */
      Router.Push(
        `/area/area-home-more-list?type=${
          ESearchType.HOME_AREA_ALL_WARING
        }${getRegionQuery()}`
      )
    } else {
      console.error('未识别的角色')
    }
  }

  /** 大区，片区的红牌门店 */
  function redWarning() {
    if (!data.redNum) {
      Toast.info('恭喜您，暂无红牌预警门店！')
      return
    }
    Sa.sendClickData('04knnp4', {
      eleid: '3',
      eletitle: `红牌查看详情`,
      ...util.saRoleParamClickObj(),
    })

    const userState = getGlobalUserConfig()
    if (isRegionManager()) {
      Router.Push(
        `/region/list?type=${ESearchType.REGION_RED_WARING}${getRegionQuery(
          false
        )}`
      )
    } else if (userState.accountRole === EAccountRole.AREA) {
      Router.Push(
        `/area/list?type=${ESearchType.AREA_RED_WARING}${getRegionQuery()}`
      )
    }
  }

  /** 大区，片区的黄牌门店 */
  function yellowWarning() {
    Sa.sendClickData('04knnp4', {
      eleid: '4',
      eletitle: `黄牌查看详情`,
      ...util.saRoleParamClickObj(),
    })
    if (!data.yellowNum) {
      Toast.info('恭喜您，暂无黄牌预警门店！')
      return
    }

    const userState = getGlobalUserConfig()
    if (isRegionManager()) {
      Router.Push(
        `/region/list?type=${ESearchType.REGION_YELLOW_WARING}${getRegionQuery(
          false
        )}`
      )
    } else if (userState.accountRole === EAccountRole.AREA) {
      Router.Push(
        `/area/list?type=${ESearchType.AREA_YELLOW_WARING}${getRegionQuery()}`
      )
    }
  }

  useEffect(() => {
    fetchData()

    return () => {
      if (unWatchRole) {
        unWatchRole()
        unWatchRole = null
      }
      if (unWatchSliceArea) {
        unWatchSliceArea()
        unWatchSliceArea = null
      }
    }
  }, [])

  return (
    <div className="shop-purchase-performance">
      <div className="operating-data waring-store">
        <div className="top">
          <div className="top-left">
            <img src={honghuangpai} className="left-image" />
            <span className="left-title">
              红黄牌预警<span className="left-title-small">(当季)</span>
            </span>
          </div>
          {/* <div className="top-right" onClick={nationalWarningClick}>
            <span className="right-title">查看详情</span>
            <Icon type="right" size="xs" color="#4A90E2" />
          </div> */}
        </div>
        <div className="warning-data-box">
          <div className="warning-section" onClick={redWarning}>
            <div className="num">{data?.redNum}</div>
            <div className="name">红牌预警门店数</div>
          </div>
          <div className="warning-section" onClick={yellowWarning}>
            <div className="num">{data?.yellowNum}</div>
            <div className="name">黄牌预警门店数</div>
          </div>
          <div className="warning-section" onClick={redWarning}>
            <div className="num">{data?.redProportion}</div>
            <div className="name">红牌预警门店占比</div>
          </div>
          <div className="warning-section" onClick={yellowWarning}>
            <div className="num">{data?.yellowProportion}</div>
            <div className="name">黄牌预警门店占比</div>
          </div>
        </div>
      </div>
    </div>
  )
}
