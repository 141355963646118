/*
 * @Author: 苏鑫
 * @Date: 2022-09-09 9:40:43
 * @Last Modified by: 12072513
 * @Last Modified time: 2023-07-14 11:08:58
 */

import './index.less'

import NoDataImg from '@assets/images/no-store-data.png'

export const NoData = (prop: ModalParams) => {
  const { text = '暂无相关结果', onRefresh, isShowButton = false } = prop

  return (
    <div className="todo-item-data-wrap">
      <div className="img-wrap">
        <img src={NoDataImg} />
      </div>
      <div className="error-text">{text}</div>
      {isShowButton && (
        <div
          className="refresh-button"
          onClick={() => {
            onRefresh()
          }}
        >
          重新加载
        </div>
      )}
    </div>
  )
}

export type ModalParams = {
  text?: string
  onRefresh?: () => void
  isShowButton?: boolean
}
