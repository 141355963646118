/* eslint-disable snrcfe/with-file-header */
/** [年] */
export type ByYear = [/** 年 */ number]

/** [年, 季度] */
export type ByQuarter = [/** 年 */ number, /** 季度 */ number]

/** [年, 月] */
export type ByMonth = [/** 年 */ number, /** 月 */ number]

/** [年, 月, 日] */
export type ByDay = [/** 年 */ number, /** 月 */ number, /** 日 */ number]

/** [年, 月, 日] */
export type PickerEmptyValue = []

export type ValueTypes = ByYear | ByQuarter | ByMonth | ByDay | PickerEmptyValue

export type PickerValue<
  C extends EMode,
  V extends ValueTypes
> = V extends PickerEmptyValue
  ? PickerEmptyValue
  : C extends EMode.Year
  ? ByYear
  : C extends EMode.Quarter
  ? ByQuarter
  : C extends EMode.Month
  ? ByMonth
  : C extends EMode.Day
  ? ByDay
  : PickerEmptyValue

export interface IDedaultDateByMode {
  /** mode is typeof EMode */
  [mode: string]: number[]
}

/**
 * time picker props
 *
 * @description 只有属性[defaultValue]支持异步监听
 */
export interface ITheTimePickerProps<
  CheckedMode extends EMode,
  DefaultValue extends ValueTypes
> {
  /**
   * 模式（非必传，该属性使用请参见 props -> checkedMode的remarks说明） [{@link EMode}]
   *
   * @default
   *
   * ```jsx
   * []
   * ```
   */
  modes?: EMode[]
  /**
   * 默认选中的模式 {@link EMode}
   *
   * @require
   *
   * @remarks
   *
   * ```jsx
   * // 1. 单一场景选择模式
   *
   * // a1 ✅
   * const [pickerValue, setPickerValue] = useState<PickerEmptyValue | ByMonth>([]);
   *
   * <TheTimePicker
   *   checkedMode={EMode.Month}
   *   defaultValue={[2021, 5]}
   *   onConfirm={(values, mode) => {
   *     console.log(values, mode);
   *   }}
   * />
   *
   * // or
   *
   * <TheTimePicker
   *   checkedMode={EMode.Month}
   *   defaultValue={pickerValue}
   *   onConfirm={(values, mode) => {
   *     console.log(values, mode);
   *   }}
   * />
   *
   * // TIPS: 使用单一场景选择模式时，请不要同时 modes={[EMode.XXX]}
   * // 此时，组件以checkedMode为准，效果同a1
   *
   * // a2 ❌
   * <TheTimePicker
   *   modes={[EMode.Year]}
   *   checkedMode={EMode.Month}
   *   defaultValue={[2021, 5]}
   *   onConfirm={(values, mode) => {
   *     console.log(values, mode);
   *   }}
   * />
   *
   * ```
   *
   * @remarks
   *
   * ```tsx
   * // 多场景选择模式 (例如：支持“按年 & 按月”，且默认选中的模式为按月)
   *
   * const [pickerValue, setPickerValue] = useState<PickerEmptyValue | ByMonth>([]);
   *
   * <TheTimePicker
   *   modes={[EMode.Year, EMode.Month]}
   *   checkedMode={EMode.Month}
   *   defaultValue={[2021, 5]}
   *   onConfirm={(values, mode) => {
   *     console.log(values, mode);
   *   }}
   * />
   *
   * // or
   *
   * <TheTimePicker
   *   modes={[EMode.Year, EMode.Month]}
   *   checkedMode={EMode.Month}
   *   defaultValue={pickerValue}
   *   onConfirm={(values, mode) => {
   *     console.log(values, mode);
   *   }}
   * />
   * ```
   */
  checkedMode: CheckedMode
  /**
   * 默认值
   * @type DV {@link ValueTypes}
   *
   * @default undefined
   */
  defaultValue?: PickerValue<CheckedMode, DefaultValue>
  /**
   * 用于控制选项的开始时间点 {@link IDedaultDateByMode}
   *
   * @remarks
   *
   * 如果传入的日期大于当前日期，则参数自动失效，并采用默认值
   *
   * @default
   *
   * ```jsx
   * // 组件默认值
   * {
   *   [EMode.Year]: [2017], // 2017年
   *   [EMode.Quarter]: [2017, 1], // 2017年第一季度
   *   [EMode.Month]: [2019, 8], // 2019年8月
   *   [EMode.Day]: [2019, 8, 1], // 2019年8月1日
   * }
   *
   * // 组件引用时候，传入自定义配置（会覆盖默认值）
   * <TheTimePicker
   *   ...
   *   optionsStartDate={{
   *     [EMode.Month]: [2018, 2],
   *   }}
   * />
   * ```
   */
  optionsStartDate?: IDedaultDateByMode
  /**
   * 用于控制选项的结束时间点 (不支持大于当前时间) {@link IDedaultDateByMode}
   *
   * @default
   *
   * ```jsx
   * // 组件默认值
   * {
   *   [EMode.Year]: [new Date().getFullYear()],
   *   [EMode.Quarter]: [new Date().getFullYear(), 当前月所处季度],
   *   [EMode.Month]: [new Date().getFullYear(), new Date().getMonth() + 1],
   *   [EMode.Day]: [new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()],
   * }
   *
   * // 组件引用时候，传入自定义配置（会覆盖默认值）
   * // 同 optionsStartDate
   * ```
   */
  optionsEndDate?: IDedaultDateByMode
  /**
   * 是否半圆角样式?
   *
   * @default false
   */
  radius?: boolean
  /**
   * 确定回调函数
   *
   * @param values 选择的值 extends {@link ValueTypes}
   * @param mode 选择的模式 extends {@link EMode}
   * @param label 格式化文本 string
   */
  onConfirm?: (
    values: PickerValue<CheckedMode, DefaultValue>,
    mode: EMode,
    label: string
  ) => void
  /**
   * 每月1，2号转成上一月 (特殊情况：1月1，2号 转成上一年12月)
   *
   * @default false
   */
  monthConvert?: boolean
  /**
   * 季度首月1，2号转成上一月 (特殊情况：1月1，2号 转成上一年12月)
   *
   * @default false
   */
  quarterConvert?: boolean
  /**
   * 模式发生变化时，自动调整picker列数
   *
   * @default false
   */
  autoAdjustCols?: boolean
  /**
   * picker只读，不可点击
   *
   * @default false
   */
  readonly?: boolean
}

/**
 * time picker 模式（可配置多场景）
 *
 * @param Year      按年 ->     显示列 "XXXX年"
 * @param Quarter   按季度 ->   显示列 "XXXX年" & "X季度"
 * @param Month     按月 ->     显示列 "XXXX年" & "XX月"
 * @param Day       按日 ->     显示列 "XXXX年" & "XX月" & "XX日"
 */
export enum EMode {
  /**
   * 按年 -> 显示列 "XXXX年"
   */
  Year = '4',
  /**
   * 按季度 ->  显示列 "XXXX年" & "X季度"
   */
  Quarter = '3',
  /**
   * 按月 -> 显示列 "XXXX年" & "XX月"
   */
  Month = '2',
  /**
   * 按日 ->  显示列 "XXXX年" & "XX月" & "XX日"
   */
  Day = '1'
}

export const allModes = [EMode.Year, EMode.Quarter, EMode.Month, EMode.Day]

export const modeDescs = {
  [EMode.Year]: '按年',
  [EMode.Quarter]: '按季度',
  [EMode.Month]: '按月',
  [EMode.Day]: '按日'
}

export const modeClassName = {
  [EMode.Year]: 'm-year',
  [EMode.Quarter]: 'm-quarter',
  [EMode.Month]: 'm-month',
  [EMode.Day]: 'm-day'
}

/**
 * 选择器选项
 */
export interface ITimePickerOption {
  /** 展示文案 */
  label: string | JSX.Element
  /** 每个选择项对应的值 */
  value: number | EMode
  /** 下一级选项 */
  children?: ITimePickerOption[]
}
