/*
 * @Author: angrycans
 * @Date: 2018-10-23 19:52:03
 * @Last Modified by: william_tang
 * @Last Modified time: 2019-11-14 14:58:38
 */
import mitt from 'mitt'

const Msg: mitt.Emitter = mitt()

export default Msg
