import RoutePaths from '@/route-paths'
import ShopNoData from '../shop-no-date'
import systemPicNoData from '@assets/images/weikaitong@3x2.png'
import { Badge, Tabs } from 'antd-mobile'
import { EAccountRole } from '@genre'
import { globalUserState } from '@/stores'
import { INociceDetailProps } from '@/route-param-types'
import { INoticeItem, INoticeTab } from '@/views/home/types'
import {
  queryNotices,
  queryNoticeTabs,
  updateTabNoticeCount,
} from '@/views/home/HomeService'
import { Router } from '@kits'
import { NoDataPage, ScrollableContainer } from '@components'
import pageLoading from '@assets/images/loading.svg'
import { useSnapshot } from 'valtio'
import { useEffect, useMemo, useRef, useState } from 'react'
import './NoticePage.less'

/** 公告通知页 */
export default function NoticePage({
  onUpdateSysUnread,
}: {
  onUpdateSysUnread: () => Promise<void>
}) {
  const pageNum = useRef(1)
  const [loading, setLoading] = useState(false)
  const [totalPageCount, setTotalPageCount] = useState(0)
  const [activeTabId, setActiveTabId] = useState('')
  const [tabs, setTabs] = useState<INoticeTab[]>([])
  const [data, setData] = useState<INoticeItem[]>([])
  const userInfo = useSnapshot(globalUserState)

  const tabData = useMemo(
    () =>
      tabs.map((item) => ({
        title: <Badge dot={item.unReadNum > 0}>{item.noticeTypeName}</Badge>,
        typeId: item.noticeTypeId,
      })),
    [tabs]
  )

  async function fetchTabs() {
    const items = await queryNoticeTabs()
    if (items.length > 0) setActiveTabId(items[0].noticeTypeId)
    setTabs(items)
  }

  async function fetchNotices(isFirst = true, tabId?: string) {
    if (isFirst) setLoading(true)
    const { totalPageCount: count, dataList } = await queryNotices({
      pageNum: pageNum.current,
      noticeTypeId: tabId || activeTabId,
    })
    setTotalPageCount(count)
    setData((prev) => (isFirst ? [...dataList] : [...prev, ...dataList]))
    setLoading(false)
  }

  async function onReachBottom() {
    if (
      data.length > 0 &&
      totalPageCount > 1 &&
      pageNum.current < totalPageCount
    ) {
      pageNum.current += 1
      fetchNotices(false)
    }
  }

  function goToNoticeDetail(noticeId: string) {
    setData((prev) => {
      const items = [...prev]
      items.forEach((item) => {
        if (item.noticeId === noticeId) {
          item.readFlag = '1'
        }
      })
      return items
    })
    updateTabNoticeCount()

    Router.Open<INociceDetailProps>(RoutePaths.NOTICE_DETAIL, {
      noticeId,
      noticeTypeId: activeTabId,
    })
  }

  useEffect(() => {
    fetchTabs()
    fetchNotices(true)
  }, [])

  return !tabs.length ? null : (
    <div className="message-tab-view">
      <Tabs
        tabs={tabData}
        initialPage={0}
        onTabClick={(tab) => {
          pageNum.current = 1
          setActiveTabId(tab.typeId)
          fetchNotices(true, tab.typeId)
          updateTabNoticeCount()
          onUpdateSysUnread()
        }}
        swipeable={false}
        tabBarActiveTextColor="#3377FF"
        tabBarInactiveTextColor="#999"
        tabBarUnderlineStyle={{
          transform: `scale(${(40 / 750) * 5}, 1)`,
          marginBottom: '0.05rem',
        }}
      >
        <ScrollableContainer
          style={{
            height:
              userInfo?.accountRole !== EAccountRole.AREA
                ? 'calc(100vh - 1.88rem)'
                : 'calc(100vh - 2.68rem)',
          }}
          onReachBottom={onReachBottom}
        >
          {loading && (
            <NoDataPage
              textTag="玩命加载中，请稍后…"
              loadingImg={pageLoading}
            />
          )}
          {data.map((item) => (
            <div
              key={item.noticeId}
              className="tool"
              onClick={() => goToNoticeDetail(item.noticeId)}
            >
              <span
                className={
                  item.readFlag === '0' ? 'tool-dotActive' : 'tool-dot'
                }
              />
              <div className="essay">
                <span className="text">{item.noticeTitle}</span>
                <span className="stext">{item.releaseStartDate}</span>
              </div>
            </div>
          ))}
          {!loading && !data.length && (
            <ShopNoData textTag="暂无通知公告" imgUrl={systemPicNoData} />
          )}
        </ScrollableContainer>
      </Tabs>
    </div>
  )
}
