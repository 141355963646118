/*
 * @Author: 柯庆荣19040892
 * @Email: 19040892@cnsuning.com
 * @Date: 2019-10-31 15:56:59
 * @Last Modified by: 刘嘉晨(17031607)
 * @Last Modified time: 2023-04-Tu 05:06:35
 * @Description: Description
 */
export enum ESearchType {
  /** 全国维度 门店采购业绩业绩 */
  NATIONAL_PERFORMANCE = 'NATIONAL_PERFORMANCE',
  /** 全国维度 红黄牌警告 */
  NATIONAL_WARNING = 'NATIONAL_WARNING',
  /** 大区维度 门店采购业绩业绩 */
  REGION_PERFORMANCE = 'REGION_PERFORMANCE',
  /** 大区维度  查看全部红黄牌 */
  REGION_MORE = 'regionMore',
  /** 大区维度 红牌警告 */
  REGION_RED_WARING = 'REGION_RED_WARING',
  /** 大区维度 黄牌警告 */
  REGION_YELLOW_WARING = 'REGION_YELLOW_WARING',
  /** 片区维度 门店采购业绩业绩 */
  AREA_PERFORMANCE = 'AREA_PERFORMANCE',
  /** 片区维度 红牌警告 */
  AREA_RED_WARING = 'AREA_RED_WARING',

  /**首页红黄牌片区更多 */
  HOME_AREA_ALL_WARING = 'HOME_AREA_ALL_WARING',
  /**首页红黄牌片区只红牌 */
  HOME_AREA_RED_WARING = 'HOME_AREA_RED_WARING',
  /**首页红黄牌片区只黄牌 */
  HOME_AREA_YELLOW_WARING = 'HOME_AREA_YELLOW_WARING',
  /** 大区维度 黄牌警告 */
  AREA_YELLOW_WARING = 'AREA_YELLOW_WARING',
  /** 员工 */
  EMPLOYEE = 'EMPLOYEE',

  //门店管理
  /** 全国维度门店管理 */
  NATIONAL_STORE_MANAGER = 'NATIONAL_STORE_MANAGER',
  /** 大区维度门店管理 */
  REGIN_STORE_MANAGER = 'REGIN_STORE_MANAGER',
  /** 片区维度门店管理 */
  AERA_STORE_MANAGER = 'AERA_STORE_MANAGER',

  // --------   巡店
  /** 全国维度巡检列表 */
  NATIONAL_INSECTION_INFO = 'NATIONAL_INSECTION_INFO',
  /** 大区维度巡检列表  搜索 片区列表*/
  REGION_INSECTION_INFO = 'REGION_INSECTION_INFO',
  /** 大区维度巡检列表  搜索片区所有片区下 店铺*/
  REGION_INSECTION_INFO_SLICE = 'REGION_INSECTION_INFO_SLICE',

  /** 片区维度巡检列表 */
  AREA_INSPECTION_INFO = 'AREA_INSPECTION_INFO',

  // 运营业绩评分
  /** 全国维度-大区业绩评分列表 */
  NATIONAL_PERFORMANCE_SCORE = 'NATIONAL_PERFORMANCE_SCORE',
  /** 全国维度-大区员工业绩评分列表 */
  NATIONAL_USER_PERFORMANCE_SCORE = 'NATIONAL_USER_PERFORMANCE_SCORE',
  /** 片区运营业绩人员绩效 */
  REGION_PERFORMANCE_SCORE = 'REGION_PERFORMANCE_SCORE',
  /** 片区运营业绩人员绩效 */
  REGION_USER_PERFORMANCE_SCORE = 'REGION_USER_PERFORMANCE_SCORE',
  /** 片区维度-门店业绩评分列表 */
  AERA_PERFORMANCE_SCORE = 'AERA_PERFORMANCE_SCORE',

  //存销比
  /** 全国纬度存销售比 */
  NATIONAL_STOCK_SALE_RATIO = 'national_stock_sale_ratio',
  /** 大区纬度存销售比 */
  REGION_STOCK_SALE_RATIO = 'region_stock_sale_ratio',
  /** 片区纬度存销售比 */
  AREA_STOCK_SALE_RATIO = 'area_stock_sale_ratio',

  //sab样机
  /** 全国纬度存销售比 */
  NATIONAL_SAB_YANGJI = 'national_sab_yangji',
  /** 大区纬度存销售比 */
  REGION_SAB_YANGJI = 'region_sab_yangji',
  /** 片区纬度存销售比 */
  AREA_SAB_YANGJI = 'area_sab_yangji',

  //拓展人员绩效
  /** 全国拓展人员绩效 */
  NATIONAL_TUOZHAN_PERF_AREA = 'national_tuozhan_perf_area',
  /** 大区拓展人员绩效 */
  NATIONAL_TUOZHAN_PERF_USER = 'national_tuozhan_perf_user',
  /** 片区拓展人员绩效 */
  REGION_TUOZHAN_PERF_AREA = 'region_tuozhan_perf_area',
  /** 片区拓展人员绩效 */
  REGION_TUOZHAN_PERF_USER = 'region_tuozhan_perf_user',
  /** 新开店，存量店 店铺查询 */
  SLICE_TUOZHAN_SHOP = 'slice_tuozhan_shop',

  /*巡店报表*/
  /*片区维度巡店报表*/
  AREA_INSPECTION_REPORT = 'area_inspection_report'
}

/**
 * 经纬度数据来源
 */
export enum EGeoSource {
  /** 百度地图 */
  BMAP = '0',
  /** 高德地图 */
  AMAP = '1',
  /** 谷歌地图 */
  GMAP = '2'
}

// 用户信息
export enum EAccountRole {
  /** 全国维度的角色    ->   总部管理员  */
  NATIONAL = '1',
  /** 大区维度的角色     ->   大区云总*/
  REGION = '2',
  /** 大区运营经理 */
  REGION_Manager = 'dqyyjl',
  /** 片区维度的角色     ->   片区负责人*/
  AREA = '3'
}

export enum ELoadingStatus {
  PENDING = 'PENDING',
  FULLFILLED = 'FULLFILLED',
  REJECTED = 'REJECTED'
}

//功能模块
export enum ECateFuntion {
  /** 巡店打卡 */
  INSECTION_CARD = 'xddk',
  /**巡店明细 */
  INSRCTION_INFO = 'xdmx',
  //todo
  /**任务明细 */
  TASK_DETAIL = 'rwmx',
  /** 远程巡店 */
  REMOTE_MONITOR = 'ycxd',
  /** 巡店报表 */
  INSECTION_REPORT = 'xdbb',

  /** 门店管理 */
  STORE_MANAGER = 'mdgl',
  /** 员工管理 */
  EMPLOYEE_MANAGER = 'yggl',
  /** 片区管理 */
  AERA_MANAGER = 'pqgl',

  /** 门店筹建 **/
  STORE_PREPARATION = 'mdcj',
  /** 筹建明细 **/
  STORE_PREPARATION_DETAIL = 'cjmx',
  /** 店铺解封申请 */
  STORE_REOPEN_APPLY = 'dpjfsq'
}
