import { proxy } from 'valtio'

export * from './modules/user'
export * from './modules/baseAllInfo'
export * from './modules/currentAreaCode'

/** tab未读信息数量 */
export const unReadNoticeCount = proxy({ value: 0 })

/** tab未完成待办数量 */
export const unFinishTodoCount = proxy({ value: 0 })

/** 切换 年， 季度 */
export const purchaseSwitch = proxy({ value: false })

/** userinfo接口错误信息 */
export const userErrorMsg = proxy({ value: '' })

/**
 * 更新 tab未读信息数量
 */
export function updateUnReadNoticeCount(count: number) {
  unReadNoticeCount.value = count
}

/**
 * 更新 tab未完成待办数量
 */
export function updateUnFinishTodoCount(count: number) {
  unFinishTodoCount.value = count
}

/**
 * 更新 "切换 年， 季度"
 */
export function updatePurchaseSwitch(value: boolean) {
  purchaseSwitch.value = value
}

/**
 * 更新 "userinfo接口错误信息"
 */
export function updateUserErrorMsg(value: string) {
  userErrorMsg.value = value
}
