import kucunshuju from '@assets/images/kucunshuju@2x.png'
import qs from 'qs'
import { Cleanup } from '@/stores/util'
import { Divider } from '@components'
import { EAccountRole } from '@genre'
import { Flex, Icon } from 'antd-mobile'
import { queryStockData } from '@/views/home/HomeService'
import { getRegionInfo } from '@/views/home/utils'
import { currentAreaCode, getGlobalUserConfig, globalUserState } from '@/stores'
import { IHomeSURRes } from '@/views/home/types'
import { Router, Sa, util } from '@kits'
import { subscribeKey } from 'valtio/utils'
import { useEffect, useState } from 'react'
import './index.less'

let unWatchRole: Cleanup
let unWatchSliceArea: Cleanup

interface IProps {
  titleName: string
}

/** 库存数据 */
export default function StockData({ titleName }: IProps) {
  const [data, setData] = useState({} as IHomeSURRes)

  if (!unWatchRole) {
    unWatchRole = subscribeKey(globalUserState, 'selectRole', () => {
      // 用于首页角色切换后刷新状态
      fetchStockData()
    })
  }

  if (!unWatchSliceArea) {
    unWatchSliceArea = subscribeKey(currentAreaCode, 'value', () => {
      // 用于首页片区切换后刷新状态
      fetchStockData()
    })
  }

  async function fetchStockData() {
    const userState = getGlobalUserConfig()

    if (userState.originalInfo && userState.originalInfo.personNo) {
      const { params } = getRegionInfo(userState.originalInfo)
      const res = await queryStockData(params)
      setData(() => res)
    }
  }

  /** 跳转到自页面 */
  function onGoStockSaleRatio() {
    Sa.sendClickData('04knnp4', {
      eleid: '7',
      eletitle: `库存查看详情`,
      ...util.saRoleParamClickObj(),
    })

    const userState = getGlobalUserConfig()
    switch (userState.accountRole) {
      case EAccountRole.NATIONAL:
        Router.Push(`/stock-sale-ratio/nation`)
        break
      case EAccountRole.REGION:
      case EAccountRole.REGION_Manager:
        Router.Push(
          `/stock-sale-ratio/region?regionCode=${
            userState.regionCode || ''
          }&regionName=${userState.regionName || ''}`
        )
        break
      case EAccountRole.AREA:
        Router.Push(
          `/stock-sale-ratio/area?${qs.stringify({
            regionCode: userState.sliceArea
              ? userState.sliceArea.sliceAreaCode || ''
              : '',
            regionName: userState.sliceArea
              ? userState.sliceArea.sliceAreaName || ''
              : '',
          })}`
        )
        break
    }
  }

  useEffect(() => {
    fetchStockData()

    return () => {
      if (unWatchRole) {
        unWatchRole()
        unWatchRole = null
      }
      if (unWatchSliceArea) {
        unWatchSliceArea()
        unWatchSliceArea = null
      }
    }
  }, [])

  return (
    <Flex direction="column" justify="center" className="home-stock-data">
      <Flex direction="row" justify="between" className="stock-title">
        <Flex>
          <img src={kucunshuju} className="image" />
          <span className="sub-title">{titleName}</span>
        </Flex>

        <Flex
          justify="end"
          onClick={onGoStockSaleRatio}
          className="detail-content"
        >
          <span className="home-warning-store-sub-item-detail">查看详情</span>
          <Icon type="right" size="xs" color="#4A90E2" />
        </Flex>
      </Flex>

      <Divider width="90%" className="divider" />

      <Flex direction="row" justify="between" className="stock-content">
        <Flex.Item>
          <Flex direction="column" justify="center" className="in-item">
            <span className="number">{data?.stockUseRatio || '-'}</span>
            <span className="name">实货存销比</span>
          </Flex>
        </Flex.Item>

        <Flex.Item>
          <Flex direction="column" justify="center" className="in-item">
            <span className="number">{data?.transitStockUseRatio || '-'}</span>
            <span className="name">加在途存销比</span>
          </Flex>
        </Flex.Item>
      </Flex>
    </Flex>
  )
}
