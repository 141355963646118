enum TIME_DURATION {
  TODAY = 1,
  LATEST_7_DAY,
  LATEST_30_DAY,
  BY_DAY,
  BY_MONTH
}

export { TIME_DURATION }

// export const TIME_DURATION = {
//   "1": '今天',
//   "2": '近7天',
//   "3": '近30天',
//   "4": '按日查询',
//   "5": '按月查询',
// }
