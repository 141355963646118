import Config from '@config'
import { IGetBaseAllInfoParam } from './types'
import {
  IGlobalUser,
  IRoleItem,
  IUserMeta,
  currentAreaCode,
  updateCurrentAreaCode,
} from '@/stores'
import { EAccountRole, ECateFuntion } from '@genre'
import { Flex, Modal } from 'antd-mobile'

export function getHttpErrorMessage(error: any) {
  return (
    error?.message ||
    error?.statusText ||
    error?.body?.message ||
    error?.body?.error ||
    error?.response?.message ||
    error.response?.error ||
    ''
  )
}

/**
 * @param info user接口返回得原始数据
 */
export function getRegionInfo(info: IUserMeta) {
  const sliceArea = info.sliceAreaList[0] || {
    sliceAreaCode: '',
    sliceAreaName: '',
  }

  return {
    params: {
      regionCode:
        info.selectRole.dataDimension !== EAccountRole.NATIONAL
          ? info.regionCode || ''
          : '',
      sliceAreaCode:
        info.selectRole.dataDimension === EAccountRole.AREA
          ? currentAreaCode.value || sliceArea.sliceAreaCode || ''
          : '',
    } as IGetBaseAllInfoParam,
    sliceArea,
  }
}

export async function formatUseInfo(res: IUserMeta) {
  // USER_NOT_LOGIN("SRCPMF_USER_NOT_LOGIN", "无账号信息，请重新登录"),
  // USER_NO_LOGIN_PERMISSION("SRCPMF_USER_NO_LOGIN_PERMISSION", "您的账户没有权限使用"),
  // USER_NO_FUNCTION_PERMISSION("SRCPMF_USER_NO_LOGIN_PERMISSION", "对不起，你暂无权限使用该功能"),
  // USER_NO_DATA_PERMISSION("SRCPMF_USER_NO_LOGIN_PERMISSION", "对不起，你暂无权限访问该数据"),
  // USER_NOT_EXISTS("SRCPMF_USER_NOT_EXISTS", "用户信息不存在"),
  // USER_DIMISSION("SRCPMF_USER_DIMISSION", "员工已离职"),

  const { selectRole, regionCode, regionName } = res
  const { sliceArea } = getRegionInfo(res)

  const sliceAreaCode = res?.sliceAreaList[0]?.sliceAreaCode || ''
  updateCurrentAreaCode(sliceAreaCode)

  return {
    accountRole: selectRole?.dataDimension as EAccountRole,
    sliceArea,
    regionCode,
    regionName,
    sliceAreaList: res.sliceAreaList || [],
    menuList: selectRole?.menuList,
    selectedRoleCode: selectRole?.roleCode,
    selectStoreType: res.selectStoreType,
    dataGroupList: selectRole?.dataGroupList,
    originalInfo: { ...res },
  } as IGlobalUser
}

const HOME_MENU_ELEID_MAP = {
  /** 巡店打卡 */
  [ECateFuntion.INSECTION_CARD]: '1',
  /** 巡店明细 */
  [ECateFuntion.INSRCTION_INFO]: '2',
  /** 门店管理 */
  [ECateFuntion.STORE_MANAGER]: '3',
  /** 员工管理 */
  [ECateFuntion.EMPLOYEE_MANAGER]: '4',
  /** 片区管理 */
  [ECateFuntion.AERA_MANAGER]: '5',
  /** 远程巡店 */
  [ECateFuntion.REMOTE_MONITOR]: '7',
}

/**
 * 根据 menuCode 获取埋点坑位 id
 */
export function getEleidByMenuCode(menuCode: ECateFuntion) {
  if (HOME_MENU_ELEID_MAP[menuCode]) {
    return HOME_MENU_ELEID_MAP[menuCode]
  }
  return ''
}

export const getSelectStoreType = (
  roleCode: string,
  roleList: IRoleItem[] = []
) => {
  const list = roleList.find((v) => v.roleCode === roleCode).storeTypeList!
  return list
}

export const getStoreTypeName = (code: string) => {
  let name = ''
  switch (code) {
    case 'ST1':
      name = '快修店'
      break
    case 'ST2':
      name = '综合家电精选店'
      break
    case 'ST12':
      name = '手机快修'
      break
    case 'ST13':
      name = '电子烟专卖'
      break
    case 'ST14':
      name = '家居'
      break
  }
  return name
}

function getLocationMessage() {
  return (
    <Flex direction="column">
      <span>1、未开启【豆芽】定位服务权限</span>
      <span>2、网络无法连接</span>
      <span>请确认后重试一下！</span>
    </Flex>
  )
}

/** 无法定位提示弹框 */
export function openLocationMsgModal(onOk?: () => void) {
  // Modal.alert('无法获取定位原因', getLocationMessage(), [
  //   {
  //     text: '知道了',
  //     onPress: () => {
  //       onOk && onOk()
  //     },
  //   },
  // ])
}
