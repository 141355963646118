import AreaList from './widgets/area-list/AreaList'
import DetailNoDataView from '@/views/store-info-new/category-detail/components/detail-nodata-view'
import GuideDialog from '../../components/guide-dialog/GuideDialog'
import Header from './widgets/header/Header'
import Indexstorelocation from './widgets/index-store-location/Indexstorelocation'
import RenderCards from '../../components/render-cards/RenderCards'
import RoleList from './widgets/role-list/RoleList'
import { openLocationMsgModal } from '../../utils'
import { Cleanup } from '@/stores/util'
import { useActivate } from 'react-activation'
import {
  currentAreaCode,
  globalUserState,
  updatePurchaseSwitch,
  userErrorMsg,
} from '@/stores'
import { updateUnFinishTodoSize } from '@/views/todos/webapi'
import {
  initBaseAllInfo,
  initMapMode,
  updateTabNoticeCount,
} from '../../HomeService'
import { PlaceholderView } from '@components'
import { Sa, util } from '@kits'
import { subscribeKey } from 'valtio/utils'
import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import '../../index.less'

let unWatchRole: Cleanup
let unWatchSliceArea: Cleanup

/** 数据 */
export default function HomeData() {
  const [messageGuideVisible, setMessageGuideVisible] = useState(false)
  const [top, setTop] = useState<undefined | string>(undefined)
  const [loading, setLoading] = useState(true)
  const [showRoleList, setShowRoleList] = useState(false) // 显示用户角色列表modal
  const [showAreaList, setShowAreaList] = useState(false) // 显示用户角色列表modal
  const authMessage = useSnapshot(userErrorMsg)

  if (!unWatchRole) {
    unWatchRole = subscribeKey(globalUserState, 'selectRole', () => {
      // 用于首页角色切换后刷新状态
      initBaseAllInfo()
      inintPageSa()
    })
  }

  if (!unWatchSliceArea) {
    unWatchSliceArea = subscribeKey(currentAreaCode, 'value', () => {
      // 用于首页片区切换后刷新状态
      initBaseAllInfo()
      inintPageSa()
    })
  }

  async function initGlobalSetting() {
    setLoading(true)
    updateTabNoticeCount()
    updateUnFinishTodoSize()
    await updatePurchaseSwitch(false)
    await initMapMode()
    setLoading(false)
  }

  function openLocationTip() {
    if (!window.localStorage.getItem('work-bench-guide')) {
      openLocationMsgModal()
    }
  }

  function toggleRoleList() {
    setShowRoleList((prev) => !prev)
  }

  function toggleAreaList() {
    setShowAreaList((prev) => !prev)
  }

  function onFixedTopChange(top = '') {
    if (top) setTop(top)
  }

  function inintPageSa() {
    Sa.update(`pgtitle=超级经理人首页;pageid=ahj6nBaAAa;${util.saRoleParam()}`)
  }

  useActivate(() => {
    inintPageSa()
  })

  useEffect(() => {
    initGlobalSetting()

    // 引导弹窗新增
    if (!window.localStorage.getItem('work-bench-guide')) {
      openLocationMsgModal()
      window.localStorage.setItem('work-bench-guide', '1')
    }
    if (!window.localStorage.getItem('first-guide-page2')) {
      // setMessageGuideVisible(true)
      window.localStorage.setItem('first-guide-page2', '1')
    }

    return () => {
      if (unWatchRole) {
        unWatchRole()
        unWatchRole = null
      }
      if (unWatchSliceArea) {
        unWatchSliceArea()
        unWatchSliceArea = null
      }
    }
  }, [])

  return !authMessage.value ? (
    <>
      {loading ? (
        <DetailNoDataView />
      ) : (
        <>
          {/** 引导弹窗 */}
          <GuideDialog
            visible={messageGuideVisible}
            onCloseMessageGuide={() => setMessageGuideVisible(false)}
          />
          {/** content */}
          <div className="home-container">
            <Header
              toggleRoleList={toggleRoleList}
              toggleAreaList={toggleAreaList}
              onFixedTopChange={onFixedTopChange}
            />
            <div className="test-content" style={top ? { top } : {}}>
              {/** 首页定位区块 */}
              <Indexstorelocation openLocationTip={openLocationTip} />
              {/** 卡片区域 */}
              <RenderCards />
            </div>

            {/** 区域列表选择弹框 */}
            <AreaList visible={showAreaList} toggleVisible={toggleAreaList} />

            {/** 切换角色弹框 */}
            <RoleList
              showRoleList={showRoleList}
              toggleRoleList={toggleRoleList}
            />
          </div>
        </>
      )}
    </>
  ) : (
    <div>
      {/** 错误数据文案提示区块 */}
      <PlaceholderView title={authMessage.value} />
    </div>
  )
}
