/*
 * @Author: keqingrong (19040892)
 * @Date: 2019-08-11 09:12:12
 * @LastEditors: keqingrong (19040892)
 * @LastEditTime: 2019-08-19 17:29:57
 * @Description:
 */
import Douya from './douya'
/**
 * 高德Web服务API调用限制流量 <https://lbs.amap.com/api/webservice/guide/tools/flowlevel>
 * 高德地图JS API无明确限制？ <https://lbs.amap.com/api/javascript-api/reference/lnglat-to-address>
 * 优先使用地图JS API，地图未加载时使用Web服务 API。
 */
/*
 * 县级行政区划单位后缀，长后缀优先
 * 截至2019年10月，共包含：
 * 963个市辖区、
 * 382个县级市、1329个县、117个自治县、
 * 49个旗、3个自治旗、
 * 1 个特区、
 * 1个林区，
 * 合计2845个县级区划。
 */
const DISTRICT_SUFFIXES = [
  '市辖区',
  '特区',
  '林区',
  '区',
  '县级市',
  '市',
  '自治县',
  '县',
  '自治旗',
  '旗',
]

/**
 * 56个名族名称
 * 数据来源 http://www.gov.cn/test/2006-04/04/content_244533.htm
 * 相关国家标准 GB/T 3304-1991 中国各民族名称的罗马字母拼写法和代码
 */
const NATIONALITY_NAMES = [
  '阿昌族',
  '白族',
  '保安族',
  '布朗族',
  '布依族',
  '藏族',
  '朝鲜族',
  '达斡尔族',
  '傣族',
  '德昂族',
  '东乡族',
  '侗族',
  '独龙族',
  '俄罗斯族',
  '鄂伦春族',
  '鄂温克族',
  '高山族',
  '仡佬族',
  '哈尼族',
  '哈萨克族',
  '汉族',
  '赫哲族',
  '回族',
  '基诺族',
  '京族',
  '景颇族',
  '柯尔克孜族',
  '拉祜族',
  '黎族',
  '傈僳族',
  '珞巴族',
  '满族',
  '毛南族',
  '门巴族',
  '蒙古族',
  '苗族',
  '仫佬族',
  '纳西族',
  '怒族',
  '普米族',
  '羌族',
  '撒拉族',
  '畲族',
  '水族',
  '塔吉克族',
  '塔塔尔族',
  '土家族',
  '土族',
  '佤族',
  '维吾尔族',
  '乌孜别克族',
  '锡伯族',
  '瑶族',
  '彝族',
  '裕固族',
  '壮族',
]
const NATIONALITY_NAMES_REGEX = new RegExp(NATIONALITY_NAMES.join('|'), 'g')

/**
 * 获取用户当前的位置信息，仅 https 协议或者 localhost 可用，优先使用浏览器定位，失败后使用IP定位。
 */
function getCurrentPosition() {
  return new Promise<AMap.Geolocation.GeolocationResult>((resolve, reject) => {
    if (!('AMap' in window)) {
      reject(new Error('高德地图未加载成功'))
      return
    }
    AMap.plugin(['AMap.Geolocation'], () => {
      const geolocation = new AMap.Geolocation({
        enableHighAccuracy: true, //是否使用高精度定位，默认:true
        timeout: 10000, //超过10秒后停止定位，默认：无穷大
        noIpLocate: 0, //是否禁止使用IP定位，默认值为0，可以使用IP定位
        noGeoLocation: 0, //是否禁止使用浏览器Geolocation定位，默认值为0，可以使用浏览器定位
        GeoLocationFirst: false, //默认为false，设置为true的时候可以调整PC端为优先使用浏览器定位，失败后使用IP定位
        maximumAge: 0, //定位结果缓存0毫秒，默认：0
        convert: true, //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
        showButton: false, //显示定位按钮，默认：true
        showMarker: false, //定位成功后在定位到的位置显示点标记，默认：true
        showCircle: false, //定位成功后用圆圈表示定位精度范围，默认：true
        panToLocation: false, //定位成功后将定位到的位置作为地图中心点，默认：true
        zoomToAccuracy: true, //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        useNative: false, //是否使用安卓定位sdk用来进行定位，默认：false
        extensions: 'base', //是否需要周边POI、道路交叉口等信息，默认为'base'，只返回地址信息
      })
      geolocation.getCurrentPosition((status, result) => {
        __ENV_DEV__ && console.log('[geolocation.getCurrentPosition]', result)
        if (
          status === 'complete' &&
          typeof result !== 'string' &&
          result.info === 'SUCCESS'
        ) {
          resolve(result as AMap.Geolocation.GeolocationResult)
        } else {
          reject(new Error('定位失败'))
        }
      })
    })
  })
}

/**
 * 根据经纬度查询详细地址信息
 * @param lnglat - 经纬度
 * @param city - 城市，地址所在城市，设置后可以提高准确度
 */
function getAddressByLnglat(lnglat: AMap.LocationValue, city?: string) {
  return new Promise<AMap.Geocoder.ReGeocode>((resolve, reject) => {
    if (!('AMap' in window)) {
      reject(new Error('高德地图未加载成功'))
      return
    }
    AMap.plugin(['AMap.Geocoder'], () => {
      const geocoder = new AMap.Geocoder({
        city: city || '全国',
        radius: 1000,
        batch: false,
        extensions: 'base',
      })
      geocoder.getAddress(lnglat, (status, result) => {
        __ENV_DEV__ && console.log('[geocoder.getAddress]', lnglat, result)
        if (
          status === 'complete' &&
          typeof result !== 'string' &&
          result.info === 'OK'
        ) {
          resolve(result.regeocode)
        } else {
          reject(new Error('经纬度的详细地址查询失败'))
        }
      })
    })
  })
}

/**
 * 根据地名查询详细地址信息
 * @param address - 地址
 * @param city - 城市，地址所在城市，设置后可以提高准确度
 */
function getAddressesByName(address: string, city?: string) {
  return new Promise<AMap.Geocoder.Geocode[]>((resolve, reject) => {
    if (!('AMap' in window)) {
      reject(new Error('高德地图未加载成功'))
      return
    }
    AMap.plugin(['AMap.Geocoder'], () => {
      const geocoder = new AMap.Geocoder({
        city: city || '全国',
        radius: 1000,
        batch: false,
        extensions: 'base',
      })
      geocoder.getLocation(address, (status, result) => {
        __ENV_DEV__ && console.log('[geocoder.getLocation]', address, result)
        if (
          status === 'complete' &&
          typeof result !== 'string' &&
          result.geocodes.length > 0
        ) {
          resolve(result.geocodes)
        } else {
          reject(new Error('地址详细信息查询失败'))
        }
      })
    })
  })
}

/**
 * 坐标转换，将其他地图服务商的坐标转换成高德地图经纬度坐标
 */
function convertCoordinates(
  lnglat: AMap.LocationValue,
  type: 'gps' | 'baidu' | 'mapbar'
) {
  return new Promise<[number, number]>((resolve, reject) => {
    if (!('AMap' in window)) {
      reject(new Error('高德地图未加载成功'))
      return
    }
    AMap.convertFrom(lnglat, type, (status, result) => {
      __ENV_DEV__ && console.log('[convertFrom]', lnglat, result)
      if (
        status === 'complete' &&
        typeof result !== 'string' &&
        Array.isArray(result.locations) &&
        result.locations.length > 0
      ) {
        const lnglat = result.locations[0]
        resolve([lnglat.getLng(), lnglat.getLat()])
      } else {
        reject(new Error('坐标转换失败'))
      }
    })
  })
}

/**
 * 地理编码（地址 -> 坐标）
 * @param address - 地址
 * @param city - 城市，地址所在城市，设置后可以提高准确度
 */
function geocode(address: string, city?: string) {
  return new Promise<[number, number]>((resolve, reject) => {
    getAddressesByName(address, city).then(
      (geocodes) => {
        // 一个地名可能查出多个地址，默认返回第一个地址的经纬度
        const lnglat = geocodes[0].location
        resolve([lnglat.getLng(), lnglat.getLat()])
      },
      (err) => {
        reject(err)
      }
    )
  })
}

/**
 * 逆地理编码（坐标 -> 地址）
 * @param lnglat - 经纬度
 * @param city - 城市，地址所在城市，设置后可以提高准确度
 */
function regeocode(lnglat: AMap.LocationValue, city?: string) {
  return new Promise<string>((resolve, reject) => {
    getAddressByLnglat(lnglat, city).then(
      (regeocodeResult) => {
        resolve(regeocodeResult.formattedAddress)
      },
      (err) => {
        reject(err)
      }
    )
  })
}

/**
 * 获取区县名称，包含省市名称可以提高准确率
 * '陕西省咸阳市彬县城关街道' -> ['彬州市', '彬县']
 * '江西省鹰潭市锦江镇兴安街' -> ['余江区', '余江县']
 * '江西省鹰潭市余江县横溪镇' -> ['余江区', '余江县']
 * '重庆市酉阳县桃花源镇' -> ['酉阳土家族苗族自治县']
 * '辽宁阜新阜新县阜新镇繁荣大街1号楼8号' -> ['太平区']
 * @param placeName - 地名
 */
async function getDistrictNames(placeName: string) {
  // 一个地名可能查出多个地址，默认取第一个地址。
  // 这里高德地图返回的区县名称不一定是最新的，可以根据经纬度重新查。
  const geocodes = await getAddressesByName(placeName)
  const address1 = geocodes[0]
  const district1 = address1.addressComponent.district
  const address2 = await getAddressByLnglat(address1.location)
  const district2 = address2.addressComponent.district
  let districtNames = []
  if (district1 === district2) {
    districtNames = [district1]
  } else {
    districtNames = [district2, district1]
  }
  __ENV_DEV__ && console.log('[getDistrictNames]', districtNames)
  return districtNames
}

/**
 * 判断坐标是否在区县坐标范围内
 * @param lnglat - 经纬度坐标
 * @param keyword - 县级行政单位名称
 * @see https://lbs.amap.com/api/javascript-api/reference/search
 * @see https://lbs.amap.com/api/webservice/guide/api/district
 * @see https://lbs.amap.com/api/javascript-api/guide/services/district-search
 */
function isLngLatInDistrictBounds(lnglat: [number, number], keyword: string) {
  return new Promise<boolean>((resolve, reject) => {
    if (!('AMap' in window)) {
      reject(new Error('高德地图未加载成功'))
      return
    }
    AMap.plugin(['AMap.DistrictSearch'], () => {
      // 创建行政区查询对象
      const district = new AMap.DistrictSearch({
        level: 'district',
        showbiz: false,
        extensions: 'all',
        subdistrict: 0,
      })

      district.search(keyword, (status, result) => {
        __ENV_DEV__ && console.log('[district.search]', keyword, result)
        if (
          status === 'complete' &&
          typeof result !== 'string' &&
          result.districtList.length > 0
        ) {
          let isInRange = false // 默认视为不在范围内
          let matchedDistrictList = [] // 匹配到的区县列表
          const districtList = result.districtList.filter((item) => {
            // 理论上应该只保留"district"，但存在撤县级市、县升地级市的问题
            return item.level === 'district' || item.level === 'city'
          }) // 区县列表
          const matchedDistrict = districtList.find(
            (item) => item.name === keyword
          ) // 匹配到的区县
          if (matchedDistrict) {
            matchedDistrictList = [matchedDistrict]
          } else {
            // 理论上应该直接从 districtList 中匹配和 keyword 同名的区县，但不准确。
            // 搜 "阜新"，返回 "阜新市", "阜新蒙古族自治县"，全部保留
            // 搜 "义县"，返回 "尚义县", "义县", "武义县", "安义县", "崇义县"，只保留"义县"
            matchedDistrictList = districtList.filter((item) =>
              isSameDistrictName(item.name, keyword)
            )
          }

          // 获取边界信息，判断坐标是否在边界内
          for (let i = 0; i < matchedDistrictList.length; i++) {
            const bounds = matchedDistrictList[i].boundaries
            for (let j = 0; j < bounds.length; j++) {
              if (AMap.GeometryUtil.isPointInRing(lnglat, bounds[j])) {
                isInRange = true
                break
              }
            }
            if (isInRange) {
              break
            }
          }

          __ENV_DEV__ &&
            console.log(
              '[district.search] isInRange',
              isInRange,
              matchedDistrictList
            )
          resolve(isInRange)
        } else {
          reject(new Error('坐标范围判断失败'))
        }
      })
    })
  })
}

/**
 * 判断坐标是否在区县范围内
 * 方式一：根据传入的坐标获取所属区县，判断是否和传入的区县名称一致
 * 方式二：根据传入的区县县名获取坐标范围，判断是否包含传入的坐标
 * @param lnglat - 经纬度坐标
 * @param keyword - 县级行政单位名称
 */
async function isLngLatInDistrict(lnglat: [number, number], keyword: string) {
  // 优先反查 lnglat 对应的区县名称进行比较，但不准确。
  // 如 [121.76195, 42.0422] -> "辽宁省阜新市太平区城南街道繁荣大街" -> "太平区"
  // "太平区"和"阜新县"虽然不匹配，但实际应该视为同一个区县。
  const regeocodeResult = await getAddressByLnglat(lnglat)
  if (isSameDistrictName(regeocodeResult.addressComponent.district, keyword)) {
    return true
  }
  // 查询 keyword 对应区县的坐标范围，判断是否包含 lnglat
  const isInRange = await isLngLatInDistrictBounds(lnglat, keyword)
  if (isInRange) {
    return true
  }
  // 高德地图查不到"酉阳县"，只能通过"酉阳"查到"酉阳土家族苗族自治县"
  const isInRangeAgain = await isLngLatInDistrictBounds(
    lnglat,
    shortenDistrictName(keyword)
  )
  __ENV_DEV__ && console.log('[isLngLatInDistrict] isInRange', isInRangeAgain)
  return isInRangeAgain
}

/**
 * 判断两个区县是否是同一个
 * @example
 * isSameDistrictName('新宾县', '新宾满族自治县') -> true
 * isSameDistrictName('酉阳县', '酉阳土家族苗族自治县') -> true
 * isSameDistrictName('阜新县', '阜新蒙古族自治县') -> true
 * @param a - 县级行政单位名称
 * @param b - 县级行政单位名称
 */
function isSameDistrictName(a: string, b: string) {
  __ENV_DEV__ && console.log('[isSameDistrictName]', a, b)
  if (a === b) {
    return true
  } else {
    const shortA = shortenDistrictName(a)
    const shortB = shortenDistrictName(b)
    if (shortA === shortB) {
      return true
      // TODO: 下面的判断是不准确的，需要维护别名列表
    } else if (shortA.length < shortB.length) {
      return shortB.indexOf(shortA) === 0
    } else {
      return shortA.indexOf(shortB) === 0
    }
  }
}

/**
 * 缩短区县名称
 * @example
 * '新宾县' -> '新宾'
 * '新宾满族自治县' -> '新宾'
 * '景宁畲族自治县' -> '景宁'
 * '酉阳土家族苗族自治县' -> '酉阳'
 * '双江拉祜族佤族布朗族傣族自治县' -> '双江'
 * '鄂伦春自治旗' -> '鄂伦春'
 * '鄂温克族自治旗' -> '鄂温克族自治旗'
 * @param districtName - 县级行政单位名称
 */
function shortenDistrictName(districtName: string) {
  // 去除名字中的民族
  const shortDistrictName = districtName.replace(NATIONALITY_NAMES_REGEX, '')
  // 名字中包含县级行政单位名称
  const districtSuffix = DISTRICT_SUFFIXES.find(
    (suffix) => shortDistrictName.lastIndexOf(suffix) > -1
  )
  const tinyDistrictName = districtSuffix
    ? shortDistrictName.slice(0, shortDistrictName.indexOf(districtSuffix))
    : shortDistrictName
  // 去除民族名称、行政单位名称后直接为空时，返回原名字
  return tinyDistrictName.length > 0 ? tinyDistrictName : districtName
}

/**
 * 预加载插件
 */
function preloadPlugins() {
  if ('AMap' in window) {
    AMap.plugin(
      ['AMap.DistrictSearch', 'AMap.Geocoder', 'AMap.Geolocation'],
      () => {}
    )
  }
}

/**
 * 判断是否是合法经纬度，仅支持数字，不支持字符串经纬度
 * longitude: [-180, 180]
 * latitude: [-90, 90]
 *
 * [null, null], [NaN, NaN] 非法
 */
function isValidCoordinates(lnglat: [number, number]) {
  if (
    Array.isArray(lnglat) &&
    lnglat.length === 2 &&
    typeof lnglat[0] === 'number' &&
    typeof lnglat[1] === 'number' &&
    lnglat[0] >= -180 &&
    lnglat[0] <= 180 &&
    lnglat[1] >= -90 &&
    lnglat[1] <= 90
  ) {
    return true
  }
  return false
}

/**
 * 解析经纬度，将经纬度字符串数组解析成数字数组
 * 解析失败返回 null
 */
function parseCoordinates(lnglat: [number, number] | [string, string]) {
  if (Array.isArray(lnglat) && lnglat.length === 2) {
    const lng =
      typeof lnglat[0] === 'number' ? lnglat[0] : parseFloat(lnglat[0])
    const lat =
      typeof lnglat[1] === 'number' ? lnglat[1] : parseFloat(lnglat[1])
    const newLnglat: [number, number] = [lng, lat]
    if (isValidCoordinates(newLnglat)) {
      return newLnglat
    }
    return null
  }
  return null
}

/**
 * 判断两个经纬度是否相等
 * @param lnglat1
 * @param lnglat2
 */
function isCoordinatesEqual(lnglat1, lnglat2) {
  if (isValidCoordinates(lnglat1) && isValidCoordinates(lnglat2)) {
    return lnglat1[0] === lnglat2[0] && lnglat1[1] === lnglat2[1]
  }
  return lnglat1 === lnglat2
}

/**
 * 计算两个经纬度点之间的实际距离。单位：米
 */
function calculateDistance(
  lnglat1: AMap.LocationValue,
  lnglat2: AMap.LocationValue
) {
  if (!('AMap' in window)) {
    console.error('[calculateDistance] 高德地图未加载成功')
    return Infinity
  }
  const distance = AMap.GeometryUtil.distance(lnglat1, lnglat2)
  __ENV_DEV__ && console.log('[calculateDistance]', lnglat1, lnglat2, distance)
  return distance
}

/**
 * 获取用户当前经纬度，优先使用豆芽API，失败时使用高德地图API（优先使用浏览器定位，失败后使用IP定位）
 */
async function getCurrentLngLat() {
  try {
    if (!('AMap' in window)) {
      throw new Error('高德地图未加载成功')
    }

    if (Douya.canIuse('getLocationInfo')) {
      const locationInfo = await Douya.getLocationInfo()
      let douyaLngLat: [number, number] = parseCoordinates([
        locationInfo.longitude,
        locationInfo.latitude,
      ])
      // 旧版豆芽，不返回mapType，默认为百度地图坐标，需要转换成高德坐标。
      // 新版豆芽，返回mapType，需要进行判断，如果是百度左边则转换成高德坐标。
      // 0:百度地图坐标，1:高德地图坐标
      if (Number(locationInfo.mapType) !== 1) {
        douyaLngLat = await convertCoordinates(douyaLngLat, 'baidu')
      }
      __ENV_DEV__ &&
        console.log('[getCurrentLngLat]', locationInfo, douyaLngLat)
      return douyaLngLat
    } else {
      // 非豆芽环境使用高德地图定位插件作为回退
      const currentPosition = await getCurrentPosition()
      __ENV_DEV__ && console.log('[getCurrentLngLat]', currentPosition)
      return [
        currentPosition.position.getLng(),
        currentPosition.position.getLat(),
      ] as [number, number]
    }
  } catch (err) {
    throw new Error('定位失败')
  }
}

export {
  convertCoordinates,
  geocode,
  regeocode,
  geocode as address2lnglat,
  regeocode as lnglat2address,
  getAddressByLnglat,
  getAddressesByName,
  getDistrictNames,
  getCurrentPosition,
  getCurrentLngLat,
  isLngLatInDistrict,
  isSameDistrictName,
  isValidCoordinates,
  isCoordinatesEqual,
  calculateDistance,
  parseCoordinates,
  preloadPlugins,
}
