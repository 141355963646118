/*
 * @Author: tzz
 * @LastEditors: Please set LastEditors
 * @Description:圆环进度条
 * @Date: 2019-03-23 17:57:14
 * @LastEditTime: 2019-11-25 17:39:41
 */

import * as React from 'react'
import { toCanvas } from './draw-rate'
import './index.less'
import star_big from '@assets/images/star_big@3x.png'
import star_little from '@assets/images/star_little@3x.png'
import uuidv4 from 'uuid/v4'
// const uuidv4 = require('uuid/v4')

interface IPros {
  name: string
  value: string
  type: string
  stepName: string
  authStatusText?: string //审核状态文案
  curStepText?: string
}

interface IState {}

export default class CanvasRate extends React.Component<IPros, IState> {
  canvasId: string

  constructor(props) {
    super(props)
    this.canvasId = uuidv4()
  }

  componentDidMount() {
    toCanvas(
      this.canvasId,
      this.props.value,
      this.props.name,
      //Sx - 红牌，Sy - 黄牌，S0 - S0，S1 - S1，S2 - S2，S3 - S3。
      //@deprecated 0:不展示 1:星图案 2:红牌预警 3:黄牌预警 4：红牌 5：黄牌
      this.props.type,
      this.props.curStepText
    )
  }

  componentWillReceiveProps(props) {
    console.log('---->componentWillReceiveProps', props)
    toCanvas(
      this.canvasId,
      props.value,
      props.name,
      props.type,
      props.curStepText
    )
  }

  render() {
    return (
      <div className="pic-canvas">
        <div className="canvas-inner">
          <canvas id={this.canvasId} width="250" height="250" />
          <div className="rating">{this._renderStepName()}</div>
        </div>
      </div>
    )
  }

  _renderStepName = () => {
    //Sx - 红牌，Sy - 黄牌，S0 - S0，S1 - S1，S2 - S2，S3 - S3。
    //审核
    if (this.props.authStatusText) {
      return (
        <span className="des-bg" style={{ background: '#CCCCCC' }}>
          {`${this.props.stepName}(${this.props.authStatusText})`}
        </span>
      )
    }

    //红牌
    if (this.props.type === 'Sx') {
      return (
        <span className="des-bg" style={{ background: 'red' }}>
          {this.props.stepName}
        </span>
      )
    }
    //黄牌
    if (this.props.type === 'Sy') {
      return (
        <span className="des-bg" style={{ background: 'orange' }}>
          {this.props.stepName}
        </span>
      )
    }

    //其他 S0 S1
    return (
      <span className="des-bg" style={{ background: '#3377FF' }}>
        {this.props.stepName}
      </span>
    )
  }

  //@deprecated
  _renderStar = () => {
    //0:不展示 1:星图案 2:红牌预警 3:黄牌预警 4：红牌 5：黄牌 废弃
    //Sx - 红牌，Sy - 黄牌，S0 - S0，S1 - S1，S2 - S2，S3 - S3。
    if (this.props.type === '0') {
      return null
    }
    if (this.props.type === '1') {
      return (
        <div className="stars">
          <img className="star-little" src={star_little} />
          <img className="star-big" src={star_big} />
          <img className="star-little" src={star_little} />
        </div>
      )
    }
    if (this.props.type === '2') {
      if (this.props.stepName) {
        return (
          <span className="des-bg" style={{ background: 'red' }}>
            {this.props.stepName}
          </span>
        )
      } else {
        return (
          <span className="des-bg" style={{ background: 'red' }}>
            · 红牌预警 ·
          </span>
        )
      }
    }
    if (this.props.type === '3') {
      if (this.props.stepName) {
        return (
          <span className="des-bg" style={{ background: 'red' }}>
            {this.props.stepName}
          </span>
        )
      } else {
        return (
          <span className="des-bg" style={{ background: 'orange' }}>
            · 黄牌预警 ·
          </span>
        )
      }
    }
    if (this.props.type === '4') {
      if (this.props.stepName) {
        return (
          <span className="des-bg" style={{ background: 'red' }}>
            {this.props.stepName}
          </span>
        )
      } else {
        return (
          <span className="des-bg" style={{ background: 'red' }}>
            · 红牌 ·
          </span>
        )
      }
    }
    if (this.props.type === '5') {
      if (this.props.stepName) {
        return (
          <span className="des-bg" style={{ background: 'red' }}>
            {this.props.stepName}
          </span>
        )
      } else {
        return (
          <span className="des-bg" style={{ background: 'orange' }}>
            · 黄牌 ·
          </span>
        )
      }
    }
  }
}
