import { useRouteError } from 'react-router-dom'
import loadFailedImg from '@/assets/images/load-failed.png'
import './index.less'

export default function ErrorPage() {
  const error = useRouteError() as any
  let errMsg: any = error?.statusText || error?.message || null
  errMsg =
    typeof errMsg === 'string' && errMsg.toLowerCase() === 'not found'
      ? '页面未找到'
      : errMsg

  return (
    <div className="sys-error-page">
      <img
        src={loadFailedImg}
        className="palceholder-view-image"
        alt="pageNotFound"
      />
      <h1>抱歉!</h1>
      <div className="sys--desc">发生了一些错误~</div>
      {errMsg && (
        <p>
          <i>{errMsg}</i>
        </p>
      )}
    </div>
  )
}
