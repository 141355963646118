/*
 * @Author: tzz
 * @LastEditors: tzz
 * @Description: cell三行显示
 * @Date: 2019-03-20 17:29:49
 * @LastEditTime: 2019-03-22 11:27:47
 */

import * as React from 'react'
import './index.less'

interface IProps {
  name: string
  value?: string
  des?: string
  desStyleType?: string //0无样式，1蓝色背景，2灰色背景
}

interface IState {}

export default class TriCell extends React.Component<IProps, IState> {
  static defaultProps = {
    name: '',
    value: '',
    des: '',
    desStyleType: '0'
  }

  render() {
    let desStyle = 'des'
    if (this.props.desStyleType === '1') {
      desStyle += ' des-h'
    }
    if (this.props.desStyleType === '2') {
      desStyle += ' des-l'
    }

    return (
      <div className="cell">
        <span className="name">{this.props.name}</span>
        <span className="value">{this.props.value}</span>
        <span className={`${desStyle}`}>{this.props.des}</span>
      </div>
    )
  }
}
