/*
 * @Author: 韩念琪[18112596]
 * @Email: 18112596@cnsuning.com
 * @Date: 2019-07-24 01:21:38
 * @Last Modified by: 刘嘉晨(17031607)
 * @Last Modified time: 2020-01-08 16:47:48
 * @Description: 端测异常上报插件
 */

import { util } from '@kits'
import Config, { __ENV__ } from '@config'
import ArgoPlugin from './argo-plugin'

declare global {
  namespace Argo {
    /** Argo js 调试插件 */
    namespace ExceptionsUploadArgoPlugin {
      /** 加载状态 code 标识码 */
      const LOADING_STATUS: typeof LoadingStatus
    }
  }
}

export enum LoadingStatus {
  /** 页面加载中 ... */
  LOADING = 'LOADING',
  /** 页面加载成功 ... */
  SUCCESSFULL = 'SUCCESSFULL',
  /** 页面加载失败 ... */
  FAILED = 'FAILED'
}

export interface ISendMsgV2Param {
  /** 神奇的 siteid */
  siteid?: string
  /** 神奇的 resourceType */
  resourceType?: string
  /** 产品线标识 */
  bid?: string
  /** 异常类型; 1: 系统异常 2: 产品异常 */
  error_type?: 1 | 2
  /** 异常编码, 某个产品下的业务异常对应的唯一编码 */
  error_code?: string
  /** 调用对应业务接口之后, 对应请求响应情况的状态码; 0: 成功, 1: 异常 */
  status?: 0 | 1
  /** 接口名称 */
  type_name?: string
  /** 异常详情描述备注, 这个是描述该异常：JSON 格式字符串 */
  error_detail?: {
    /** 异常描述 Value */
    errorDesc?: string
    /** 场景描述(自定义) */
    prefix?: string
    /** 品类 Value */
    productClass?: string
    /** 经营方式 Value */
    magemode?: string
    /** 营销活动 Value */
    saleactivity?: string
    /** 当前页面来源页的 url 的 Value */
    furl?: string
    /** 品牌 Value */
    brand?: string
    /** 商品编码 Value */
    goodsCode?: string
    /** sku 的 Value */
    sku?: string
    /** 事业部 Value */
    busunit?: string
    /** 店铺 Value */
    shop?: string
    /** 商品组 Value */
    productGroup?: string
    /** 地区 Value */
    region?: string
  }
  /** 会员编码, 如易购网站需要, 则从 cookie 里取、变量名称为 custno */
  member_id?: string
  /** 会员等级, 如易购网站需要、则从 cookie 里取、变量名称为 custLevel */
  member_level?: string
  /** 区域, 如易购网站需要、则从 cookie 里取、变量名称为 SN_CITY */
  region?: string
  /** 是否超级会员 */
  issuper?: boolean
}

/**
 * 注意: 此构造类是从 sa-analytics.js 中搬运出来的，不要随意改动
 */
export class UOM {
  /**
   * @description 手动上报 uom 数据
   * @param params
   */
  sendMsgV2(params: ISendMsgV2Param) {
    try {
      const {
        _snma: visitor = '',
        _snmb: session = '',
        custno: member_id = '',
        custLevel: member_level = '',
        SN_CITY: region = ''
      } = util.parseCookie(document.cookie)
      const { 1: visitorID = '' } = visitor.split('|')
      const { 0: sessionID = '' } = session.split('|')

      const {
        bid = '-',
        type_name = '-',
        error_type = '-',
        error_code,
        error_detail,
        resourceType,
        issuper = '-',
        siteid = '-'
      } = params
      /** 当前所处页面的 url */
      const url = document.location.href
      const ua = navigator.userAgent
      const platform_type = resourceType || 'web'

      //TODO: pageName

      var errdetail = '{}'
      try {
        errdetail = encodeURIComponent(JSON.stringify(error_detail))
      } catch (e) {
        console.error('[uom]', 'error_detail', e)
        errdetail = '{}'
      }

      const uploadParamStr =
        '' +
        'typname=' +
        type_name +
        '&errtype=' +
        error_type +
        '&errcode=' +
        error_code +
        '&errdetail=' +
        errdetail +
        '&mbrid=' +
        member_id +
        '&mbrlevel=' +
        member_level +
        '&region=' +
        region +
        '&url=' +
        encodeURIComponent(url) +
        '&ua=' +
        encodeURIComponent(ua) +
        '&issuper=' +
        issuper +
        '&type=' +
        platform_type +
        '&vid=' +
        visitorID +
        '&sid=' +
        sessionID +
        '&furl=' +
        encodeURIComponent(this.getFromUrl()) +
        '&pvid=' +
        `${Date.now()}${(Math.random() * 100000) | 0}` +
        '&ct=' +
        `${Date.now()}` +
        '&bizid=' +
        bid +
        '&v=' +
        '-' +
        '&siteid=' +
        siteid

      const _protocol = `${document.location.protocol}//`
      this.sendByImg(
        _protocol + this.getServer() + '/excp.gif?' + uploadParamStr
      )
    } catch (e) {
      console.error('[uom]', 'sendMsgV2', e)
    }
  }

  /**
   * @description 通过img标签发送数据
   * @param { string } url
   */
  private sendByImg(url: string) {
    const n = (Math.random() * 2147483647) | 0
    var img = new Image(1, 1)
    window['__n'] = img
    img.onload = img.onerror = function() {
      window['__n'] = null
      img = null
    }
    img.src = url + '&i=' + n
  }

  /**
   * @description 获取服务器地址
   */
  private getServer() {
    switch (__ENV__) {
      // case 'sit':
      //   return 'sasit.suning.cn'
      case 'sit':
      case 'prexz':
      case 'prexg':
        return 'saprexg.cnsuning.com'
      case 'pro':
        return 'sa.suning.cn'
      default:
        return 'sasit.suning.cn'
    }
  }

  private getFromUrl() {
    var fromUrl = document.referrer
    var toUrl = document.location.href // 不能使用全局变量，因为此处可能需要根据锚点的值获取来自页面
    var _protocol = location.protocol
    var { _snml: httpsUrl } = util.parseCookie(document.cookie)
    if (
      (_protocol == 'https:' &&
        fromUrl != '' &&
        httpsUrl &&
        httpsUrl.substring(0, 6) == 'https:' &&
        httpsUrl != fromUrl) || // https
      (_protocol == 'http:' &&
        fromUrl == '' &&
        httpsUrl &&
        httpsUrl.substring(0, 6) == 'https:') ||
      (_protocol == 'https:' &&
        fromUrl == '' &&
        httpsUrl &&
        httpsUrl.substring(0, 6) == 'https:') ||
      (fromUrl != '' && httpsUrl && httpsUrl != fromUrl) // 页面使用iframe
    ) {
      fromUrl = httpsUrl
    }
    if (httpsUrl) {
      this.delCookie('_snml')
    }
    if (toUrl != '' && toUrl.indexOf('sourceUrl4Sa') != -1) {
      var sourceUrl4Sa = this.saPick(toUrl, 'sourceUrl4Sa', '&')
      fromUrl = decodeURIComponent(sourceUrl4Sa)
    } else if (
      (!fromUrl || fromUrl == null || fromUrl == '') &&
      toUrl != '' &&
      toUrl.indexOf('returnUrl') != -1
    ) {
      var parms = toUrl.substring(toUrl.indexOf('?') + 1, toUrl.length)
      var parmsArr = parms.split('&')
      for (var i = 0; i < parmsArr.length; i++) {
        var parmArr = parmsArr[i].split('=')
        if (parmArr[0] == 'returnUrl') {
          fromUrl = parmArr[1]
        }
      }
    }
    return fromUrl
  }

  private saPick(map, key, separator) {
    function isEmpty(o) {
      return undefined == o || '' == o || '-' == o
    }
    var result = '-',
      idx
    if (!isEmpty(map) && !isEmpty(key) && !isEmpty(separator)) {
      idx = map.indexOf(key)
      if (idx > -1) {
        var endIdx = map.indexOf(separator, idx)
        if (endIdx < 0) {
          endIdx = map.length
        }
        result = map.substring(idx + key.length + 1, endIdx)
      }
    }
    return result
  }

  /**
   * 删除cookie
   */
  private delCookie(name) {
    this.addCookie(name, '', '/', -10000, '')
  }

  /**
   * @description 增加 cookie
   * @param name
   * @param value
   * @param path
   * @param expires
   * @param domain
   */
  private addCookie(name, value, path, expires, domain) {
    var str = name + '=' + escape(value)
    if (expires != '') {
      var date = new Date()
      date.setTime(date.getTime() + expires)
      str += ';expires=' + (date as any).toGMTString()
    }
    if (path != '') {
      str += ';path=' + path // 指定可访问cookie的目录
    }
    var dm = this.getTopDomain()
    str += ';domain=' + dm
    document.cookie = str
  }

  /**
   * @description 获取顶级域名
   * @param webUrl
   */
  private getTopDomain(webUrl?) {
    var d = webUrl || location.hostname
    var c = d.split('.')
    if (d.indexOf('localhost') > -1) {
      return 'localhost'
    }
    if (c instanceof Array) {
      var b = c.length,
        num = 2, // 根域名
        s = '',
        e = c.splice(b - num, b) // 处理 三级四级域名
      if (d.indexOf('.com.cn') != -1) {
        // 处理 .com.cn的域名
        e = d.split('.').splice(b - num - 1, b)
      }
      for (var i = 0; i < e.length; i++) {
        s += '.' + e[i]
      }
      return s
    }
  }
}

const uploadMsg = new UOM()

import { BaseFetch } from '@kits'

// 终端编码: 809bdac26064457e9075af8a20a44a67resourceType: TensiteId: Ten 接口类型: 300293

const param4UomBase: ISendMsgV2Param = {
  type_name: '-',
  resourceType: 'Ten',
  siteid: 'Ten'
}

/** 增加了异常处理的 JSON.stringify */
function JsonStringify(param) {
  try {
    if (typeof param === 'object') {
      return JSON.stringify(param)
    } else if (typeof param === 'string') {
      return param
    } else {
      return 'null'
    }
  } catch (e) {
    return 'null'
  }
}

/**
 * @description 上报页面初始化崩溃的异常
 * @param store
 */
const pageInitExceptionsMiddleware = store => next => async action => {
  const param4Uom: ISendMsgV2Param = {
    ...param4UomBase,
    bid: Config.EXCEPTIONS_UPLOAD.BIZ_ID,
    error_type: 1,
    error_code: `argojs-${Config.EXCEPTIONS_UPLOAD.BIZ_ID}-10001`
  }

  try {
    /** 获取之前的状态 */
    var { argoLoading } = store.getState()
    if (!argoLoading) {
      console.error('未捕获到 argoLoading 状态位，请检查 store 定义！')
    }
  } catch (e) {
    console.log(e)
  }

  await next(action)

  try {
    /**
     * 获取之后的状态
     * 判断若页面状态加载失败，上报本次异常
     */
    const { argoLoading: nextArgoLoading } = store.getState()
    if (
      argoLoading &&
      argoLoading.code !== nextArgoLoading.code &&
      nextArgoLoading.code === LoadingStatus.FAILED
    ) {
      param4Uom.error_detail = {
        errorDesc: `${nextArgoLoading.msg}#${location.href}#${document.cookie}`
      }
      uploadMsg.sendMsgV2(param4Uom)
    }
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description 上报接口请求异常(非200)
 * @param next
 */
const apiSystemExceptionsMiddleware: BaseFetch.middleware = next => async (
  requestConfig,
  state
) => {
  await next(requestConfig, state)
  try {
    if (state.rawRes.status !== 200) {
      /** 接口请求异常(非200) */
      const param4Uom: ISendMsgV2Param = {
        ...param4UomBase,
        bid: Config.EXCEPTIONS_UPLOAD.BIZ_ID,
        error_type: 1,
        status: 1,
        error_code: `argojs-${Config.EXCEPTIONS_UPLOAD.BIZ_ID}-10002`,
        error_detail: {
          errorDesc: `${location.href}#${requestConfig.url}#${JsonStringify(
            requestConfig.params || requestConfig.data
          )}#${state.rawRes.status || '302'}#${document.cookie}`
        }
      }
      uploadMsg.sendMsgV2(param4Uom)
    } else {
      if (state.ret.code !== '000000') {
        /** 业务异常 */
        const param4Uom: ISendMsgV2Param = {
          bid: Config.EXCEPTIONS_UPLOAD.BIZ_ID,
          error_type: 2,
          status: 1,
          error_code: `argojs-${Config.EXCEPTIONS_UPLOAD.BIZ_ID}-10003`,
          error_detail: {
            errorDesc: `${location.href}#${requestConfig.url}#${JsonStringify(
              requestConfig.params || requestConfig.data
            )}#${JsonStringify(state.rawRes.data)}#${document.cookie}`
          }
        }
        uploadMsg.sendMsgV2(param4Uom)
      }
    }
  } catch (e) {
    console.log(e)
  }
}

class ExceptionsUploadArgoPlugin extends ArgoPlugin {
  init() {
    this.jsScriptError()
  }

  fetchRequest() {
    return apiSystemExceptionsMiddleware
  }

  simReduxDataChange() {
    return pageInitExceptionsMiddleware
  }

  /**
   * @description 上报 js 脚本报错
   */
  jsScriptError() {
    window.addEventListener('error', ({ message }) => {
      const param4Uom: ISendMsgV2Param = {
        ...param4UomBase,
        bid: Config.EXCEPTIONS_UPLOAD.BIZ_ID,
        error_type: 1,
        error_code: `argojs-${Config.EXCEPTIONS_UPLOAD.BIZ_ID}-10004`,
        error_detail: {
          errorDesc: `${location.href}#${message}#${document.cookie}`
        }
      }
      uploadMsg.sendMsgV2(param4Uom)
    })
  }

  globalExport() {
    return {
      LOADING_STATUS: LoadingStatus
    }
  }
}

export default new ExceptionsUploadArgoPlugin()
