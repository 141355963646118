/*
 * @Author: 韩念琪[18112596]
 * @Email: 18112596@cnsuning.com
 * @Date: 2019-07-23 21:39:51
 * @Last Modified by: 韩念琪[18112596]
 * @Last Modified time: 2019-08-17 10:43:59
 * @Description: Argojs 插件基类
 */

import { BaseFetch } from '@kits'
declare global {
  /** 全局 ArgoJs 的对象 */
  namespace Argo {}
}
abstract class ArgoPlugin {
  // /** 初始化标识符，用于标记初始化是否成功 */
  // $$init = false;
  /** 【生命周期】 插件初始化，如果返回 false 或 reject 则认为初始化失败，后续生命周期将不会再触发 */
  init?(): void | Promise<void> | boolean | Promise<boolean>
  /** 插件对外导出的对象，导出的对象会被挂载在名为 Argo 的全局变量上 */
  globalExport?(): any
  /** 【生命周期】 @argojs/sim-redux 每次调用 actions 时触发 */
  simReduxDataChange?(): any
  /** 【生命周期】 每次发起网络请求请求触发 */
  fetchRequest?(): BaseFetch.middleware
  /** 【生命周期】 路由跳转 */
  // onRouteChange?(): void;
}

export default ArgoPlugin
