/*
 * @Author: hannianqi
 * @Date: 2019-03-27 15:17:55
 * @Last Modified by: hannianqi
 * @Last Modified time: 2019-04-02 17:52:11
 */

import React, { useState } from 'react'
import styles from './index.module.less'
import { Router } from '@kits'
import IconEX from '../icon-ex'
import Config from '@config'

interface IProps {
  /** 是否固定到顶部 */
  fixedTop?: boolean
  /** 是否展示左侧退出按钮 */
  showLeft?: boolean
  /** navBar 的标题 */
  title?: string
  /** 右侧自定义坑位 */
  right?: JSX.Element
  /** tab 切换栏 */
  tab?: string[]
  /** tab栏下标切换事件 */
  onTabChange?: (index: number) => void
  /** 是否需要下边线 */
  border?: boolean
}

/**
 * 通用头部导航栏
 * @param { IProps } props
 * @return { JSX.Element }
 */
function NavBar(props: IProps) {
  /** 设置默认值 */
  const fixedTop = props.fixedTop === undefined ? true : props.fixedTop
  const border = props.border === undefined ? true : props.border
  const showLeft = props.showLeft === undefined ? true : props.showLeft
  const { title, right, tab, onTabChange } = props
  const [tabIndex, setTabIndex] = useState(0)
  return (
    <div className={`${styles['navbar-container']}`}>
      <div
        className={`
          ${styles['navbar-wrapper']}
          ${border ? styles['navbar-border'] : ''}
        `}
        style={{ position: fixedTop ? 'fixed' : 'absolute' }}
      >
        {showLeft && (
          <div
            className={`${styles['left-wrapper']}`}
            onClick={() => {
              !Config.ROOT_PATH.includes(Config.CURRENT_PATH) && Router.Back()
            }}
          >
            <IconEX type="left" />
          </div>
        )}
        {!tab ? (
          <div className={`${styles.title}`}>{title}</div>
        ) : (
          <div className={`${styles['tab-wrapper']}`}>
            {tab.map((val, index) => (
              <span
                className={`
                    ${styles.tabItem}
                    ${index === tabIndex ? styles.active : ''}
                  `}
                key={val}
                onClick={() => {
                  setTabIndex(index)
                  onTabChange && onTabChange(index)
                }}
              >
                {val}
              </span>
            ))}
          </div>
        )}
        <div>{right}</div>
      </div>
    </div>
  )
}

export default NavBar
