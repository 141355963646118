/*
 * @Author: 苏鑫
 * @Date: 2023-07-03 19:40:43
 * @Last Modified by: 12072513
 * @Last Modified time: 2023-07-24 17:38:52
 */

import './index.less'

import { ITaskItem, queryTaskItemByPage } from './webapi'
import { ListView, PullToRefresh } from 'antd-mobile'
import { Sa, util } from '@kits'
import { useEffect, useLayoutEffect, useState } from 'react'

import { CustormTab } from './components/custorm-tab/custorm-tab'
import { NoData } from './components/no-data'
import TodosTaskItem from './components/todo-task-item'
import loadingImg from '@assets/images/loading.gif'
import { updateTabNoticeCount } from '../home/HomeService'
import { updateUnFinishTodoCount } from '@/stores'
import { updateUnFinishTodoSize } from '@/views/todos/webapi'
import { useActivate } from 'react-activation'

const mapTab = {
  1: 'bR3TZhaAaa',
  2: 'expired',
  3: 'completed',
}
const ds = new ListView.DataSource({
  rowHasChanged: (r1, r2) => r1 !== r2,
})

/** 首页待办 */
const Todos = () => {
  const [currentTab, setCurrentTab] = useState<number>(1)
  const [dataList, setDataList] = useState<ITaskItem[]>([])
  const [pullRefreshLoading, setRefreshLoading] = useState<boolean>(false)
  let [pullLoading, setPullLoading] = useState<boolean>(true)
  let [pageNum, setPageNum] = useState<number>(1)
  const [totalPageCount, setTotalPageCount] = useState<number>(1)
  const fetchData = (taskStatus) => {
    setPullLoading(true)
    queryTaskItemByPage(taskStatus, pageNum)
      .then((data) => {
        const resList = data?.dataList || []
        const list = pageNum == 1 ? resList : resList.concat(dataList)
        setDataList(list)
        setPullLoading(false)
        setRefreshLoading(false)
        setTotalPageCount(data?.totalPageCount || 1)

        if (currentTab === 1 && data?.dataList?.length > 0) {
          updateUnFinishTodoCount(data?.dataList.length)
        }
      })
      .catch(() => {
        setPullLoading(false)
        setRefreshLoading(false)
      })
  }
  const renderRow = (item, i) => {
    return <TodosTaskItem data={item} type={currentTab} />
  }
  // 触底加载
  const onEndReached = () => {
    if (Number(pageNum) < totalPageCount) {
      pageNum++
      setPageNum(pageNum)
    }
  }
  //下拉刷新
  const onRefresh = () => {
    setRefreshLoading(true)
    setPageNum(1)
    if (pageNum === 1) {
      fetchData(currentTab)
    }
  }

  useActivate(() => {
    document.title = '超级经理人'
    Sa.update(`pgtitle=任务系统待办;pageid=bR3TZhaAaa;${util.saRoleParam()}`)
  })

  useLayoutEffect(() => {
    document.title = '超级经理人'
    updateTabNoticeCount()
    updateUnFinishTodoSize()
  }, [])

  useEffect(() => {
    Sa.update(`pgtitle=任务系统待办;pageid=bR3TZhaAaa;${util.saRoleParam()}`)
  }, [])

  useEffect(() => {
    fetchData(currentTab)
  }, [pageNum, currentTab])

  const onTabChange = (index, text) => {
    setCurrentTab(index)
    setPageNum(1)
    //点击埋点
    Sa.sendClickData('tab', {
      eleid: mapTab[index],
      eletitle: text,
      ...util.saRoleParamClickObj(),
    })
  }

  return (
    <div className="task-details-container">
      <CustormTab
        onChange={(index, text) => onTabChange(index, text)}
        currentTab={currentTab}
      />
      {dataList.length > 0 && (
        <ListView
          className={'list-view-container'}
          dataSource={ds.cloneWithRows(dataList)}
          renderRow={(rowData, id1, i) => renderRow(rowData, i)}
          onEndReached={onEndReached}
          onEndReachedThreshold={10}
          pullToRefresh={
            //@ts-ignore
            <PullToRefresh
              refreshing={pullRefreshLoading}
              onRefresh={onRefresh}
            />
          }
          renderFooter={() => {
            if (
              Number(pageNum) == totalPageCount &&
              dataList.length > 0 &&
              !pullLoading
            ) {
              return (
                <div className="bottom">
                  --&nbsp;&nbsp;&nbsp; &nbsp; 到底了&nbsp; &nbsp; &nbsp; &nbsp;
                  --
                </div>
              )
            }
          }}
        />
      )}
      {dataList.length == 0 && pullLoading && (
        <div className="init-loading">
          <img src={loadingImg} />
          <p>加载中...</p>
        </div>
      )}

      {dataList.length == 0 && !pullLoading && (
        <NoData
          onRefresh={() => {
            fetchData(currentTab)
          }}
          isShowButton={true}
        />
      )}
    </div>
  )
}

export default Todos
