/*
 * @Author: 韩念琪[18112596]
 * @Email: 18112596@cnsuning.com
 * @Date: 2019-07-11 21:06:43
 * @Last Modified by: 唐正宗(18133443)
 * @Last Modified time: 2021-09-15 16:17:33
 * @Description: uom 自动埋点
 */

import { BaseFetch, util } from '@kits'
import Config, { __ENV__ } from '@config'

import ArgoPlugin from './argo-plugin'
import dayjs from 'dayjs'
import loadScript from 'load-script'

interface ISaHost {
  /** 埋点探针 */
  SA_HOST: string
  /** uom探针 */
  SA_ANALYTICS: string
}

export interface IUomParam {
  /** sa.openAPI.ISendMsgV2Param 的 error_code */
  code: string
  /** sa.openAPI.ISendMsgV2Param 的 error_detail 下的 errorDesc */
  desc?: string | { key: string; value: string }[]
}

const uomMiddleware: BaseFetch.middleware = next => async (
  requestConfig,
  state
) => {
  const cookie = util.parseCookie(document.cookie)
  const uomParam = state.uomParam
  // 初始化 uom 参数
  const initParam4Uom = {
    bid: Config.UOM.BIZ_ID,
    error_type: null,
    error_code: null,
    status: null,
    error_detail: {}
  }

  let { code, desc } = uomParam || ({} as IUomParam)

  const param4Uom: sa.openAPI.ISendMsgV2Param = {
    ...initParam4Uom,
    error_code: code
  }

  if (typeof desc === 'string') {
    param4Uom.error_detail.errorDesc = desc
  }

  try {
    param4Uom.type_name = requestConfig.url.split('/').pop()
    param4Uom.member_id = cookie.custno
    param4Uom.member_level = cookie.custLevel
    param4Uom.region = cookie.SN_CITY
    param4Uom.status = 0
  } catch (e) {
    console.error(e)
  }

  await next(requestConfig, state)

  if (state.rawRes.status !== 200) {
    // status 非 200
    param4Uom.status = 1
    param4Uom.error_type = 1
    param4Uom.error_detail = null
    uomParam && sa.openAPI.sendMsgV2(param4Uom)
    console.log('[uom]', 'uomParam', uomParam, param4Uom)
  } else if (state.ret.code !== '000000') {
    try {
      // 插入对应 uom 参数
      param4Uom.error_type = 2
      if (Array.isArray(desc)) {
        const errorDescObj = desc.find(({ key }) => key === code) ||
          desc.find(({ key }) => key === 'default') || { value: '' }
        param4Uom.error_detail.errorDesc = errorDescObj.value
      }
      param4Uom.error_detail.errorDesc = `${dayjs(Date.now()).format(
        'YYYY-MM-DD'
      )}#${cookie.custno}#${param4Uom.error_detail.errorDesc}`
      param4Uom.error_detail = JSON.stringify(param4Uom.error_detail) as any
      /** 设置 resourceType、siteId */
      uomParam && sa.openAPI.sendMsgV2(param4Uom)
      uomParam && console.log('[uom]', 'uomParam', param4Uom)
    } catch (e) {
      console.error(e)
    }
  }
}

type IENV = 'dev' | 'sit' | 'prexz' | 'prexg' | 'pro'
type IUomArgoPluginConfig = {
  [key in IENV]: {
    /** 埋点引入 js 地址 */
    SA_HOST: string
    /** uom 探针地址 */
    SA_ANALYTICS: string
  }
}

class UomArgoPlugin extends ArgoPlugin {
  private SA_HOST_CONFIG: ISaHost
  private readonly config: IUomArgoPluginConfig = {
    dev: {
      SA_HOST: '//sitres.suning.cn/javascript/sn_da/sa_simple.js',
      SA_ANALYTICS: '//sitres.suning.cn/javascript/sn_da/sa-analytics.js'
    },
    sit: {
      SA_HOST: '//sitres.suning.cn/javascript/sn_da/sa_simple.js',
      SA_ANALYTICS: '//sitres.suning.cn/javascript/sn_da/sa-analytics.js'
    },
    prexz: {
      SA_HOST: '//sitres.suning.cn/javascript/sn_da/sa_simple.js',
      SA_ANALYTICS: '//sitres.suning.cn/javascript/sn_da/sa-analytics.js'
    },
    prexg: {
      SA_HOST: '//resprexg.suning.cn/javascript/sn_da/sa_simple.js',
      SA_ANALYTICS: '//resprexg.suning.cn/javascript/sn_da/sa-analytics.js'
    },
    pro: {
      SA_HOST: '//res.suning.cn/javascript/sn_da/sa_simple.js',
      SA_ANALYTICS: '//res.suning.cn/javascript/sn_da/sa-analytics.js'
    }
  }

  constructor(private readonly __ENV__: IENV = 'dev') {
    super()
  }

  init() {
    this.SA_HOST_CONFIG = this.config[this.__ENV__]
    return this.loadSaScript()
  }

  fetchRequest() {
    return uomMiddleware
  }

  /**
   * 动态插入 [埋点/uom] meta script 标签
   */
  private loadSaScript() {
    const metaFragment = document.createDocumentFragment()
    const autoclickMeta = document.createElement('meta')
    const siteidMeta = document.createElement('meta')
    const pageidMeta = document.createElement('meta')

    pageidMeta.name = 'pageid'
    // 默认需要配置一个有效的 pageid
    // 目前必须要有非空字符串的初始值才能支持无痕化采集
    pageidMeta.content = 'null'
    metaFragment.appendChild(pageidMeta)
    // 创建 自动埋点 meta
    autoclickMeta.name = 'autoclick'
    autoclickMeta.content = 'true'
    metaFragment.appendChild(autoclickMeta)
    // 这里填站点的siteid
    siteidMeta.name = 'siteid'
    siteidMeta.content = Config.UOM.SITE_ID
    metaFragment.appendChild(siteidMeta)
    document.head.appendChild(metaFragment)

    // 这里加入隐藏域
    const inputFragment = document.createDocumentFragment()
    ;['resourceType', 'pagename'].forEach(id => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.id = id
      input.value = id === 'resourceType' ? Config.UOM.RESOURCE_TYPE : ''
      inputFragment.appendChild(input)
    })
    document.body.appendChild(inputFragment)

    // 引入 uom 探针
    const uomTask = new Promise((resolve, reject) => {
      loadScript(this.SA_HOST_CONFIG.SA_ANALYTICS, err => {
        if (err) {
          console.error(err)
          reject(err)
        } else {
          try {
            // 初始化
            sa.openAPI = sa.openAPI || ({} as typeof sa.openAPI)
            sa.initTrackerConfig()
            resolve(null)
          } catch (e) {
            console.error(e)
            reject()
          }
        }
      })
    })

    // 插入 埋点 脚本
    const saTask = new Promise((resolve, reject) => {
      loadScript(this.SA_HOST_CONFIG.SA_HOST, err => {
        if (err) {
          console.error(err)
          reject(err)
        } else {
          resolve(null)
        }
      })
    })

    return Promise.all([uomTask, saTask]).then(
      () => {
        console.log('------- 埋点 / uom 脚本加载完毕 -------')
      },
      () => {
        console.error('------- 埋点 / uom 脚本加载失败 -------')
      }
    )
  }
}

export default new UomArgoPlugin(__ENV__)
