/*
 * @Author: keqingrong (19040892)
 * @Date: 2019-07-26 17:59:13
 * @LastEditors: keqingrong (19040892)
 * @LastEditTime: 2019-08-11 09:30:44
 * @Description:
 */
import Msg from './msg'
import BaseFetch from './base-fetch'
import * as util from './util'
import throttle, { decThrottle } from './dec-throttle'
import debounce from './dec-debounce'
import Sa from './dec-sa'
import lock from './dec-lock'
import * as AMapUtils from './amap-utils'
import * as BMapUtils from './bmap-utils'
import Douya from './douya'
import DecNavigateTitle from './dec-navigate-title'
import {
  BackEvent,
  OpenEvent,
  PushEvent,
  ReplaceEvent,
} from '@/components/layout/Layout'

const Router = {
  Top: PushEvent,
  Open: OpenEvent,
  Push: PushEvent,
  Replace: ReplaceEvent,
  Back: BackEvent,
}

export {
  AMapUtils,
  BMapUtils,
  Msg,
  BaseFetch,
  Router,
  util,
  throttle,
  decThrottle,
  debounce,
  Sa,
  lock,
  Douya,
  DecNavigateTitle,
}
