import { Variants, motion } from 'framer-motion'
import qs from 'qs'
import { getRouteTransition, getTransition } from './FramerVariants'
import {
  NavigateOptions,
  Outlet,
  To,
  useLocation,
  useNavigate,
  useNavigationType,
} from 'react-router-dom'
import { HTMLAttributes, useEffect } from 'react'
import TabBars from '@/views/home/components/tab-bars/TabBars'
import { CacheWhen, IRoute, RouterCacheKeys } from '@/routes'
import { useAliveController } from 'react-activation'
import { managerRouteStackAndCaches, routeStacks } from './routeStack'
import { Douya, Msg } from '@kits'
import { IRouteAnimation, ROOTPATH } from '@/router-render'

let oldUrl = ''

interface IPageProp extends HTMLAttributes<JSX.Element>, IRouteAnimation {
  action?: Action
  options?: IRoute
}

export default function Layout({
  options = {} as IRoute,
  routerAnimationMode = 'slide',
}: IPageProp) {
  const navigate = useNavigate()
  const location = useLocation()
  const action = useNavigationType()
  const { PushVariants, PopVariants } = getTransition(routerAnimationMode)
  const RouteTransition = getRouteTransition(routerAnimationMode)

  const { dropScope, getCachingNodes } = useAliveController()

  useEffect(() => {
    window.navigate = navigate
    bindRouteEvents()

    return () => {
      removeRouteEvents()
    }
  }, [])

  useEffect(() => {
    const { pathname = '', search = '' } = location
    console.log(
      `%c[Route change] action ==> ${action}, to: ${pathname}${search}`,
      'color: darkviolet'
    )

    if (!routeStacks.length && ROOTPATH === oldUrl && action === 'POP') {
      if (Douya.canIuse('closeBrowser')) {
        Douya.closeBrowser()
      }
    }
    oldUrl = pathname
  }, [location, action])

  useEffect(() => {
    managerRouteStackAndCaches({
      route: options,
      action,
      unmountRoute,
    })
  }, [action, location])

  async function unmountRoute(routePath: string, when: CacheWhen) {
    // setWating(false);
    await dropScope(RouterCacheKeys[routePath])
    console.log(
      `%c[Cache route: ${routePath}]%c auto droped with <${when} mode>, left caching keys: ${getLeftCacheNodeKeys()}`,
      'color: #f60',
      'color: #666'
    )
  }

  function getLeftCacheNodeKeys() {
    const nodes = getCachingNodes() || []
    return JSON.stringify(nodes.map((v) => v.name))
  }

  return (
    <motion.div
      key={location.pathname}
      animate="in"
      className="motion-wrapper"
      exit="out"
      initial="initial"
      transition={RouteTransition}
      variants={
        action === Action.Push
          ? (PushVariants as Variants)
          : (PopVariants as Variants)
      }
    >
      <div
        className={`layout-container ${options.withTabs ? 'with-tabs' : ''} ${
          options.cache ? 'with-cache' : ''
        }`}
      >
        <Outlet />
      </div>
      {/** 底部tabs */}
      {options.withTabs && <TabBars />}
    </motion.div>
  )
}

function openHandler<T extends Record<string, any>>({
  pathName,
  param = {} as T,
}: {
  pathName: string
  param?: T
}) {
  window.navigate(pathName + (param ? '?' + qs.stringify(param) : ''))
}

function pushHandler({
  to,
  options = {},
}: {
  to: To
  options?: NavigateOptions
}) {
  window.navigate(to, { ...options, ...{ replace: false } })
}

function goHandler(deep = -1) {
  window.navigate(deep)
}

function doReplace({
  to,
  options = {},
}: {
  to: To
  options?: NavigateOptions
}) {
  window.navigate(to, { ...options, ...{ replace: true } })
}

function removeRouteEvents() {
  Msg.off('navigate::route::open', openHandler)
  Msg.off('navigate::route::push', pushHandler)
  Msg.off('navigate::route::go', goHandler)
  Msg.off('navigate::route::replace', doReplace)
}

function bindRouteEvents() {
  Msg.on('navigate::route::open', openHandler)
  Msg.on('navigate::route::push', pushHandler)
  Msg.on('navigate::route::go', goHandler)
  Msg.on('navigate::route::replace', doReplace)
}

export function OpenEvent<T extends Record<string, any>>(
  pathName: string,
  param?: T
) {
  Msg.emit('navigate::route::open', { pathName, param })
}

export function PushEvent<State>(to: To, state?: State) {
  Msg.emit('navigate::route::push', { to, options: { state } })
}

export function BackEvent(deep = -1) {
  Msg.emit('navigate::route::go', deep)
}

export function ReplaceEvent<State>(to: To, state?: State) {
  Msg.emit('navigate::route::replace', { to, options: { state } })
}

export enum Action {
  Pop = 'POP',
  Push = 'PUSH',
  Replace = 'REPLACE',
}
