import MessageSegmentControl from './widgets/message-segment-control/MessageSegmentControl'
import NoticePage from './widgets/notices/NoticePage'
import SystemMessage from './widgets/system-message-page/SystemMessage'
import { EAccountRole } from '@genre'
import { globalUserState } from '@/stores'
import { updateUnFinishTodoSize } from '@/views/todos/webapi'
import { queryNoticeCount, updateTabNoticeCount } from '../../HomeService'
import { Sa, util } from '@kits'
import { useActivate } from 'react-activation'
import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import './HomeMessages.less'

const tabKeys = ['notices', 'system-messages']

/** 消息 */
export default function HomeMessages() {
  const [activeTab, setActiveTab] = useState(tabKeys[0])
  const [systemMessageCount, setSystemMessageCount] = useState(0)
  const userInfo = useSnapshot(globalUserState)

  async function fetchMessageCount() {
    const count = await queryNoticeCount()
    setSystemMessageCount(count)
  }

  useActivate(() => {
    Sa.update(`pgtitle=公告通知页;pageid=Aut8oDaAAA;${util.saRoleParam()}`)
  })

  useEffect(() => {
    Sa.update(`pgtitle=公告通知页;pageid=Aut8oDaAAA;${util.saRoleParam()}`)
    fetchMessageCount()
    updateTabNoticeCount()
    updateUnFinishTodoSize()
  }, [])

  return (
    <>
      {userInfo?.accountRole !== EAccountRole.AREA ? (
        <NoticePage onUpdateSysUnread={fetchMessageCount} />
      ) : (
        <div className="home-message-page">
          <div className="segment-container">
            <MessageSegmentControl
              noticeTitle="公告通知"
              sysMsgTitle="系统消息"
              sysMsgNum={systemMessageCount}
              onNoticeTabActive={() => {
                setActiveTab(tabKeys[0]) // 点击公告通知
              }}
              onSysMsgTabActive={() => {
                setActiveTab(tabKeys[1]) // 点击系统消息
                fetchMessageCount()
              }}
            />
          </div>
          {activeTab === tabKeys[0] && (
            <NoticePage onUpdateSysUnread={fetchMessageCount} />
          )}
          {activeTab === tabKeys[1] && (
            <SystemMessage onUpdateSysUnread={fetchMessageCount} />
          )}
        </div>
      )}
    </>
  )
}
