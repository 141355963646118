/*
 * @Author: tzz
 * @LastEditors: 唐正宗(18133443)
 * @Description: 进度圆弧
 * @Date: 2019-03-19 14:45:09
 * @LastEditTime: 2019-05-23 14:50:04
 */

/**
 *
 * @param id html元素id
 * @param progress 进度值
 * @param name 进度名称
 * @param type 进度描述
 */
// let circleLoading

function toCanvas(id, progress, name, stepCode, curStepText: string = '') {
  progress = progress.replace('%', '');

  //圆弧起止点颜色
  //默认蓝色
  //0:不展示 1:星图案 2:红牌预警 3:黄牌预警 4：红牌 5：黄牌
  //Sx - 红牌，Sy - 黄牌，S0 - S0，S1 - S1，S2 - S2，S3 - S3。

  let startColor: string = '#68C5F8';
  let endColor: string = '#3A88F4';

  if (stepCode === 'Sx') {
    startColor = '#F63E8A';
    endColor = '#F62D2D';
  }
  if (stepCode === 'Sy') {
    startColor = '#F3BA3E';
    endColor = '#F26508';
  }

  let devicePixelRatio = window.devicePixelRatio || 1;
  let canvas0 = document.getElementById(id) as HTMLCanvasElement;
  let context = canvas0.getContext('2d');
  let backingStoreRatio = (context as any).webkitBackingStorePixelRatio || 1;
  let ratio = devicePixelRatio / backingStoreRatio;

  //canvas进度条
  let canvas = document.getElementById(id) as HTMLCanvasElement;
  let ctx = canvas.getContext('2d');
  let percent = progress;
  //最终百分比

  let radius = 100; //圆环半径
  let lineWidth = 16; // 圆形线条的宽度
  let fontSize = 14; //字体大小

  canvas.width = 250 * ratio;
  canvas.height = 250 * ratio;

  let circleX = canvas.width / 2 / ratio; // 中心x坐标
  let circleY = canvas.height / 2 / ratio; //中心y坐标

  canvas.style.width = '250px';
  canvas.style.height = '250px';
  ctx.scale(ratio, ratio);

  /**
   * 	画圆环
   * 	cx 圆环中心x坐标
   * 	cy 圆环中心y坐标
   * 	r  圆环半径
   * */
  function drawCircle(cx, cy, r) {
    ctx.beginPath();
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = '#eee';
    ctx.lineCap = 'round';
    ctx.arc(cx, cy, r, Math.PI * (1 - 1 / 6), Math.PI * (2 + 1 / 6));
    ctx.stroke();
  }

  /**
   * 	画弧线
   * 	cx 圆环中心x坐标
   * 	cy 圆环中心y坐标
   * 	r  圆环半径
   * 	endProgress 弧线占圆弧百分比
   * */
  function drawSector(cx, cy, r, endProgress) {
    ctx.beginPath();
    ctx.lineWidth = lineWidth;
    // 渐变色 - 可自定义
    let linGrad = ctx.createLinearGradient(
      circleX - radius - lineWidth,
      circleY,
      circleX + radius + lineWidth,
      circleY
    );
    linGrad.addColorStop(0.0, startColor);
    linGrad.addColorStop(1.0, endColor);
    ctx.strokeStyle = linGrad;
    //圆弧两端的样式
    ctx.lineCap = 'round';
    ctx.arc(
      cx,
      cy,
      r,
      Math.PI * (1 - 1 / 6),
      Math.PI * (1 - 1 / 6 + ((endProgress / 100) * 8) / 6)
    );
    ctx.stroke();
  }

  //刷新
  function loading() {
    //清除canvas内容
    ctx.clearRect(0, 0, circleX * 2, circleY * 2);

    //文字 综合完成率
    ctx.font = fontSize + 'px April';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = '#999';
    ctx.fillText(name, circleX, (circleY * 5) / 8);

    //百分比 50%
    ctx.font = 28 + 'px bold Courier';
    ctx.fillStyle = endColor;
    if (progress === '--') {
      ctx.fillStyle = '#d0d0d0';
      ctx.fillText(toFixed(process + ''), circleX, circleY - 10);
    } else {
      ctx.fillText(toFixed(process + '') + '%', circleX, circleY - 10);
    }

    //文字 当前台阶
    if (curStepText) {
      ctx.font = fontSize + 'px April';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = '#999';
      ctx.fillText(curStepText, circleX, circleY + 20);
    }

    //最外层圆环
    drawCircle(circleX, circleY, radius);

    let endProgress = process;
    if (process > 100) {
      endProgress = 100;
    }
    if (process < 0) {
      endProgress = 0;
    }

    //圆弧
    drawSector(circleX, circleY, radius, endProgress);
    //控制结束时动画的速度
    if (process < progress) {
      if (process / percent > 0.9) {
        process += 0.3;
      } else if (process / percent > 0.8) {
        process += 0.55;
      } else if (process / percent > 0.7) {
        process += 0.75;
      } else {
        process += 1.0;
      }
    }
    if (process >= progress) {
      process = progress;
    }
  }

  let process: number = 0.0;
  process = progress;

  loading();
  setTimeout(() => {
    loading();
  }, 10);
}

const toFixed = (ori: string) => {
  if (ori.includes('.')) {
    return parseFloat(ori + '').toFixed(1);
  } else {
    return ori;
  }
};

export { toCanvas };
