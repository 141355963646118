/*
 * @Author: 杨建新(20014264)
 * @Email: 20014264@cnsuning.com
 * @Date: 2021-12-07 15:46:36
 * @Last Modified by: 杨建新(20014264)
 * @Last Modified time: 2021-12-07 15:47:13
 * @Description: 不知道谁写的页面报错了。错误提示没有加头
 */

const RoutePaths = {
    /** 公告详情 */
    NOTICE_DETAIL:"/notice-detail"
}

export default RoutePaths