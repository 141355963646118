/*
 * @Author: 柯庆荣19040892
 * @Email: 19040892@cnsuning.com
 * @Date: 2019-12-10 19:35:44
 * @Last Modified by: 吴超(20023244)
 * @Last Modified time: 2023-06-09 09:48:48
 * @Description: Description
 */
import { EDeviceType } from '@/stores'
import { ISystemConfig } from './config'

export default class Config implements ISystemConfig {
  HOST = 'https://lsypartenerprexz.cnsuning.com'
  ROOT_PATH = []
  CURRENT_PATH = ''
  SERVICE_IMG_HOST = ''
  APP_BOSS_REPORT_HOST = '//srcfewsh5prexz.cnsuning.com'
  // --------------------------------------------------------- >
  PASSPORT = {
    PASSPORT_LOGIN_HOST: '//passportpre.cnsuning.com/ids/',
    PASSPORT_LOGIN_THEME: 'lsy_skf',
    PASSPORT_DOMAIN_HOST: '//lsybosspcprexz.cnsuning.com/'
  }
  UOM = {
    SITE_ID: '08CJJLR',
    RESOURCE_TYPE: '08CJJLR',
    BIZ_ID: 'lsypjg'
  }
  EXCEPTIONS_UPLOAD = {
    BIZ_ID: 'super-manager'
  }
  Current_Store_Type = ''
  USER_INFO = {
    uid: '',
    phone: '',
    companyName: '',
    mobileId: '',
    AppSource: '' as EDeviceType,
    isPhoneRoot: false,
    onVirtual: false,
    latitude: '',
    longitude: '',
    street: '',
    AddrStr: '',
    city: '',
    country: '',
    roleList: [],
    selectRole: {
      roleCode: '',
      roleName: '',
      dataDimension: '',
      roleStatus: ''
    },
    selectStoreType: '',
    duty: '',
    dutyDesc: '',
    position: '',
    sliceAreaList: [],
    status: '',
    regionName: '',
    regionCode: ''
  }
  AMAP_KEY = '709309ab6e53b4b179bb3802805ef411'
  AMAP_JS_CODE = 'eddc1bfc79530eeeebc79068a55fb1cf'
  saveSearchStore = {}
  mpAppid = ''
  MAP_TYPE = ''
}
